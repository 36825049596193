export const ALERT_TYPE_OPTIONS = [
  { value: 'Notification', label: 'Notification' },
  { value: 'Mark as urgent', label: 'Mark as Urgent' },
];

export const ALERT_UNIT_OPTIONS = [
  { value: 'hours.before', label: 'Hours (before)' },
  { value: 'hours.after', label: 'Hours (after)' },
  { value: 'minutes.before', label: 'Mins (before)' },
  { value: 'minutes.after', label: 'Mins (after)' },
];

export const AGING_ALERT_UNIT_OPTIONS = [
  { value: 'hrs.before', label: 'Hours (before)' },
  { value: 'hrs.after', label: 'Hours (after)' },
  { value: 'min.before', label: 'Mins (before)' },
  { value: 'min.after', label: 'Mins (after)' },
];

export const ALERT_BASED_ON_OPTIONS = [
  { label: 'Created at', value: 'createdAt' },
  { label: 'Due date', value: 'dueDate' },
];
