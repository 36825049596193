import { AvailabilityWorkHours } from './availability.types';

export const getAvailableDays = (workHorus?: AvailabilityWorkHours) => {
  const result: { [key: string]: boolean } = {};
  if (workHorus) {
    for (const key in workHorus) {
      if (workHorus.hasOwnProperty(key)) {
        result[key] = !!workHorus[key].length;
      }
    }
  }
  return result;
};
