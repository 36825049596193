import { useEffect, useState } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import Loader from 'components/common/Loader';
import { getIsNotSubmitted } from 'components/patient/Overview/PatientInfo/IdentityField/identityField.settings';
import IdentityGallery from 'components/tasks/slidingPane/IdentityGallery';
import { getVerifyTagsProps } from 'components/tasks/slidingPane/IdentitySection/identitySection.settings';
import { useAppDispatch } from 'hooks/redux';
import { ManualVerifyStatus, ManualVerifyStatusProps } from 'models/patientIdentity.types';
import { Identity } from 'models/tasks.types';
import { closeModal } from 'store/modal/modalSlice';
import {
  useLazyDownloadIdentityJobsQuery,
  useUpdateVerifyIdentityMutation,
} from 'store/patientIdentity/patientIdentitySlice';
import { useLazyGetPatientQuery } from 'store/patients/patientsSlice';

import { IdentityModalProps } from './identityModal.types';

const IdentityModal: React.FC<IdentityModalProps> = ({ identity, patientId }) => {
  const dispatch = useAppDispatch();
  const [identityInfo, setIdentityInfo] = useState<Identity>();
  const [updateVerifyIdentity, { isLoading }] = useUpdateVerifyIdentityMutation();
  const [downloadIdentityJobs, { isLoading: isDownloading, isFetching }] = useLazyDownloadIdentityJobsQuery();
  const [getPatient, { isLoading: isGettingPatient }] = useLazyGetPatientQuery();
  const isNotSubmitted = getIsNotSubmitted(identity);
  const verifyTagsProps = !!identityInfo && getVerifyTagsProps(identityInfo);

  const crosscheckVerified = identity.crosscheckVerified;
  const crosscheckJobId = identity.crosscheckJobId;
  const disableSubmit = !identityInfo?.governmentIssuedId || !identityInfo?.selfPortrait;
  const showLoader = isLoading || isDownloading || isFetching || isGettingPatient;

  const handleChangeIdentity = (newIdentityInfo: Partial<Identity>) => {
    setIdentityInfo((prevIdentityInfo) => ({ ...prevIdentityInfo, ...newIdentityInfo }));
  };

  const handleVerifiedIdentity = (manualVerifyStatus: ManualVerifyStatusProps | null) => {
    updateVerifyIdentity({ patientId, manualVerifyStatus })
      .unwrap()
      .then(() => {
        handleChangeIdentity({ manualVerifyStatus });
        dispatch(closeModal());
        getPatient({ id: patientId });
      });
  };

  const handleViewInVouched = () => {
    const jobId = identityInfo?.crosscheckJobId;
    if (jobId) {
      downloadIdentityJobs({ jobId })
        .unwrap()
        .then((response) => {
          window.open(window.URL.createObjectURL(response));
        });
    }
  };

  useEffect(() => {
    setIdentityInfo(identity);
  }, [identity]);

  return (
    <div className="p-6">
      <Loader isVisible={showLoader} />
      <h2 className="text-xl font-bold text-gray-700">Identity verification</h2>
      {!isNotSubmitted && verifyTagsProps && (
        <div className="mt-2 flex flex-wrap gap-4 font-semibold">
          {verifyTagsProps.map((tagProps) => (
            <Common.ColorTag {...tagProps} key={tagProps.text} />
          ))}
        </div>
      )}

      <IdentityGallery
        patientId={patientId}
        identity={identityInfo}
        handleChangeIdentity={handleChangeIdentity}
        shouldShowIdentity={!crosscheckVerified}
        className="mt-6 flex justify-center gap-2"
      />

      <div className="mt-6 grid w-full grid-cols-2 gap-x-2">
        {isNotSubmitted ? (
          <Common.Button
            className="col-span-2 flex justify-self-end"
            color="blue"
            size="sm"
            disabled={disableSubmit}
            onClick={() => handleVerifiedIdentity(ManualVerifyStatus.verified)}
          >
            Submit
          </Common.Button>
        ) : (
          <>
            <Common.Button
              className="flex w-full justify-center"
              color="red"
              size="sm"
              onClick={() => handleVerifiedIdentity(ManualVerifyStatus.unverified)}
            >
              Mark as unverified
            </Common.Button>
            <Common.Button
              className="flex w-full justify-center"
              color="white-alt"
              size="sm"
              preIcon="check"
              onClick={() => handleVerifiedIdentity(ManualVerifyStatus.verified)}
            >
              Mark as verified
            </Common.Button>
          </>
        )}
        {crosscheckVerified && crosscheckJobId && (
          <Common.Button
            className="col-span-2 mt-2 flex w-full justify-center"
            style="text-only"
            size="sm"
            onClick={handleViewInVouched}
          >
            View in vouched
          </Common.Button>
        )}
      </div>
    </div>
  );
};

export default IdentityModal;
