import { OptionProps } from '@thecvlb/design-system/lib/src/common/Select';
import { PersonalInfo } from 'models/tasks.types';
import { PatientProps } from 'store/patients/patients.types';

export interface CancelPlanProps {
  patientInfo?: PatientProps | PersonalInfo;
  isAutoRenew?: boolean;
}

export enum CancellationSteps {
  CancelPlan = 'Cancel plan',
  ChooseReason = 'Choose reason',
  CancelAppointments = 'Cancel appointments',
  Confirmation = 'Confirmation',
}

export enum CancellationType {
  CancelAutoRenew = 'Cancel auto-renew',
  CancelAccountAndRefundPatient = 'Cancel account & refund patient',
  DowngradeRefundPatient = 'Downgrade & refund patient',
}

export interface CancelPlanFormData {
  cancelReason: OptionProps;
  receivedManagerApproval: boolean;
  refundAmount?: number;
  details?: string;
}
