import { Common } from '@thecvlb/design-system/lib/src';
import { ManualVerifyStatus } from 'models/patientIdentity.types';
import { Identity } from 'models/tasks.types';

import { IdentityDocumentFileData } from './identitySection.types';

export const getStaffVerifyTagProps = (identity: Identity): Common.ColorTagProps | undefined => {
  if (identity.manualVerifyStatus === ManualVerifyStatus.verified) {
    return { icon: 'check', color: 'secondary', text: 'Verified by staff' };
  } else if (identity.manualVerifyStatus === ManualVerifyStatus.unverified) {
    return { icon: 'close', color: 'red', text: 'Unverified' };
  }
};

export const getVouchedVerifyTagsProps = (identity: Identity): Common.ColorTagProps[] | undefined => {
  if (!identity.manualVerifyStatus) {
    if (identity.isVerifiedByVouched) {
      return [{ icon: 'check', color: 'secondary', text: 'Verified by Vouched' }];
    } else {
      const verifiedByVouchedError = identity.errors?.filter((value, index, errors) => {
        return errors.findIndex((error) => error.message === value.message) === index;
      });
      if (!verifiedByVouchedError) {
        return;
      } else {
        return verifiedByVouchedError.map((error) => ({
          icon: 'close',
          color: 'red',
          text: `Vouched error: ${error?.message}`,
        }));
      }
    }
  }
};

export const getVerifyTagsProps = (identity: Identity): Common.ColorTagProps[] => {
  const vouchedVerifyTagsProps = getVouchedVerifyTagsProps(identity);
  if (vouchedVerifyTagsProps && !identity.manualVerifyStatus && !identity.crosscheckVerified) {
    return vouchedVerifyTagsProps;
  } else if (identity.manualVerifyStatus === ManualVerifyStatus.verified) {
    return [{ icon: 'check', color: 'secondary', text: 'Verified by staff' }];
  } else if (identity.crosscheckVerified) {
    return [{ icon: 'check', color: 'secondary', text: 'Verified with contact info' }];
  } else return [{ icon: 'close', color: 'red', text: 'Unverified' }];
};

export const getFilesData = (identity: Identity): IdentityDocumentFileData[] =>
  identity.selfPortrait && identity.governmentIssuedId
    ? [
        {
          ...identity.selfPortrait,
          label: 'Photo:',
        },
        {
          ...identity.governmentIssuedId,
          label: 'ID:',
        },
      ]
    : [];
