import { useEffect } from 'react';

import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import Loader from 'components/common/Loader';
import { notifyError, notifySuccess } from 'components/common/Toast/Toast';
import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import InputField from 'components/forms/controlled/InputField';
import { PatientLabels } from 'enums/orderLabels';
import { useForm } from 'react-hook-form';
import { useToggle } from 'react-use';
import { useUpdateOrderMutation } from 'store/orders/ordersSlice';

import { getItems, getSubmitOrderData, states } from './patientInfo.settings';
import { OrderFormStateProps, PatientInfoProps } from './patientInfo.types';

const PatientInfo: React.FC<PatientInfoProps> = ({
  order,
  patient,
  wrapperClassNames,
  labelClassNames,
  headingClassNames,
  isEditable,
}) => {
  const [isEditing, setIsEditing] = useToggle(false);
  const [updateOrder, { isLoading: isLoadingEdit }] = useUpdateOrderMutation();

  const { handleSubmit, control, reset } = useForm<OrderFormStateProps>({
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    mode: 'onChange',
  });

  useEffect(() => {
    if (typeof order.patient !== 'string') {
      reset({
        ...order.patient.address,
        state: states.find((c) => {
          if (typeof order.patient === 'string') return false;
          return c.value.toLocaleLowerCase() === order.patient.address?.state?.toLocaleLowerCase();
        }),
      });
    }
  }, [reset, order]);

  const handleSave = async (formData: OrderFormStateProps) => {
    const hasRequiredData = (o: {
      line1: string;
      city: string;
      state: { label: string; value: string };
      postal: string;
    }) => {
      if (!o.line1) return false;
      if (!o.city) return false;
      if (!o.state) return false;
      if (!o.postal) return false;

      return true;
    };

    if (hasRequiredData(formData)) {
      const submitOrder = getSubmitOrderData(formData);

      if (submitOrder) {
        if (typeof order.patient === 'string') return;
        updateOrder({
          orderId: order.id,
          order: { patient: { ...order.patient, address: { ...formData, state: formData.state?.label } } },
        })
          .unwrap()
          .then((data) => {
            notifySuccess(data.message || 'Successfully updated order');
            setIsEditing(false);
          })
          .catch((error) => {
            notifyError(error.data?.message);
          });
      }
    } else {
      notifyError('Missing required data');
    }
    return;
  };

  const EditButtons = () => (
    <div className="mb-4">
      {isEditing && (
        <div className="flex">
          <Common.Button
            color="white-alt"
            size="sm"
            className="mr-2"
            onClick={() => setIsEditing(false)}
            preIconClassName="shrink-0"
          >
            <span className="whitespace-normal text-left font-semibold">Cancel</span>
          </Common.Button>
          <Common.Button disabled={isLoadingEdit} color="blue" size="sm" className="" preIconClassName="shrink-0">
            <span className="whitespace-normal text-left font-semibold">Save</span>
          </Common.Button>
        </div>
      )}
      {!isEditing && (
        <div>
          <Common.Button
            color="green"
            size="sm"
            preIcon="pencil"
            className=""
            onClick={() => setIsEditing(true)}
            preIconClassName="shrink-0"
          >
            <span className="whitespace-normal text-left font-semibold">Edit</span>
          </Common.Button>
        </div>
      )}
    </div>
  );

  const headingClasses = classNames(headingClassNames || 'mb-2 text-base font-bold text-gray-700');
  const wrapperClasses = classNames(wrapperClassNames || 'grid grid-cols-2 gap-x-6 gap-y-2');
  const labelClasses = classNames('text-base mr-5 min-w-fit flex-none text-gray', labelClassNames || 'w-28');

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <Loader isVisible={isLoadingEdit} />
      <div className="flex justify-between">
        <h4 className={headingClasses}>Patient info</h4>
        {isEditable && <EditButtons />}
      </div>
      <div className={wrapperClasses}>
        {getItems(patient).map((el) => {
          if (!isEditing) {
            return (
              <div data-testid="personal_info_data_field" key={el.label} className="flex self-start">
                <p className={labelClasses}>{el.label}</p>
                <p className={classNames('text-base text-gray-700', { underline: el.isUnderline })}>{el.value}</p>
              </div>
            );
          }

          if (el.label === PatientLabels.Address) {
            return (
              <div data-testid="personal_info_data_field" key={el.label}>
                <p className={labelClasses}>{el.label}</p>

                <div key={el.label} className="max-w-[420px]">
                  <div className="mb-2">
                    <p className="mb-2 text-base text-gray-700">Street address</p>
                    <InputField control={control} name={'line1'} className="w-full" />
                  </div>
                  <div className="mb-2 flex gap-x-4">
                    <div>
                      <p className="mb-2 text-base text-gray-700">City</p>
                      <InputField control={control} name={'city'} />
                    </div>
                    <div>
                      <p className="mb-2 text-base text-gray-700">State</p>
                      <ControlledSelect control={control} options={states} name={'state'} placeholder="Select..." />
                    </div>
                    <div>
                      <p className="mb-2 text-base text-gray-700">ZIP code</p>
                      <InputField control={control} name={'postal'} type="number" />
                    </div>
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <div data-testid="personal_info_data_field" key={el.label} className="flex self-baseline">
                <p className={labelClasses}>{el.label}</p>
                <p className={classNames('text-base text-gray-700', { underline: el.isUnderline })}>{el.value}</p>
              </div>
            );
          }
        })}
      </div>
    </form>
  );
};

export default PatientInfo;
