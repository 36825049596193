import { createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper<{ key: string; value: string }>();
export const columns = [
  columnHelper.accessor('key', {
    header: () => <span className="flex items-center text-base font-bold">Key</span>,
    cell: (info) => <p className="text-base first-letter:uppercase">{info.getValue()}</p>,
  }),
  columnHelper.accessor('value', {
    header: () => <span className="flex items-center text-base font-bold">Value</span>,
    cell: (info) => (
      <div className="lg:w-[768px] xl:w-[960px] 2xl:w-[1200px]">
        <span className="overflow-auto break-words text-sm font-medium">{info.getValue()}</span>
      </div>
    ),
  }),
];
