import { ROUTE_TO_OPTIONS } from 'constants/staff';
import { STATUS_OPTIONS } from 'constants/status';

import { useEffect } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import { notifySuccess } from 'components/common/Toast/Toast';
import ControlledMultiSelect from 'components/forms/controlled/ControlledMultiSelect';
import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import InputField from 'components/forms/controlled/InputField';
import PopupFooter from 'components/modals/components/PopupFooter';
import PopupHeader from 'components/modals/components/PopupHeader';
import { useAppSelector } from 'hooks/redux';
import startCase from 'lodash/startCase';
import queryString from 'query-string';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { resetBulkEdits, selectBulkEdit } from 'store/bulkEdit/bulkEditSlice';
import {
  useCreateFrontDeskRequestTypeMutation,
  useDeleteFrontDeskRequestTypesMutation,
  useLazyGetFrontDeskRequestTypeQuery,
  useLazyGetFrontDeskRequestTypesQuery,
  useUpdateFrontDeskRequestTypesMutation,
} from 'store/frontDeskRequestTypes/frontDeskRequestTypesSlice';
import { closeModal, openModal } from 'store/modal/modalSlice';
import { selectTriggers, useGetTriggersQuery } from 'store/triggers/triggersSlice';
import { validation } from 'utils/helpers';
import { ANY_CHARACTER_REGEXP } from 'utils/regExp';

import { PhysicianRequestFormData, PhysicianRequestTypesProps } from './physicianRequestTypes.types';
import UpdateFailed from '../bulkEditModals/UpdateFailed';

const selectPhysicianRequestTypesState = createSelector([selectBulkEdit, selectTriggers], (bulkEdit, triggers) => ({
  options: triggers.options,
  bulkEdits: bulkEdit.bulkEdits,
}));

const PhysicianRequestTypes: React.FC<PhysicianRequestTypesProps> = ({ id, isBulkEdit = false }) => {
  const dispatch = useDispatch();
  const [getSingleType, { data: singleTypeData, isLoading }] = useLazyGetFrontDeskRequestTypeQuery();
  const [addFrontDeskRequestType, { isLoading: loadingCreate }] = useCreateFrontDeskRequestTypeMutation();
  const [updateFrontDeskRequestTypes, { isLoading: loadingEdits }] = useUpdateFrontDeskRequestTypesMutation({
    fixedCacheKey: 'frontDeskRequestsTypes',
  });
  const [deleteFrontDeskRequestTypes, { isLoading: loadingDeletes }] = useDeleteFrontDeskRequestTypesMutation();
  useGetTriggersQuery({ params: { status: 'active' } });
  const [getFrontDesk] = useLazyGetFrontDeskRequestTypesQuery();
  const { options, bulkEdits } = useAppSelector(selectPhysicianRequestTypesState);
  const { control, handleSubmit, formState, reset } = useForm<PhysicianRequestFormData>({ reValidateMode: 'onChange' });

  const itemsLabel = `${bulkEdits.length} ${bulkEdits.length === 1 ? 'item' : 'items'}`;

  const isDisabled = isLoading || loadingCreate || loadingEdits || loadingDeletes;

  const onSuccess = (message: string) => {
    notifySuccess(message);
    getFrontDesk({ params: queryString.parse(location.search) });
    dispatch(closeModal());
  };

  const onFormSubmit = (formData: PhysicianRequestFormData) => {
    if (formData) {
      const body = {
        requestType: formData.request,
        routeTo: formData.routeTo?.value,
        triggerId: formData.automation?.map((item) => item.value),
        status: formData.status?.value,
      };

      if (id || isBulkEdit) {
        const updateData = {
          ...body,
          requestIds: id ? [id] : bulkEdits,
        };
        updateFrontDeskRequestTypes(updateData)
          .unwrap()
          .then((data) => {
            if (data.data?.failed.length) {
              dispatch(
                openModal({
                  size: 'sm',
                  hideClose: true,
                  modalContent: <UpdateFailed requestBody={updateData} />,
                }),
              );
            } else {
              onSuccess(data.message);
              dispatch(resetBulkEdits());
              dispatch(closeModal());
            }
          });
      } else {
        addFrontDeskRequestType(body)
          .unwrap()
          .then((data) => onSuccess(data.message));
      }
    }
  };

  const onRemoveType = () => {
    if (id || isBulkEdit) {
      deleteFrontDeskRequestTypes({ body: { requestIds: id ? [id] : bulkEdits } })
        .unwrap()
        .then((data) => {
          onSuccess(data.message);
          dispatch(closeModal());
        });
    }
  };

  useEffect(() => {
    if (singleTypeData) {
      reset({
        request: singleTypeData.requestType,
        routeTo: { value: singleTypeData.routeTo, label: singleTypeData.routeTo },
        automation: singleTypeData.triggers.map((item) => ({ value: item._id, label: item.name })),
        status: { value: singleTypeData.status, label: startCase(singleTypeData.status) },
      });
    }
  }, [reset, singleTypeData]);

  useEffect(() => {
    if (id) {
      getSingleType({ id });
    }
  }, [getSingleType, id]);

  const popupClassnames = classNames({ 'p-8': !isBulkEdit });

  return (
    <div data-testid="front_desk_request_type_popup" className={popupClassnames}>
      {!isBulkEdit && <PopupHeader title="Physician request type" id={id} />}
      {isBulkEdit && <PopupHeader title={`${itemsLabel} selected`} />}

      <form onSubmit={handleSubmit(onFormSubmit)} className="flex flex-col gap-4">
        {!isBulkEdit && (
          <InputField
            dataTestId="request_type_field"
            name="request"
            placeholder="Enter name..."
            label="Request"
            control={control}
            labelDirection="row"
            type="text"
            errors={formState.errors.request}
            rules={validation('Name', ANY_CHARACTER_REGEXP)}
          />
        )}
        <ControlledSelect
          dataTestId="route_to_field"
          control={control}
          labelDirection="row"
          options={ROUTE_TO_OPTIONS}
          placeholder="Select route to..."
          label="Route to"
          name="routeTo"
          rules={validation('Route to', null, null, isBulkEdit)}
        />
        <ControlledMultiSelect
          control={control}
          label="Automations"
          name="automation"
          placeholder="Select automation..."
          options={options}
          labelDirection="row"
          errors={formState.errors.automation}
          rules={validation('Automations', null, null, isBulkEdit)}
        />
        <ControlledSelect
          dataTestId="status_field"
          control={control}
          labelDirection="row"
          options={STATUS_OPTIONS}
          placeholder="Select status..."
          label="Status"
          name="status"
          rules={validation('Status', null, null, isBulkEdit)}
        />

        <PopupFooter
          hiddenDeleteButton={!id && !isBulkEdit}
          deleteButtonText={`Delete ${isBulkEdit ? itemsLabel : ''}`}
          onRemove={onRemoveType}
          disabled={isDisabled}
        />
      </form>
    </div>
  );
};

export default PhysicianRequestTypes;
