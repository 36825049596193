export enum Gender {
  Man = '0',
  Woman = '1',
  TransgenderMan = '2',
  TransgenderWoman = '3',
  NonBinary = '4',
  TwoSpirit = '5',
  NotListed = '6',
  PreferToHide = '7',
  Unknown = '8',
}

export enum SexAtBirth {
  Male = '0',
  Female = '1',
  Other = '2',
  Unknown = '3',
}
