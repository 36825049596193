import Skeleton from 'react-loading-skeleton';

const ChannelSkeleton = () => {
  return (
    <div className="w-screen-xs">
      <div className="relative flex flex-row pr-4">
        <div className="ml-10 mr-4 w-full gap-1 truncate border-b border-gray-200 py-4">
          <div className="flex w-full justify-between">
            <p data-testid="channel" className="text-left text-base font-bold">
              <Skeleton className="w-36" />
            </p>
            <p className="truncate text-sm text-gray-500">
              <Skeleton className="w-12" />
            </p>
          </div>
          <p data-testid="last_channel_msg" className="mt-1 truncate text-left text-base text-gray-500">
            <Skeleton className="w-32" />
          </p>
        </div>
      </div>
    </div>
  );
};

export default ChannelSkeleton;
