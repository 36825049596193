import { useCallback, useEffect } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';
import Alert from 'components/common/Alert';
import Loader from 'components/common/Loader';
import dayjs from 'dayjs';
import { useAppDispatch } from 'hooks/redux';
import { closeModal } from 'store/modal/modalSlice';
import { useCreateNewTaskMutation } from 'store/tasks/tasksSlice';

import { PrescribeCompoundResultProps } from './prescribeCompoundResult.types';
import ResultStatusPoint from './ResultStatusPoint/ResultStatusPoint';

const PrescribeCompoundResult: React.FC<PrescribeCompoundResultProps> = ({ prescribeResult, requestDetails }) => {
  const dispatch = useAppDispatch();
  const [createNewTask, { isLoading: isLoadingCreateRequest, data: createdTask }] = useCreateNewTaskMutation();

  const showLoader = isLoadingCreateRequest;
  const createdTaskId = createdTask?.data?.id;

  const isPrescriptionCreated = prescribeResult.prescription.success;

  const iconBackgroundClasses = classNames('flex justify-center items-center w-28 h-28 rounded-full p-8', {
    'bg-red-100': !isPrescriptionCreated,
    'bg-secondary-100': isPrescriptionCreated,
  });

  const iconName = isPrescriptionCreated ? 'check' : 'close';
  const iconClasses = classNames('h-9 w-9', {
    'text-red-500': !isPrescriptionCreated,
    'text-secondary-500': isPrescriptionCreated,
  });

  const handleCreateTaskForMA = useCallback(() => {
    if (!createdTaskId) {
      createNewTask({
        body: {
          patientId: requestDetails?.patientId || '',
          note: `Prescription payment failed. Please follow up with patient to create payment.  \nAmount: ${requestDetails?.paymentAmount} \n Prescription: ${requestDetails?.prescription}`,
          audience: 'CS',
          ...(requestDetails?.parentTaskId && { parentTaskId: requestDetails?.parentTaskId }),
          elationPrescriptionId: prescribeResult.prescription.elationId,
          timezone: dayjs.tz.guess(),
        },
      });
    }
  }, [
    createNewTask,
    prescribeResult.prescription.elationId,
    requestDetails?.parentTaskId,
    requestDetails?.patientId,
    requestDetails?.paymentAmount,
    requestDetails?.prescription,
    createdTaskId,
  ]);

  useEffect(() => {
    if ('success' in prescribeResult.payment && !createdTaskId) {
      const paymentRequestTaskId = prescribeResult?.payment?.requestTaskId;
      if (!prescribeResult?.payment?.success && prescribeResult?.prescription?.success && !paymentRequestTaskId) {
        handleCreateTaskForMA();
      }
    }
    // we don't need check whole prescribeResult.payment object in dependency array
  }, [
    prescribeResult?.payment?.success,
    prescribeResult?.prescription?.success,
    prescribeResult?.payment?.requestTaskId,
    handleCreateTaskForMA,
    createdTaskId,
  ]);

  return (
    <div data-testid="prescribe_compaund_result_popup" className="p-6">
      {showLoader && <Loader isVisible />}
      <div className="mb-6 flex flex-col items-center gap-8 py-4">
        <div className={iconBackgroundClasses}>
          <Common.Icon name={iconName} className={iconClasses} />
        </div>
        <div className="flex w-full flex-col items-center gap-4 text-base font-medium text-gray-700">
          <ResultStatusPoint
            dataTestId="prescription_result"
            type="prescription_status"
            isSuccess={isPrescriptionCreated}
          />
          <ResultStatusPoint
            dataTestId="prescription_not_signed"
            type="prescription_not_signed"
            isSuccess={isPrescriptionCreated}
          />
        </div>
        {isPrescriptionCreated && (
          <Alert
            type="info"
            children={
              <p className="ml-1 text-base font-medium">Please remember to sign this prescription in Elation.</p>
            }
          />
        )}
      </div>
      <Common.Button
        color={'blue'}
        className="flex w-full justify-center"
        onClick={() => dispatch(closeModal())}
        size="sm"
      >
        Close
      </Common.Button>
    </div>
  );
};

export default PrescribeCompoundResult;
