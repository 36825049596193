import { LENGTH_OPTIONS, LENGTH_OPTIONS_WITH_DEFAULT } from 'constants/appointmentType';

import { AppointmentTypeLengths, LengthByPlan } from 'store/appointments/appointments.types';

export const returnLengthOption = (
  lengths: LengthByPlan[],
  index: number,
  field: 'initial' | 'subsequent',
  isEditDefault?: boolean,
) => {
  const lengthOptions = isEditDefault ? LENGTH_OPTIONS : LENGTH_OPTIONS_WITH_DEFAULT;
  const length = lengths[index]?.appointmentLengths?.[field]?.length;

  return !length ? lengthOptions[0] : lengthOptions.find((item) => item.value === length.toString());
};

export const getDefaultLengthsData = (
  appointmentLengthsData: AppointmentTypeLengths[],
  watchLengthByPlan: LengthByPlan[],
  isEditDefault?: boolean,
) => {
  const hasLengthByPlan =
    watchLengthByPlan?.length && !isEditDefault && watchLengthByPlan?.length !== appointmentLengthsData?.length;

  return appointmentLengthsData.map((item) => {
    if (hasLengthByPlan) {
      const editableElement = watchLengthByPlan.find((length) => length.planId === item._id);
      if (editableElement) return editableElement;
    }

    return {
      planId: item._id,
      appointmentLengths: {
        initial: {
          isDefault: true,
          length: isEditDefault ? item.appointmentLengths.initial.length : 0,
          label: item.appointmentLengths.initial.label,
        },
        subsequent: {
          isDefault: true,
          length: isEditDefault ? item.appointmentLengths.subsequent.length : 0,
          label: item.appointmentLengths.subsequent.label,
        },
      },
    };
  });
};
