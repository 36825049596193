export enum ReviewStatuses {
  New = 'new',
  Published = 'published',
  Hidden = 'hidden',
}

export interface ReviewProps {
  _id: string;
  patientInfo: {
    name: string;
    dob: string;
  };
  providerInfo: {
    name: string;
  };
  rating: number;
  createdAt: string;
  reviewComment: string;
  status: ReviewStatuses.New | ReviewStatuses.Published | ReviewStatuses.Hidden;
}
export interface ReviewResponseProps {
  data: {
    prevReview: ReviewProps;
    review: ReviewProps;
  };
  info: {
    nextReviewId: string;
    prevReviewId: string;
  };
}

export interface ReviewsProps {
  data: ReviewProps[];
  info: {
    totalCount: number;
    counter: {
      new: number;
      hidden: number;
      published: number;
      total: number;
    };
  };
}

export interface ReviewsQueryParams {
  limit?: number;
  pageNo?: number;
  sortField?: string;
  sortOrder?: string;
  searchKey?: string;
  filter?: string;
  status?: string;
  localTz?: string;
}

export const initialState: ReviewsProps = {
  data: [
    {
      _id: '',
      patientInfo: {
        name: '',
        dob: '',
      },
      providerInfo: {
        name: '',
      },
      rating: 0,
      createdAt: '',
      reviewComment: '',
      status: ReviewStatuses.New,
    },
  ],
  info: {
    totalCount: 0,
    counter: {
      new: 0,
      hidden: 0,
      published: 0,
      total: 0,
    },
  },
};
