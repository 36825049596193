import { useEffect } from 'react';

import { routes } from 'config/routes/protectedRoutes';
import { useAppSelector } from 'hooks/redux';
import { useDispatch } from 'react-redux';
import { selectSystem, setStartLocation } from 'store/system/systemSlice';
import { selectUser } from 'store/user/userSlice';
import { checkIsProtectedRoute } from 'utils/router';

// This hook is used to store the location the user started from before they logged in.
// This is used to redirect the user to the previous location after they log in.
// This hook is used in the App component.
// This should NOT be used if the user is logged in or if the user logs out manually.

const useStartLocation = () => {
  const dispatch = useDispatch();
  const { accessToken } = useAppSelector(selectUser);
  const { wasLoggedOutManually, startLocation } = useAppSelector(selectSystem);
  const { pathname, search } = window.location;
  const isProtectedRoute = checkIsProtectedRoute(pathname, routes);

  useEffect(() => {
    if (!startLocation && !accessToken && isProtectedRoute && !wasLoggedOutManually) {
      dispatch(setStartLocation({ pathname, search }));
    }
  }, [dispatch, startLocation, accessToken, isProtectedRoute, pathname, search, wasLoggedOutManually]);
};

export default useStartLocation;
