import { useFlag } from '@unleash/proxy-client-react';
import { PathName } from 'enums/pathName';
import groupBy from 'lodash/groupBy';
import { adminLinks } from 'pages/Admin/admin.settings';

export const useFilterAdminPageLinks = () => {
  const isRequestMessageTemplateDisabled = useFlag('hide-request-message-templates');

  let filteredLinks = [...adminLinks];

  if (isRequestMessageTemplateDisabled) {
    filteredLinks = filteredLinks.filter((link) => link.link !== PathName.RequestMessageTemplates);
  }

  const groupedAdminLinks = groupBy(filteredLinks, 'tag');

  return groupedAdminLinks;
};
