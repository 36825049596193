import { FRONTEND_DATE_FORMAT } from 'constants/dateFormat';
import { GENDER_OPTIONS } from 'constants/user';

import { MouseEvent, useEffect, useMemo, useState } from 'react';

import { Common } from '@thecvlb/design-system';
import DatePickerInput from 'components/common/DatePickerInput';
import PhoneInput from 'components/common/form/PhoneInput';
import ControlledMultiSelect from 'components/forms/controlled/ControlledMultiSelect';
import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import InputField from 'components/forms/controlled/InputField';
import UpdateEmail from 'components/modals/updateEmail';
import dayjs from 'dayjs';
import { useAppDispatch } from 'hooks/redux';
import { Controller, useFormContext } from 'react-hook-form';
import { openModal } from 'store/modal/modalSlice';
import { useLazyGetTeamsQuery } from 'store/teams/teamsSlice';
import { validation } from 'utils/helpers';
import { EMAIL_REGEXP, NAME_REGEXP_EDIT_PROFILE, PREFIX_REGEXP } from 'utils/regExp';

import { MainFormComponentsProps } from './mainFormComponents.types';

const MainFormComponents: React.FC<MainFormComponentsProps> = ({
  labelClasses,
  canChangeEmail,
  isMyProfile,
  licensedTeams,
}) => {
  const dispatch = useAppDispatch();
  const { control, formState, getValues } = useFormContext();
  const [selected, setSelected] = useState<Date>();
  const [getTeams, { data: teamsData }] = useLazyGetTeamsQuery();

  const userEmail = getValues('email');

  const teamOptions = useMemo(
    () => teamsData?.teams.map((team) => ({ label: team.name, value: team._id })),
    [teamsData],
  );

  const handleEmailUpdate = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatch(
      openModal({
        size: 'sm',
        modalContent: <UpdateEmail />,
        hideClose: true,
      }),
    );
  };

  useEffect(() => {
    if (!isMyProfile) {
      getTeams({});
    }
  }, []);

  return (
    <>
      <InputField
        name="firstName"
        label="First name"
        control={control}
        placeholder="Enter first name..."
        labelDirection="row"
        type="text"
        errors={formState.errors.firstName}
        rules={validation('First name', NAME_REGEXP_EDIT_PROFILE)}
      />
      <InputField
        name="lastName"
        label="Last name"
        control={control}
        labelDirection="row"
        placeholder="Enter last name..."
        type="text"
        errors={formState.errors.lastName}
        rules={validation('Last name', NAME_REGEXP_EDIT_PROFILE)}
      />
      <InputField
        name="prefix"
        label="Prefix"
        control={control}
        labelDirection="row"
        type="text"
        placeholder="Enter prefix..."
        errors={formState.errors.prefix}
        rules={validation('Prefix', PREFIX_REGEXP, null, true)}
      />
      <InputField
        name="displayName"
        label="Display name"
        control={control}
        labelDirection="row"
        placeholder="Enter display name..."
        type="text"
        errors={formState.errors.displayName}
        rules={validation('Display name', NAME_REGEXP_EDIT_PROFILE)}
      />

      <ControlledSelect
        dataTestId="gender_dropdown"
        control={control}
        labelDirection="row"
        options={GENDER_OPTIONS}
        placeholder="Select gender..."
        label="Gender"
        name="gender"
        rules={validation('Gender')}
      />

      <Controller
        control={control}
        name="dob"
        rules={{
          required: {
            value: true,
            message: 'Invalid date',
          },
        }}
        render={({ field }) => (
          <DatePickerInput
            dataTestId="dob_field"
            placeholder={'Select date'}
            label="Birthdate"
            labelDirection="row"
            size="sm"
            inputValue={(() => {
              const dobDate = getValues('dob');
              return dobDate ? dayjs(dobDate).format(FRONTEND_DATE_FORMAT) : dobDate;
            })()}
            setInputValue={field.onChange}
            selectedDate={selected}
            setSelectedDate={setSelected}
            startAllowedDate={dayjs().subtract(150, 'year').toDate()}
            lastAllowedDate={new Date()}
            captionLayout={'dropdown'}
            wrapperClasses="w-full relative"
            errors={formState.errors[field.name]}
          />
        )}
      />

      {isMyProfile ? (
        <div className="flex items-baseline gap-1">
          <span className="min-w-[120px] text-sm font-semibold text-gray-700">Email</span>
          <div className="flex flex-col">
            {userEmail && (
              <span data-testid="user_email" className="mb-3 w-full truncate text-sm font-medium underline">
                {userEmail}
              </span>
            )}
            <Common.Button dataTestId="update_email_btn" size="sm" color="white-alt" onClick={handleEmailUpdate}>
              Update
            </Common.Button>
          </div>
        </div>
      ) : (
        <InputField
          name="email"
          label="Email"
          control={control}
          labelDirection="row"
          placeholder="Enter email..."
          type="text"
          errors={formState.errors.email}
          rules={validation('Email', EMAIL_REGEXP)}
          disabled={!canChangeEmail}
        />
      )}

      <PhoneInput
        name="phone"
        control={control}
        labelTitle="Phone"
        placeholder="Enter phone..."
        globalFormState={formState}
        labelClasses={labelClasses}
        inputClasses={`w-full pl-4 py-3 rounded-md placeholder-text text-sm`}
      />

      {isMyProfile ? (
        <>
          {!!licensedTeams?.length && (
            <div className="flex items-baseline gap-1">
              <div className="min-w-[120px] text-sm font-semibold">Teams</div>
              <div className="flex flex-wrap gap-2">
                {licensedTeams.map((state) => (
                  <Common.ColorTag dataTestId="licensed_in_state" color="gray" key={state.teamId} text={state.name} />
                ))}
              </div>
            </div>
          )}
        </>
      ) : (
        <ControlledMultiSelect
          control={control}
          label="Teams"
          name="teams"
          placeholder="Select teams..."
          options={teamOptions}
          labelDirection="row"
        />
      )}
    </>
  );
};

export default MainFormComponents;
