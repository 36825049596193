export const formatPaymentMethod = (cardNumber?: string, cardType?: string) => {
  const amexCardNumbers = ['4', '7'];
  if (!cardNumber && !cardType) {
    return 'none';
  }
  if (cardNumber) {
    switch (cardNumber[0]) {
      case '3':
        if (amexCardNumbers.includes(cardNumber[1])) {
          return 'american_express';
        }
        return 'none';
      case '4':
        return 'visa';
      case '5':
        return 'master_card';
      case '6':
        return 'discover';

      default:
        return 'none';
    }
  } else {
    switch (cardType?.toLowerCase()) {
      case 'visa':
        return 'visa';
      case 'master card':
        return 'master_card';
      default:
        return 'none';
    }
  }
};
