import { Common } from '@thecvlb/design-system/lib/src';

import { ChoosePlanTab } from './pauseSubscription.types';

export const choosePauseTabs: Common.DataItemProps[] = [
  { label: ChoosePlanTab.EveryTwoWeeks, value: 14 },
  { label: ChoosePlanTab.EveryMonth, value: 30 },
  { label: ChoosePlanTab.EveryTwoMonths, value: 60 },
];

export const choosePauseTabsAdmin: Common.DataItemProps[] = [
  { label: ChoosePlanTab.EveryThreeMonths, value: 90 },
  { label: ChoosePlanTab.EveryFourMonths, value: 120 },
  { label: ChoosePlanTab.EveryFiveMonths, value: 150 },
  { label: ChoosePlanTab.EverySixMonths, value: 180 },
];
