import { TOOLTIP_FOR_SEEN_MESSAGES } from '../Message/message.settings';

const ManageMessageTooltip = () => (
  <div className="relative">
    <div className="absolute right-0 z-10 -mr-9 mt-[-92px] w-[200px] rounded-md bg-black px-4 py-2 text-sm text-white shadow-lg">
      {TOOLTIP_FOR_SEEN_MESSAGES}
    </div>
    {/* Triangle */}
    <div className="absolute right-0 z-10 -mr-6 mt-[-27px] size-0 border-x-[10px] border-t-[10px] border-x-transparent border-t-black" />
  </div>
);
export default ManageMessageTooltip;
