export const fileValidator = (files: FileList) => {
  const { allowedFileFormats, fileSizeMBLimit } = {
    allowedFileFormats: [
      'doc',
      'docx',
      'application/msword',
      'application/vnd.ms-excel',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'text/plain',
      'application/pdf',
      'image/png',
      'image/jpg',
      'image/jpeg',
      'image/gif',
      'image/webp',
      'image/heic',
      'video/quicktime',
      'video/mp4',
    ],
    fileSizeMBLimit: 20,
  };
  const { length } = files;
  const result = {
    errVal: '',
    isValidFile: false,
  };
  if (length === 0) {
    return result;
  }
  const { size, type } = files[0];
  if (!allowedFileFormats.includes(type)) {
    result.errVal = 'File format is invalid';
  } else if (size / 1024 / 1024 > fileSizeMBLimit) {
    result.errVal = `File size exceeded the limit of ${fileSizeMBLimit}MB`;
  } else {
    result.isValidFile = true;
  }
  return result;
};
