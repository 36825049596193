import { useEffect, useRef } from 'react';

import classNames from 'classnames';
import CustomTimeInput from 'components/common/form/timeInput/CustomTimeInput/CustomTimeInput';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { motion } from 'framer-motion';
import { useController, useFormContext } from 'react-hook-form';
import { useClickAway, useToggle } from 'react-use';
import { twMerge } from 'tailwind-merge';

import { AvailabilityTimeInputProps } from './availabilityTimeInput.types';
import { defaultTimeInputValue } from '../timePeriod.settings';

const AvailabilityTimeInput: React.FC<AvailabilityTimeInputProps> = ({
  name,
  validate,
  onBlur,
  testId,
  wrapperClassNames,
}) => {
  const timePickerRef = useRef(null);
  const [showTimePicker, setShowTimePicker] = useToggle(false);

  const timePickerClassName = (current: string, selected: string) => {
    return classNames(
      'flex w-full rounded-lg cursor-pointer items-center justify-center px-2 py-[7.5px] text-sm text-gray-700 hover:bg-gray-800/5',
      { 'bg-primary-400 text-white hover:bg-primary-400': current === selected },
    );
  };

  const { control, setValue } = useFormContext();
  const { field } = useController({ name, control, rules: { validate } });
  const currentTime = field.value || defaultTimeInputValue;
  const initialTime = dayjs(currentTime, DateFormat.HH_mm).format(DateFormat.hh_mm);
  const amPMValue = dayjs(currentTime, DateFormat.HH_mm).format(DateFormat.a);
  const currentHour = dayjs(currentTime, DateFormat.HH_mm).format(DateFormat.hh);
  const currentMinute = dayjs(currentTime, DateFormat.HH_mm).format(DateFormat.mm);

  useEffect(() => {
    setValue(name, currentTime);
  }, []);

  const handleChangeTime = (newTime: string) => {
    field.onChange(dayjs(`${newTime} ${amPMValue}`, DateFormat.hh_mm_a).format(DateFormat.HH_mm));
  };

  const handleChangeAMPM = (newAMPM: string) => {
    field.onChange(dayjs(`${initialTime} ${newAMPM}`, DateFormat.hh_mm_a).format(DateFormat.HH_mm));
    onBlur?.();
  };

  const handleTimePicker = (value: string, type: 'hour' | 'minute') => {
    if (type === 'hour') {
      field.onChange(dayjs(`${value}:${currentMinute} ${amPMValue}`, DateFormat.hh_mm_a).format(DateFormat.HH_mm));
    } else {
      field.onChange(dayjs(`${currentHour}:${value} ${amPMValue}`, DateFormat.hh_mm_a).format(DateFormat.HH_mm));
    }
    onBlur?.();
  };

  useClickAway(timePickerRef, () => setShowTimePicker(false));

  const wrapperClasses = twMerge(
    'flex w-20 items-center justify-between self-end rounded-lg border border-gray-400 px-[7.5px] py-[10px]',
    wrapperClassNames,
  );

  return (
    <div ref={timePickerRef} className={wrapperClasses}>
      <div className="relative flex items-center justify-center">
        <CustomTimeInput
          initialTime={initialTime}
          onChange={(event) => handleChangeTime(event.target.value)}
          onFocus={() => setShowTimePicker(true)}
          onBlur={onBlur}
          data-testid={testId}
          className="text-gray-700"
        />
        {showTimePicker && (
          <motion.div
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute -left-2 top-7 z-10 w-36 rounded-lg border border-gray-400 bg-white"
          >
            <div className="grid h-48 grid-cols-3 gap-1 overflow-auto p-1">
              <div data-testid="hours_block" className="flex flex-col overflow-auto">
                {[...Array(12)].map((_, index) => {
                  const value = dayjs()
                    .hour(index + 1)
                    .format(DateFormat.hh);
                  return (
                    <span
                      key={index + 'hour'}
                      className={timePickerClassName(value, currentHour)}
                      onClick={() => handleTimePicker(value, 'hour')}
                    >
                      {value}
                    </span>
                  );
                })}
              </div>
              <div data-testid="minutes_block" className="flex flex-col overflow-auto">
                {[...Array(60)].map((_, index) => {
                  const value = dayjs().minute(index).format(DateFormat.mm);
                  return (
                    <span
                      key={index + 'minute'}
                      className={timePickerClassName(value, currentMinute)}
                      onClick={() => handleTimePicker(value, 'minute')}
                    >
                      {value}
                    </span>
                  );
                })}
              </div>
              <div data-testid="am_pm_block" className="flex flex-col">
                <span onClick={() => handleChangeAMPM('am')} className={timePickerClassName('am', amPMValue)}>
                  am
                </span>
                <span onClick={() => handleChangeAMPM('pm')} className={timePickerClassName('pm', amPMValue)}>
                  pm
                </span>
              </div>
            </div>
          </motion.div>
        )}
        <span
          className="appearance-none border-none bg-none p-0 text-sm text-gray-700 focus:ring-0 focus:ring-offset-0"
          onClick={() => setShowTimePicker(true)}
          data-testid="am_pm_value"
        >
          {amPMValue}
        </span>
      </div>
    </div>
  );
};

export default AvailabilityTimeInput;
