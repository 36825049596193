import { DayPicker } from 'react-day-picker';
import styled from 'styled-components';
import tw from 'twin.macro';

export const StyledDatePickerInput = styled(DayPicker)`
  .rdp-month.rdp-caption_start.rdp-caption_end {
    ${tw`p-6 rounded-md bg-white shadow`}
  }
  .rdp-cell {
    ${tw`text-sm`}
  }

  .rdp-caption_label {
    --rdp-accent-color: #fff !important;
    --rdp-outline: 2px solid #fff;
    --rdp-background-color: #fff;
    ${tw`text-gray-700 font-bold text-xl`}
  }

  .rdp-day .selected-day {
    ${tw`text-white font-bold`}
  }

  .rdp-day_selected:not(.rdp-day_disabled):not(.rdp-day_outside) {
    ${tw`bg-primary hover:bg-primary hover:opacity-90`};
  }

  .rdp-day:not(.rdp-day_disabled):not(.rdp-day_selected) {
    ${tw`text-gray-700`};
  }

  .rdp-day:not(.rdp-day_disabled):not(.rdp-day_selected):hover {
    ${tw`text-primary font-bold bg-secondary-100`};
  }
`;
