import { CancellationType } from 'components/modals/CancelPlan/cancelPlan.types';

import { DeactivateReasonsProps } from './subscription.types';
import { apiSlice } from '../api/apiSlice';

export const subscriptionSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getSubscriptionCancelReasons: build.query<
      { value: string; label: string }[],
      { cancellationType?: CancellationType } | null
    >({
      query: () => ({
        url: '/subscription-cancel-reasons',
        params: { version: 2 },
      }),
      transformResponse: ({ data }: DeactivateReasonsProps, _, arg) => {
        let returnedArray = data.all;
        if (arg?.cancellationType === CancellationType.CancelAccountAndRefundPatient) {
          returnedArray = data.deactivateWithRefund;
        } else if (arg?.cancellationType === CancellationType.CancelAutoRenew) {
          returnedArray = data.cancelSubscriptionAutoRenew;
        }
        const result = returnedArray.map((reason) => ({
          label: reason.description,
          value: reason.code,
        }));
        return result;
      },
    }),
  }),
});

export const { useGetSubscriptionCancelReasonsQuery, useLazyGetSubscriptionCancelReasonsQuery } = subscriptionSlice;
