import { Common } from '@thecvlb/design-system/lib/src';
import ViewAsButton from 'components/common/ViewAsButton';
import { PathName } from 'enums/pathName';
import { useLazyViewEMRQuery } from 'store/patients/patientsSlice';

import MessagesSection from './MessagesSection';
import { SMSModalProps } from './smsModal.types';

const SMSModal: React.FC<SMSModalProps> = ({ patientDetails }) => {
  const [viewEMR] = useLazyViewEMRQuery();

  const phoneNumber =
    typeof patientDetails.phone === 'string' ? patientDetails.phone : patientDetails.phone.phoneNumber;

  const addDashes = (phone: string) => {
    return `${phone.slice(0, 3)}-${phone.slice(3, 6)}-${phone.slice(6)}`;
  };

  const handleViewChart = () => {
    window.open(`${PathName.Patient}/${patientDetails._id}`, '_blank');
  };

  const handleViewEMR = () => {
    viewEMR({ id: patientDetails._id });
  };

  return (
    <div data-testid="sms_popup" className="my-auto p-10">
      <div className="flex flex-wrap justify-between gap-x-12 gap-y-4">
        <div className="flex w-1/5 min-w-fit items-center justify-start px-2">
          <Common.ProfileImage src={patientDetails?.profileImage} size="lg" />

          <div className="mx-3">
            <div data-testid="patient_first_last_name" className="text-xl font-bold">
              {patientDetails.firstName} {patientDetails.lastName}
            </div>
            <div data-testid="patient_phone" className="font-bold text-gray-400">
              {phoneNumber && addDashes(phoneNumber)}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-5 md:flex-row">
          <ViewAsButton type="case" onClick={handleViewChart} />
          <ViewAsButton type="emr" onClick={handleViewEMR} />
        </div>
      </div>

      <div className="m-2 border-t" />

      {patientDetails.phone && typeof patientDetails.phone !== 'string' && (
        <MessagesSection patientId={patientDetails._id} patientPhone={patientDetails.phone} />
      )}
    </div>
  );
};

export default SMSModal;
