import { notifySuccess } from 'components/common/Toast/Toast';
import AppointmentTypeLengths from 'components/modals/AppointmentTypes/AppointmentTypeLengths';
import PopupFooter from 'components/modals/components/PopupFooter';
import PopupHeader from 'components/modals/components/PopupHeader';
import { FormProvider, useController, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useEditAppointmentLengthsMutation } from 'store/appointmentLength/appointmentLengthSlice';
import { EditLengthDefaultsBody, LengthByPlanWithLabel } from 'store/appointments/appointments.types';
import { closeModal } from 'store/modal/modalSlice';

const AppointmentLengthDefaults = () => {
  const [editLengthDefaults, { isLoading }] = useEditAppointmentLengthsMutation();
  const dispatch = useDispatch();
  const methods = useForm<{ lengthByPlan: LengthByPlanWithLabel[] }>({
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const { control, formState } = methods;

  const onFormSubmit = (data: { lengthByPlan: LengthByPlanWithLabel[] }) => {
    const plans: EditLengthDefaultsBody[] = data.lengthByPlan?.map((length) => ({
      _id: length.planId,
      appointmentLengths: length.appointmentLengths,
    }));

    editLengthDefaults({ plans })
      .unwrap()
      .then(({ message }) => {
        notifySuccess(message);
        dispatch(closeModal());
      });
  };

  useController({
    control,
    defaultValue: [],
    name: 'lengthByPlan',
    rules: { required: true },
  });

  return (
    <FormProvider {...methods}>
      <form data-testid="appo_length_popup" className="p-8" onSubmit={methods.handleSubmit(onFormSubmit)}>
        <PopupHeader title={'Appointment type'} />

        <AppointmentTypeLengths isEditDefault />

        <div className="mt-8">
          <PopupFooter hiddenDeleteButton disabled={isLoading || formState.isDirty} />
        </div>
      </form>
    </FormProvider>
  );
};

export default AppointmentLengthDefaults;
