import { useContext } from 'react';

import { Common } from '@thecvlb/design-system';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';

const activeArrowClassName = 'hidden md:block text-primary p-3.5 mx-1 border rounded-full';
const inactiveArrowClassName = 'hidden md:block text-gray-200 p-3.5 mx-1 border rounded-full';

export function LeftArrow() {
  const { scrollPrev, useIsVisible } = useContext(VisibilityContext);
  const isFirstItemVisible = useIsVisible('first', true);

  return !isFirstItemVisible ? (
    <button
      className={!isFirstItemVisible ? activeArrowClassName : inactiveArrowClassName}
      disabled={isFirstItemVisible}
      type="button"
      onClick={() => scrollPrev()}
      data-testid="arrow-left-button"
    >
      <Common.Icon name="arrow-left" />
    </button>
  ) : null;
}

export function RightArrow() {
  const { useIsVisible, scrollNext } = useContext(VisibilityContext);

  const isLastItemVisible = useIsVisible('last', false);

  return !isLastItemVisible ? (
    <button
      type="button"
      className={!isLastItemVisible ? activeArrowClassName : inactiveArrowClassName}
      disabled={isLastItemVisible}
      onClick={() => scrollNext()}
      data-testid="arrow-right-button"
    >
      <Common.Icon name="arrow-right" />
    </button>
  ) : null;
}
