import { useLocation } from 'react-router-dom';

import { renderBulkEditContent } from './bulkEdit.settings';

const BulkEditContent = () => {
  const location = useLocation();
  return (
    <div data-testid="bulk_edit_popup" className="p-6">
      {renderBulkEditContent(location.pathname)}
    </div>
  );
};

export default BulkEditContent;
