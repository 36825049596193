import classNames from 'classnames';
import { priceInfo } from 'components/patient/Billing/PlanDetails/planDetails.settings';

import { UpdatePlanCardProps } from './updatedPlanCard.types';

const UpdatePlanCard: React.FC<UpdatePlanCardProps> = ({
  creditBalance,
  oldPlan,
  plan,
  patientInfo,
  previewSubscription,
}) => {
  const oldPricePointLabel = oldPlan?.pricePoint ? `$${priceInfo(oldPlan.pricePoint, true)}` : 'Pay as you go';
  const newPricePointLabel = plan.pricePoint ? `$${priceInfo(plan.pricePoint, true)}` : 'Pay as you go';
  const patientFirstName = patientInfo?.firstName;
  const newPlanTotalCost = (plan.pricePoint ? Number(plan.pricePoint.totalCost) : 0).toFixed(2);
  const isCreditedFinalPrice = !!previewSubscription && !!Number(previewSubscription.creditApplied);
  const finalTextData = isCreditedFinalPrice ? 'credited to their account.' : 'due.';
  const discount = previewSubscription.discount;
  const newDiscount = previewSubscription.newDiscount;
  const billingPeriodDaysRemaining = previewSubscription.billingPeriodDaysRemaining;
  const totalCost = !!isCreditedFinalPrice ? previewSubscription.creditApplied : previewSubscription.paymentDue;

  const rowClassName = 'mb-1 flex justify-between font-bold md:mb-0.5';
  const subheadingClassName = 'block text-mSm md:text-sm';

  return (
    <div className="mb-4 rounded-2xl border border-secondary-200 bg-secondary-50 p-6">
      {oldPlan && (
        <div className="mb-3 text-primary-700">
          <div className={rowClassName}>
            <span>Old plan</span>
            <span className="line-through">{oldPricePointLabel}</span>
          </div>
          <span className={subheadingClassName}>{oldPlan.planName}</span>
        </div>
      )}
      {!!discount && (
        <div className="mb-3 text-orange">
          <div className={rowClassName}>
            <span>{`Discount: ${discount.title}`}</span>
            <span>-${discount.discountAmount}</span>
          </div>
        </div>
      )}
      <div className="mb-3 text-secondary">
        <div className={rowClassName}>
          <span>New plan</span>
          <span>{newPricePointLabel}</span>
        </div>
        <span className={subheadingClassName}>{plan.planName}</span>
      </div>
      {!!newDiscount && (
        <div className="mb-3 text-orange">
          <div className={rowClassName}>
            <span>{`Discount: ${newDiscount.title}`}</span>
            <span>-${newDiscount.discountAmount}</span>
          </div>
        </div>
      )}
      <div className="my-4 h-px w-full bg-secondary" />
      <div className="mb-3 text-primary-700">
        <div className={rowClassName}>
          <span>{isCreditedFinalPrice ? 'Credit applied' : 'Total due today'}</span>
          <span className={classNames({ 'text-green': isCreditedFinalPrice })}>
            {!!isCreditedFinalPrice && '+'}${totalCost}
          </span>
        </div>
      </div>

      <p className="text-sm">
        {`${patientFirstName}’s new membership begins immediately and will renew again in ${
          plan.pricePoint.paymentInterval.qty
        } month${plan.pricePoint.paymentInterval.qty === 1 ? '' : 's'}. We’ll prorate a refund of $${
          previewSubscription.proratedAdjustment
        }
      for the ${billingPeriodDaysRemaining} day${
        Number(billingPeriodDaysRemaining) <= 1 ? '' : 's'
      } remaining in their previous billing cycle, and charge a full amount of $${newPlanTotalCost} for their new plan.
      This will result in a total of $${totalCost} ${finalTextData}`}
      </p>
      {Number(creditBalance) > 0 && !isCreditedFinalPrice && (
        <p className="mt-3 text-sm">
          Their <span className="font-bold">LifeMD credit balance of ${creditBalance}</span> will be billed first,
          followed by their card on file.
        </p>
      )}
    </div>
  );
};

export default UpdatePlanCard;
