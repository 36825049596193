import { MembershipPlanProps, PricePointProps } from 'components/crossSell/PlanType/planType.types';
import { PersonalInfo } from 'models/tasks.types';
import { PatientProps } from 'store/patients/patients.types';

export interface PauseSubscriptionProps {
  patientInfo?: PatientProps | PersonalInfo;
  currentPlan?: MembershipPlanProps;
  activePP?: PricePointProps;
}

export enum ChoosePlanTab {
  EveryTwoWeeks = '2 weeks',
  EveryMonth = '1 month',
  EveryTwoMonths = '2 months',
  EveryThreeMonths = '3 months',
  EveryFourMonths = '4 months',
  EveryFiveMonths = '5 months',
  EverySixMonths = '6 months',
}
