import React, { useCallback, useState } from 'react';

import Loader from 'components/common/Loader';
import { notifyError } from 'components/common/Toast/Toast';
import { AppointmentListType } from 'enums/appointments';
import { PlanCodesProps } from 'enums/appointmentStatus';
import { useAppSelector } from 'hooks/redux';
import { FormProvider, useForm } from 'react-hook-form';
import { useToggle } from 'react-use';
import { AppointmentItemProps, CreateAppointmentResProps, MembershipData } from 'store/appointments/appointments.types';
import {
  useCheckFreeAppointmentsMutation,
  useCreateAppointmentMutation,
  useGetPatientAppointmentsQuery,
  useUploadFilesMutation,
} from 'store/appointments/appointmentsSlice';
import { useGetMembershipPlansQuery } from 'store/crossSell/crossSellSlice';
import { selectPatient, useChangeSubscriptionMutation, useLazyGetTagsQuery } from 'store/patients/patientsSlice';

import ProgressBar from './components/ProgressBar';
import {
  buildCreateAppointmentBody,
  buildMembershipDataBody,
  defaultAppointmentData,
  defaultMembershipData,
  getDefaultStepContent,
  getStepContentTitle,
  listOfPlanThatIncludeAppointment,
} from './CreateAppointmentModal.settings';
import {
  AppointmentProps,
  AppointmentSteps,
  PlanType,
  StepName,
  StepsContentProps,
} from './CreateAppointmentModal.types';
import AttachFiles from './steps/AttachFiles';
import CategoryAndDetails from './steps/CategoryAndDetails';
import Confirmation from './steps/Confirmation';
import DateAndTime from './steps/DateAndTime';
import MakeUp from './steps/MakeUp';
import Membership from './steps/Membership';
import { findPlan } from './steps/Membership/membership.settings';
import PaymentCheckout from './steps/PaymentCheckout';

const CreateAppointmentModal: React.FC<{
  upcomingAppointmentsList: AppointmentItemProps[];
  predefinedDate?: string;
  hasPastAppointment?: boolean;
}> = ({ upcomingAppointmentsList, predefinedDate, hasPastAppointment }) => {
  const { patientInfo } = useAppSelector(selectPatient);

  const { data: plans, isFetching: isFetchingPlans } = useGetMembershipPlansQuery();
  const [changeSubscription, { isLoading: isLoadingChangingPlan }] = useChangeSubscriptionMutation();
  const [getTags] = useLazyGetTagsQuery();
  const [uploadFiles, { isLoading: isLoadingUploadFiles }] = useUploadFilesMutation();
  const [createAppointment, { isLoading: isLoadingCreateAppointment }] = useCreateAppointmentMutation();
  const [checkFreeAppointments, { isLoading: isLoadingCheckFree }] = useCheckFreeAppointmentsMutation();
  const { data: pastAppointments, isFetching: isFetchingPastList } = useGetPatientAppointmentsQuery({
    patientId: patientInfo?._id || '',
    params: {
      appointmentListType: AppointmentListType.PastAppointments,
      limit: 50,
    },
  });
  const pastAppointmentsList = pastAppointments?.appointments || [];

  const [isAdHoc, toggleIsAdHoc] = useToggle(false);
  const [selectedPastAppointment, toggleSelectedPastAppointment] = useToggle(false);
  const [step, setStep] = useState(0);
  const [stepsList] = useState<StepName[]>(getDefaultStepContent(hasPastAppointment));
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isInsuranceUser, setIsInsuranceUser] = useState(false);
  const [membershipData, setMembershipData] = useState<MembershipData>({
    ...defaultMembershipData,
    planID: patientInfo?.planInfo?._id ?? '',
  });

  const plan = plans?.find((p) => p._id === membershipData.planID);
  const existingPlan = plans?.find((p) => p._id === patientInfo?.planInfo?._id);

  const isTotalCarePlan = patientInfo?.planInfo?.planCode === PlanCodesProps.TotalCareMembership;
  const isFree =
    membershipData &&
    membershipData?.freeAppointmentInfo?.subscriptionHasFree &&
    membershipData?.freeAppointmentInfo?.isFree;
  const planIncludeAppointment = listOfPlanThatIncludeAppointment.includes(
    patientInfo?.planInfo?.planCode as PlanCodesProps,
  );

  const isLoading = isLoadingChangingPlan || isLoadingUploadFiles || isLoadingCreateAppointment || isLoadingCheckFree;
  const methods = useForm<AppointmentProps>({
    mode: 'onChange',
    defaultValues: {
      ...defaultAppointmentData,
      doctorId: patientInfo?.doctorId ?? '',
    },
  });
  const newAppointmentData = methods.watch();
  const goToNextStep = () => {
    setStep(Math.min(step + 1, stepsList.length - 1));
  };

  const handleScheduleNewAppointmentThen = (createdAppointment: CreateAppointmentResProps) => {
    methods.setValue('_id', createdAppointment._id);

    if (membershipData.type === PlanType.Unlimited) {
      setIsPopupOpen(true);
    }

    if (newAppointmentData.files.length) {
      const formData = new FormData();
      newAppointmentData.files.forEach((file: File) => {
        formData.append('appointmentImages', file);
      });

      uploadFiles({ appointmentId: createdAppointment._id, body: formData })
        .unwrap()
        .then(() => {
          setStep(stepsList.length - 1);
        });
    } else {
      setStep(stepsList.length - 1);
    }
  };

  const scheduleNewAppointment = () => {
    if (patientInfo?._id) {
      createAppointment({
        patientId: patientInfo._id,
        body: buildCreateAppointmentBody(newAppointmentData, selectedPastAppointment, isAdHoc),
      })
        .unwrap()
        .then((data) => handleScheduleNewAppointmentThen(data));
    }
  };

  const updateMembershipDate = useCallback((newData: MembershipData) => {
    setMembershipData((prevState) => ({ ...prevState, ...newData }));
  }, []);

  const selectMembership = () => {
    if (!membershipData.type) {
      return;
    }

    if (membershipData.type === PlanType.OneTime) {
      return scheduleNewAppointment();
    }

    if (patientInfo?._id) {
      changeSubscription({
        patientId: patientInfo?._id,
        planId: membershipData.planID,
        planPricePointId: membershipData.pp?.planPricePointId ?? '',
      })
        .unwrap()
        .then(() => {
          scheduleNewAppointment();

          // NOTE: Update tags after changing subscription, but after a delay
          setTimeout(() => {
            getTags({ patientId: patientInfo?._id });
          }, 2000);
        });
    }
  };

  const onCheckFreeAppointments = () => {
    if (newAppointmentData.startTime && patientInfo?._id) {
      const activePlanCode = patientInfo?.planInfo?.planCode ?? '';
      const activePricePoint = patientInfo?.activePricePoint ?? '';
      const currentPlan = findPlan(activePlanCode as PlanCodesProps, plans ?? []);
      const currentPricePoints = currentPlan?.pricePoints.find((pp) => pp.planPricePointId === activePricePoint);

      const insuranceCategory = currentPricePoints?.categories?.includes('insurance');
      setIsInsuranceUser(!!insuranceCategory);

      checkFreeAppointments({
        patientUserId: patientInfo?._id,
        appointmentStartTime: newAppointmentData.startTime,
      })
        .unwrap()
        .then((data) => {
          if (currentPlan) {
            updateMembershipDate(buildMembershipDataBody(currentPlan._id, currentPricePoints, data));
          }
          if (data?.insuranceEligibility && !['ACTIVE', 'ACTIVE_AT_RISK'].includes(data?.insuranceEligibility.status)) {
            return notifyError('Unable to verify insurance');
          }
          if (insuranceCategory) {
            return setStep(step + 2);
          }
          goToNextStep();
        });
    } else {
      notifyError('Choose appointment time');
    }
  };

  const nextStep = () => {
    switch (stepsList[step]) {
      case AppointmentSteps.DATE_TIME:
        return selectedPastAppointment ? scheduleNewAppointment() : onCheckFreeAppointments();
      case AppointmentSteps.MEMBERSHIP:
        return (isTotalCarePlan && isFree) || planIncludeAppointment ? scheduleNewAppointment() : goToNextStep();
      default:
        goToNextStep();
    }
  };

  const getStepContent = () => {
    const steps: StepsContentProps = {
      MAKE_UP: (
        <MakeUp
          handleNext={goToNextStep}
          toggleSelectedPastAppointment={toggleSelectedPastAppointment}
          selectedPastAppointment={selectedPastAppointment}
          pastAppointmentsList={pastAppointmentsList}
        />
      ),
      CATEGORY: (
        <CategoryAndDetails
          onClickNext={(required) => {
            setStep(required ? step + 1 : step + 2);
          }}
          handleBack={() => setStep((prev) => prev - 1)}
          hideHandleBackButton={!hasPastAppointment}
        />
      ),
      FILES: <AttachFiles handleNext={nextStep} handleBack={() => setStep((prev) => prev - 1)} />,
      DATE_TIME: (
        <DateAndTime
          upcomingAppointmentsList={upcomingAppointmentsList}
          pastAppointmentsList={pastAppointmentsList}
          initialDate={predefinedDate}
          onSelect={nextStep}
          handleBack={() => setStep((prev) => (newAppointmentData.uploadRequired ? prev - 1 : prev - 2))}
          isLoading={isLoading}
          isAdHoc={isAdHoc}
          toggleIsAdHoc={toggleIsAdHoc}
        />
      ),
      MEMBERSHIP: (
        <Membership
          freeAppointmentInfo={membershipData.freeAppointmentInfo}
          onSelect={updateMembershipDate}
          handleNext={nextStep}
          handleBack={() => setStep((prev) => prev - 1)}
          isLoading={isLoading}
          membershipPlans={plans ?? []}
          planIncludeAppointment={planIncludeAppointment}
        />
      ),
      CHECKOUT:
        !!plan && !!existingPlan ? (
          <PaymentCheckout
            existingPlan={existingPlan}
            membershipData={membershipData}
            patientInfo={patientInfo}
            selectedPlan={plan}
            onProceed={selectMembership}
            isLoading={isLoading}
            isInsuranceUser={isInsuranceUser}
          />
        ) : (
          <span>No plan selected</span>
        ),
      CONFIRMATION: <Confirmation closePopup={() => setIsPopupOpen(false)} isPopupOpen={isPopupOpen} />,
    };

    return steps[stepsList[step]];
  };

  return (
    <div className="flex h-full flex-1 flex-col rounded-2xl p-6">
      <Loader isVisible={isFetchingPlans || isFetchingPastList} />
      <div className="text-xl font-bold text-gray-700">Create appointment</div>
      <div className="w-full pb-12 pt-10">
        <ProgressBar color="primary" step={step} totalSteps={stepsList.length - 1} />
      </div>
      <h1 className="text-center text-2xl font-bold text-primary-500">
        {getStepContentTitle(step, stepsList, patientInfo?.planInfo, isFree)}
      </h1>

      <FormProvider {...methods}>
        <form action="">{getStepContent()}</form>
      </FormProvider>
    </div>
  );
};

export default CreateAppointmentModal;
