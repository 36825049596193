import { PARequestStatus } from 'enums/taskDetailsStatus';

export const PA_STATUSES = [
  { label: 'PA approved', value: PARequestStatus.APPROVED },
  { label: 'PA denied', value: PARequestStatus.DENIED },
  { label: 'PA error', value: PARequestStatus.ERROR },
  { label: 'PA requested', value: PARequestStatus.CREATED },
  { label: 'PA sent', value: PARequestStatus.SENT },
  { label: 'Patient canceled', value: PARequestStatus.PA_REQUEST_PATIENT_CANCELLED },
];
