import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { RadioButtonListProps } from './radioButtonList.types';

const RadioButtonCard: React.FC<RadioButtonListProps> = ({
  isSelected,
  disabled,
  handleChange,
  label,
  value,
  color = 'secondary',
  className,
  first = false,
  last = false,
}) => {
  const styles = classNames(
    'cursor-pointer transition-all bg-white w-full flex flex-wrap border px-4 py-3 -mt-0.5 relative',
    {
      'shadow border': color === 'secondary',
      'border-1': color === 'blue',
      'font-bold bg-secondary-100 border-secondary': isSelected && color === 'secondary',
      'font-bold bg-blue-50 border-blue z-10': isSelected && color === 'blue',
      'border-gray-200': !isSelected && color === 'secondary',
      'opacity-50': disabled,
      'rounded-tl-md rounded-tr-md': first,
      'rounded-bl-md rounded-br-md': last,
    },
    className,
  );

  return (
    <div className={styles} onClick={() => !disabled && handleChange(value)}>
      <Common.RadioButton
        color={color}
        onChange={() => handleChange(value)}
        checked={isSelected}
        disabled={disabled}
        size="md"
      >
        {label}
      </Common.RadioButton>
    </div>
  );
};

export default RadioButtonCard;
