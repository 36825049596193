export interface QuickLinksProps {
  tabLinks: string[];
  onChange: (params: string) => void;
  type?: 'standard' | 'custom';
}

export enum QuickLinksTypes {
  Standard = 'standard',
  Custom = 'custom',
}
