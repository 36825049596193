import { BACKEND_DATE_FORMAT, FRONTEND_DATE_FORMAT } from 'constants/dateFormat';
import { COUNTRY_CODE_US, GENDER_OPTIONS } from 'constants/user';

import { nanoid } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import capitalize from 'lodash/capitalize';
import { parsePhoneNumber } from 'react-phone-number-input';
import { TimeZone } from 'store/lookup/lookup.types';
import { Staff } from 'store/staffs/staffs.types';

import { ProfileFormStateProps } from './editProfileForm.types';

export const getSubmitStaffMemberData = (
  formData: ProfileFormStateProps,
  profileDetails?: Staff,
  isMyProfile?: boolean,
) => {
  const profileInfo = profileDetails;
  const experience = formData.education?.map((valueField: { value: unknown }) => String(valueField.value)) || [];

  return {
    userRoleId: String(formData.role?.value) || profileInfo?.userRoleInfo._id || '',
    firstName: formData.firstName,
    lastName: formData.lastName,
    prefix: formData.prefix,
    displayName: formData.displayName,
    gender: String(formData.gender.value),
    dob: dayjs.utc(formData.dob).format(BACKEND_DATE_FORMAT),
    email: formData.email,
    phone: {
      countryCode: parsePhoneNumber(formData.phone)?.country ?? COUNTRY_CODE_US,
      phoneNumber: parsePhoneNumber(formData.phone)?.nationalNumber.toString() ?? '',
    },
    ...(profileDetails?.notificationPreferences && {
      notificationPreferences: {
        notifyWhenOnline: formData.notificationPreferences?.notifyWhenOnline,
        emailNotification: formData.notificationPreferences?.emailNotification,
        textNotification: formData.notificationPreferences?.textNotification,
      },
    }),
    npi: formData.npi?.trim(),
    ...(formData.timeZone?.value && { timezone: String(formData.timeZone?.value) }),
    ...(!isMyProfile && {
      activeStates: formData.licensedIn?.map((item: { label: unknown }) => String(item.label)) ?? [],
    }),
    specialization: profileInfo?.specialization || ' ',
    specialtyAreas: formData.specialtyAreas?.map((item: { value: unknown }) => String(item.value)) ?? [],
    providerVideo: formData.providerVideo,
    aboutDoctor: formData.biography,
    shortBio: formData.shortBio,
    experience: experience,
    aboutNursePractitioner: formData.biography,
    status: String(formData.status?.value) || profileInfo?.status || 'active',
    ...(formData.payAmount && { payAmount: formData.payAmount }),
    ...(formData.payType && { payType: formData.payType.label }),
    staffCostId: String(formData.payType?.value || '') || profileInfo?.staffCostInfo?._id,
    asyncProviderEnabled: formData.asyncProviderEnabled,
    advancedCallLogging: formData.advancedCallLogging,
    ...(formData.teams && { teams: formData.teams.map((item: { value: unknown }) => String(item.value)) }),
  };
};

export const getResetMemberStaffData = (profileDetails: Staff, timeZoneData?: TimeZone[]) => {
  const {
    firstName,
    prefix,
    lastName,
    phone,
    activeStates,
    specialtyAreasInfo,
    userRoleInfo,
    npi,
    gender,
    dob,
    email,
    timezone,
    displayName,
    aboutDoctor,
    aboutNursePractitioner,
    notificationPreferences,
    experience,
    staffCostInfo,
    shortBio,
    status,
    providerVideo,
    asyncProviderEnabled,
    advancedCallLogging,
    teams,
  } = profileDetails;
  const userTimeZone = timeZoneData?.find((timeZone) => timeZone.value === timezone);
  const profilePhone = `${COUNTRY_CODE_US}${phone.phoneNumber}`;
  const licensedIn = (activeStates || []).map((licensedItem) => ({ label: licensedItem, value: licensedItem }));
  const specialtyAreasInfoOption = specialtyAreasInfo.map((area) => ({ label: area.name, value: area._id }));
  const role = { value: userRoleInfo._id, label: userRoleInfo.name };
  const education = experience?.length
    ? experience.map((valueField) => ({ value: valueField || '', id: nanoid() }))
    : [{ value: '', id: nanoid() }];
  const teamsList = teams?.map((team) => ({ label: team.name, value: team.teamId }));

  return {
    role,
    firstName,
    lastName,
    prefix,
    gender: GENDER_OPTIONS.find((option) => option.value === gender),
    dob: dob ? dayjs.utc(dob).format(FRONTEND_DATE_FORMAT) : dob,
    email,
    displayName,
    phone: profilePhone,
    npi: npi?.trim(),
    timeZone: userTimeZone,
    biography: aboutDoctor || aboutNursePractitioner,
    specialtyAreas: specialtyAreasInfoOption,
    licensedIn,
    education,
    shortBio,
    notificationPreferences: notificationPreferences,
    ...education.reduce((prev, curr) => ({ ...prev, [`education${curr.id}`]: curr.value }), {}),
    ...(staffCostInfo && {
      payType: { value: staffCostInfo._id, label: staffCostInfo.payType },
      payAmount: staffCostInfo.payAmount,
    }),
    status: { value: status, label: capitalize(status) },
    providerVideo,
    asyncProviderEnabled,
    advancedCallLogging,
    teams: teamsList,
  };
};

export const getBulkEditUpdateData = (formData: ProfileFormStateProps) => ({
  userRoleId: formData.role?.value,
  status: String(formData.status?.value),
  activeStates: formData.licensedIn?.map((item: { label: unknown }) => String(item.label)),
  specialtyAreas: formData.specialtyAreas?.map((item: { value: unknown }) => String(item.value)),
});

export const appointmentFilterParams =
  '?limit=50&pageNo=0&sort[dueDate]=ASC&category=Appointment&status=Active&status=New&status=URGENT&status=Open&status=Cancelled';
