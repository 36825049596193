import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { SubscriptionDetailsResponse } from 'store/patients/patients.types';

import { CancellationType } from '../cancelPlan.types';

const getCancellationTypesLabel = (cancellationType: CancellationType, subTitle: string) => {
  const subTitleClasses = cancellationType === CancellationType.CancelAccountAndRefundPatient ? 'text-red' : '';
  return (
    <span className="ml-1 flex flex-col gap-0.5">
      <span className={subTitleClasses}>{cancellationType}</span>
      <span className="text-sm font-medium text-gray">{subTitle}</span>
    </span>
  );
};

export const getCancellationTypes = (
  isPatientInactive?: boolean,
  subscriptionDetails?: SubscriptionDetailsResponse,
) => {
  const currentPeriodEndsAt = !!subscriptionDetails?.currentPeriodEndsAt
    ? dayjs(subscriptionDetails?.currentPeriodEndsAt).format(DateFormat.MMM_DYYYY)
    : '';

  return [
    {
      label: getCancellationTypesLabel(
        CancellationType.CancelAutoRenew,
        `Patient will not receive a refund, and will continue to have access to their plan benefits until ${currentPeriodEndsAt}.`,
      ),
      value: CancellationType.CancelAutoRenew,
      disabled: !!subscriptionDetails?.cancelSubscriptionAutoRenewComplete,
    },
    {
      label: getCancellationTypesLabel(
        CancellationType.DowngradeRefundPatient,
        'Patient will receive a refund, and will be downgraded to a free plan with limited benefits.',
      ),
      value: CancellationType.DowngradeRefundPatient,
      disabled: isPatientInactive,
    },
    {
      label: getCancellationTypesLabel(
        CancellationType.CancelAccountAndRefundPatient,
        'Patient will receive a refund, and will immediately lose their plan benefits.',
      ),
      value: CancellationType.CancelAccountAndRefundPatient,
      disabled: isPatientInactive,
    },
  ];
};
