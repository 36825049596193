import InputField from 'components/forms/controlled/InputField';
import { useFormContext } from 'react-hook-form';
import { HealthMetrics } from 'store/patientChart/patientChart.types';
import { validation } from 'utils/helpers';
import { NUMBER_REGEXP } from 'utils/regExp';

const UpdateCholesterol: React.FC<{ metric?: HealthMetrics }> = ({ metric }) => {
  const { control, formState } = useFormContext();

  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-6 md:gap-4">
        <InputField
          name="total"
          label="Total cholesterol"
          control={control}
          labelDirection="row"
          type="number"
          maxLength={10}
          errors={formState.errors.total}
          rules={validation('Total cholesterol', NUMBER_REGEXP)}
          postText="mg/dl"
          defaultValue={metric?.cholesterol?.total}
        />
      </div>
      <div className="flex gap-6 md:gap-4">
        <InputField
          name="HDL"
          label="HDL"
          control={control}
          labelDirection="row"
          type="number"
          maxLength={10}
          errors={formState.errors.HDL}
          rules={validation('HDL', NUMBER_REGEXP)}
          postText="mg/dl"
          defaultValue={metric?.cholesterol?.HDL}
        />
      </div>
      <div className="flex gap-6 md:gap-4">
        <InputField
          name="nonHDL"
          label="Non-HDL"
          control={control}
          labelDirection="row"
          type="number"
          maxLength={10}
          errors={formState.errors.nonHDL}
          rules={validation('Non-HDL', NUMBER_REGEXP)}
          postText="mg/dl"
          defaultValue={metric?.cholesterol?.nonHDL}
        />
      </div>
      <div className="flex gap-6 md:gap-4">
        <InputField
          name="LDL"
          label="LDL"
          control={control}
          labelDirection="row"
          type="number"
          maxLength={10}
          errors={formState.errors.LDL}
          rules={validation('LDL', NUMBER_REGEXP)}
          postText="mg/dl"
          defaultValue={metric?.cholesterol?.LDL}
        />
      </div>
    </div>
  );
};

export default UpdateCholesterol;
