import { createSlice } from '@reduxjs/toolkit';
import type { Control, FieldValues, UseFormGetValues, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import type { RootState } from 'store';

/**
 * This is a hack to handle scenarios where we need access to a form's methods outside of its context.
 *
 * For example, consider a chat form that is managed within a specific context (e.g., react-hook-form's FormProvider).
 * In certain cases, there might be UI elements, like a button, located elsewhere on the page that need to interact with this form.
 * A common use case is a button that pastes predefined text into the chat input field, even though the button exists outside of the form's context.
 *
 * Since direct access to form methods (e.g., setValue, getValues) from outside the context is not normally possible,
 * this hack provides a workaround by allowing us to indirectly manipulate the form.
 *
 * Use this approach carefully, as it can introduce complexity and tightly couples components that would otherwise be independent.
 */

type SharedFormContext = {
  control?: Control<FieldValues>;
  setValue?: UseFormSetValue<FieldValues>;
  getValues?: UseFormGetValues<FieldValues>;
  watch?: UseFormWatch<FieldValues>;
  formData?: FieldValues;
};

type SharedFormContexts = {
  [key: string]: SharedFormContext;
};

const initialState: SharedFormContexts = {};

const sharedFormContextsSlice = createSlice({
  name: 'sharedFormContexts',
  initialState,
  reducers: {
    setSharedFormContext: (state, action) => {
      state[action.payload.name] = action.payload.value;
    },
    removeSharedFormContext: (state, action) => {
      delete state[action.payload];
    },
    reset: () => initialState,
  },
});

export const selectSharedFormContext = (state: RootState) => state.sharedFormContexts;

export const { setSharedFormContext, removeSharedFormContext, reset } = sharedFormContextsSlice.actions;

export default sharedFormContextsSlice.reducer;
