import { TaskTags } from 'enums/taskCategories';
import { BenefitsStatus, MIFStatus } from 'enums/taskDetailsStatus';
import { MIFqaData, OnboardedBy } from 'models/tasks.types';
export interface QueueTaskDetails {
  _id: string;
  subCategory: string;
  category: string;
  patientStatus: string;
  patientName: string;
  createdAt: string;
  dynamicStatus?: string;
  maImageUrl?: string | null;
  addedToQueueAt?: string;
}

export interface QueueResponse {
  patientsCount: number;
  taskInProgress: boolean;
  taskDetails?: Omit<QueueTaskDetails, '_id' | 'patientName'>;
}

export interface NumberOfPatientsBreakdown {
  MIF_INCOMPLETE: number;
  ONBOARDED: number;
  SC_COMPLETE: number;
  SC_INCOMPLETE: number;
  SC_SKIPPED: number;
  PRE_PAYMENT: number;
  SC_URGENT: number;
  NO_PROVIDER_COVERAGE: number;
}

export interface ProvidersQueueData {
  userId: string;
  doctorName: string;
  doctorImage: string;
  timeSlots: {
    startTime: string;
    endTime: string;
  }[];
  isProviderOnCall: boolean;
}

export interface OldestOrAvailablePreConsultTask {
  createdAt: string;
  patientStatus: string;
  _id: string;
}

export interface QueueTypes {
  averageWaitingTime?: number;
  numberOfPatientsBreakdown?: NumberOfPatientsBreakdown;
  queueEndAt?: string;
  oldestPreConsultTask?: OldestOrAvailablePreConsultTask;
  taskAvailableForAppointment?: OldestOrAvailablePreConsultTask;
  taskSCUrgent?: OldestOrAvailablePreConsultTask;
  taskNoProviderCoverage?: OldestOrAvailablePreConsultTask;
  queueAvailableAt?: string;
  numberOfProviders: number;
  numberOfPatients: number;
  licensedTasks?: number;
  outsideLicenseTasks?: number;
  providersQueueData: ProvidersQueueData[] | null;
}

export interface QueueTasksProps {
  patientName: string;
  createdAt: string;
  assignedAt?: string;
  category?: string;
  details?: string;
  doctor?: string;
  assignedTo?: string;
  patientStatus: string;
  dynamicStatus?: string;
  subCategory?: string;
  _id?: string;
  maImageUrl?: string;
  status?: string;
  subRows: [];
  addedToQueueAt?: string;
  tags?: TaskTags[];
  mifDetails?: {
    id: string;
    status: MIFStatus;
    warningCount?: number;
    qaData?: MIFqaData[];
  };
  benefitsStatus?: BenefitsStatus;
  preConsultTaskCompletedBy?: OnboardedBy;
  patientInfo?: {
    _id: string;
    state: string;
    name: string;
    dob: string;
    nickName?: string;
    isValidAddress?: boolean;
    isIdentityVerified?: boolean;
    tags?: string[];
  };
  symptomCheckerStatus?: string;
  insuranceBenefitsStatus?: string;
}

export interface InitialQueueState {
  queueInfo: { taskInProgress: boolean };
  queueScheduleDetails: QueueTypes;
  onGetQueueDetails?: () => void;
  queueTask: QueueTasksProps;
  loading: boolean;
}

export const initialState: InitialQueueState = {
  queueInfo: { taskInProgress: false },
  queueTask: {
    patientName: '',
    createdAt: '',
    category: '',
    details: '',
    doctor: 'Unassigned',
    assignedTo: 'Unassigned',
    patientStatus: 'New',
    maImageUrl: '',
    subRows: [],
  },
  queueScheduleDetails: {
    numberOfPatientsBreakdown: {
      MIF_INCOMPLETE: 0,
      ONBOARDED: 0,
      SC_COMPLETE: 0,
      SC_INCOMPLETE: 0,
      SC_SKIPPED: 0,
      PRE_PAYMENT: 0,
      SC_URGENT: 0,
      NO_PROVIDER_COVERAGE: 0,
    },
    oldestPreConsultTask: {
      createdAt: '',
      patientStatus: 'MIF_INCOMPLETE',
      _id: '',
    },
    taskAvailableForAppointment: {
      createdAt: '',
      patientStatus: 'SC_COMPLETE',
      _id: '',
    },
    averageWaitingTime: 15,
    queueEndAt: '',
    queueAvailableAt: '',
    numberOfProviders: 0,
    numberOfPatients: 0,
    licensedTasks: 0,
    outsideLicenseTasks: 0,
    providersQueueData: [],
  },
  loading: false,
};
