import { AvailabilityFilterType } from 'enums/availability';
import { ShiftTypesResponseProps } from 'store/calendar/calendar.types';

export const generateShiftTypesList = (shiftTypes: ShiftTypesResponseProps[]): ShiftTypesResponseProps[] => {
  const overviewItem: ShiftTypesResponseProps = {
    _id: '',
    name: '',
    title: AvailabilityFilterType.Overview,
  };

  const offlineItem: ShiftTypesResponseProps = {
    _id: 'offline',
    name: 'offline',
    title: AvailabilityFilterType.Offline,
  };

  const newShiftTypesList: ShiftTypesResponseProps[] = shiftTypes.map((shiftType) => ({
    ...shiftType,
    title:
      shiftType.title.toLowerCase() === 'Specialized: Weight management'.toLowerCase()
        ? 'Specialized'
        : shiftType.title,
  }));

  return newShiftTypesList && newShiftTypesList.length > 0 ? [overviewItem, ...newShiftTypesList, offlineItem] : [];
};
