import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CognitoAuthUser } from 'hooks/pages/useSignIn.types';
import type { RootState } from 'store';

import { UserProps } from './user.types';

const initialState: UserProps = {
  _id: '',
  cognitoUsername: '',
  isFederated: false,
  accessToken: '',
  idToken: '',
  refreshToken: '',
  elationId: '',
  elationPracticeId: '',
  displayName: '',
  isEmailVerified: false,
  lastLogin: '',
  status: '',
  firstName: '',
  lastName: '',
  profileImage: '',
  email: '',
  timezone: '',
  userRoleInfo: {
    color: '',
    editingPermissions: [''],
    name: '',
    pagePermissions: [''],
    shortName: 'MD',
    _id: '',
  },
  userType: {
    name: '',
    shortCode: '',
    _id: '',
  },
  rating: undefined,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setToken: (
      state,
      { payload: { accessToken, idToken } }: PayloadAction<{ accessToken: string; idToken: string }>,
    ) => {
      state.accessToken = accessToken;
      state.idToken = idToken;
    },
    setUser: (state, action: PayloadAction<UserProps | (CognitoAuthUser & UserProps & { isFederated: boolean })>) => {
      if ('session' in action.payload) {
        const session = (action.payload as CognitoAuthUser)?.session;

        const user: { [i: string]: unknown } = {};
        if (session?.accessToken && session?.idToken) {
          const idPayload = session.idToken.payload;
          // MongoDB user _id ref
          user._id = action.payload._id;
          user.isFederated = action.payload.isFederated;
          user.cognitoUsername = idPayload['cognito:username'];
          user.accessToken = session?.accessToken?.toString();
          user.idToken = session?.idToken?.toString();
          user.elationId = action.payload.elationId;
          user.elationPracticeId = action.payload.elationPracticeId;
          user.isEmailVerified = idPayload.email_verified;
          user.lastLogin = action.payload.lastLogin;
          user.status = action.payload.status;
          user.firstName = idPayload.given_name || action.payload.firstName;
          user.lastName = idPayload.family_name || action.payload.lastName;
          user.displayName = idPayload.displayName || action.payload.displayName;
          user.profileImage = idPayload.picture || action.payload.profileImage;
          user.email = idPayload.email;
          user.timezone = idPayload.zoneinfo || action.payload.timezone;
          user.ai = action.payload.ai;

          const userRoleInfo = action.payload.userRoleInfo;
          if (userRoleInfo) {
            user.userRoleInfo = {
              color: userRoleInfo.color,
              editingPermissions: userRoleInfo.editingPermissions,
              name: userRoleInfo.name,
              pagePermissions: userRoleInfo.pagePermissions,
              shortName: userRoleInfo.shortName,
              _id: userRoleInfo._id,
            };
          }
          const userType = action.payload.userType;
          if (userType) {
            user.userType = {
              name: userType.name,
              shortCode: userType.shortCode,
              _id: userType._id,
            };
          }
          user.rating = action.payload.rating;
        }

        return {
          ...state,
          ...user,
        };
      } else
        return {
          ...state,
          ...action.payload,
        };
    },
    updateAIMessageTaskAutoRoutingRead: (state, action: PayloadAction<{ messageTaskAutoRoutingRead: string }>) => {
      return {
        ...state,
        ai: { messageTaskAutoRoutingRead: action.payload.messageTaskAutoRoutingRead },
      };
    },
    updateUserTimezone: (state, action: PayloadAction<{ timezone: string }>) => {
      return {
        ...state,
        timezone: action.payload.timezone,
      };
    },
    clearUser: (): UserProps => {
      return initialState;
    },
  },
});

export const selectUser = (state: RootState) => state.user;

export const selectUserPermissions = (state: RootState) => ({
  pagePermission: state.user.userRoleInfo.pagePermissions,
  editingPermission: state.user.userRoleInfo.editingPermissions,
});

export const selectCanCreateFrontDeskChannel = createSelector(
  (state: RootState) => state.user.userRoleInfo.editingPermissions,
  (editingPermissions) => editingPermissions?.includes('CREATE_FRONT_DESK_CHANNEL'),
);

export const { setToken, setUser, updateUserTimezone, clearUser, updateAIMessageTaskAutoRoutingRead } =
  userSlice.actions;

export default userSlice.reducer;
