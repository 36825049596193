import { ReviewStatuses } from 'store/reviews/reviews.types';

import { ReviewsFiltersProps } from './reviewsFiltersForm.types';

export const defaultValues: ReviewsFiltersProps = {
  searchKey: '',
  providerName: { value: '', label: '' },
  patientName: { value: '', label: '' },
  startCreateDate: '',
  endCreateDate: '',
  createdAt: '',
  status: { value: '', label: '' },
  rating: { value: '', label: '' },
};

export const RATING_FILTER = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
];

export const REVIEW_STATUS_FILTER = [
  { label: 'All', value: '' },
  { label: 'New', value: ReviewStatuses.New },
  { label: 'Published', value: ReviewStatuses.Published },
  { label: 'Hidden', value: ReviewStatuses.Hidden },
];
