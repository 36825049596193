// NOTE: Because I can't position Queue slice before Api slice because of using extra reducers

import { FULL_BACKEND_DATE_FORMAT } from 'constants/dateFormat';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { QueueTaskStatuses } from 'enums/taskCategories';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { TaskDetailsProps } from 'models/tasks.types';
import socketStaff from 'socket/socketStaff';
import type { RootState } from 'store';
import { apiSlice } from 'store/api/apiSlice';
import { UpdatedTaskResponseProps } from 'store/tasks/task.types';
import { checkToRemoveQueueGroupedTask } from 'utils/tasks';

import { normelizeQueueTasks } from './queueSlice.settings';
import { initialState, QueueResponse, QueueTaskDetails, QueueTypes } from './queueSlice.types';

export const queueApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => {
    return {
      getQueueInfo: build.query<{ data: QueueResponse; message: string }, void>({
        query: () => `/queue`,
      }),
      getQueueList: build.query<QueueTaskDetails[], void>({
        query: () => `/queue/tasks`,
        transformResponse: (response: { data: { tasks: QueueTaskDetails[] } }) => response.data.tasks,
        async onCacheEntryAdded(_, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
          try {
            await cacheDataLoaded;

            const updateTaskInList = (data: UpdatedTaskResponseProps) => {
              if (isEmpty(data.updatedData)) return;

              updateCachedData((draft) => {
                const updatedTasks: QueueTaskDetails[] = cloneDeep(draft);
                if (!updatedTasks?.length) return;
                const taskIndex = updatedTasks.findIndex((task: QueueTaskDetails) => task._id === data.taskId);
                if (taskIndex !== -1) {
                  // Remove task from list or update it
                  if (checkToRemoveQueueGroupedTask(data)) {
                    updatedTasks.splice(taskIndex, 1);
                  } else {
                    updatedTasks[taskIndex] = {
                      ...updatedTasks[taskIndex],
                      ...data.updatedData,
                      patientName: data.updatedData.patientInfo.name,
                    };
                  }

                  return updatedTasks;
                }
              });
            };

            socketStaff.on('taskUpdatedData', updateTaskInList);
          } catch {
            // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
            // in which case `cacheDataLoaded` will throw
          }
          await cacheEntryRemoved;
        },
      }),
      getQueueTaskDetails: build.query<TaskDetailsProps, void>({
        query: () => `/queue/next-task`,
        transformResponse: (response: { data: TaskDetailsProps }) => response.data,
      }),
      getQueueScheduleDetails: build.query<QueueTypes, { localTz: string }>({
        query: (localTz) => ({
          url: `/queue/schedule-details`,
          params: localTz,
        }),
        transformResponse: (response: { data: QueueTypes }) => response.data,
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setQueueScheduleDetails(data));
          }
        },
        async onCacheEntryAdded(_, { updateCachedData, cacheDataLoaded, cacheEntryRemoved, dispatch }) {
          try {
            const { data: cacheData } = await cacheDataLoaded;

            if (cacheData) {
              dispatch(setQueueScheduleDetails(cacheData));
            }

            const updateQueueData = (data: QueueTypes) => {
              if (data) {
                updateCachedData(() => {
                  return data;
                });
                dispatch(setQueueScheduleDetails(data));
              }
            };
            socketStaff.on('queueDataUpdated', updateQueueData);
          } catch {
            // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
            // in which case `cacheDataLoaded` will throw
          }
          await cacheEntryRemoved;
        },
      }),
    };
  },
});

const queueSlice = createSlice({
  name: 'queue',
  initialState,
  reducers: {
    setQueueScheduleDetails: (state, action) => {
      state.queueScheduleDetails = action.payload;
    },
    setQueueInfoInProgress: (state, action) => {
      state.queueInfo.taskInProgress = action.payload;
    },
    resetQueueScheduleDetails: (state) => {
      state.queueScheduleDetails = initialState.queueScheduleDetails;
    },
    setQueueLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setOnGetQueueDetails: (state, action) => {
      state.onGetQueueDetails = action.payload;
    },
    resetQueueState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(queueApiSlice.endpoints.getQueueInfo.matchFulfilled, (state, action) => {
      if (action.payload.data) {
        state.queueTask = {
          patientName: action.payload.data.patientsCount.toString(),
          createdAt: action.payload.data?.taskDetails?.createdAt ?? dayjs().format(FULL_BACKEND_DATE_FORMAT),
          category: action.payload.data.taskDetails?.category ?? 'Appointment',
          details: action.payload.data.taskDetails?.subCategory,
          maImageUrl: action.payload.data?.taskDetails?.maImageUrl ?? '',
          patientStatus: action.payload.data.taskDetails?.patientStatus ?? QueueTaskStatuses.MIF_INCOMPLETE,
          dynamicStatus: action.payload.data.taskDetails?.dynamicStatus ?? '',
          addedToQueueAt: action.payload.data.taskDetails?.addedToQueueAt,
          doctor: 'Unassigned',
          assignedTo: 'Unassigned',
          status: 'New',
          subRows: state.queueTask.subRows,
        };
        state.queueInfo = { taskInProgress: action.payload.data.taskInProgress };
      }
    });

    builder.addMatcher(queueApiSlice.endpoints.getQueueList.matchFulfilled, (state, action) => {
      if (action.payload) {
        state.queueTask.subRows = normelizeQueueTasks(action.payload) as unknown as [];
      }
    });
  },
});

export default queueSlice.reducer;

export const { setQueueScheduleDetails, setOnGetQueueDetails, resetQueueState, setQueueLoading } = queueSlice.actions;

export const selectQueue = (state: RootState) => state.queue;

export const {
  useLazyGetQueueInfoQuery,
  useLazyGetQueueListQuery,
  useLazyGetQueueTaskDetailsQuery,
  useLazyGetQueueScheduleDetailsQuery,
} = queueApiSlice;
