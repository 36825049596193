import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import { PlanCodesProps } from 'enums/appointmentStatus';
import { useToggle } from 'react-use';

import { PlanCardProps } from './planCard.types';

const PlanCard: React.FC<PlanCardProps> = ({ plan, activePlanId, selectedPlan, planName, price, isUsedFreeVisit }) => {
  const [isShowDetails, toggleShowDetails] = useToggle(false);

  const toggle = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    toggleShowDetails();
  };

  const planStatus = !!plan
    ? `${
        plan._id === activePlanId
          ? 'current'
          : plan.planCode === PlanCodesProps.LifeMDMembership
            ? 'downgrade'
            : 'upgrade your'
      } plan`
    : '';
  const questionClassName = (value: boolean) =>
    classNames('text-mSm md:text-sm font-medium', value ? 'text-primary' : 'text-gray');
  const questionWrapperClassName = 'mb-2 flex items-center gap-2';
  const wrapperClassName = 'mb-4 flex h-8 w-full items-center justify-between';
  const statusClassName = (planCode: PlanCodesProps) =>
    classNames('rounded w-fit py-1 px-2 uppercase text-sm font-semibold', {
      'bg-secondary text-white': planCode === PlanCodesProps.UnlimitedMembership && selectedPlan !== plan._id,
      'bg-secondary-100 text-secondary-600':
        planCode !== PlanCodesProps.UnlimitedMembership && selectedPlan !== plan._id,
      'bg-primary-100 text-primary': selectedPlan === plan._id,
    });
  const planPriceClassName = 'mb-1 text-mXl font-semibold md:text-lg';
  const planMonthClassName = 'text-mSm md:text-sm';
  const circleClassName = 'h-7 w-7 text-primary';
  const questionIconClassName = (value: boolean) => classNames('flex-none', value ? 'text-secondary' : 'text-gray');
  const planNameClassName = 'mb-4 text-mLg font-bold text-primary md:text-lg';
  const planDescriptionClassName = 'mb-4 block';
  const additionalInfoText = 'text-mXs text-gray md:text-xs';

  const description = [PlanCodesProps.LifeMDMembership, PlanCodesProps.InitialAppointment].includes(plan.planCode)
    ? 'Each appointment with a provider is limited to a single issue.'
    : plan.planCode === PlanCodesProps.TotalCareMembership
      ? `The patients’ current plan includes one free visit per month. They have already ${
          isUsedFreeVisit ? 'used' : 'scheduled'
        } this free visit, and will need to pay to schedule another appointment.`
      : 'Upgrade their plan to get unlimited appointments and much more.';

  return (
    <>
      <div className={wrapperClassName}>
        <div className={statusClassName(plan.planCode)}>{planStatus}</div>
        {selectedPlan === plan._id && <Common.Icon className={circleClassName} name="check-circle" />}
      </div>
      <h3 className={planPriceClassName}>
        ${price}
        {plan.planCode === PlanCodesProps.UnlimitedMembership && <span className={planMonthClassName}>/mo</span>}
      </h3>
      <h4 className={planNameClassName}>{planName || plan.planName}</h4>
      <span className={planDescriptionClassName}>{description}</span>
      {plan.isUnlimitedPlan && (
        <>
          {isShowDetails && (
            <div className="my-4">
              {plan.additionalInfo.map((item) => (
                <div className={questionWrapperClassName} key={item.question}>
                  <Common.Icon className={questionIconClassName(item.value)} name={item.value ? 'check' : 'close'} />
                  <span className={questionClassName(item.value)}>{item.question}</span>
                </div>
              ))}
              <div className="flex flex-col gap-2">
                <span className={additionalInfoText}>
                  * With the option to choose a new primary care provider at any time.
                </span>
                <span className={additionalInfoText}>** Outside of controlled substances.</span>
              </div>
            </div>
          )}
          <a data-testid="detail-icon" className="flex items-center gap-1 font-bold text-primary" onClick={toggle}>
            <Common.Icon name={isShowDetails ? 'arrow-alt-up' : 'arrow-alt-down'} />
            <span>{isShowDetails ? 'Hide details' : 'See details'}</span>
          </a>
        </>
      )}
    </>
  );
};

export default PlanCard;
