import InputField from 'components/forms/controlled/InputField';
import { useFormContext } from 'react-hook-form';
import { validation } from 'utils/helpers';
import { NUMBER_REGEXP } from 'utils/regExp';

const UpdateWeight: React.FC<{ metric?: number }> = ({ metric }) => {
  const { control, formState } = useFormContext();

  return (
    <div className="flex flex-col gap-4">
      <InputField
        name="lbs"
        label="Weight"
        control={control}
        labelDirection="row"
        type="number"
        maxLength={10}
        errors={formState.errors.lbs}
        rules={validation('Lbs', NUMBER_REGEXP)}
        postText="lbs"
        defaultValue={metric}
      />
    </div>
  );
};

export default UpdateWeight;
