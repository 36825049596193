import { useEffect, useState } from 'react';

import dayjs from 'dayjs';

const DateLabel = ({ date }: { date: string }) => {
  const [displayDate, setDisplayDate] = useState('');

  useEffect(() => {
    if (dayjs(date).isToday()) {
      setDisplayDate('Today');
    } else {
      setDisplayDate(new Date(date).toLocaleDateString());
    }
  }, [date]);

  return (
    <div className="sticky top-2 z-[1] mx-auto mb-4 flex w-32 items-center justify-center rounded-full border border-gray-500 bg-gray-50 px-10 py-1 text-center text-sm font-bold text-gray transition-all md:top-3">
      {displayDate}
    </div>
  );
};

export default DateLabel;
