import { twMerge } from 'tailwind-merge';

import { CardProps } from './card.types';

const Card: React.FC<CardProps> = ({ children, className, onClick, dataTestId }) => {
  return (
    <div data-testid={dataTestId} onClick={onClick} className={twMerge('rounded-lg bg-white shadow', className)}>
      {children}
    </div>
  );
};

export default Card;
