import { ChangeEvent, useEffect, useRef, useState } from 'react';

import { nanoid } from '@reduxjs/toolkit';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import { notifyError } from 'components/common/Toast/Toast';

import { fileValidator } from './uploader.settings';
import { UploaderProps } from './uploader.types';

const Uploader: React.FC<UploaderProps> = ({
  isSuccess,
  uploadDocuments,
  setIsError,
  isError,
  toggleDisplayUploader,
}) => {
  const [dragOverlay, setDragOverlay] = useState(false);
  const [filesForUpload, setFilesForUpload] = useState<FileList | null>(null);
  const dragCounter = useRef(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const [fileList, setFileList] = useState<{ file: File; isValidFile: boolean; errVal: string }[]>([]);
  const [countError, setCountError] = useState(0);

  const handleUpdatedFilesForUpload = () => {
    if (isError) {
      notifyError('File format is invalid');
    }
    if (fileList.length) {
      Array.from(fileList).forEach((item) => {
        if (item.isValidFile) {
          const formData = new FormData();
          formData.append('patientDocumentFile', item.file);
          uploadDocuments(formData);
        }
      });
    }
  };

  useEffect(handleUpdatedFilesForUpload, [fileList]);

  const preventBrowserDefaults = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e: React.DragEvent<HTMLDivElement>) => {
    preventBrowserDefaults(e);
    dragCounter.current++;
    if (e.dataTransfer.items.length > 0) {
      setDragOverlay(true);
    }
  };
  const handleDragOut = (e: React.DragEvent<HTMLDivElement>) => {
    preventBrowserDefaults(e);
    dragCounter.current--;
    if (dragCounter.current === 0) {
      setDragOverlay(false);
    }
  };

  const closeUploader = (timer: number) => {
    setTimeout(() => {
      toggleDisplayUploader();
      setIsError(false);
    }, timer);
  };

  const onChangeFiles = (files: FileList) => {
    setDragOverlay?.(false);
    dragCounter.current = 0;
    let isValid = true;
    const list = [];
    let count = 0;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const { isValidFile, errVal } = fileValidator(file);
      if (isValidFile === false) {
        count++;
      }
      list.push({ file, isValidFile, errVal });
      isValid = isValid && isValidFile;
    }
    setCountError(count);
    setFileList(list);
    if (!isValid) {
      setIsError(true);
      if (list.length === 1) {
        closeUploader(1000);
      } else {
        closeUploader(3500);
      }
      return false;
    }
    setFilesForUpload(files);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    if (!e.dataTransfer) {
      return;
    }
    const files = e.dataTransfer.files;
    onChangeFiles(files);
    preventBrowserDefaults(e);
  };

  const handleBrowseFiles = () => {
    inputRef.current?.click();
  };

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    onChangeFiles(e.target.files);
  };

  const uploadWrapperClassName = classNames(
    'p-8 border-dashed border border-gray-400 text-gray rounded-xl my-4 flex flex-col gap-4 items-center text-center',
    { 'bg-primary-400 text-white border-2 md:border-solid border-primary-500 justify-center h-[280px]': dragOverlay },
  );

  return isSuccess || isError ? (
    <>
      {isSuccess && !isError ? (
        <div className="my-4 flex h-64 flex-col items-center justify-center gap-4 rounded-xl bg-green p-8 text-base font-bold text-white">
          <Common.Icon name="check-circle" className="size-10" />
          Success!
        </div>
      ) : (
        <>
          <div className="my-4 flex h-64 flex-col items-center justify-center gap-4 rounded-xl bg-red-100 p-8 text-base font-bold text-red-500">
            <Common.Icon name="error" className="size-10" />
            File type not allowed.
          </div>
          {fileList?.length > 1 && (
            <div className="my-4 flex flex-col items-center justify-center gap-4 rounded-xl border border-gray-200 bg-white p-8 text-black">
              <Common.Icon name="check-circle" className="size-10 text-green" />
              Upload complete with {countError} error.
              {fileList?.length &&
                fileList.map((item) => (
                  <div key={nanoid()} className="w-full max-w-[385px]">
                    {item.errVal.length ? (
                      <div
                        key={item.file.lastModified}
                        className="flex w-full max-w-[385px] items-center gap-2 truncate rounded-2xl bg-red-100 px-4 py-2 text-base"
                      >
                        <Common.Icon name="error" className="size-8 text-red-500" />
                        <div className="flex flex-col">
                          <p className="font-medium text-gray-700">{item.file.name}</p>
                          <p className="font-bold text-red-500">Failed virus scan.</p>
                        </div>
                      </div>
                    ) : (
                      <div
                        key={item.file.lastModified}
                        className="flex w-full max-w-[385px] items-center gap-2 truncate rounded-2xl border border-gray-200 bg-white px-4 py-2 text-base"
                      >
                        <Common.Icon name="articles" className="size-8 text-primary" />
                        <div className="flex flex-col">
                          <p className="font-medium text-gray-700">{item.file.name}</p>
                          <p className="font-bold text-green-500">Upload successful.</p>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          )}
        </>
      )}
    </>
  ) : filesForUpload ? (
    <div className="my-4 flex flex-col items-center gap-4 rounded-xl bg-gray-100 p-8">
      <Common.Logo name="cross" />
      <p className="text-base font-bold text-gray-700">Uploading 1 of {filesForUpload.length}...</p>
      <div className="flex w-full max-w-[385px] flex-col gap-2">
        {Array.from(filesForUpload).map((file: File) => (
          <div
            key={nanoid()}
            className="flex items-center gap-2 truncate rounded-2xl bg-gray-200 px-4 py-2 text-base font-medium text-gray-700"
          >
            <Common.Icon name="articles" className="size-8 text-primary" />
            <span className="flex-1 truncate">{file.name}</span>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <div
      className={uploadWrapperClassName}
      onDragEnter={handleDragIn}
      onDragLeave={handleDragOut}
      onDragOver={preventBrowserDefaults}
      onDrop={handleDrop}
    >
      <input type="file" multiple className="hidden" ref={inputRef} onChange={onChangeInput} />
      {!dragOverlay && <Common.Icon name="articles" className="size-12 text-gray" />}
      <h2 className="text-mLg font-bold text-primary md:hidden">Upload a document</h2>
      <div>
        <p className="hidden text-base font-bold md:block">Drop your photos or videos here to start uploading.</p>
        <p className="text-base font-medium"> Your patient will be able to see any documents you upload here.</p>
      </div>
      {!dragOverlay && (
        <>
          <span className="hidden text-base font-bold text-gray md:block">OR</span>
          <Common.Button color="blue-alt" onClick={handleBrowseFiles}>
            Browse files
          </Common.Button>
        </>
      )}
    </div>
  );
};

export default Uploader;
