import React, { Suspense, useEffect, useState } from 'react';

import IdentityFile from 'components/tasks/slidingPane/IdentitySection/IdentityFile';
import { twMerge } from 'tailwind-merge';
import { lazyWithRetries } from 'utils/common/lazy';

import type { IdentityGalleryProps } from './IdentityGallery.types';
import { IdentityFileCategories } from '../IdentitySection/IdentityFile/identityFile.types';

const loadImageGallery = () => import('components/modals/TaskPopover/TaskDetails/ImageGallery');
const ImageGallery = lazyWithRetries(loadImageGallery);

const IdentityGallery: React.FC<IdentityGalleryProps> = ({
  patientId,
  shouldShowIdentity,
  handleChangeIdentity,
  identity,
  className,
}) => {
  const [galleryImages, setGalleryImages] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const onOpenGallery = (index: number) => {
    setPhotoIndex(galleryImages.length > 1 ? index : 0);
    setIsOpen(true);
  };

  useEffect(() => {
    const selfPortraitPath = identity?.selfPortrait?.filePath;
    const governmentIssuedPath = identity?.governmentIssuedId?.filePath;
    const updatedGalleryImages: string[] = [
      ...(selfPortraitPath ? [selfPortraitPath] : []),
      ...(governmentIssuedPath ? [governmentIssuedPath] : []),
    ];

    setGalleryImages(updatedGalleryImages);
  }, [identity]);

  return (
    <>
      {shouldShowIdentity && (
        <div className={twMerge('flex gap-2', className)}>
          <IdentityFile
            file={identity?.selfPortrait}
            type="selfPortrait"
            label="Add photo"
            patientId={patientId}
            onChangeIdentityImg={handleChangeIdentity}
            onOpenGallery={() => onOpenGallery(0)}
            category={IdentityFileCategories.USER_IDENTITY}
          />
          <IdentityFile
            file={identity?.governmentIssuedId}
            type="governmentIssuedId"
            label="Add ID"
            patientId={patientId}
            onChangeIdentityImg={handleChangeIdentity}
            onOpenGallery={() => onOpenGallery(1)}
            category={IdentityFileCategories.USER_IDENTITY}
          />
        </div>
      )}

      <Suspense fallback="Loading...">
        {galleryImages.length >= 1 ? (
          <ImageGallery
            images={galleryImages}
            photoIndex={photoIndex}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            setPhotoIndex={setPhotoIndex}
            disableShowChangeImageButton={galleryImages.length < 2}
          />
        ) : null}
      </Suspense>
    </>
  );
};

export default IdentityGallery;
