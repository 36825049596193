import { Role, RolesQueryParams } from 'store/roles/roles.types';

import { apiSlice } from '../api/apiSlice';

export const rolesApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getRoles: build.query<{ roles: Role[]; totalCount: number }, { params: RolesQueryParams }>({
      query: ({ params }) => ({ url: `/user-roles`, params }),
      transformResponse: (response: { data: Role[]; info: { totalCount: number } }) => ({
        roles: response.data,
        totalCount: response.info.totalCount,
      }),
      providesTags: (result) =>
        result ? ['Role', ...result.roles.map(({ _id }) => ({ type: 'Role' as const, id: _id }))] : ['Role'],
    }),
    getRole: build.query<Role, { id: string }>({
      query: ({ id }) => `/user-roles/${id}`,
      transformResponse: (response: { data: Role }) => response.data,
    }),
    updateRole: build.mutation({
      query: ({ body, id }) => ({
        url: `/user-roles/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'Role', id: arg.id }],
    }),
    addRole: build.mutation({
      query: ({ body }) => ({
        url: `/user-roles`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Role'],
    }),
    deleteRole: build.mutation({
      query: ({ id }) => ({
        url: `/user-roles/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Role'],
    }),
  }),
});

export const {
  useGetRolesQuery,
  useLazyGetRolesQuery,
  useLazyGetRoleQuery,
  useUpdateRoleMutation,
  useAddRoleMutation,
  useDeleteRoleMutation,
} = rolesApi;
