import {
  RequestMessage,
  RequestMessageTemplate,
  RequestMessageTemplatesQueryParams,
} from 'store/requestMessageTemplates/requestMessageTemplates.types';
import { SPEC_CHARS_TO_ESCAPE_REGEXP } from 'utils/regExp';

import { apiSlice } from '../api/apiSlice';

export const requestMessageTemplatesApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getRequestMessageTemplates: build.query<RequestMessage, { params: RequestMessageTemplatesQueryParams }>({
      query: ({ params }) => ({
        url: `/request-message-templates`,
        params: {
          ...params,
          searchKey: params?.searchKey?.match(SPEC_CHARS_TO_ESCAPE_REGEXP)
            ? params.searchKey.replace(SPEC_CHARS_TO_ESCAPE_REGEXP, '\\$&')
            : params.searchKey,
        },
      }),
      transformResponse: (response: { data: RequestMessageTemplate[]; info: { totalCount: number } }) => ({
        data: response.data,
        totalCount: response.info.totalCount,
      }),
      providesTags: (result) =>
        result
          ? [
              'RequestMessageTemplate',
              ...result.data.map(({ _id }) => ({ type: 'RequestMessageTemplate' as const, id: _id })),
            ]
          : ['RequestMessageTemplate'],
    }),

    getRequestMessageTemplateById: build.query<RequestMessageTemplate, { id: string }>({
      query: ({ id }) => `/request-message-templates/${id}`,
      transformResponse: (response: { data: RequestMessageTemplate }) => response.data,
      providesTags: (_result, _error, arg) => [{ type: 'RequestMessageTemplate', id: arg.id }],
    }),

    createRequestMessageTemplate: build.mutation({
      query: ({ body }) => ({
        url: `/request-message-templates`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: { data: RequestMessageTemplate; message: string }) => response,
      invalidatesTags: ['RequestMessageTemplate'],
    }),

    updateRequestMessageTemplate: build.mutation({
      query: ({ body, id }) => ({
        url: `/request-message-templates/${id}`,
        method: 'PUT',
        body: body,
      }),
      transformResponse: (response: { data: RequestMessageTemplate; message: string }) => response,
      invalidatesTags: (_result, _error, arg) => [{ type: 'RequestMessageTemplate', id: arg.id }],
    }),

    deleteRequestMessageTemplate: build.mutation({
      query: (id) => ({
        url: `/request-message-templates/${id}`,
        method: 'DELETE',
      }),
      transformResponse: (response: { data: RequestMessageTemplate; message: string }) => response,
      invalidatesTags: ['RequestMessageTemplate'],
    }),
  }),
});

export const {
  useGetRequestMessageTemplatesQuery,
  useLazyGetRequestMessageTemplateByIdQuery,
  useCreateRequestMessageTemplateMutation,
  useUpdateRequestMessageTemplateMutation,
  useDeleteRequestMessageTemplateMutation,
} = requestMessageTemplatesApiSlice;
