import Diamonds from 'assets/icons/chat/Diamonds.svg?react';
import classNames from 'classnames';

import { ScoreContainer, ScoreText } from './suggestionScore.style';
import { Rating } from './suggestionScore.types';

const scoreToRating = [
  { max: 10, min: 8, rating: Rating.excellent },
  { max: 7, min: 5, rating: Rating.good },
  { max: 4, min: 2, rating: Rating.fair },
  { max: 1, min: 0, rating: Rating.poor },
];

const SuggestionScore = ({ score }: { score: number | null }) => {
  const suggestionRating = score
    ? scoreToRating.find((r) => score <= r.max && score >= r.min)?.rating || Rating.poor
    : Rating.poor;

  const containerClasses = classNames(
    'self-start flex w-fit h-7 flex-none items-center gap-2 rounded-full bg-black/30 p-1 pl-3',
    !!score || score === 0 ? 'pr-1' : 'pr-3',
  );

  return (
    <div className={containerClasses}>
      <Diamonds width={16} height={16} fill="#fff" />
      <span className="text-sm font-semibold text-white">Suggested response</span>
      {(!!score || score === 0) && (
        <ScoreContainer $rating={suggestionRating}>
          <ScoreText className="capitalize" $rating={suggestionRating}>
            {suggestionRating}
          </ScoreText>
        </ScoreContainer>
      )}
    </div>
  );
};

export default SuggestionScore;
