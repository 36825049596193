import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import { Trigger, TriggerModule, TriggersQueryParams } from 'store/triggers/triggers.types';

import { apiSlice } from '../api/apiSlice';

const initialState = {
  options: [] as { value: string; label: string }[],
};

export const triggersSlice = createSlice({
  name: 'triggers',
  initialState,
  reducers: {
    setTriggerOptions(state, action: PayloadAction<Trigger[]>) {
      state.options = action.payload.map((trigger) => ({ value: trigger._id, label: trigger.name }));
    },
  },
});

export const { setTriggerOptions } = triggersSlice.actions;

export const triggersApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getTriggers: build.query<
      { triggers: Trigger[]; totalCount: number },
      { params: TriggersQueryParams; triggerModule?: TriggerModule }
    >({
      query: ({ params, triggerModule }) => ({
        url: `/triggers`,
        params: { ...params, triggerModule },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setTriggerOptions(data.triggers));
        } catch (error) {
          console.error(error);
        }
      },
      transformResponse: (response: { data: Trigger[]; info: { totalCount: number } }) => {
        return {
          triggers: response.data,
          totalCount: response.info.totalCount,
        };
      },
      providesTags: (result) =>
        result ? ['Trigger', ...result.triggers.map(({ _id }) => ({ type: 'Trigger', id: _id }))] : ['Trigger'],
    }),
    getTriggerById: build.query<Trigger, { id: string }>({
      query: ({ id }) => `/triggers/${id}`,
      transformResponse: (response: { data: Trigger }) => response.data,
    }),

    createTrigger: build.mutation({
      query: ({ body }) => ({
        url: `/triggers`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: { data: Trigger; message: string }) => response,
      invalidatesTags: ['Trigger'],
    }),

    updateTrigger: build.mutation({
      query: ({ id, body }) => ({
        url: `/triggers/${id}`,
        method: 'PUT',
        body: body,
      }),
      transformResponse: (response: { data: Trigger; message: string }) => response,
      invalidatesTags: (_result, _error, arg) => [{ type: 'Trigger', id: arg.id }],
    }),

    deleteTrigger: build.mutation<{ message: string }, { id: string }>({
      query: ({ id }) => ({
        url: `/triggers/${id}`,
        method: 'DELETE',
      }),
      transformResponse: (response: { message: string }) => response,
      invalidatesTags: ['Trigger'],
    }),
  }),
});

export const selectTriggers = (state: RootState) => state.triggers;

export const {
  useGetTriggersQuery,
  useLazyGetTriggersQuery,
  useLazyGetTriggerByIdQuery,
  useCreateTriggerMutation,
  useDeleteTriggerMutation,
  useUpdateTriggerMutation,
} = triggersApiSlice;

export default triggersSlice.reducer;
