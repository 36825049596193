import { useCallback, useEffect, useMemo, useState } from 'react';

import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import Loader from 'components/common/Loader';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import fileDownload from 'js-file-download';
import { useToggle } from 'react-use';
import {
  patientDocumentsSelectors,
  resetDocuments,
  useLazyDownloadPatientDocumentQuery,
  useLazyGetPatientDocumentsQuery,
  useUploadDocumentsMutation,
} from 'store/patients/patientsSlice';

import Document from './Document';
import DocumentSkeleton from './Document/Skeleton';
import { TABS_DOCUMENTS } from './documents.settings';
import { DocumentsDataItemProps, DocumentsProps } from './documents.types';
import Uploader from './Uploader';
import { DataItemProps } from '../../filters/FilterButtons/filterButtons.types';

const Documents: React.FC<DocumentsProps> = ({ patient }) => {
  const dispatch = useAppDispatch();
  const documents = useAppSelector(patientDocumentsSelectors.selectAll);
  const [getDocuments, { data, isLoading: isLoadingDocuments, isFetching: isFetchingDocuments }] =
    useLazyGetPatientDocumentsQuery();
  const [uploadDocuments, { isSuccess, isLoading }] = useUploadDocumentsMutation();
  const [getDocument] = useLazyDownloadPatientDocumentQuery();
  const [tab, setTab] = useState(TABS_DOCUMENTS[0].label);
  const [page, setPage] = useState(0);
  const [isShowSuccess, setIsShowSuccess] = useState(isSuccess);
  const [isError, setIsError] = useState(false);
  const [displayUploader, toggleDisplayUploader] = useToggle(isLoading);

  const source = useMemo(() => TABS_DOCUMENTS.find((t) => t.label === tab)?.value, [tab]);
  const showLoadMore =
    data && data?.data.length > 0 && data?.info?.totalCount > data?.data.length && !isLoadingDocuments;
  const patientFullName = `${patient?.firstName} ${patient?.lastName}`;

  const handleClickDocument = (document: DocumentsDataItemProps) => {
    window.open(`${document.filePath}`, '_blank');
  };

  const handleDownloadDocument = async (document: DocumentsDataItemProps) => {
    getDocument({ documentId: document._id })
      .unwrap()
      .then((res) => {
        fileDownload(res, document.fileName);
      });
  };

  const handleChangeTab = useCallback(
    (value: DataItemProps) => {
      if (tab !== value.label) {
        dispatch(resetDocuments());
        setPage(0);
        setTab(value.label);
      }
    },
    [dispatch, tab],
  );

  const loadMore = () => {
    setPage(page + 1);
  };

  const handleUploadDocuments = useCallback(
    (body: FormData) => {
      uploadDocuments({ body, patientId: patient._id });
    },
    [uploadDocuments, patient._id],
  );

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        if (!isError) {
          toggleDisplayUploader();
          if (tab === TABS_DOCUMENTS[2].label) {
            dispatch(resetDocuments());
            getDocuments({ patientId: patient._id, source, pageNo: page });
          }
        }
        setIsShowSuccess(false);
      }, 1000);
      if (isError) {
        setIsShowSuccess(false);
      } else {
        setIsShowSuccess(isSuccess);
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    getDocuments({ patientId: patient._id, source, pageNo: page });
  }, [getDocuments, page, patient._id, source]);

  const isLoadingDoc = isLoadingDocuments || isFetchingDocuments;

  return (
    <div className="px-6 pb-6 pt-4">
      <Loader isVisible={isLoadingDocuments} />
      <div data-testid="header" className="flex items-center justify-between">
        <span className="hidden text-xl font-bold text-gray-700 md:block">Documents</span>
        {!displayUploader ? (
          <Common.Button
            preIcon="plus"
            color="blue"
            onClick={toggleDisplayUploader}
            className="fixed inset-x-0 bottom-20 mx-auto shadow-2xl md:static md:m-0 md:shadow-none"
            disabled={isLoading || isFetchingDocuments}
          >
            Upload
          </Common.Button>
        ) : (
          <Common.Button
            preIcon="close"
            color="white-alt"
            onClick={toggleDisplayUploader}
            className="fixed inset-x-0 bottom-20 mx-auto text-gray-700 shadow-2xl md:static md:m-0 md:shadow-none"
            disabled={isLoading || isFetchingDocuments}
          >
            Cancel
          </Common.Button>
        )}
      </div>
      <Common.Tabs
        data={TABS_DOCUMENTS}
        type="bar"
        onChange={handleChangeTab}
        className={classNames('my-4', {
          'pointer-events-none opacity-30': isFetchingDocuments || isLoadingDocuments,
        })}
      />
      {displayUploader ? (
        <Uploader
          isSuccess={isShowSuccess}
          uploadDocuments={handleUploadDocuments}
          setIsError={(error) => setIsError(error)}
          isError={isError}
          toggleDisplayUploader={toggleDisplayUploader}
        />
      ) : (
        <div className="divide-y bg-white">
          {/*If the document is empty and load is complete we will show an empty message. Else, show the documents */}
          {documents.length === 0 && !isLoadingDoc ? (
            <span className="block p-4 text-base text-gray">No documents</span>
          ) : (
            documents?.map((document: DocumentsDataItemProps) => (
              <Document
                dataTestId="uploaded_document"
                key={document._id}
                id={document._id}
                patientId={patient._id}
                title={document?.fileName}
                patientFullName={patientFullName}
                date={document?.createdAt}
                uploadedByName={document?.uploadedBy}
                byCurrentUser={document?.isUploadedByCurrentUser}
                handleClick={() => handleClickDocument(document)}
                handleDownload={() => handleDownloadDocument(document)}
              />
            ))
          )}

          {/*
           If documents are loading, we show skeleton.
           This works for the first loading and show skeleton below the already uploaded documents
          */}
          {isLoadingDoc &&
            Array.from(Array(5).keys()).map((val) => {
              return <DocumentSkeleton key={val} />;
            })}
        </div>
      )}
      {showLoadMore && !displayUploader && (
        <Common.Button
          dataTestId="load_more_btn"
          color="white-alt"
          disabled={isLoadingDoc}
          onClick={loadMore}
          className="mx-auto my-4 text-center"
        >
          Load more...
        </Common.Button>
      )}
    </div>
  );
};

export default Documents;
