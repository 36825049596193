import { useEffect, useState } from 'react';

import { OptionProps } from '@thecvlb/design-system/lib/src/common';
import Loader from 'components/common/Loader';
import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import ControlledTextArea from 'components/forms/controlled/ControlledTextArea';
import { HelperTextForTextArea } from 'components/modals/Automation/AutomationForm/automationForm.settings';
import { Option } from 'models/form.types';
import { useFormContext } from 'react-hook-form';
import { useGetTaskCategoriesQuery, useLazyGetUserTypesQuery } from 'store/lookup/lookupSlice';
import { validation } from 'utils/helpers';

const TaskContent = () => {
  const { control, formState, setValue, getValues } = useFormContext();
  const [getUserTypes, { data: userTypes, isFetching: userTypesFetching }] = useLazyGetUserTypesQuery();
  const { data: taskCategories } = useGetTaskCategoriesQuery();

  const [categoryOptions, setCategoryOptions] = useState<Option[]>([]);
  const [triggerToOptions, setTriggerOptions] = useState<Option[]>([]);

  const onChangeTaskCategory = (option: OptionProps) => {
    setTriggerOptions([]);
    setValue('triggerTo', { label: '', value: '' });
    option && getUserTypes((option as Option).value);
  };

  useEffect(() => {
    if (taskCategories) {
      const newCategoryOptions = taskCategories
        .filter((item) => item !== 'Appointment' && item !== 'Post-consult')
        .map((category) => ({
          value: category,
          label: category,
        }));

      setCategoryOptions(newCategoryOptions);
    }
  }, [taskCategories]);

  useEffect(() => {
    if (userTypes) {
      const newUserTypeOptions = userTypes.map((userType) => ({
        value: userType.shortCode,
        label: userType.name,
      }));

      setTriggerOptions(newUserTypeOptions);
    }
  }, [userTypes]);

  useEffect(() => {
    const taskCategory = getValues('category')?.value;
    getUserTypes(taskCategory);
  }, [getUserTypes, getValues]);

  return (
    <>
      <Loader isVisible={userTypesFetching} />
      <ControlledSelect
        control={control}
        labelDirection="row"
        options={categoryOptions}
        placeholder="Select category..."
        onChange={onChangeTaskCategory}
        rules={validation('Category')}
        label="Category"
        name="category"
      />

      <ControlledTextArea
        control={control}
        name="note"
        label="Note"
        helper={<HelperTextForTextArea />}
        placeholder="Enter note..."
        labelDirection="row"
        rows={4}
        errors={formState.errors.note}
        rules={validation('Note')}
      />

      <ControlledSelect
        control={control}
        labelDirection="row"
        options={triggerToOptions}
        placeholder="Select trigger to..."
        rules={validation('Trigger to')}
        label="Trigger to"
        name="triggerTo"
      />
    </>
  );
};

export default TaskContent;
