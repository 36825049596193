import { createColumnHelper } from '@tanstack/react-table';
import { Common } from '@thecvlb/design-system';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import isEmpty from 'lodash/isEmpty';
import { OrderProps, PatientProps, Prescription } from 'store/orders/orders.types';

import EditCell from './EditCell';
import { formatHeaderText, getColorMapValue } from './ordersTable.settings';
import { OrdersTableTypes } from './ordersTable.types';
import Header from '../Header';

const columnHelper = createColumnHelper<OrdersTableTypes>();

export const columns = [
  columnHelper.accessor(
    (row) => {
      if (isEmpty(row)) {
        return '';
      }
      const patient = row.patient as PatientProps;
      if (!patient) return '';
      return `${patient?.firstName} ${patient?.lastName}`;
    },
    {
      id: 'fullName',
      cell: (info) => <span className="max-w-[300px] font-bold">{`${info.getValue()}`}</span>,
      header: (context) => <Header text="Patient" sortField="patient.lastName" headerContext={context} />,
    },
  ),
  columnHelper.accessor('orderStatus', {
    header: (context) => <Header text="Order status" sortField="orderStatus" headerContext={context} />,
    cell: (info) =>
      info.getValue() && (
        <Common.ColorTag color={getColorMapValue(info.getValue())} text={formatHeaderText(info.getValue()) || ''} />
      ),
  }),
  columnHelper.accessor(
    (row) => {
      if (isEmpty(row)) {
        return '';
      }
      const prescription = row.prescriptions[0] as Prescription;
      return prescription?.brandName ? prescription?.brandName : prescription.name;
    },
    {
      id: 'Prescription',
      cell: (info) => {
        const order = info.cell.row?.original as OrderProps;
        const isCompoundPrescription = order?.isCompound;
        return (
          <div className="flex max-w-[240px] items-center truncate text-sm">
            {isCompoundPrescription ? (
              <>
                <span className="mr-2 flex items-center gap-0.5 rounded-full bg-black/5 px-1.5 py-0.5 text-sm text-primary">
                  <Common.Icon name={'prescription-outline'} />
                </span>{' '}
                {info.getValue()}
              </>
            ) : (
              <>
                <span className="mr-2 flex min-w-[30px] items-center justify-center gap-0.5 rounded-full bg-black/5 p-1 text-sm">
                  {`${info.getValue()?.substring(0, 1)}`}
                </span>{' '}
                {info.getValue()}
              </>
            )}
          </div>
        );
      },
      header: (context) => <Header text="Prescription" sortField="prescription" headerContext={context} />,
    },
  ),
  columnHelper.accessor('createdAt', {
    header: (context) => <Header text="Created date" sortField="metadata.createdAt" headerContext={context} />,
    cell: (info) => dayjs(info.getValue()).format(DateFormat.MM_DD_YYYY_HH_mm),
  }),
  columnHelper.accessor('_id', {
    header: (context) => <Header text="Order id" sortField="_id" headerContext={context} />,
  }),
  columnHelper.accessor('type', {
    header: (context) => <Header text="Pharmacy" sortField="type" headerContext={context} />,
    cell: (info) => <div className="text-sm text-yellow">{info.getValue()}</div>,
  }),
  columnHelper.accessor('pharmacyStatus', {
    header: (context) => <Header text="Pharmacy status" sortField="pharmacyStatus" headerContext={context} />,
    cell: (info) =>
      info.getValue() && (
        <Common.ColorTag color={getColorMapValue(info.getValue())} text={formatHeaderText(info.getValue()) || ''} />
      ),
  }),
  columnHelper.accessor('shippingStatus', {
    header: (context) => <Header text="Shipping" sortField="shippingStatus" headerContext={context} />,
    cell: (info) =>
      info.getValue() && (
        <Common.ColorTag color={getColorMapValue(info.getValue())} text={formatHeaderText(info.getValue()) || ''} />
      ),
  }),
  columnHelper.accessor('trackingNumber', {
    header: (context) => <Header text="Tracking number" sortField="trackingNumber" headerContext={context} />,
    cell: (info) => <p className="max-w-[150px] truncate text-sm font-medium">{info.getValue()}</p>,
  }),
  columnHelper.accessor('actions', {
    header: () => <span className="text-sm font-bold">Actions</span>,
    cell: (info) => <EditCell id={info.row.original._id} />,
    enableSorting: false,
  }),
];
