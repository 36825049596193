import { Suspense, useMemo, useState } from 'react';

import IdentityFile from 'components/tasks/slidingPane/IdentitySection/IdentityFile';
import { BodyImageDocument } from 'models/tasks.types';
import { lazyWithRetries } from 'utils/common/lazy';

import { BodyImageGalleryProps } from './bodyImageGallery.types';
import { IdentityFileCategories } from '../../IdentitySection/IdentityFile/identityFile.types';

const loadImageGallery = () => import('components/modals/TaskPopover/TaskDetails/ImageGallery');
const ImageGallery = lazyWithRetries(loadImageGallery);

const BodyImageGallery: React.FC<BodyImageGalleryProps> = ({ documents, onChangeBodyImage, patientId }) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const galleryImages = useMemo(() => documents.map((document) => document.filePath), [documents]);

  const onOpenGallery = (index: number) => {
    setPhotoIndex(galleryImages.length > 1 ? index : 0);
    setIsOpen(true);
  };

  const handleChangeBodyImage = (newBodyImg: BodyImageDocument | null, documentId?: string) => {
    const updatedBodyImage = documents?.find((document) => document._id === documentId);
    if (!updatedBodyImage) {
      newBodyImg && onChangeBodyImage({ documents: [...documents, newBodyImg] });
    } else if (!newBodyImg) {
      const updatedDocuments = documents.filter((document) => document._id !== documentId);
      onChangeBodyImage({ documents: updatedDocuments });
    } else {
      const updatedDocuments = documents.map((document) =>
        document._id === documentId ? { ...document, ...newBodyImg } : document,
      );
      onChangeBodyImage({ documents: updatedDocuments });
    }
  };

  return (
    <>
      {!!documents.length ? (
        documents.map((document, index) => (
          <IdentityFile
            key={document._id}
            file={document}
            label="Add photo"
            patientId={patientId}
            onChangeBodyImg={handleChangeBodyImage}
            onOpenGallery={() => onOpenGallery(index)}
            category={IdentityFileCategories.BODY_IMAGE}
          />
        ))
      ) : (
        <IdentityFile
          file={{ filePath: '', fileName: '' }}
          label="Add photo"
          patientId={patientId}
          onChangeBodyImg={handleChangeBodyImage}
          onOpenGallery={() => onOpenGallery(0)}
          category={IdentityFileCategories.BODY_IMAGE}
        />
      )}

      <Suspense fallback="Loading...">
        {documents.length >= 1 ? (
          <ImageGallery
            images={galleryImages}
            photoIndex={photoIndex}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            setPhotoIndex={setPhotoIndex}
            disableShowChangeImageButton
          />
        ) : null}
      </Suspense>
    </>
  );
};

export default BodyImageGallery;
