import { Common } from '@thecvlb/design-system';

import { ToggleSuggestionProps } from './toggleSuggestion.type';

const buttonClasses = 'flex flex-none items-center justify-center gap-1 text-sm font-bold text-white/50';

const ToggleSuggestion = ({ isOpen, setIsOpen }: ToggleSuggestionProps) => {
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <button onClick={handleClick} className={buttonClasses}>
      {isOpen ? (
        <>
          <Common.Icon name="close" /> Hide
        </>
      ) : (
        <>
          <Common.Icon name="arrow-alt-down" /> Show
        </>
      )}
    </button>
  );
};

export default ToggleSuggestion;
