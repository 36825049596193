import { Common } from '@thecvlb/design-system';
import Loader from 'components/common/Loader';
import { InsuranceInfoAlt } from 'components/order/Overview/InsuranceInfo';
import { getDateOfBenefits } from 'components/patient/Insurance/Benefits/benefits.settings';
import { useAppSelector } from 'hooks/redux';
import { useGetBenefitsQuery } from 'store/patients/patientsSlice';

import Benefits from '../../../../components/patient/Insurance/Benefits';
import { formatHeaderText, getColorMapValue } from '../../../../components/tables/OrdersTable/ordersTable.settings';
import OrderInfo from '../../Overview/OrderInfo';
import PatientInfo from '../../Overview/PatientInfo';
import PharmacyInfo from '../../Overview/PharmacyInfo';
import PrescriptionInfo from '../../Overview/PrescriptionInfo';

const Body: React.FC = () => {
  const { orderDetails } = useAppSelector(({ orders }) => orders);
  const { data, isFetching } = useGetBenefitsQuery(
    {
      patientId: typeof orderDetails.patient === 'object' ? orderDetails.patient?.externalId : '',
    },
    { skip: typeof orderDetails.patient !== 'object' },
  );

  const mostRecentDate = getDateOfBenefits(data);

  return (
    <>
      <div className="divide-y px-6">
        <div className="grid grid-cols-2 gap-x-16 gap-y-2 py-4">
          <div className="flex items-center">
            <span className="mr-1 w-28 text-base font-bold">Order status</span>
            {orderDetails.orderStatus && (
              <Common.ColorTag
                color={getColorMapValue(orderDetails.orderStatus)}
                text={formatHeaderText(orderDetails.orderStatus) || ''}
              />
            )}
          </div>
          <div className="flex items-center">
            <span className="mr-1 w-28 text-base font-bold">Pharmacy status</span>
            {orderDetails.pharmacyStatus && (
              <Common.ColorTag
                color={getColorMapValue(orderDetails.pharmacyStatus)}
                text={formatHeaderText(orderDetails.pharmacyStatus) || ''}
              />
            )}
          </div>
          <div className="flex items-center">
            <span className="mr-1 w-28 text-base font-bold">Shipping status</span>
            {orderDetails.shippingStatus && (
              <Common.ColorTag
                color={getColorMapValue(orderDetails.shippingStatus)}
                text={formatHeaderText(orderDetails.shippingStatus) || ''}
              />
            )}
          </div>
          <div className="flex items-center">
            <span className="mr-1 w-28 text-base font-bold">Tracking number</span>
            <span className="text-base">{orderDetails.trackingNumber}</span>
          </div>
        </div>
        <div className="flex divide-x pb-6 pt-4">
          <div className="w-full max-w-[224px] pr-8">
            <div className="mb-4 font-bold">Insurance/Coupon used on order</div>
            <div>
              <InsuranceInfoAlt order={orderDetails} insurance={orderDetails.insurance} />
            </div>
          </div>
          <div className="grow pl-8">
            <div className="flex h-full">
              <div className="flex grow flex-col">
                <div className="mb-4 font-bold">Patient benefits</div>
                <div className="min-h-[280px] grow">
                  {isFetching ? (
                    <Loader isVisible={isFetching} />
                  ) : (
                    <Benefits data={data} mostRecentDate={mostRecentDate} isLoading={isFetching} inOrderPanel />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="divide-y bg-gray-100 px-6 pb-6">
        <div className="py-6">
          <OrderInfo order={orderDetails} headingClassNames="mb-2 font-bold text-gray-700" isEditable />
        </div>
        <div className="py-6">
          {typeof orderDetails.patient !== 'string' && orderDetails.patient && (
            <PatientInfo
              order={orderDetails}
              patient={orderDetails.patient}
              headingClassNames="mb-2 font-bold text-gray-700"
              isEditable
            />
          )}
        </div>
        <div className="py-6">
          <h4 className="mb-2 font-bold text-gray-700">Prescription info</h4>
          {orderDetails.prescriptions?.length > 0 && <PrescriptionInfo prescription={orderDetails.prescriptions[0]} />}
        </div>
        {orderDetails.pharmacy && (
          <div className="py-6">
            <PharmacyInfo pharmacy={orderDetails.pharmacy} />
          </div>
        )}
      </div>
    </>
  );
};

export default Body;
