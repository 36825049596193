import { useState } from 'react';

import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { ImageProps } from './image.types';

const Image: React.FC<ImageProps> = ({
  fileName,
  src,
  imageClasses,
  isBlurred,
  onImageClick,
  onMouseEnterImg,
  onFocusImg,
}) => {
  const [isBlur, setIsBlur] = useState(isBlurred);

  const wrapperImageClasses = classNames({
    'backdrop-filter-animation': isBlur === undefined,
    'backdrop-filter-sm': isBlur,
  });
  const wrapperBackdropClassName = classNames('group absolute inset-0', {
    'opacity-100': isBlur,
    'pointer-events-none opacity-0': isBlur === false,
    'remove-backdrop-filter': isBlur === undefined,
  });
  const backDropClassName =
    'w-full flex h-full flex-col items-center justify-center gap-3 whitespace-normal px-1 text-center text-white text-mSm';
  const showButtonClassName = 'h-fit border border-white';

  return (
    <div className="relative w-fit min-w-[100px] overflow-hidden rounded-xl">
      <div className={wrapperImageClasses}>
        <img
          data-testid="sent_img"
          src={src}
          alt={fileName || 'image'}
          className={imageClasses}
          onClick={onImageClick}
          onMouseEnter={onMouseEnterImg}
          onFocus={onFocusImg}
        />
      </div>
      <div className={wrapperBackdropClassName}>
        <div className={backDropClassName}>
          <span>Hidden for your privacy</span>
          <Common.Button
            className={showButtonClassName}
            style="pill"
            onClick={() => {
              setIsBlur(false);
            }}
          >
            Show
          </Common.Button>
        </div>
      </div>
    </div>
  );
};

export default Image;
