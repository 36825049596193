import { tooltip, WEIGHT_TABS } from 'constants/weightChart';

import { useEffect, useMemo, useRef, useState } from 'react';

import { Common } from '@thecvlb/design-system';
import { Chart, ChartType, ChartTypeRegistry, TooltipItem } from 'chart.js';
import classNames from 'classnames';
import EditTargetWeight from 'components/modals/EditTargetWeight';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { DateFormat } from 'enums/dateFormats';
import { useToggle } from 'react-use';

import { externalTooltipHandler, generateChart, getChartData, getRangeFormat } from './weightChart.settings';
import { WeightChartProps } from './weightChart.types';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const WeightChart: React.FC<WeightChartProps> = ({ target, current, patientName, updateWeight, showTabs = true }) => {
  const [isOpenEditTargetWeight, toggleIsOpenEditTargetWeight] = useToggle(false);
  const chartCurrentRef = useRef<HTMLCanvasElement>(null);
  const chartTargetRef = useRef<HTMLCanvasElement>(null);
  const chartCurrentId = useRef<string | null>(null);
  const chartTargetId = useRef<string | null>(null);
  const currentArray = useMemo(() => current || [], [current]);

  const [selectedTab, setSelectedTab] = useState<string>('All');
  const [chartCurrent, setChartCurrent] =
    useState<Chart<keyof ChartTypeRegistry, { date: number; value: number }[], unknown>>();
  const [weightDeltaPercentage, setWeightDeltaPercentage] = useState<number>(0);
  const [chartTarget, setChartTarget] =
    useState<Chart<keyof ChartTypeRegistry, { date: number; value: number }[], unknown>>();
  const labels = useMemo(() => [...currentArray, ...(target ? [target] : [])], [currentArray, target])
    .sort((a, b) => dayjs(a?.date).diff(b?.date))
    .map((el) => dayjs(el?.date).valueOf());
  const currentData = currentArray.map((el) => ({
    date: dayjs(el.date).valueOf(),
    value: el.value,
  }));

  const targetData = target
    ? [
        ...(currentArray.length
          ? [{ date: dayjs(currentArray[0]?.date).valueOf(), value: currentArray[0]?.value }]
          : []),
        ...[{ date: dayjs(target?.date).valueOf(), value: target?.value }],
      ]
    : [];

  const handleSetWeightDeltaPercentage = (_maxX?: number, minX?: number) => {
    if (!currentData.length || !chartCurrent) {
      return;
    }
    const firstWeight = currentData[0];
    const lastWeight = currentData[currentData.length - 1];
    if (minX) {
      const startElementIndex = currentData.findIndex((el) => minX < el.date);
      const startElement = currentData[startElementIndex - 1];
      if (startElement) {
        const endElement = currentData[startElementIndex];

        const start = { x: startElement.date, y: startElement.value };
        const end = { x: endElement.date, y: endElement.value };

        const slope = (end.y - start.y) / (end.x - start.x);
        const yIntercept = start.y - slope * start.x;

        firstWeight.value = slope * minX + yIntercept;
      }
    }

    setWeightDeltaPercentage(Math.round(((firstWeight.value - lastWeight.value) / firstWeight.value) * 100));
  };

  const updateChart = (
    chart: Chart<
      keyof ChartTypeRegistry,
      {
        date: number;
        value: number;
      }[],
      unknown
    >,
    chartType: 'current' | 'target',
    maxX?: number,
    minX?: number,
  ) => {
    const isCurrent = chartType === 'current';
    if (!chart || !chart.canvas || !chart.options?.scales?.x || !chart.options?.plugins) {
      return;
    }

    const rangeFormat: DateFormat = getRangeFormat(labels, minX, maxX);

    chart.data.datasets[0].data = currentData;
    chart.data.datasets[1].data = targetData;
    chart.options.scales.x.max = maxX;
    chart.options.scales.x.min = minX;
    chart.options.scales.x = {
      ...chart.options.scales.x,
      ticks: {
        ...chart.options.scales.x.ticks,
        callback: (_value, index, ticks) => {
          if (index === 0) {
            return dayjs(minX || labels[isCurrent ? 0 : index]).format(rangeFormat);
          } else if (index === ticks.length - 1) {
            return dayjs(maxX || labels[labels.length - 1]).format(rangeFormat);
          } else {
            return undefined;
          }
        },
      },
    };
    if (isCurrent) {
      chart.options.plugins.tooltip = {
        ...chart.options.plugins.tooltip,
        callbacks: {
          ...tooltip.callbacks,
          title: (tooltipItems: TooltipItem<ChartType>[]) =>
            tooltipItems.length
              ? tooltipItems[0].dataIndex === currentData.length - 1
                ? 'Current'
                : dayjs(currentData[tooltipItems[0].dataIndex].date).format(
                    dayjs().diff(currentData[tooltipItems[0].dataIndex].date, 'year')
                      ? DateFormat.MMM_D
                      : DateFormat.MMM_DD_YYYY,
                  )
              : undefined,
        },
      };
    } else {
      chart.options.plugins.tooltip = {
        ...chart.options.plugins.tooltip,
        external: (context) => externalTooltipHandler(context, toggleIsOpenEditTargetWeight),
      };
    }
    chart.update();
    isCurrent && handleSetWeightDeltaPercentage(maxX, minX);
  };

  useEffect(() => {
    const { maxX, minX } = getChartData(selectedTab, labels, currentArray, targetData);
    if (chartCurrent) {
      updateChart(chartCurrent, 'current', maxX, minX);
    } else {
      generateChart(chartCurrentRef, currentData, targetData, 'current', chartCurrentId, setChartCurrent);
    }
    if (chartTarget) {
      updateChart(chartTarget, 'target', maxX, minX);
      const showTooltip =
        !minX || (dayjs(target?.date).isSameOrAfter(minX) && dayjs(target?.date).isSameOrBefore(maxX));
      const tooltipExternal = document.querySelector('#tooltip-external') as HTMLElement | null;
      if (tooltipExternal) {
        tooltipExternal.style.display = showTooltip ? 'flex' : 'none';
      }
    } else {
      generateChart(chartTargetRef, currentData, targetData, 'target', chartTargetId, setChartTarget);
    }
  }, [selectedTab, chartCurrent, currentData]);

  useEffect(() => {
    return () => {
      chartCurrent?.destroy();
      chartTarget?.destroy();
    };
    // handle on unmount
  }, []);

  return (
    <div data-testid="weight_graph" className="flex flex-col gap-4">
      <EditTargetWeight
        isOpen={isOpenEditTargetWeight}
        onClose={(goalWeight) => updateWeight(toggleIsOpenEditTargetWeight, goalWeight)}
        patientName={patientName}
      />
      <div>
        {currentData.length > 1 && (
          <p data-testid="weight_graph_title" className="mt-2 flex gap-1 text-mSm font-semibold text-gray">
            <span
              className={classNames('flex font-bold text-green', weightDeltaPercentage < 0 ? 'text-red' : 'text-green')}
            >
              <Common.Icon name={weightDeltaPercentage < 0 ? 'arrow-up' : 'arrow-down'} />
              {Math.abs(weightDeltaPercentage)}%
            </span>
            {selectedTab === 'All' ? 'from start' : `from last ${selectedTab.toLowerCase()}`}
          </p>
        )}
      </div>
      <div className="relative mx-auto w-full">
        <canvas className="absolute" ref={chartCurrentRef} />
        <canvas ref={chartTargetRef} />
      </div>
      {showTabs && (
        <Common.Tabs
          className="grid !w-full grid-flow-col justify-stretch rounded-full bg-white p-1 shadow"
          data={WEIGHT_TABS}
          defaultSelected={[selectedTab]}
          type="bar"
          isDesktop
          onChange={(el) => {
            setSelectedTab(el.label);
          }}
        />
      )}
    </div>
  );
};

export default WeightChart;
