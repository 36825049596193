import { useEffect, useState } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';
import { useAppSelector } from 'hooks/redux';
import { marked } from 'marked';
import { selectAiAssistant, useSendAnalyticsMutation } from 'store/aiAssistant/aiAssistantSlice';
import { selectSharedFormContext } from 'store/sharedFormContexts/sharedFormContextsSlice';
import { stripHTML } from 'utils/common/parseHTML';

import { PasteResponseProps } from './pasteResponse.types';

const pasteResponseState = createSelector(
  [selectAiAssistant, selectSharedFormContext],
  (aiAssistant, sharedFormContexts) => ({
    hashKey: aiAssistant.hashKey,
    setValue: sharedFormContexts.patientMessages?.setValue,
    formData: sharedFormContexts.patientMessages?.formData,
  }),
);

const PasteResponse = ({ content, isDisabled = false }: PasteResponseProps) => {
  const { hashKey, setValue, formData } = useAppSelector(pasteResponseState);
  const [sendAnalytics] = useSendAnalyticsMutation();
  const [isPasted, setIsPasted] = useState(false);

  const message = formData?.message;

  useEffect(() => {
    const checkIsPasted = async (markdown: string) => {
      const convertedMarkdown = await marked(markdown);

      setIsPasted(
        !!content &&
          !!message &&
          stripHTML(convertedMarkdown).replace(/\s/g, '') === stripHTML(message).replace(/\s/g, ''),
      );
    };

    checkIsPasted(content);
  }, [content, message]);

  const btnText = isPasted ? 'Pasted' : 'Paste response';

  const btnClasses = classNames(
    'flex items-center text-sm px-4 py-2 gap-1 rounded-lg disabled:bg-white/30 disabled:text-gray-700',
    {
      'text-white/50 cursor-default': isPasted || !content,
      'border border-white/50': isPasted,
      'bg-white font-bold  text-gray-700': !isPasted,
    },
  );

  const handleClick = () => {
    if (!isPasted && !!setValue) {
      setValue('message', marked(content));
      hashKey && sendAnalytics({ event: 'isClickedPastResponse', key: hashKey });
    }
  };

  return (
    <button className={btnClasses} onClick={handleClick} disabled={isDisabled || isPasted || !setValue}>
      <Common.Icon name={isPasted ? 'check' : 'chat'} className="size-4" />

      <span>{btnText}</span>
    </button>
  );
};

export default PasteResponse;
