import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { CalendarTab } from 'enums/calendarTabs';
import { DateFormat } from 'enums/dateFormats';
import { EventTypes } from 'models/event.types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  useGetShiftTypesQuery,
  useLazyGetCombineStaffScheduleQuery,
  useLazyGetDayStaffScheduleQuery,
} from 'store/calendar/calendarSlice';

import AvailabilityTable from './AvailabilityTable';
import Header from './Header';
import { generateDaysList, generateUserTimeList } from './providerAvailability.setting';
import { DaysProps, UsersProps } from './providerAvailability.types';

const ProviderAvailability: React.FC = () => {
  const { data: shiftTypes } = useGetShiftTypesQuery();
  const [getDayStaffSchedule, { data, isFetching, isLoading, isUninitialized }] = useLazyGetDayStaffScheduleQuery();
  const [
    getCombineStaffSchedule,
    {
      data: dataCombined,
      isFetching: isFetchingCombined,
      isLoading: isLoadingCombinedData,
      isUninitialized: isCombineUninitialized,
    },
  ] = useLazyGetCombineStaffScheduleQuery();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [week, setWeek] = useState<number>(dayjs().week());
  const [selectedDay, setSelectedDay] = useState<number | null>(null);
  const [activeShiftTypes, setActiveShiftTypes] = useState<string>('');

  const [days, setDays] = useState<DaysProps[]>([]);
  const [users, setUsers] = useState<UsersProps[]>([]);

  const activeShiftTypesParam = searchParams.get('active-shift-types');

  // Now we use local time zones for roles that cannot set their own time zones (AD, MA).
  // Since only AD and MA can see this component, we immediately take the local timezone.
  const localTz = dayjs.tz.guess();

  const isLoadingCombined = isFetchingCombined || isCombineUninitialized || isLoadingCombinedData;

  const onSetShiftTypes = (value: string) => {
    setActiveShiftTypes(value);
    navigate(`?active-tab=${CalendarTab.Availability}${value && `&active-shift-types=${value}`}`, {
      replace: true,
    });
  };

  useEffect(() => {
    if (dataCombined) setDays(generateDaysList(dataCombined, week));
  }, [dataCombined, week]);

  useEffect(() => {
    if (data) {
      setUsers(generateUserTimeList(data, shiftTypes, localTz));
    }
  }, [data, localTz, shiftTypes]);

  const getFilter = (filterTypes: string) => {
    if (!filterTypes) {
      return {
        types: [EventTypes.SHIFT, EventTypes.BREAK, EventTypes.TIME_OFF],
      };
    }

    if (filterTypes === 'offline') {
      return {
        types: [EventTypes.BREAK, EventTypes.TIME_OFF],
      };
    }

    return {
      shiftTypes: [filterTypes],
      types: [EventTypes.SHIFT],
    };
  };

  useEffect(() => {
    if (selectedDay != null && days.length > 0) {
      const fromDate = days[selectedDay]?.date;
      const toDate = dayjs(fromDate).add(1, 'day').format(DateFormat.YYYY_MM_DD);
      const filter = getFilter(activeShiftTypes);

      if (fromDate) {
        const params = {
          fromDate,
          toDate,
          timezone: localTz,
          ...filter,
        };
        getDayStaffSchedule({ params: params });
      }
    }
  }, [selectedDay, week, localTz, getDayStaffSchedule, activeShiftTypes, days]);

  useEffect(() => {
    const filter = getFilter(activeShiftTypes);

    const params = {
      fromDate: dayjs().week(week).day(0).format(DateFormat.YYYY_MM_DD),
      toDate: dayjs().week(week).day(7).format(DateFormat.YYYY_MM_DD),
      timezone: localTz,
      ...filter,
    };
    getCombineStaffSchedule({ params: params });
  }, [week, localTz, getCombineStaffSchedule, activeShiftTypes]);

  useEffect(() => {
    if (activeShiftTypesParam) setActiveShiftTypes(activeShiftTypesParam);
  }, [activeShiftTypesParam]);

  return (
    <div className="pb-4">
      <Header
        selectedWeek={week}
        onChangeWeek={setWeek}
        selectedShiftTypes={activeShiftTypes}
        setSelectedShiftType={onSetShiftTypes}
        shiftTypes={shiftTypes ?? []}
      />
      {!isLoadingCombined && days.length === 0 ? (
        <div className="mx-6 my-16 flex items-center justify-center">
          <div className="text-center text-lg font-semibold text-gray-700">
            Unfortunately, physicians have no available events this week, choose another week.
          </div>
        </div>
      ) : (
        <AvailabilityTable
          days={days}
          users={users}
          selectedDay={selectedDay}
          onSetSelectedDay={setSelectedDay}
          shiftTypes={shiftTypes}
          isLoading={isFetching || isUninitialized || isLoading}
          isLoadingCombined={isLoadingCombined}
        />
      )}
    </div>
  );
};

export default ProviderAvailability;
