import { useEffect, useState } from 'react';

import { OptionProps } from '@thecvlb/design-system/lib/src/common';
import { notifySuccess } from 'components/common/Toast/Toast';
import MessageTemplatesForm from 'components/modals/components/MessageTemplatesForm';
import {
  FormDataTemplates,
  MessageTemplatesProps,
  TemplateTypes,
} from 'components/modals/components/MessageTemplatesForm/messageTemplatesForm.types';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useGetVariablesAndButtonsQuery } from 'store/lookup/lookupSlice';
import { closeModal } from 'store/modal/modalSlice';
import {
  useCreateRequestMessageTemplateMutation,
  useDeleteRequestMessageTemplateMutation,
  useLazyGetRequestMessageTemplateByIdQuery,
  useUpdateRequestMessageTemplateMutation,
} from 'store/requestMessageTemplates/requestMessageTemplatesSlice';

const RequestMessageTemplates: React.FC<MessageTemplatesProps> = ({ id }) => {
  const dispatch = useDispatch();
  const [getTemplateData, { data: singleTemplateData, isLoading }] = useLazyGetRequestMessageTemplateByIdQuery();
  const { data: variablesAndButtons } = useGetVariablesAndButtonsQuery({ type: 'Message' });
  const [updateTemplate, { isLoading: loadingEdit }] = useUpdateRequestMessageTemplateMutation();
  const [deleteTemplate, { isLoading: loadingDelete }] = useDeleteRequestMessageTemplateMutation();
  const [postMessageTemplate, { isLoading: loadingCreate }] = useCreateRequestMessageTemplateMutation();

  const [variableOptions, setVariableOptions] = useState<OptionProps[]>([]);
  const { control, formState, handleSubmit, reset, getValues, setValue } = useForm<FormDataTemplates>({
    reValidateMode: 'onChange',
  });

  const isDisabled = isLoading || loadingCreate || loadingEdit || loadingDelete;

  const onSuccess = (message: string) => {
    notifySuccess(message);
    dispatch(closeModal());
  };

  const onFormSubmit = (formData: FormDataTemplates) => {
    if (formData) {
      const body = {
        message: formData.message,
        shortCode: formData.shortCode,
        status: formData.status?.value,
      };

      const request = id ? updateTemplate({ id, body }) : postMessageTemplate({ body });
      request.unwrap().then((res) => onSuccess(res.message));
    }
  };

  const onDeleteTemplate = () => {
    if (id) {
      deleteTemplate(id)
        .unwrap()
        .then((res) => onSuccess(res.message));
    }
  };

  useEffect(() => {
    if (variablesAndButtons) {
      const newVariables = variablesAndButtons.variables.map((variable) => ({
        label: variable.label,
        value: variable.variable,
      }));

      setVariableOptions(newVariables);
    }
  }, [variablesAndButtons]);

  useEffect(() => {
    if (singleTemplateData) {
      reset({
        message: singleTemplateData.message,
        shortCode: singleTemplateData.shortCode,
        status: { label: singleTemplateData.status, value: singleTemplateData.status },
      });
    }
  }, [reset, singleTemplateData]);

  useEffect(() => {
    if (id) {
      getTemplateData({ id });
    }
  }, [getTemplateData, id]);

  return (
    <MessageTemplatesForm
      type={TemplateTypes.request}
      popupHeaderTitle="Request message template"
      onDeleteTemplate={onDeleteTemplate}
      onFormSubmit={onFormSubmit}
      handleSubmit={handleSubmit}
      formState={formState}
      setValue={setValue}
      getValues={getValues}
      control={control}
      variableOptions={variableOptions}
      disabledButtons={isDisabled}
      id={id}
    />
  );
};

export default RequestMessageTemplates;
