export const getPrescriptionStatusText = (
  type: 'prescription_status' | 'prescription_not_signed',
  isSuccess: boolean,
) => {
  if (type === 'prescription_status') {
    return isSuccess ? 'Draft prescription created' : 'Draft prescription failed';
  } else if (type === 'prescription_not_signed') {
    return 'Not yet signed in Elation';
  } else return '';
};
