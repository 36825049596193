import { useState } from 'react';

import classNames from 'classnames';

import { QuickLinksProps, QuickLinksTypes } from './quickLinks.types';

const QuickLinks: React.FC<QuickLinksProps> = ({ tabLinks, onChange, type = 'standard' }) => {
  const [activeLink, setActiveLink] = useState(tabLinks[0]);

  const linkClasses = (link: string) =>
    classNames({
      'block mt-5': type === QuickLinksTypes.Standard,
      'text-primary font-bold': activeLink === link && type === QuickLinksTypes.Standard,
      'flex gap-2.5 p-4 text-left items-center text-gray': type === QuickLinksTypes.Custom,
      'w-full font-bold text-gray-700 bg-secondary-100 rounded-lg':
        activeLink === link && type === QuickLinksTypes.Custom,
    });

  return (
    <nav>
      {type === QuickLinksTypes.Standard && <p className="font-bold text-black">Quick links</p>}
      {tabLinks.map((link, index) => (
        <button
          onClick={() => {
            setActiveLink(link);
            onChange(link);
          }}
          className={linkClasses(link)}
          key={link}
        >
          {type === QuickLinksTypes.Custom && (
            <div className="size-4 flex-none rounded-full bg-primary text-center align-middle text-xs text-white">
              {index + 1}
            </div>
          )}
          {link}
        </button>
      ))}
    </nav>
  );
};

export default QuickLinks;
