import { PatientPrescriptionsType } from 'enums/prescriptions';

import { PrescriptionsTabProps } from './prescriptions.types';

export const PRESCRIPTIONS_TABS: PrescriptionsTabProps[] = [
  { label: 'All', value: 'all' },
  { label: 'Draft', value: 'true', param: 'isDraft' },
  { label: 'LifeMD orders', value: PatientPrescriptionsType.Order, param: 'type' },
  { label: 'Stable dosages', value: 'true', param: 'isStableDose' },
  { label: 'LifeMD prescriptions', value: PatientPrescriptionsType.LMDPrescription, param: 'type' },
  { label: 'Outside of LifeMD', value: PatientPrescriptionsType.Prescription, param: 'type' },
];
