import { TABLE_ITEMS_AMOUNT_OPTIONS } from 'constants/tables';

import { useCallback, useEffect, useMemo } from 'react';

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import queryString from 'query-string';
import Skeleton from 'react-loading-skeleton';
import ReactPaginate from 'react-paginate';
import { useLocation, useSearchParams } from 'react-router-dom';
import { selectBasicTables, setNewBasicTablesSorting } from 'store/basicTable/basicTableSlice';

import { PaginationProps } from './pagination.types';

const Pagination: React.FC<PaginationProps> = ({
  showingFrom,
  showingTo,
  totalCount,
  pageNoParamName = 'pageNo',
  defaultItemsPerPage,
  resetBulkEditState,
  loading,
  customSortingTableName,
}) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const basicTablesParams = useAppSelector(selectBasicTables);
  const queryParams = useMemo(() => {
    return queryString.parse(searchParams.toString());
  }, [searchParams]);

  const dataLimit = customSortingTableName ? basicTablesParams?.[customSortingTableName]?.limit : queryParams?.limit;

  const itemsPerPage =
    dataLimit ??
    defaultItemsPerPage ??
    (location.pathname.includes('tasks') || location.search.includes('active-tab=Tasks') ? 50 : 10);

  const pageCount = Math.ceil(totalCount / Number(itemsPerPage));
  const pageNumber = customSortingTableName
    ? basicTablesParams?.[customSortingTableName]?.pageNo
    : Number(searchParams.get(pageNoParamName)) || 0;

  const arrowIconClasses = (direction: 'left' | 'right') =>
    classNames(
      'relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-base font-medium hover:bg-gray-50',
      {
        'rounded-l-md': direction === 'left',
        'rounded-r-md': direction === 'right',
        hidden: (direction === 'left' && !pageNumber) || (direction === 'right' && pageNumber === pageCount - 1),
      },
    );

  const handleChangeItemsPerPage = (limit: string) => {
    if (customSortingTableName) {
      dispatch(setNewBasicTablesSorting({ key: customSortingTableName, limit: [limit] }));
    } else {
      searchParams.set('limit', limit);
      const newQueryParams = searchParams.toString();
      setSearchParams(newQueryParams);
    }
  };

  const changePage = useCallback(
    (page: { selected: number }) => {
      if (customSortingTableName) {
        dispatch(setNewBasicTablesSorting({ key: customSortingTableName, pageNo: page.selected }));
      } else {
        searchParams.set(pageNoParamName, page.selected.toString());
        const newQueryParams = searchParams.toString();
        setSearchParams(newQueryParams);
      }

      resetBulkEditState?.();
    },
    [customSortingTableName, resetBulkEditState, dispatch, searchParams, pageNoParamName, setSearchParams],
  );

  useEffect(() => {
    if (pageCount <= pageNumber) {
      changePage({ selected: pageCount - 1 });
    }
  }, [pageNumber, changePage, pageCount]);

  return (
    <div className="flex w-full items-center justify-between">
      {loading ? (
        <>
          <Skeleton width={160} height={30} />
          <Skeleton width={160} height={30} />
        </>
      ) : (
        <>
          <div className="flex items-center gap-3 text-gray">
            <span>Results per page</span>
            <div className="w-20">
              <Common.SelectAlt
                optionsAbove
                hideSuccessState
                onChange={handleChangeItemsPerPage}
                value={String(itemsPerPage)}
                options={TABLE_ITEMS_AMOUNT_OPTIONS}
              />
            </div>
          </div>
          <div className="flex items-center gap-4">
            {!!showingFrom && !!showingTo && (
              <p data-testid="pagination_info" className="text-base">
                Showing <b>{showingFrom}</b> to <b>{showingTo}</b> of <b>{totalCount}</b> results
              </p>
            )}
            <ReactPaginate
              pageCount={pageCount}
              forcePage={pageNumber}
              marginPagesDisplayed={3}
              pageRangeDisplayed={3}
              onPageChange={changePage}
              className="relative flex rounded-md py-4 text-gray"
              pageLinkClassName="border-gray-300 relative inline-flex items-center h-[38px] px-4 py-2 border text-base font-medium"
              activeLinkClassName="bg-secondary-100 border-secondary-400 text-secondary border-secondary"
              nextLabel={
                <span data-testid="paginate_right" className={arrowIconClasses('right')}>
                  <ChevronRightIcon className="size-5" />
                </span>
              }
              previousLabel={
                <span data-testid="paginate_left" className={arrowIconClasses('left')}>
                  <ChevronLeftIcon className="size-5" />
                </span>
              }
              breakLabel={'...'}
              breakClassName="border-gray-300 relative inline-flex items-center px-4 border text-base font-medium"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Pagination;
