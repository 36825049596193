import { PatientAutomationsFiltersFormProps } from './patientAutomationsFiltersForm.types';

export const defaultValues: PatientAutomationsFiltersFormProps = {
  name: '',
  _id: '',
  'triggerTime.timeInHours': '',
  'triggerTime.occurenceOrder': '',
  timeInHours: '',
  searchKey: '',
  status: { label: '', value: '' },
  triggerUnit: { label: '', value: '' },
  triggerType: { label: '', value: '' },
};
