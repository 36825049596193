export enum AvailabilityTab {
  Appointments = 'Appointments',
  Queue = 'Queue',
}

export enum AvailabilityFilterType {
  Overview = 'Overview',
  Appointments = 'Appointments',
  Queue = 'Queue',
  Specialized = 'Specialized',
  Offline = 'Offline',
}
