import { createColumnHelper } from '@tanstack/react-table';

import EditCell from './EditCell';
import { RequestMessagesTemplatesTableProps } from './requestMessagesTemplatesTable.types';
import Header from '../Header';

const columnHelper = createColumnHelper<RequestMessagesTemplatesTableProps>();
export const columns = [
  columnHelper.accessor('message', {
    header: () => <Header text="Message" sortField="message" />,
  }),
  columnHelper.accessor('shortCode', {
    header: () => <Header text="Shortcode" sortField="shortCode" />,
  }),
  columnHelper.accessor('_id', {
    header: () => <Header text="Unique ID" sortField="uniqueId" />,
  }),
  columnHelper.accessor('status', {
    header: () => <Header text="Status" sortField="status" />,
    cell: (info) => (
      <span className={`capitalize ${info.getValue() === 'active' ? 'text-blue' : 'text-red'}`}>{info.getValue()}</span>
    ),
  }),
  columnHelper.accessor('actions', {
    header: () => <span className="text-sm font-bold">Actions</span>,
    cell: (info) => <EditCell id={info.row.original._id} />,
    enableSorting: false,
  }),
];
