import {
  PatientMessage,
  PatientMessageTemplate,
  PatientMessageTemplateQueryProps,
} from 'store/patientMessageTemplates/patientMessageTemplates.types';
import { SPEC_CHARS_TO_ESCAPE_REGEXP } from 'utils/regExp';

import { apiSlice } from '../api/apiSlice';

export const patientMessageTemplatesApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getPatientMessageTemplates: build.query<PatientMessage, { params: PatientMessageTemplateQueryProps }>({
      query: ({ params }) => ({
        url: `/patient-message-templates`,
        params: {
          ...params,
          searchKey: params?.searchKey?.match(SPEC_CHARS_TO_ESCAPE_REGEXP)
            ? params.searchKey.replace(SPEC_CHARS_TO_ESCAPE_REGEXP, '\\$&')
            : params.searchKey,
        },
      }),
      transformResponse: (response: { data: PatientMessageTemplate[]; info: { totalCount: number } }) => ({
        data: response.data,
        totalCount: response.info.totalCount,
      }),
      providesTags: (result) =>
        result
          ? [
              'PatientMessageTemplate',
              ...result.data.map(({ _id }) => ({ type: 'PatientMessageTemplate' as const, id: _id })),
            ]
          : ['PatientMessageTemplate'],
      keepUnusedDataFor: 60 * 3600, // 1 hour
    }),

    getPatientMessageTemplateById: build.query<PatientMessageTemplate, { templateId: string }>({
      query: ({ templateId }) => `/patient-message-templates/${templateId}`,
      transformResponse: (response: { data: PatientMessageTemplate }) => response.data,
    }),

    createPatientMessageTemplates: build.mutation({
      query: ({ message, shortCode, status }) => ({
        url: `/patient-message-templates`,
        method: 'POST',
        body: { message, shortCode, status },
      }),
      transformResponse: (response: { data: PatientMessageTemplate; message: string }) => response,
      invalidatesTags: ['PatientMessageTemplate'],
    }),

    updatePatientMessageTemplates: build.mutation({
      query: ({ body, id }) => ({
        url: `/patient-message-templates/${id}`,
        method: 'PUT',
        body: body,
      }),
      transformResponse: (response: { data: PatientMessageTemplate; message: string }) => response,
      invalidatesTags: (_result, _error, arg) => [{ type: 'PatientMessageTemplate', id: arg.id }],
    }),

    deletePatientMessageTemplates: build.mutation({
      query: (id) => ({
        url: `/patient-message-templates/${id}`,
        method: 'DELETE',
      }),
      transformResponse: (response: { data: PatientMessageTemplate; message: string }) => response,
      invalidatesTags: ['PatientMessageTemplate'],
    }),
  }),
});

export const {
  useGetPatientMessageTemplatesQuery,
  useGetPatientMessageTemplateByIdQuery,
  useLazyGetPatientMessageTemplateByIdQuery,
  useLazyGetPatientMessageTemplatesQuery,
  useUpdatePatientMessageTemplatesMutation,
  useDeletePatientMessageTemplatesMutation,
  useCreatePatientMessageTemplatesMutation,
} = patientMessageTemplatesApiSlice;
