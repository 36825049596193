import { Tab, TabGroup, TabList } from '@headlessui/react';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import UpdateTimezonePopover from 'components/appointments/UpdateTimezone/UpdateTimezonePopover';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { setCalendarTimezone } from 'store/calendar/calendarSlice';
import { useGetStaffQuery } from 'store/staffs/staffsSlice';
import { selectUser } from 'store/user/userSlice';

import Skeleton from './Skeleton';
import { TimezoneHeaderProps } from './timezoneHeader.types';

const timezoneVariants = ['local', 'provider'];

const TimezoneHeader: React.FC<TimezoneHeaderProps> = ({ viewAvailabilityForStaff, staffId }) => {
  const { timezone: loggedInUserTimezone, userType } = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const { data: staffDetails, isLoading: isLoadingStaffDetails } = useGetStaffQuery(
    { staffId: staffId },
    { skip: !staffId },
  );

  const localTimezone = userType.shortCode === 'PH' ? loggedInUserTimezone : dayjs.tz.guess();
  const localFormattedTimezone = dayjs().tz(localTimezone).format(DateFormat.z);

  const providerTimezone = staffDetails?.timezone ? staffDetails?.timezone : dayjs.tz.guess();
  const providerFormattedTimezone = dayjs().tz(providerTimezone).format(DateFormat.z);

  const getTabClassNames = (isActive: boolean) =>
    classNames('text-sm px-3 rounded-full font-semibold py-2.5', {
      'bg-secondary text-white': isActive,
      'bg-gray-100 text-gray-500': !isActive,
    });

  const handleTimezoneChange = (index: number) => {
    const staffTimezone = timezoneVariants[index] === 'local' ? localTimezone : providerTimezone;

    dispatch(setCalendarTimezone(staffTimezone));
  };

  return (
    <>
      {viewAvailabilityForStaff ? (
        <div
          className="mx-6 mt-6 flex items-center justify-between gap-3 rounded-lg bg-secondary-100 px-5 py-3 text-base"
          data-testid="timezone-header"
        >
          <div className="flex w-4/5 items-center justify-between 2xl:w-10/12">
            {isLoadingStaffDetails ? (
              <Skeleton />
            ) : (
              <div className="flex items-center">
                <Common.ProfileImage
                  src={staffDetails?.profileImage}
                  size="sm"
                  role={staffDetails?.userRoleInfo.shortName}
                  className="mr-2"
                />
                <span data-testid="availability_timezone_title" className="font-semibold">
                  Viewing availability for {staffDetails?.firstName} {staffDetails?.lastName} (
                  {providerFormattedTimezone})
                </span>
              </div>
            )}
            <>
              <TabGroup defaultIndex={1} onChange={handleTimezoneChange}>
                <TabList className="flex items-center gap-4">
                  <Tab className={({ selected }) => getTabClassNames(selected)}>
                    Local time ({localFormattedTimezone})
                  </Tab>
                  <Tab className={({ selected }) => getTabClassNames(selected)}>
                    Provider's time ({providerFormattedTimezone})
                  </Tab>
                </TabList>
              </TabGroup>
            </>
          </div>

          <div className="border-l border-gray-400 pl-4">
            <UpdateTimezonePopover
              popoverButtonTitle={`Update ${staffDetails?.firstName} timezone`}
              staffDetails={staffDetails}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default TimezoneHeader;
