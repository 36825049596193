import React from 'react';

import { Common } from '@thecvlb/design-system';
import { OptionProps } from '@thecvlb/design-system/lib/src/common/Select';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { useAppDispatch } from 'hooks/redux';
import { Controller, useFormContext } from 'react-hook-form';
import { closeModal } from 'store/modal/modalSlice';

import { MakeUpProps } from './makeUp.types';

const formatFullDate = (date?: string) => dayjs(date).format(DateFormat.MMM_D__h_mm_a_z);

const MakeUp: React.FC<MakeUpProps> = ({
  handleNext,
  selectedPastAppointment,
  toggleSelectedPastAppointment,
  pastAppointmentsList,
}) => {
  const dispatch = useAppDispatch();
  const { control, watch } = useFormContext();

  const PastAppointmentId = watch('pastAppointmentId');

  const options =
    pastAppointmentsList?.map((app) => {
      const startTime = app.appointmentTime?.startTime && formatFullDate(app.appointmentTime?.startTime);
      return {
        label: `${startTime ?? ''} ${app.appointmentType.displayName}`,
        value: app._id,
      };
    }) || [];

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <>
      <p className="mx-3 mt-8 text-base text-gray-700">
        <b>Make-up appointments</b> are for appointments that have been missed. Patients are not charged for a make-up
        appointment. If this is a make-up appointment, please check below.
      </p>

      <div className="my-8 flex justify-center">
        <Common.Checkbox checked={selectedPastAppointment} onChange={toggleSelectedPastAppointment} color="blue">
          <span>Make-up appointment</span>
        </Common.Checkbox>
      </div>

      {selectedPastAppointment && (
        <>
          <Controller
            name="pastAppointmentId"
            control={control}
            render={({ field }) => (
              <Common.Select
                dataTestId="pastAppointmentId"
                label="Past appointment being made up"
                placeholder="Select..."
                options={options}
                value={options.find((e) => e.value === field.value) as OptionProps}
                onChange={(e: OptionProps) => field.onChange(e.value as string)}
              />
            )}
          />

          <Controller
            name="pastAppointmentDescription"
            control={control}
            render={({ field }) => (
              <Common.TextArea
                className="my-4 w-full"
                label="Details"
                placeholder="Why is the appointment being made up?"
                rows={3}
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              />
            )}
          />
        </>
      )}

      <div className="flex items-center justify-center gap-3">
        <Common.Button color="white-alt" onClick={handleCloseModal}>
          Cancel
        </Common.Button>
        <Common.Button
          color="blue"
          disabled={selectedPastAppointment && !PastAppointmentId}
          postIcon="arrow-right"
          onClick={handleNext}
        >
          Next
        </Common.Button>
      </div>
    </>
  );
};

export default MakeUp;
