import { MIFStatus } from 'enums/taskDetailsStatus';
import { MIFqaData } from 'models/tasks.types';

export enum MIFTabs {
  WarningsOnly = 'Warnings only',
  AllAnswers = 'All answers',
}

export interface MifDetailsProps {
  mifDetails?: {
    id: string;
    status: MIFStatus;
    warningCount?: number;
    qaData?: MIFqaData[];
  };
}
