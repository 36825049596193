import Timezone from 'timezone-enum';
import { ObjectValues } from 'utils/common/types';

export const RepeatOptions = {
  DOES_NOT_REPEAT: 'Does not repeat',
  EVERY_WEEKDAY: 'daily',
  WEEKLY: 'weekly',
  BI_WEEKLY: 'Bi-weekly',
  MONTHLY: 'monthly',
} as const;

export const TimeZoneOptions = {
  UTC: 'UTC',
} as const;

export const EventTypes = {
  SHIFT: 'shift',
  TIME_OFF: 'time-off',
  BREAK: 'break',
} as const;

export const EditEventOptions = {
  CURRENT: 'thisEvent',
  CURRENT_AND_FOLLOWING: 'thisAndFollowingEvent',
  ALL: 'allEvents',
} as const;

export const EventCategoryNames = {
  FILTERS: 'filters',
  EXCLUSIONS: 'exclusions',
} as const;

export type EventType = ObjectValues<typeof EventTypes>;
export type EditEventOption = ObjectValues<typeof EditEventOptions>;

export interface EventDate {
  timeZone: Timezone | string;
  dateTime: Date;
}

export interface ShiftTypeModel {
  _id: string;
  bgColor: string;
  textColor: string;
  title: string;
}

export interface EventModel {
  _id: string;
  type: EventType;
  shiftTypes: { _id: string }[];
  participants: { userId: string }[];
  createdBy: string;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;
  title: string;
  description?: string;
  start: EventDate;
  end: EventDate;
  originalStartTime?: EventDate;
  recurring?: {
    type: ObjectValues<typeof RepeatOptions>;
    interval?: number;
    daysOfWeek?: number[];
    dayOfMonth?: number;
    until?: Date;
  } | null;
  parentEventId?: string;
  recurringEventId?: string;
  deletedAt?: Date;
}

export type EventMultiSelectValue = {
  value: string;
  label: string;
  bgColor?: string;
  textColor?: string;
  category?: string;
};
