import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';

import { MIFOptionProps } from './mifOption.types';

const MIFOption: React.FC<MIFOptionProps> = ({ warning, option, showAllOptions, answersArray, isAdditionalOption }) => {
  const isChosen = (!showAllOptions && !isAdditionalOption) || answersArray.includes(option);
  const isChosenOrAdditional = isChosen || isAdditionalOption;

  const optionClassNames = classNames(
    'flex items-center gap-1',
    isChosenOrAdditional ? 'font-bold' : 'font-medium',
    isChosenOrAdditional ? (warning ? 'text-red-500' : 'text-green-500') : 'text-black/30',
    { 'pl-5': !isChosen || isAdditionalOption },
  );
  return (
    <span className={optionClassNames}>
      {isChosen && (
        <span data-testid="arrow-alt-right">
          <Common.Icon name="arrow-alt-right" className={classNames('h-4 w-4')} />
        </span>
      )}
      {option}
    </span>
  );
};

export default MIFOption;
