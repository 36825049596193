import React, { ReactElement, useEffect, useState } from 'react';

import { Common } from '@thecvlb/design-system';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import queryString from 'query-string';
import { matchPath, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { selectBasicTables, setNewBasicTablesSorting } from 'store/basicTable/basicTableSlice';

import { tablesWithAllowedMultiSort } from './header.settings';
import { ColumnProps, OrderTypes } from './header.types';

const Header: React.FC<ColumnProps> = ({
  text,
  sortField,
  tableName = undefined,
  noSort = undefined,
  customSortingTableName,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const isMultiSortAllowed = !!tablesWithAllowedMultiSort.find(
    (route) =>
      !!matchPath(route.pathname, location.pathname) &&
      Object.entries(route.requiredParams || {}).every(
        ([key, value]) => searchParams.has(key) && searchParams.get(key) === value,
      ),
  );
  const basicTablesParams = useAppSelector(selectBasicTables);
  const basicTablesParamsSortField = customSortingTableName && basicTablesParams?.[customSortingTableName]?.sortField;
  const basicTablesParamsSortOrder = customSortingTableName && basicTablesParams?.[customSortingTableName]?.sortOrder;
  const multiSortFieldName = `sort[${sortField}]`;

  const [order, setOrder] = useState<OrderTypes>(searchParams.get('sortOrder') as OrderTypes);

  const handleClick = (value: OrderTypes) => {
    setOrder(value);

    if (customSortingTableName) {
      if (value) {
        dispatch(setNewBasicTablesSorting({ key: customSortingTableName, sortField, sortOrder: value }));
      } else {
        dispatch(setNewBasicTablesSorting({ key: customSortingTableName, sortField: null, sortOrder: null }));
      }
    } else {
      const queryParams = queryString.parse(location.search);
      let newQueryParams: Record<string, unknown>;

      if (tableName) {
        newQueryParams = {
          ...queryParams,
          ...(value && { [tableName + '--sf']: sortField }),
          ...(value && { [tableName + '--so']: value }),
        };
        if (!value) {
          delete newQueryParams[tableName + '--sf'];
          delete newQueryParams[tableName + '--so'];
        }
      } else
        newQueryParams = {
          ...queryParams,
          ...(value && !isMultiSortAllowed && { sortField: sortField }),
          ...(value && !isMultiSortAllowed && { sortOrder: value }),
          ...(value && isMultiSortAllowed && { [multiSortFieldName]: value }),
        };
      if (!value) {
        if (isMultiSortAllowed) {
          delete newQueryParams[multiSortFieldName];
        } else {
          delete newQueryParams.sortField;
          delete newQueryParams.sortOrder;
        }
      }

      if (isMultiSortAllowed && value) {
        const newParams = new URLSearchParams();
        const currentValue = searchParams.get(multiSortFieldName);

        if (currentValue) {
          // If parameter exists, update its value without changing order
          for (const [param, paramValue] of searchParams.entries()) {
            newParams.append(param, param === multiSortFieldName ? value : paramValue);
          }
        } else {
          // If parameter does not exist, add it to the beginning
          newParams.append(multiSortFieldName, value);
          for (const [key, value] of searchParams.entries()) {
            newParams.append(key, value);
          }
        }
        setSearchParams(newParams);
      } else navigate({ search: queryString.stringify(newQueryParams) });
    }
  };

  const renderArrow = (value: OrderTypes) => {
    const queryParams = queryString.parse(location.search);
    const tableSortField = customSortingTableName ? basicTablesParamsSortField : queryParams?.sortField;
    const multiSortField = queryParams[multiSortFieldName];

    if (tableName && !customSortingTableName) {
      queryParams.sortField = queryParams[tableName + '--sf'];
      value = queryParams[tableName + '--so'] ? (queryParams[tableName + '--so'] as OrderTypes) : null;
    }

    if (!value || (sortField && sortField !== tableSortField && !multiSortField)) {
      return (
        <button data-testid="sort_btn" onClick={() => handleClick('ASC')} className="ml-2">
          <Common.Icon name="arrow-up" className="size-2 text-gray-400" />
          <Common.Icon name="arrow-down" className="size-2 text-gray-400" />
        </button>
      );
    }

    const arrows: { DESC: ReactElement; ASC: ReactElement } = {
      DESC: (
        <button data-testid="sort_btn" onClick={() => handleClick(null)} className="ml-2">
          <Common.Icon name="arrow-alt-up" className="size-3 text-primary" />
        </button>
      ),
      ASC: (
        <button data-testid="sort_btn" onClick={() => handleClick('DESC')} className="ml-2">
          <Common.Icon name="arrow-alt-down" className="size-3 text-primary" />
        </button>
      ),
    };

    if (sortField === tableSortField || multiSortField) {
      return arrows[value];
    }
  };

  useEffect(() => {
    const hasSortOrder = isMultiSortAllowed ? searchParams.has(multiSortFieldName) : searchParams.has('sortOrder');
    if (!customSortingTableName && hasSortOrder) {
      const sortOrderValue = isMultiSortAllowed ? searchParams.get(multiSortFieldName) : searchParams.get('sortOrder');
      setOrder(sortOrderValue as OrderTypes);
    }
  }, [searchParams, customSortingTableName]);

  useEffect(() => {
    if (customSortingTableName && basicTablesParamsSortField === sortField) {
      setOrder(basicTablesParamsSortOrder || 'DESC');
    }
  }, [basicTablesParamsSortField, basicTablesParamsSortOrder, customSortingTableName, sortField]);

  // Note: This is a workaround for the case when the user has the old query params in the URL
  useEffect(() => {
    if (isMultiSortAllowed && (searchParams.has('sortField') || searchParams.has('sortOrder'))) {
      const newParams = new URLSearchParams();
      const currentSortField = searchParams.get('sortField') as string;
      const currentSortOrder = searchParams.get('sortOrder') as string;

      if (currentSortField && currentSortOrder) {
        newParams.append(`sort[${currentSortField}]`, currentSortOrder);
      }
      newParams.delete('sortField');
      newParams.delete('sortOrder');
      setSearchParams(newParams);
    }
  }, []);

  return (
    <div className="flex items-center text-sm font-bold">
      <span>{text}</span>
      {noSort ? <></> : renderArrow(order)}
    </div>
  );
};

export default Header;
