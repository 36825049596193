import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import capitalize from 'lodash/capitalize';
import type { BillingHistoryData } from 'store/patients/patients.types';

import { getStatusClasses } from './billingHistory.settings';
import PaymentCell from './PaymentCell';
import Header from '../Header';

const columnHelper = createColumnHelper<BillingHistoryData>();

export const columns = [
  columnHelper.accessor('title', {
    header: () => <Header text="Invoice" sortField="title" noSort />,
    cell: (info) => <span className="font-semibold underline">{info.getValue()}</span>,
  }),
  columnHelper.accessor('createdAt', {
    header: () => <Header text="Date" sortField="createdAt" />,
    cell: (info) => <span>{dayjs(info.getValue()).format(DateFormat.MMM_DD_YYYY)}</span>,
  }),
  columnHelper.accessor('payment.paymentMethod', {
    header: () => <Header text="Payment Method" sortField="payment.paymentMethod.type" noSort />,
    cell: (info) => <PaymentCell paymentInfo={info.getValue()} credit={info.row.original.credit} />,
  }),
  columnHelper.accessor('status', {
    header: () => <Header text="Status" sortField="status" />,
    cell: (info) => (
      <div className={getStatusClasses(info.getValue())}>
        <h4 className="text-sm font-semibold first-letter:uppercase">{capitalize(info.getValue())}</h4>
      </div>
    ),
  }),
  columnHelper.accessor('amount', {
    header: () => <Header text="Amount" sortField="amount" />,
    cell: (info) => <span className="font-bold">${Number(info.getValue()).toFixed(2)}</span>,
  }),
];
