import { nanoid } from '@reduxjs/toolkit';
import { Common } from '@thecvlb/design-system';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import { AT_LEAST_ONE_LETTER_REGEXP } from 'utils/regExp';

import { AllergiesProps } from './allergies.types';

const Allergies: React.FC<AllergiesProps> = ({ onUpdate, allergyList, loading = false }) => {
  const handleAdd = () => {
    onUpdate([...allergyList, ...[{ id: nanoid(), name: '' }]]);
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && allergyList[allergyList.length - 1].name.trim().length) {
      handleAdd();
    }
  };

  const handleRemove = (id: string) => {
    onUpdate(allergyList.filter((el) => el.id !== id));
  };

  const handleChange = (name: string, id: string) => {
    onUpdate(allergyList.map((el) => (el.id === id ? { ...el, name } : el)));
  };

  const isError = (name: string) => {
    const trimmedName = name.trim();
    const isDirty = trimmedName.length > 0;
    return isDirty && (trimmedName.length < 3 || !trimmedName.match(AT_LEAST_ONE_LETTER_REGEXP));
  };

  return (
    <LayoutGroup id="m-lix">
      <AnimatePresence>
        {allergyList.map((el, i) => (
          <div className="flex flex-col rounded-2xl border bg-white p-4 shadow-sm" key={el.id}>
            <div className="mb-2 flex gap-3" key={el.id}>
              <Common.Input
                dataTestId="allergy_input"
                disabled={loading}
                errors={isError(el.name) ? { [`allergy-${i}`]: isError(el.name) } : undefined}
                helper={isError(el.name) ? 'Min 3 characters with letters' : ''}
                label="Allergy name"
                name={`allergy-${i}`}
                placeholder="Enter here..."
                size="md"
                value={el.name}
                onChange={(e) => handleChange(e.target.value, el.id)}
                onKeyPress={onKeyPress}
              />
              <button
                className="flex pt-9 text-gray transition-all hover:text-red"
                data-testid="remove_allergy_btn"
                disabled={loading}
                onClick={() => handleRemove(el.id)}
              >
                <Common.Icon name="close" />
              </button>
            </div>
          </div>
        ))}
        <motion.div key="add-btn" layoutId="add-btn">
          <Common.Button
            color="white"
            dataTestId="add_allergy_btn"
            disabled={allergyList.length >= 100 || loading}
            preIcon="plus-small"
            size="sm"
            onClick={handleAdd}
          >
            Add allergy
          </Common.Button>
        </motion.div>
      </AnimatePresence>
    </LayoutGroup>
  );
};

export default Allergies;
