import { Common } from '@thecvlb/design-system';
import { DataItemProps } from '@thecvlb/design-system/lib/src/common/Tabs/tabs.types';
import { PatientOverviewTab } from 'enums/patientOverviewTabs';
import { useNavigate } from 'react-router-dom';

import { HeaderProps } from './header.types';

const buttons: DataItemProps[] = [{ label: PatientOverviewTab.Overview }];

const Header: React.FC<HeaderProps> = ({ activeTab, setActiveTab, order }) => {
  const navigate = useNavigate();

  const onClickTab = (button: DataItemProps) => {
    setActiveTab && setActiveTab(button.label as PatientOverviewTab);
    navigate(`?active-tab=${button.label}`, {
      replace: true,
    });
  };

  return (
    <header>
      <div className="flex items-center justify-between">
        <div className="flex w-1/5 min-w-fit items-center justify-start px-2">
          <p data-testid="patient_first+last_name" className="text-xl font-bold text-gray-700">
            Order - {order?.id}
          </p>
        </div>
      </div>
      <Common.Tabs onChange={onClickTab} data={buttons} defaultSelected={[activeTab]} type="line" />
    </header>
  );
};

export default Header;
