import { useCallback, useEffect, useRef, useState } from 'react';

import socketStaff from 'socket/socketStaff';

// NOTE: this hhok was developed to organize the logic of refreshing the tasks list
// when a new task is created we show a button to refresh the list
// if a user doesn't click on the button, the list should be refreshed automatically after cetrain amount of time

export const useRefreshTasksList = ({ refreshTasksList }: { refreshTasksList: () => void }) => {
  const [newTasksCount, setNewTasksCount] = useState(0);
  const [showRefreshTasksButton, setShowRefreshTasksButton] = useState(false);
  const [isTabActive, setIsTabActive] = useState(true);
  const [milliseconds, setMilliseconds] = useState(0);

  const timer = useRef(0);

  useEffect(() => {
    socketStaff.on('newTaskCreated', () => {
      setShowRefreshTasksButton(true);
      setNewTasksCount((prev) => prev + 1);
    });

    return () => {
      socketStaff.off('newTaskCreated');
    };
  }, []);

  useEffect(() => {
    // NOTE: this functionality should work only when the tab is active
    document.addEventListener('visibilitychange', () => {
      setIsTabActive(!document.hidden);
    });
  }, []);

  const dismissRefreshCounts = useCallback(() => {
    setNewTasksCount(0);
    setShowRefreshTasksButton(false);
    setMilliseconds(0);
    clearInterval(timer.current);
  }, []);

  const handleOnClick = () => {
    refreshTasksList();
    dismissRefreshCounts();
  };

  useEffect(() => {
    if (isTabActive && showRefreshTasksButton) {
      timer.current = window.setInterval(() => {
        setMilliseconds((prevTime) => prevTime + 1000);
      }, 1000);
    }
    return () => {
      clearInterval(timer.current);
    };
  }, [isTabActive, showRefreshTasksButton]);

  useEffect(() => {
    // refresh tasks list after 5 minutes
    if (milliseconds >= 300000) {
      refreshTasksList();
      dismissRefreshCounts();
    }
  });

  return { newTasksCount, showRefreshTasksButton, handleOnClick, dismissRefreshCounts };
};
