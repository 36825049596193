import React from 'react';

const CustomLabel: React.FC<{
  label: string;
  planName: string;
}> = ({ label, planName }) => {
  return (
    <div className="w-[120px] text-sm font-semibold">
      {label}
      <p className="text-xs text-gray-500">({planName})</p>
    </div>
  );
};

export default CustomLabel;
