import { SortOrder } from 'store/patientChart/patientChart.types';

export type CustomSorting = {
  limit?: string[] | number[];
  sortField?: string | null;
  pageNo: number;
  sortOrder?: SortOrder | null;
};

export type InitialStateBasicTablesTypes = {
  [key: string]: CustomSorting;
};

export const initialStateBasicTables: InitialStateBasicTablesTypes = {};
