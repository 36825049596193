import { CustomerFeedbackProblemType, FeedbackStatuses } from 'enums/customerFeedback';

export interface FeedbackProps {
  _id: string;
  patientInfo: {
    name: string;
    dob: string;
    doctorId: string;
  };
  providerInfo: {
    name: string;
  };
  rating: number;
  createdAt: string;
  reviewComment: string;
  status: FeedbackStatuses;
  problemType?: CustomerFeedbackProblemType;
}

export interface FeedbackQueryParams {
  limit?: number;
  pageNo?: number;
  sortField?: string;
  sortOrder?: string;
  searchKey?: string;
  status?: string;
  localTz?: string;
  providerName?: string;
  patientName?: string;
  problemType?: string;
  createdAt?: string;
  startCreateDate?: string;
  endCreateDate?: string;
  rating?: string;
}

export interface FeedbackListProps {
  data: FeedbackProps[];
  info: {
    totalCount: number;
  };
}

export const initialState: FeedbackListProps = {
  data: [
    {
      _id: '',
      patientInfo: {
        name: '',
        dob: '',
        doctorId: '',
      },
      providerInfo: {
        name: '',
      },
      rating: 0,
      createdAt: '',
      reviewComment: '',
      status: FeedbackStatuses.New,
    },
  ],
  info: {
    totalCount: 0,
  },
};
