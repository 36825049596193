import { createColumnHelper } from '@tanstack/react-table';

import { AgingAlertsTableProps } from './agingAlertsTable.types';
import EditCell from './EditCell';
import Header from '../Header';

const columnHelper = createColumnHelper<AgingAlertsTableProps>();

export const columns = [
  columnHelper.accessor('name', {
    header: () => <Header text="Name" sortField="name" />,
  }),
  columnHelper.accessor('taskType', {
    header: () => <Header text="Task type" sortField="taskType" />,
  }),
  columnHelper.accessor('criterionField', {
    header: () => <Header text="Alert based on" sortField="criterionField" />,
  }),
  columnHelper.accessor('alertUnit', {
    header: () => <Header text="Alert time" sortField="alertUnit" />,
    cell: (info) => {
      const rowId = parseInt(info.row.id);

      const alertTime = `
        ${info.table.getRowModel().rows[rowId].original.alertAmount}
        ${info.table.getRowModel().rows[rowId].original.alertUnit}
        ${info.table.getRowModel().rows[rowId].original.occurenceOrder}`;

      return <span>{alertTime}</span>;
    },
  }),
  columnHelper.accessor('audience', {
    header: () => <Header text="Trigger to" sortField="audience" />,
    cell: (info) => (
      <span>
        {info
          .getValue()
          .map((val) => val.shortCode)
          .join(',')}
      </span>
    ),
  }),
  columnHelper.accessor('alertType', {
    header: () => <Header text="Alert type" sortField="alertType" />,
    cell: (info) => <span>{info.getValue().join(', ')}</span>,
  }),
  columnHelper.accessor('status', {
    header: () => <Header text="Status" sortField="status" />,
    cell: (info) => (
      <span className={`capitalize ${info.getValue() === 'active' ? 'text-blue' : 'text-red'}`}>{info.getValue()}</span>
    ),
  }),
  columnHelper.accessor('actions', {
    header: () => <span className="text-sm font-bold">Actions</span>,
    cell: (info) => <EditCell id={info.row.original._id} />,
    enableSorting: false,
  }),
];
