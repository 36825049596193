import { MembershipPlanProps, PricePointProps } from 'components/crossSell/PlanType/planType.types';
import { PatientProps } from 'store/patients/patients.types';

export interface ChoosePlanProps {
  currentPlan?: MembershipPlanProps;
  patientInfo: PatientProps;
  setNewPlan: (type?: MembershipPlanProps) => void;
  newPlan?: MembershipPlanProps;
  activePP?: PricePointProps;
  setNewPricePoint: (type?: PricePointProps) => void;
  newPricePoint?: PricePointProps;
}

export enum ChoosePlanTab {
  EveryMonth = 'Every month',
  EveryThreeMonths = 'Every 3 months',
  EverySixMonths = 'Every 6 months',
  Yearly = 'Yearly',
}
