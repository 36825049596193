import { VideoCallIssueType, VideoCallProblemType } from 'enums/videoCall';

import { StaffFeedbackFiltersProps } from './staffFeedbackFiltersForm.types';

export const defaultValues: StaffFeedbackFiltersProps = {
  searchKey: '',
  providerName: { value: '', label: '' },
  patientName: { value: '', label: '' },
  startCreateDate: '',
  endCreateDate: '',
  createdAt: '',
  issueType: { value: '', label: '' },
  issueDescriptions: [],
  status: { value: '', label: '' },
};

export const ISSUE_TYPE_FILTER = [
  { label: 'Patient issue', value: VideoCallIssueType.PatientIssue },
  { label: 'Provider issue', value: VideoCallIssueType.ProviderIssue },
  { label: 'Both', value: VideoCallIssueType.Both },
];

export const ISSUE_DESCRIPTION_FILTER = [
  { label: 'Problem with audio', value: VideoCallProblemType.AudioProblem },
  { label: 'Problem with video', value: VideoCallProblemType.VideoProblem },
  { label: 'My computer froze', value: VideoCallProblemType.FrozeComputer },
];

export const STATUS_FILTER = [
  { label: 'All', value: '' },
  { label: '👍', value: 'good' },
  { label: '👎', value: 'bad' },
];
