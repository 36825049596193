import { ReactElement } from 'react';

import { CallType } from 'models/calls.types';
import { AppointmentTimeProps } from 'store/appointments/appointments.types';

export const enum PlanType {
  OneTime = 'one-time',
  Unlimited = 'unlimited',
}

export interface AppointmentProps {
  _id: string;
  appointmentDescription: string;
  appointmentTypeId: string;
  callType: CallType;
  category: string | null;
  displayName?: string;
  doctorId: string;
  endTime: AppointmentTimeProps['endTime'];
  files: File[];
  isWeightManagementAppointment?: boolean;
  startTime: AppointmentTimeProps['startTime'];
  uploadRequired?: boolean;
  pastAppointmentId?: string;
  pastAppointmentDescription?: string;
  randomProviderName?: string;
}

export enum AppointmentSteps {
  MAKE_UP = 'MAKE_UP',
  CATEGORY = 'CATEGORY',
  FILES = 'FILES',
  DATE_TIME = 'DATE_TIME',
  MEMBERSHIP = 'MEMBERSHIP',
  CHECKOUT = 'CHECKOUT',
  CONFIRMATION = 'CONFIRMATION',
}

export type StepName =
  | AppointmentSteps.MAKE_UP
  | AppointmentSteps.CATEGORY
  | AppointmentSteps.FILES
  | AppointmentSteps.DATE_TIME
  | AppointmentSteps.MEMBERSHIP
  | AppointmentSteps.CHECKOUT
  | AppointmentSteps.CONFIRMATION;

export type StepsContentProps = {
  [key in StepName]: ReactElement | null;
};

export type StepsTitleProps = {
  [key in StepName]: string | null;
};
