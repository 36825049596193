enum MessageType {
  Medical = 'medical',
  Support = 'support',
  SMS = 'sms',
  StaffNote = 'staffNote',
}

enum Status {
  Idle = 'idle',
  Pending = 'pending',
  Rejected = 'rejected',
  Fulfilled = 'fulfilled',
}

enum StaffNotesEvents {
  LabRequest = 'labRequest',
}

enum MessageEvents {
  AppointmentCreated = 'appointmentCreated',
  AppointmentComplete = 'appointmentComplete',
  AppointmentCancelled = 'appointmentCancelled',
  AppointmentMissed = 'appointmentMissed',
  LabRequest = 'labRequest',
}

enum MessageTemplates {
  Internal = 'internal',
  Patient = 'patient',
}

export { MessageType, Status, StaffNotesEvents, MessageEvents, MessageTemplates };
