import React from 'react';

import HoursCell from '../../Components/HoursCell';

const Header: React.FC = () => {
  return (
    <header data-testid="time_line" className="mt-5 flex h-[28px] border-b pl-44">
      <HoursCell shouldUseLabel shouldShowLeftBorder={false} />
    </header>
  );
};

export default Header;
