import { BACKEND_DATE_FORMAT } from 'constants/dateFormat';

import { useEffect, useState } from 'react';

import { PlusIcon } from '@heroicons/react/20/solid';
import { Common } from '@thecvlb/design-system';
import Calendar from 'components/common/Calendar';
import Loader from 'components/common/Loader';
import { notifySuccess } from 'components/common/Toast/Toast';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { useDispatch } from 'react-redux';
import { ExceptionProps } from 'store/availability/availability.types';
import { useGetAvailabilityQuery, useUpdateOverrideMutation } from 'store/availability/availabilitySlice';
import { closeModal } from 'store/modal/modalSlice';

import { formatTime } from './calendarPopup.settings';
import { CalendarPopupProps } from './calendarPopup.types';

const CalendarPopup: React.FC<CalendarPopupProps> = ({ getLocalTz, userId, physicianTZ, isPhysicianTab }) => {
  const dispatch = useDispatch();
  const [updateOverride, { isLoading }] = useUpdateOverrideMutation();
  const { data, refetch, isSuccess } = useGetAvailabilityQuery({ userId, localTz: getLocalTz() });

  const [selectedDay, setSelectedDay] = useState<Date>(new Date());
  const [timeGaps, setTimeGaps] = useState<ExceptionProps[]>([]);

  const disabledDays = { before: new Date() };
  const defaultTimeGaps = { startTime: '00:00', endTime: '23:59', appointments: true, queue: false };

  const timeInputClassName = 'p-2 pl-3 w-28 h-10 rounded border border-gray text-base placeholder-text outline-none';

  const handleDayClick = (day: Date) => {
    setSelectedDay(day);
  };
  const handleTimeClick = (type: 'add' | 'remove', index?: number) => {
    if (type === 'add') {
      setTimeGaps([...timeGaps, defaultTimeGaps]);
    } else {
      setTimeGaps(timeGaps.filter((_, ind) => ind !== index));
    }
  };

  const handleTimeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: 'startTime' | 'endTime' | 'appointments' | 'queue',
    periodIndex: number,
  ) => {
    const newItems = timeGaps.map((el, index) => {
      if (index !== periodIndex) {
        return el;
      }
      const value = type === 'appointments' || type === 'queue' ? event.target.checked : event.target.value;
      const newItem = { ...el, [type]: value };
      return newItem;
    });

    setTimeGaps(newItems);
  };

  const onClickSave = () => {
    const overrides: ExceptionProps[] = (timeGaps || []).map((period) => {
      const [startHours, startMinutes] = period.startTime.split(':');
      const [endHours, endMinutes] = period.endTime.split(':');

      const startTime = formatTime(selectedDay, +startHours, +startMinutes, isPhysicianTab, physicianTZ);
      const endTime = formatTime(selectedDay, +endHours, +endMinutes, isPhysicianTab, physicianTZ);

      return {
        ...period,
        startTime,
        endTime,
      };
    });

    updateOverride({
      userId,
      body: { dates: overrides.length ? overrides : [dayjs(selectedDay).format(BACKEND_DATE_FORMAT)] },
    })
      .unwrap()
      .then(() => {
        notifySuccess('Updated exceptions');
        refetch();
        dispatch(closeModal());
      });
  };

  useEffect(() => {
    const exceptions = data?.overRide?.[dayjs(selectedDay).format(DateFormat.YYYY_MM_DD)] || [];
    if (exceptions?.length) {
      const formattedExceptions = exceptions.map((exception) => {
        return {
          ...exception,
          startTime: dayjs(exception.startTime).tz(physicianTZ).format(DateFormat.HH_mm),
          endTime: dayjs(exception.endTime).tz(physicianTZ).format(DateFormat.HH_mm),
        };
      });
      setTimeGaps(formattedExceptions);
    } else {
      setTimeGaps([]);
    }
  }, [data?.overRide, isSuccess, selectedDay]);
  return (
    <>
      <Loader isVisible={isLoading} />
      <div data-testid="add_exception_popup" className="relative flex w-full flex-col rounded-xl px-6 py-4 shadow-2xl">
        <Calendar
          onDayClick={handleDayClick}
          selected={selectedDay}
          isLoading={false}
          disabled={disabledDays}
          fromMonth={new Date()}
        />
        <div className="my-4 border-y py-4">
          <span className="mb-3 block text-sm">What hours are you available?</span>
          {timeGaps.length ? (
            timeGaps.map((timePeriod, index) => {
              return (
                <div key={index}>
                  <div data-testid="time_container" className="mb-2 flex items-center">
                    <div className="flex flex-col gap-3">
                      <div className="mr-4 flex items-center">
                        <input
                          data-testid="start_time"
                          onChange={(e) => handleTimeChange(e, 'startTime', index)}
                          value={timePeriod.startTime}
                          type="time"
                          className={timeInputClassName}
                        />
                        <div className="mx-2 w-2 border" />
                        <input
                          data-testid="end_time"
                          value={timePeriod.endTime}
                          onChange={(e) => handleTimeChange(e, 'endTime', index)}
                          type="time"
                          className={timeInputClassName}
                        />
                      </div>
                    </div>
                    <div className="flex grow items-center justify-between">
                      <button data-testid="trash_btn" onClick={() => handleTimeClick('remove', index)}>
                        <Common.Icon name="trash" className={'size-4 text-gray-700'} />
                      </button>

                      <PlusIcon
                        data-testid="plus_btn"
                        onClick={() => handleTimeClick('add')}
                        className="ml-auto w-4 cursor-pointer text-primary"
                      />
                    </div>
                  </div>
                  <div className="my-3 flex items-center gap-4">
                    <Common.Checkbox
                      checked={timePeriod.queue}
                      key="queue"
                      color="blue"
                      name={'queue'}
                      onChange={(e) => handleTimeChange(e, 'queue', index)}
                    >
                      Queue
                    </Common.Checkbox>
                    <Common.Checkbox
                      checked={timePeriod.appointments}
                      key="appointments"
                      color="blue"
                      name={'appointments'}
                      onChange={(e) => handleTimeChange(e, 'appointments', index)}
                    >
                      Appointments
                    </Common.Checkbox>
                  </div>
                </div>
              );
            })
          ) : (
            <Common.Button
              dataTestId="add_btn"
              postIcon="plus"
              color="blue"
              onClick={() => setTimeGaps([defaultTimeGaps])}
              size="sm"
            >
              Add
            </Common.Button>
          )}
        </div>
        <div className="mt-6 flex justify-end gap-4">
          <Common.Button dataTestId="cancel_btn" onClick={() => dispatch(closeModal())} color="white-alt" size="sm">
            Cancel
          </Common.Button>
          <Common.Button
            dataTestId="add_exception_btn"
            disabled={isLoading || !selectedDay}
            onClick={onClickSave}
            color="blue"
            size="sm"
          >
            Add exception
          </Common.Button>
        </div>
      </div>
    </>
  );
};

export default CalendarPopup;
