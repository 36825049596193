export enum TagTypes {
  System = 'system',
  Manual = 'manual',
  Both = 'both',
}

export const Tags = {
  WeightLossPlan: 'weight-loss-plan',
  Async: 'async',
  Maintenance: 'maintenance',
  WeightLossPrevPlan: 'weight-loss-prev-plan',
  RxIncluded: 'rx-included',
} as const;

export enum TagCategories {
  PLAN = 'plan',
  SUB_PLAN = 'sub-plan',
  MEDICAL_INCLUDED = 'medication-included',
  IMPORTANT = 'important',
  PARTNER = 'partner',
  ASYNC = 'async',
  PREV_PLAN = 'prev-plan',
  OTHER = 'other',
}
