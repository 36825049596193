import { HealthMetricTypes } from 'enums/chart';

import {
  AddHealthMetricsReqProps,
  AddHealthMetricsResProps,
  DeleteHealthMetricReqProps,
  DeleteHealthMetricResProps,
  GetHealthMetricsReqProps,
  GetHealthMetricsResProps,
  GetHealthProfileResProps,
  GetLatestHealthMetricsResProps,
  UpdateHealthMetricsReqProps,
  UpdateHealthMetricsResProps,
} from './patientChart.types';
import { apiSlice } from '../api/apiSlice';

export const patientChartApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getHealthProfile: builder.query<GetHealthProfileResProps, { patientId: string }>({
      query: ({ patientId }) => `/patients/${patientId}/health-profile`,
    }),
    getHealthMetrics: builder.query<GetHealthMetricsResProps, GetHealthMetricsReqProps>({
      query: (params) => {
        const { patientId, limit, sortField, sortOrder, ...rest } = params;
        return {
          url: `/patients/${patientId}/health-metrics`,
          params: {
            ...rest,
            limit: limit || 50,
            sortField: sortField || 'collectedDate',
            sortOrder: sortOrder || 'DESC',
          },
        };
      },
      providesTags: (_result, _error, arg) => [{ type: 'HealthMetrics', id: arg.metricType }],
      transformResponse: (response: GetHealthMetricsResProps) => {
        return {
          ...response,
          ...{
            data: response.data.map((metric) => ({ ...metric, collectedDate: metric.collectedDate.split('T')[0] })),
          },
        };
      },
    }),
    getLatestHealthMetrics: builder.query<GetLatestHealthMetricsResProps, { patientId: string }>({
      query: ({ patientId }) => `/patients/${patientId}/latest-health-metrics`,
      providesTags: ['LatestHealthMetrics'],
    }),
    addHealthMetric: builder.mutation<AddHealthMetricsResProps, AddHealthMetricsReqProps>({
      query: ({ patientId, collectedDate, metrics }) => ({
        url: `/patients/${patientId}/health-metrics`,
        method: 'POST',
        body: {
          collectedDate,
          metrics,
        },
      }),
      invalidatesTags: (_result, _error, arg) => [
        'LatestHealthMetrics',
        { type: 'HealthMetrics', id: Object.keys(arg.metrics)[0] },
        ...(arg.metrics[HealthMetricTypes.Weight] || arg.metrics[HealthMetricTypes.Height]
          ? [{ type: 'HealthMetrics', id: HealthMetricTypes.BMI }]
          : []),
        ...(arg.metrics[HealthMetricTypes.Weight] ? ['ChartHealthMetrics'] : []),
      ],
    }),
    updateHealthMetric: builder.mutation<UpdateHealthMetricsResProps, UpdateHealthMetricsReqProps>({
      query: ({ patientId, collectedDate, metrics }) => ({
        url: `/patients/${patientId}/health-metrics`,
        method: 'PATCH',
        body: {
          collectedDate,
          metrics,
        },
      }),
      invalidatesTags: (_result, _error, arg) => [
        'LatestHealthMetrics',
        { type: 'HealthMetrics', id: Object.keys(arg.metrics)[0] },
        ...(arg.metrics[HealthMetricTypes.Weight] || arg.metrics[HealthMetricTypes.Height]
          ? [{ type: 'HealthMetrics', id: HealthMetricTypes.BMI }]
          : []),
        ...(arg.metrics[HealthMetricTypes.Weight] ? ['ChartHealthMetrics'] : []),
      ],
    }),
    deleteHealthMetric: builder.mutation<DeleteHealthMetricResProps, DeleteHealthMetricReqProps>({
      query: ({ patientId, collectedDate, metricType }) => ({
        url: `/patients/${patientId}/health-metrics/${collectedDate}/${metricType}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, arg) =>
        !arg.isPartOfEdit
          ? [
              'LatestHealthMetrics',
              { type: 'HealthMetrics', id: arg.metricType },
              ...(arg.metricType === HealthMetricTypes.Weight ? ['ChartHealthMetrics'] : []),
            ]
          : [],
    }),
  }),
});

export const {
  useGetHealthProfileQuery,
  useGetHealthMetricsQuery,
  useLazyGetHealthMetricsQuery,
  useGetLatestHealthMetricsQuery,
  useLazyGetLatestHealthMetricsQuery,
  useAddHealthMetricMutation,
  useUpdateHealthMetricMutation,
  useDeleteHealthMetricMutation,
} = patientChartApi;
