import { ObjectValues } from 'utils/common/types';

export const BillingHistoryTabType = {
  Invoices: 'Invoices',
  Transactions: 'Transactions',
} as const;

export type BillingHistoryTabTypes = ObjectValues<typeof BillingHistoryTabType>;

export const BILLING_HISTORY_TABS = [
  { label: BillingHistoryTabType.Invoices },
  { label: BillingHistoryTabType.Transactions },
];

export interface BillingHistoryProps {
  patientId: string;
}
