import { nanoid } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import { EventModel, EventTypes } from 'models/event.types';
import { ShiftTypesResponseProps } from 'store/calendar/calendar.types';
import { getEventTitle } from 'utils/calendar';

/**
 * Extract event types by matching event type IDs with shift type IDs.
 *
 * @param {ShiftTypesResponseProps[]} shiftTypes - An array of shift types received from the server.
 * @param {{ _id: string }[]} eventTypes - An array of event type ids.
 * @returns {ShiftTypesResponseProps[]} An array of shift types that match the event type IDs.
 */
const parseEventTypes = (shiftTypes: ShiftTypesResponseProps[], eventTypes: { _id: string }[]) => {
  return eventTypes
    .map((eventId) => shiftTypes.find((element) => element._id === eventId._id))
    .filter((item) => !!item);
};

/**
 * Maps an array of events from the server to a format suitable for the calendar.
 *
 * @param {string} timezone - The timezone to use for date conversions.
 * @param {EventModel[]} [events] - An array of events to map.
 * @param {ShiftTypesResponseProps[]} [shiftTypes] - An array of shift types to use for event type parsing. Optional.
 * @returns {Object[]} An array of mapped events. Each event is an object with properties:
 *  - start: The start time of the event in ISO string format. Used for calendar monthly view.
 *  - end: The end time of the event in ISO string format. Used for calendar monthly view.
 *  - id: The unique identifier of the event.
 *  - startEventTime: The start time of the event as a Day.js object. Used for event details popover.
 *  - endEventTime: The end time of the event as a Day.js object. Used for event details popover.
 *  - recurringEventId: The unique identifier of the recurring event that this event is part of.
 *  - title:  .
 *  - eventTypes: The types of the event.
 */
const mapEvents = (timezone: string, events?: EventModel[], shiftTypes?: ShiftTypesResponseProps[]) => {
  if (!events && !shiftTypes) return [];

  return events?.map((event) => {
    let eventTypes;

    // Break and time off are separate types, so we don't receive them from the server
    // that why I hard coded them here
    if (event.type !== EventTypes.BREAK && event.type !== EventTypes.TIME_OFF) {
      eventTypes = !isEmpty(event.shiftTypes) && shiftTypes?.length && parseEventTypes(shiftTypes, event.shiftTypes);
    } else if (event.type === EventTypes.BREAK) {
      eventTypes = [{ title: 'Break', name: EventTypes.BREAK, id: nanoid() }];
    } else if (event.type === EventTypes.TIME_OFF) {
      eventTypes = [{ title: 'Day off', name: EventTypes.TIME_OFF, id: nanoid() }];
    }

    const eventTitle = !isEmpty(eventTypes) && getEventTitle(event.type, eventTypes as ShiftTypesResponseProps[]);

    const startDateTime = dayjs(event.start.dateTime).tz(timezone);
    const endDateTime = dayjs(event.end.dateTime).tz(timezone);

    const convertedStartTime = startDateTime.toISOString();
    const convertedEndTime = endDateTime.tz(timezone).toISOString();

    return {
      start: convertedStartTime,
      end: convertedEndTime,
      id: event._id,
      startEventTime: startDateTime,
      endEventTime: endDateTime,
      ...(event.recurringEventId && { recurringEventId: event.recurringEventId }),
      ...(eventTitle && { title: eventTitle }),
      ...(eventTypes && { eventTypes: eventTypes }),
    };
  });
};

const getRightToolbar = (showAddTimeOffButton: boolean, showAddShiftButton: boolean) => {
  if (showAddTimeOffButton && showAddShiftButton) {
    return 'AddTimeOff AddShift';
  } else if (showAddTimeOffButton && !showAddShiftButton) {
    return 'AddTimeOff';
  } else if (!showAddTimeOffButton && showAddShiftButton) {
    return 'AddShift';
  } else {
    return '';
  }
};

/**
 * Generates an array of dates representing the start of each week (Sunday) within a given date range.
 *
 * @param {Date} startDate - The start date of the range.
 * @param {Date} endDate - The end date of the range.
 * @returns {Date[]} An array of dates, each representing the start of a week (Sunday) within the given date range.
 * If the start date is later than the end date, the function returns undefined.
 * If either the start date or the end date is not a valid date, the function logs an error and continues execution.
 */
const getStartWeekDates = (startDate: Date, endDate: Date) => {
  if (!dayjs(startDate).isValid || !dayjs(endDate).isValid()) {
    console.error('Invalid start or end date format');
  }
  const start = dayjs(startDate);
  const end = dayjs(endDate);

  if (start.isAfter(end)) return;

  // Find the next Sunday from the start date
  const firstSunday = start.startOf('week').day(0); // Set to Sunday

  const sundays = [];

  // Iterate through each Sunday until we reach the end date
  let currentSunday = firstSunday;

  while (currentSunday.isBefore(end, 'day')) {
    if (currentSunday.isSameOrAfter(start, 'day')) sundays.push(currentSunday.toDate()); // Add date object
    currentSunday = currentSunday.add(7, 'day'); // Move to the next Sunday
  }

  return sundays;
};

export { mapEvents, getRightToolbar, getStartWeekDates, parseEventTypes };
