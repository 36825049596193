import { Common } from '@thecvlb/design-system';
import Card from 'components/common/Card';
import { PathName } from 'enums/pathName';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();

  const goToTheDashboard = () => {
    navigate(PathName.Dashboard);
  };

  return (
    <Card className="flex h-full flex-col items-center justify-center">
      <Common.Icon name="lifemd" className="-mt-12 size-20" />
      <h1 className="mt-8 text-3xl font-bold text-gray-700">Error 404</h1>
      <h2 className="mb-11 mt-2 text-center text-base text-gray-700">We can’t find the page you are looking for.</h2>
      <Common.Button onClick={goToTheDashboard} color="blue" style="pill" size="sm">
        Dashboard
      </Common.Button>
    </Card>
  );
};
export default NotFound;
