import { IconProps } from '@thecvlb/design-system/lib/src/common';
import { PatientPrescriptionsType, PaymentStatus } from 'enums/prescriptions';

export const getPrescriptionTypeData = (
  type: PatientPrescriptionsType,
): { iconName: IconProps['name']; showLifeMDLogo?: boolean; classNames?: string } => {
  switch (type) {
    case PatientPrescriptionsType.Order:
      return { iconName: 'package', showLifeMDLogo: true, classNames: 'w-6 h-6 text-primary-500' };
    case PatientPrescriptionsType.Prescription:
      return { iconName: 'patient' };
    default:
      return { iconName: 'doctor' };
  }
};

export const getPrescriptionPaymentColorTag = (value: string) => {
  switch (value) {
    case PaymentStatus.Paid:
      return 'green';
    case PaymentStatus.Failed:
      return 'red';
    default:
      return 'gray';
  }
};
