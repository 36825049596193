import Loader from 'components/common/Loader';
import { notifySuccess } from 'components/common/Toast/Toast';
import { useLogout } from 'hooks/common/useLogout';
import { useAppDispatch } from 'hooks/redux';
import { closeModal } from 'store/modal/modalSlice';
import { useConfirmEmailMutation } from 'store/myProfile/myProfileSlice';

import ConfirmationCodeForm from './ConfirmationCodeForm';

const ConnectedConfirmationCodeForm = () => {
  const [confirmEmail, { isLoading }] = useConfirmEmailMutation();
  const { logout } = useLogout();
  const dispatch = useAppDispatch();

  const onSubmit = (data: { code: string }) => {
    confirmEmail({ code: data.code })
      .unwrap()
      .then((res) => {
        notifySuccess(res.message);
        dispatch(closeModal());
        logout();
      })
      .catch((error) => {
        console.error(JSON.stringify(error));
      });
  };

  return (
    <div data-testid="verification_code_popup" className="p-6">
      <Loader isVisible={isLoading} />

      <h2 className="mb-6 text-xl font-bold text-gray-700">Enter confirmation</h2>
      <p className="mb-2 text-base font-semibold text-gray-700">
        We’ve sent a confirmation code to your email. Please enter it below:
      </p>
      <ConfirmationCodeForm onSubmit={onSubmit} isLoading={isLoading} />
    </div>
  );
};

export default ConnectedConfirmationCodeForm;
