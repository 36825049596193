import { useEffect, useState } from 'react';

export const useChangeImage = ({ selectedFile }: { selectedFile?: File }) => {
  const [imgSrc, setImgSrc] = useState<string | ArrayBuffer | null>(null);

  useEffect(() => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onloadend = () => setImgSrc(reader.result);
    }
  }, [selectedFile]);

  return [imgSrc, setImgSrc] as const;
};
