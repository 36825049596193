import dayjs from 'dayjs';
import { HealthMetricTypes } from 'enums/chart';
import { HealthMetrics } from 'store/patientChart/patientChart.types';
import { useGetHealthMetricsQuery } from 'store/patientChart/patientChartSlice';

import { sortMetricsDesc } from '../WeightChart/weightChart.settings';

const BloodPressure = ({ latestMetrics, patientId }: { latestMetrics?: HealthMetrics; patientId: string }) => {
  const { data: bloodPressureMetrics } = useGetHealthMetricsQuery({
    metricType: HealthMetricTypes.BloodPressure,
    patientId,
  });

  const sortedMetrics = bloodPressureMetrics?.data && sortMetricsDesc(bloodPressureMetrics?.data);

  const currentBloodPressure = sortedMetrics?.[0];
  const currentDateDuration =
    currentBloodPressure && dayjs.duration(dayjs(currentBloodPressure.collectedDate).diff(), 'milliseconds').humanize();

  const currentBloodPressureText = currentBloodPressure?.metrics?.bloodPressure
    ? `${currentBloodPressure?.metrics?.bloodPressure.SYS}/${currentBloodPressure?.metrics?.bloodPressure.DIA}`
    : '-';

  const prevBloodPressure = sortedMetrics?.[1];
  const prevBloodPressureText = prevBloodPressure?.metrics?.bloodPressure
    ? `${prevBloodPressure?.metrics?.bloodPressure.SYS}/${prevBloodPressure?.metrics?.bloodPressure.DIA}`
    : '-';

  return (
    <div className="mt-6 grid grid-cols-6 gap-2 text-left">
      <div>
        <p className="truncate text-base font-bold">Blood pressure</p>
        <p className="text-sm font-bold text-gray-700">{currentDateDuration ? `${currentDateDuration} ago` : '-'}</p>
      </div>
      <div>
        <p className="text-sm text-gray-700">Current</p>
        <p className="text-sm font-bold text-gray-700">{currentBloodPressureText}</p>
      </div>
      <div>
        <p className="text-sm text-gray-700">Last entry</p>
        <p className="text-sm font-bold text-gray-700">{prevBloodPressureText}</p>
      </div>
      <div>
        <p className="text-sm text-gray-700">Pulse</p>
        <p className="text-sm font-bold text-gray-700">{latestMetrics?.bloodPressure?.pulse ?? '-'}</p>
      </div>
    </div>
  );
};

export default BloodPressure;
