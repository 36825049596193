import { Role } from 'enums/role';
import { RouteProps } from 'router/router.types';

export const getAllowedRoutes = (routes: RouteProps[], role: string, hiddenItems?: string[]) => {
  return routes.filter(
    ({ permissions, path }) =>
      !permissions?.length || (permissions.includes(role as Role) && !hiddenItems?.includes(path)),
  );
};

export const checkIsProtectedRoute = (pathname: string, routes: RouteProps[]) =>
  routes.some((route) => {
    const regex = new RegExp('^' + route.path.replace(/:[^\s/]+/g, '([\\w-]+)') + '$');
    return regex.test(pathname);
  });
