import { AppointmentStatus } from 'enums/appointmentStatus';
import { AppointmentItemProps, AppointmentViewType } from 'store/appointments/appointments.types';

export const checkShowCancelButton = (item: AppointmentItemProps, callIdInProgress: string) =>
  !item.missedAppointment &&
  !item.pastAppointment &&
  item.appointmentStatus !== AppointmentStatus.Cancelled &&
  item.appointmentStatus !== AppointmentStatus.Completed &&
  item._id !== callIdInProgress;

export const getAppointmentType = (item: AppointmentItemProps, isPhysician: boolean) => {
  let type: AppointmentViewType = 'default';
  if (item.missedAppointment) {
    type = 'missed';
  } else if (item.appointmentStatus === 'cancelled') {
    type = 'cancelled';
  } else if (item.isStartsSoon && !item.pastAppointment && isPhysician) {
    type = 'start';
  }
  return type;
};
