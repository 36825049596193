import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';

export const startInputValue = '05:00';
export const defaultTimeInputValue = '06:00';

export const validateTime = (start?: string, end?: string) => {
  const convertedStart = dayjs(start, DateFormat.HH_mm);
  const convertedEnd = dayjs(end, DateFormat.HH_mm);

  if (convertedStart.isAfter(convertedEnd)) {
    return 'End time cannot be earlier than start time';
  }

  if (convertedEnd.diff(convertedStart, 'minute') < 5) {
    return 'Shift duration should be at least 5 minutes';
  }

  return true;
};
