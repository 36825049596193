import { NOTIFICATION, PATIENT_MESSAGE } from 'constants/automation';

import { useEffect, useState } from 'react';

import { OptionProps } from '@thecvlb/design-system/lib/src/common';
import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import ControlledTextArea from 'components/forms/controlled/ControlledTextArea';
import { HelperTextForTextArea } from 'components/modals/Automation/AutomationForm/automationForm.settings';
import { useFormContext } from 'react-hook-form';
import { useLazyGetVariablesAndButtonsQuery } from 'store/lookup/lookupSlice';
import { validation } from 'utils/helpers';

import { PatientMessageContentProps } from './notificationAndPatientMessageContent.types';

const PatientMessageContent: React.FC<PatientMessageContentProps> = ({ currentType }) => {
  const { control, formState, resetField, getValues } = useFormContext();
  const currentField = currentType === PATIENT_MESSAGE.value ? 'message' : 'text';

  const [getVariablesAndButtons, { data: variablesAndButtons }] = useLazyGetVariablesAndButtonsQuery();

  const [variablesOptions, setVariablesOptions] = useState<OptionProps[]>([]);
  const [buttonOptions, setButtonOptions] = useState<OptionProps[]>([]);

  const onChangeVariable = (option: OptionProps) => {
    if (option) {
      resetField(currentField, { defaultValue: `${getValues(currentField) || ''} [${option.value}]` });
    }
  };

  useEffect(() => {
    if (variablesAndButtons) {
      const variables = variablesAndButtons.variables.map((variable) => ({
        value: variable.variable,
        label: variable.label,
      }));
      const buttons = variablesAndButtons.buttons.map((variable) => ({
        value: variable.variable,
        label: variable.label,
      }));
      setVariablesOptions(variables);
      setButtonOptions(buttons);
    }
  }, [variablesAndButtons]);

  useEffect(() => {
    const value = currentType === NOTIFICATION.value ? NOTIFICATION.value : 'Message';
    getVariablesAndButtons({ type: value });
  }, [currentType, getVariablesAndButtons]);

  return (
    <>
      <ControlledTextArea
        control={control}
        name={currentField}
        label="Message"
        labelDirection="row"
        helper={<HelperTextForTextArea />}
        placeholder="Enter message..."
        rows={4}
        rules={validation(currentType === PATIENT_MESSAGE.value ? 'Message' : 'Text')}
        errors={formState.errors[currentField]}
      />

      <ControlledSelect
        control={control}
        labelDirection="row"
        options={variablesOptions}
        placeholder="Select variables..."
        onChange={onChangeVariable}
        rules={validation('Variables', null, null, true)}
        label="Variables"
        name="variables"
      />

      <ControlledSelect
        control={control}
        labelDirection="row"
        options={buttonOptions}
        placeholder="Select button..."
        onChange={onChangeVariable}
        rules={validation('Button', null, null, true)}
        label="Button"
        name="button"
      />
    </>
  );
};

export default PatientMessageContent;
