import { HealthMetricTypes } from 'enums/chart';

import { BloodPressureProps } from './bloodPressureFromDevice.types';
import HealthMetric from '../../HealthMetric/HealthMetric';

const BloodPreasureFromDevice: React.FC<BloodPressureProps> = ({ bloodPressure }) => {
  const subtitle = bloodPressure ? `${bloodPressure.SYS}/${bloodPressure.DIA}` : '';

  return (
    <>
      <HealthMetric
        type={HealthMetricTypes.BloodPressure}
        value={bloodPressure?.DIA}
        metricSubtitle={subtitle}
        isTableOpenByDefault={false}
        isWMDevices
      >
        <div className="mb-6 grid grid-cols-3 gap-2 text-center">
          <div className="rounded-2xl bg-gray-100 p-3">
            <p className="text-sm text-gray">Systolic</p>
            <p className="mt-1 text-xl font-bold">{bloodPressure?.SYS ?? '-'}</p>
          </div>
          <div className="rounded-2xl bg-gray-100 p-3">
            <p className="text-sm text-gray">Diastolic</p>
            <p className="mt-1 text-xl font-bold">{bloodPressure?.DIA ?? '-'}</p>
          </div>
          <div className="rounded-2xl bg-gray-100 p-3">
            <p className="text-sm text-gray">Pulse</p>
            <p className="mt-1 text-xl font-bold">{bloodPressure?.pulse ?? '-'}</p>
          </div>
        </div>
      </HealthMetric>
    </>
  );
};

export default BloodPreasureFromDevice;
