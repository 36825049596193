import { apiSlice } from 'store/api/apiSlice';
import { SubmitPrescriptionResponse } from 'store/weightManagement/weightManagement.types';

import { PatientPrescription, PatientPrescriptionsProps, Titration } from './prescriptions.types';

export const prescriptionsApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getPatientPrescriptions: build.query<
      { data: PatientPrescription[]; info: { totalCount: number } },
      { patientId: string; params?: PatientPrescriptionsProps }
    >({
      query: ({ patientId, params }) => ({ url: `/patients/${patientId}/prescriptions`, params }),
      providesTags: (result) =>
        result
          ? ['Prescriptions', ...result.data?.map(({ id }) => ({ type: 'Prescriptions' as const, id }))]
          : ['Prescriptions'],
    }),
    getPrescriptionById: build.query<{ data: PatientPrescription }, { prescriptionId: string }>({
      query: ({ prescriptionId }) => ({ url: `/prescriptions/${prescriptionId}` }),
      providesTags: (_result, _error, arg) => [{ type: 'Prescriptions', id: arg.prescriptionId }],
    }),
    updatePrescription: build.mutation<
      { message: string },
      { prescriptionId: string; titrations: Partial<Titration>[] }
    >({
      query: ({ prescriptionId, titrations }) => ({
        url: `/prescriptions/${prescriptionId}`,
        method: 'PATCH',
        body: { titrations },
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'Prescriptions', id: arg.prescriptionId }],
    }),
    retryPayment: build.mutation<{ data: SubmitPrescriptionResponse }, { prescriptionId: string }>({
      query: ({ prescriptionId }) => ({
        url: `/prescriptions/${prescriptionId}/payments`,
        method: 'POST',
      }),
      invalidatesTags: ['Prescriptions'],
    }),
  }),
});

export const {
  useGetPatientPrescriptionsQuery,
  useLazyGetPatientPrescriptionsQuery,
  useGetPrescriptionByIdQuery,
  useUpdatePrescriptionMutation,
  useRetryPaymentMutation,
} = prescriptionsApiSlice;
