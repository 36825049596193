import Skeleton from 'react-loading-skeleton';

const ChartSkeleton = () => (
  <>
    <Skeleton height={30} className="block py-2" />
    <Skeleton height={60} className="block py-4" />
    <Skeleton height={30} className="block py-2" />
    <Skeleton height={60} className="block py-4" />
    <Skeleton height={30} className="block py-2" />
    <Skeleton height={60} className="block py-4" />
  </>
);

export default ChartSkeleton;
