import { Common } from '@thecvlb/design-system/lib/src';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { closeModal } from 'store/modal/modalSlice';
import { open } from 'store/slidingInPane/slidingInPaneSlice';
const AssignedToTask: React.FC<{
  taskId: string;
  assignToName?: string;
  openWithoutUrl?: boolean;
  isStaffNote?: boolean;
}> = ({ taskId, assignToName, openWithoutUrl, isStaffNote }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const openModal = () => {
    dispatch(closeModal());
    if (openWithoutUrl) {
      dispatch(open({ taskId }));
    } else {
      isStaffNote && searchParams.set('messageType', 'Staff notes');
      searchParams.set('taskModalOpenID', taskId);
      setSearchParams(searchParams);
    }
  };

  const handleCancel = () => {
    dispatch(closeModal());
  };

  return (
    <div data-testid="assigned_to_popup" className="p-6">
      <h2 className="mb-2 text-xl font-bold text-gray-700">{assignToName ?? 'Unassigned'} is assigned to this task</h2>
      <span className="text-base text-gray-700">
        If you’d like to do additional work on this task, please click ‘Takeover Task’ on the task menu.
      </span>
      <div className="mt-6 flex gap-2">
        <Common.Button
          dataTestId="cancel_btn"
          onClick={handleCancel}
          size="sm"
          color="white-alt"
          className="!w-full justify-center"
        >
          Cancel
        </Common.Button>
        <Common.Button
          dataTestId="continue_btn"
          size="sm"
          color="blue"
          className="!w-full justify-center"
          onClick={openModal}
        >
          Continue
        </Common.Button>
      </div>
    </div>
  );
};

export default AssignedToTask;
