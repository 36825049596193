import { createColumnHelper } from '@tanstack/react-table';

import EditCell from './EditCell';
import Members from './Members';
import { TeamsTableTypes } from './teamsTable.types';
import Header from '../Header';

const columnHelper = createColumnHelper<TeamsTableTypes>();

export const columns = [
  columnHelper.accessor('name', {
    header: () => <Header text="Team" sortField="name" />,
    enableSorting: false,
  }),
  columnHelper.accessor('members', {
    header: () => <Header text="Members" sortField="members" />,
    cell: (info) => <Members members={info.row.original.members || []} />,
    enableSorting: false,
  }),
  columnHelper.accessor('description', {
    header: () => <Header text="Description" sortField="description" />,
    enableSorting: false,
  }),
  columnHelper.accessor('actions', {
    header: () => <span className="text-sm font-bold">Actions</span>,
    cell: (info) => <EditCell id={info.row.original._id} name={info.row.original.name} />,
    enableSorting: false,
  }),
];
