import { FeedbackProps } from 'store/customerFeedback/customerFeedback.types';

export const getPrevNextCustomerFeedbackId = (currentId: string, feedbackList: FeedbackProps[]) => {
  const currentFeedbackIndex = feedbackList.findIndex((item) => item._id === currentId);
  const prevFeedbackId = currentFeedbackIndex >= 1 ? feedbackList[currentFeedbackIndex - 1]._id : null;
  const nextFeedbackId =
    currentFeedbackIndex >= 0 && feedbackList[currentFeedbackIndex + 1]
      ? feedbackList[currentFeedbackIndex + 1]._id
      : null;
  return { prevFeedbackId, nextFeedbackId };
};
