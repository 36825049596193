import { useEffect, useState } from 'react';

import { Common } from '@thecvlb/design-system';
import { DataItemProps } from '@thecvlb/design-system/lib/src/common/Tabs/tabs.types';
import RangePicker from 'components/common/Calendar/RangePicker';
import queryString from 'query-string';
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';

import { customButtonClassNames } from './filterTabs.settings';
import { DataItemValueProps, DataValueProps, FilterTabsProps, RangeProps } from './filterTabs.types';

const FilterTabs: React.FC<FilterTabsProps> = ({ filterData, queryParam, isMulti }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const parsed = queryString.parse(searchParams.toString());
  const [customCalendarVisible, setCustomCalendarVisible] = useState<boolean>(false);
  const [selectedDays, setSelectedDays] = useState<RangeProps>();
  const disabledDays = { after: new Date() };
  const [defaultValue, setDefaultValue] = useState<string | string[]>('');

  const onChangeMultiSelector = (val: DataItemProps[]) => {
    const categories: { [key: string]: string[] } = {};
    const filters = queryString.parse(searchParams.toString());

    filters.limit = '50';
    filters.pageNo = '0';

    val.forEach((item) => {
      const { name, param } = item.value as DataItemValueProps;
      categories[param] = [...(categories[param] || []), name];

      if (item.label.toLocaleLowerCase() === 'history') {
        filters['sort[dueDate]'] = 'DESC';
      }
    });

    Object.keys(categories).forEach((key) => {
      if (categories[key].includes('all')) {
        delete filters[key];
        return;
      }
      filters[key] = categories[key];
    });
    setSearchParams(filters as URLSearchParamsInit);
  };

  const onDayClick = (selectedDate: Date) => {
    if (selectedDate <= new Date())
      if (selectedDays) {
        if (selectedDays.from !== selectedDays.to) {
          setSelectedDays({ from: selectedDate, to: selectedDate });
        } else {
          setSelectedDays({ to: selectedDate, from: selectedDays.to });
        }
      } else {
        setSelectedDays({ to: selectedDate, from: selectedDate });
      }
  };

  const handleChange = (val: DataValueProps) => {
    const queryParams = queryString.parse(searchParams.toString());
    const { name, param } = val.value as DataItemValueProps;

    queryParams.limit = '10';
    queryParams.pageNo = '0';

    if (typeof val.value === 'string' && val?.param) {
      queryParams[val.param] = val.value;
      if (val.value === 'all') delete queryParams[val.param];
    }

    if (typeof param === 'object') {
      const data = param as DataItemValueProps[];
      queryParams.dateType = name;
      data.map(({ name: n, param: p }) => {
        queryParams[p] = n;
        if (n == 'all') delete queryParams[p];
      });
    } else if (name) queryParams[param] = name;
    if (name === 'all') {
      delete queryParams[param];
    }

    setSearchParams(queryParams as URLSearchParamsInit);
    setSelectedDays(undefined);
    setCustomCalendarVisible(false);
  };

  const selectDateRange = () => {
    handleChange({
      label: 'Custom',
      value: {
        name: 'custom',
        param: [
          {
            name: selectedDays?.from
              ? new Date(selectedDays?.from > selectedDays?.to ? selectedDays.to : selectedDays.from).setHours(0)
              : undefined,
            param: 'startDate',
          },
          {
            name: selectedDays?.to
              ? new Date(selectedDays?.from < selectedDays?.to ? selectedDays.to : selectedDays.from).setHours(
                  23,
                  59,
                  59,
                  999,
                )
              : undefined,
            param: 'endDate',
          },
        ],
      },
    } as DataItemProps);
    setCustomCalendarVisible(!customCalendarVisible);
  };

  const onCloseCalendar = () => {
    setCustomCalendarVisible(!customCalendarVisible);
  };

  useEffect(() => {
    if (searchParams.has('endDate') && searchParams.has('startDate')) {
      setSelectedDays({
        to: new Date(+(searchParams.get('endDate') as string)),
        from: new Date(+(searchParams.get('startDate') as string)),
      });
    }
  }, [searchParams, customCalendarVisible]);

  useEffect(() => {
    const query = queryString.parse(searchParams.toString());
    const value = query[queryParam] && query[queryParam] !== 'All' ? query[queryParam] : 'all';

    const itemFilter: DataItemProps[] | undefined = filterData.filter((item) => {
      if (typeof item.value === 'object') {
        if (typeof value === 'string') {
          return (item.value as { name: string }).name === value;
        }
        return (value as string[]).includes((item.value as { name: string }).name);
      }
      return item.value === value;
    });

    if (itemFilter && itemFilter.length) {
      setDefaultValue(itemFilter?.map((item) => item.label));
    } else {
      setDefaultValue('All');
    }

    if (value === 'all' && searchParams.has('category') && location.pathname.includes('tasks')) {
      searchParams.delete('category');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  const getDefaultSelected = () => {
    if (defaultValue.length) {
      return typeof defaultValue === 'string' ? [defaultValue] : defaultValue;
    }
    return undefined;
  };

  return (
    <div className="flex gap-2">
      <Common.Tabs
        data={filterData.filter((item) => item.label !== 'Custom')}
        onChangeMultiSelector={onChangeMultiSelector}
        onChange={handleChange}
        type="pill"
        multiSelector={isMulti}
        defaultSelected={getDefaultSelected()}
      />
      {filterData.find((item) => item?.label == 'Custom') ? (
        <div className="relative">
          <button
            onClick={onCloseCalendar}
            className={`${customButtonClassNames} ${
              parsed[queryParam] === 'custom' ? 'bg-secondary text-white' : 'bg-gray-100 text-gray-500'
            }`}
          >
            Custom
          </button>
          <RangePicker
            selectedDays={selectedDays}
            onDayClick={onDayClick}
            customCalendarVisible={customCalendarVisible}
            onToggleVisible={onCloseCalendar}
            selectDateRange={selectDateRange}
            disabledDays={disabledDays}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default FilterTabs;
