export enum PatientOverviewTab {
  Overview = 'Overview',
  Messages = 'Messages',
  Documents = 'Documents',
  Prescriptions = 'Prescriptions',
  Chart = 'Chart',
  Billing = 'Billing',
  History = 'History',
  Parameters = 'Parameters',
  StaffNotes = 'Staff notes',
  Tasks = 'Tasks',
  Insurance = 'Insurance',
  Appointments = 'Appointments',
}
