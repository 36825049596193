import { useEffect, useState } from 'react';

import Loader from 'components/common/Loader';
import EditProfileForm from 'components/modals/components/EditProfileForm';
import PopupHeader from 'components/modals/components/PopupHeader';
import { useUpdateProfileImageMutation } from 'store/api/apiSlice';
import { useLazyGetStaffQuery, useUpdateStaffMemberImageMutation } from 'store/staffs/staffsSlice';

import AdvancedHeader from './AdvancedHeader';
import { StaffMemberProps } from './staffMember.types';
import StandardHeader from './StandardHeader/StandardHeader';

const StaffMember: React.FC<StaffMemberProps> = ({ staffId, onUpdateMyProfile, isMyProfile }) => {
  const [fileForUpdateAvatar, setFileForUpdateAvatar] = useState<File>();
  const [getStaff, { data: profileDetails, isLoading, isFetching }] = useLazyGetStaffQuery();
  const [updateStaffMemberImage] = useUpdateStaffMemberImageMutation<File>();
  const [updateMyProfileImage] = useUpdateProfileImageMutation();

  const onFormSubmit = () => {
    if (fileForUpdateAvatar && staffId) {
      if (isMyProfile) {
        updateMyProfileImage({ profileImage: fileForUpdateAvatar })
          .unwrap()
          .then(() => {
            onUpdateMyProfile && onUpdateMyProfile();
          });
      } else {
        updateStaffMemberImage({ staffId: staffId, profileImage: fileForUpdateAvatar });
      }
    } else {
      onUpdateMyProfile && onUpdateMyProfile();
    }
  };

  const displayName = profileDetails?.displayName || '';

  const singleStaff = {
    profileImage: profileDetails?.profileImage,
    displayName,
    userRoleInfo: {
      name: profileDetails?.userRoleInfo.name || '',
      shortCode: profileDetails?.userRoleInfo.shortName || '',
    },
    userId: staffId || '',
  };

  useEffect(() => {
    if (staffId) {
      getStaff({ staffId });
    }
  }, [getStaff, staffId]);

  return (
    <div data-testid="my_account_popup_block" className="p-6">
      <Loader isVisible={isLoading || isFetching} />
      {staffId ? (
        <AdvancedHeader
          handleLogoUpdate={setFileForUpdateAvatar}
          staffData={singleStaff}
          id={staffId}
          roleName={profileDetails?.userRoleInfo?.userType?.name || ''}
          staffImage={singleStaff.profileImage}
        />
      ) : (
        <>
          <PopupHeader title="New staff member" />
          <StandardHeader handleLogoUpdate={setFileForUpdateAvatar} />
        </>
      )}

      {/* Rick: "Let’s hide that section until we’ve finished reporting." */}
      {/* <Links /> */}

      <EditProfileForm
        onFormSubmit={onFormSubmit}
        profileDetails={profileDetails}
        profileId={staffId}
        isFetching={isFetching}
        onUpdateMyProfile={onUpdateMyProfile}
        isMyProfile={isMyProfile}
      />
    </div>
  );
};

export default StaffMember;
