import { Logo } from '@thecvlb/design-system/lib/generated/logo';
import { ProfileImage } from '@thecvlb/design-system/lib/src/common';

const Header = ({ userName }: { userName?: string }) => {
  return (
    <div data-testid="header_section" className="mt-16">
      <div className="ml-16 flex items-center">
        <div className="flex w-72 items-center">
          <ProfileImage size="lg" />
          <h3 className="ml-3 text-xl">{userName || 'New Patient'}</h3>
        </div>
        <Logo name="logo" className="mx-auto" />
      </div>
    </div>
  );
};

export default Header;
