import { HTMLInputTypeAttribute, useState } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import { OptionProps } from '@thecvlb/design-system/lib/src/common/Select';
import { notifySuccess } from 'components/common/Toast/Toast';
import { Setting } from 'models/settings.types';
import { useDebounce } from 'react-use';
import { useUpdateGlobalSettingMutation } from 'store/settings/settingsSlice';

const SettingsItem = ({ setting }: { setting: Setting }) => {
  const [updateGlobalSetting] = useUpdateGlobalSettingMutation();
  const [inputValue, setInputValue] = useState('');

  const updateSetting = async (value: string) => {
    const request = updateGlobalSetting({
      id: setting.uid,
      body: {
        value: setting.type === 'number' ? Number(value) : value,
      },
    });

    request.unwrap().then(() => {
      notifySuccess('Successfully Updated');
    });
  };

  useDebounce(
    () => {
      if (inputValue && inputValue !== setting.value) {
        updateSetting(inputValue);
      }
    },
    1000,
    [inputValue],
  );

  if (setting.options.length) {
    const formattedOptions: OptionProps[] = setting.options.map((option) => {
      return {
        label: option.toString(),
        value: option.toString(),
      };
    });

    return (
      <div className="mb-2">
        <Common.Select
          label={setting.name}
          options={formattedOptions}
          onChange={(option) => updateSetting(option.value as string)}
          value={{ label: setting.value.toString(), value: setting.value as string }}
          helper={setting.description}
        />
      </div>
    );
  } else {
    return (
      <div className="mb-2">
        <Common.Input
          type={(setting.type ?? 'text') as HTMLInputTypeAttribute}
          label={setting.name}
          defaultValue={setting.value as string}
          size="md"
          hint={setting.description}
          onChange={(event) => setInputValue(event.target.value)}
        />
      </div>
    );
  }
};

export default SettingsItem;
