import { PatientProps } from 'store/patients/patients.types';

import BillingHistory from './BillingHistory';
import PlanDetails from './PlanDetails';

const Billing: React.FC<{ patient: PatientProps }> = ({ patient }) => {
  return (
    <div className="px-6 pb-6 pt-4">
      <PlanDetails patient={patient} />
      <BillingHistory patientId={patient._id} />
    </div>
  );
};

export default Billing;
