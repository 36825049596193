import { createColumnHelper } from '@tanstack/react-table';

import EditCell from './EditCell';
import { InternalTriggersTableProps } from './internalTriggersTable.types';
import Header from '../Header';

const columnHelper = createColumnHelper<InternalTriggersTableProps>();

export const columns = [
  columnHelper.accessor('name', {
    header: () => <Header text="Automation name" sortField="name" />,
  }),
  columnHelper.accessor('triggerType', {
    header: () => <Header text="Automation type" sortField="triggerType" />,
  }),
  columnHelper.accessor('audience', {
    header: () => <Header text="Audience" sortField="audience" />,
    cell: (info) => <span>{info.getValue()?.length ? info.getValue()?.join(',') : '-'}</span>,
  }),
  columnHelper.accessor('triggerTime', {
    header: () => <Header text="Automation time" sortField="triggerTime" />,
    cell: (info) => (
      <span>
        {info.getValue()
          ? info.getValue().timeInHours >= 1
            ? info.getValue().timeInHours + ' hours ' + info.getValue().occurenceOrder
            : (info.getValue().timeInHours * 60).toFixed() + ' mins ' + info.getValue().occurenceOrder
          : '-'}
      </span>
    ),
  }),
  columnHelper.accessor('_id', {
    header: () => <Header text="Unique ID" sortField="_id" />,
  }),
  columnHelper.accessor('status', {
    header: () => <Header text="Status" sortField="status" />,
    cell: (info) => (
      <span className={`capitalize ${info.getValue() === 'active' ? 'text-blue' : 'text-red'}`}>{info.getValue()}</span>
    ),
  }),
  columnHelper.accessor('actions', {
    header: () => <span className="text-sm font-bold">Actions</span>,
    cell: (info) => <EditCell id={info.row.original._id} />,
    enableSorting: false,
  }),
];
