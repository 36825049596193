import { useState } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';
import Alert from 'components/common/Alert';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { Controller, useFormContext } from 'react-hook-form';
import { validation } from 'utils/helpers';

import AmountCard from './AmountCard';
import { AmountToRefundType, CancelPlanConfirmationProps } from './cancelPlanConfirmation.types';
import { CancellationType } from '../cancelPlan.types';

const CancelPlanConfirmation: React.FC<CancelPlanConfirmationProps> = ({ cancellationType, subscriptionDetails }) => {
  const { control, resetField } = useFormContext();
  const [isFullAmount, setIsFullAmount] = useState<boolean>(true);
  const [isAmountInFocus, setIsAmountInFocus] = useState(false);

  const currentPeriodEndsAt = !!subscriptionDetails?.currentPeriodEndsAt
    ? dayjs(subscriptionDetails?.currentPeriodEndsAt).format(DateFormat.MMM_DYYYY)
    : '';

  const isCancelAutoRenew = cancellationType === CancellationType.CancelAutoRenew;
  const isDowngradeRefundPatient = cancellationType === CancellationType.DowngradeRefundPatient;
  const isCancelAccountAndRefundPatient = cancellationType === CancellationType.CancelAccountAndRefundPatient;
  const fullPayment = Number(subscriptionDetails?.subscriptionPayment || 0).toFixed(2);

  const handleChooseAmount = (amountTitle: string) => {
    setIsFullAmount(amountTitle === AmountToRefundType.FullPayment);
    resetField('refundAmount', { defaultValue: '' });
  };

  return (
    <div className="flex flex-col gap-6">
      <Alert type={isCancelAutoRenew ? 'info' : 'error'}>
        <span className="text-base font-medium">
          <span className="font-bold">
            {isCancelAutoRenew ? 'Patient will NOT receive a refund,' : 'Patient will receive a refund,'}
          </span>
          {isCancelAutoRenew
            ? ` and will continue to have access to their plan benefits until ${currentPeriodEndsAt}.`
            : isDowngradeRefundPatient
              ? ' and will immediately be moved to a free plan with limited benefits.'
              : ' and will immediately lose their plan benefits.'}
        </span>
      </Alert>

      {(isCancelAccountAndRefundPatient || isDowngradeRefundPatient) && (
        <div>
          <h3 className="mb-4 text-base font-semibold text-gray-700">Refund amount</h3>

          <div className="flex gap-4">
            <AmountCard
              isSelected={isFullAmount}
              onClick={handleChooseAmount}
              title={AmountToRefundType.FullPayment}
              subtitle={`$${fullPayment}`}
            />
            <AmountCard
              isSelected={!isFullAmount}
              onClick={handleChooseAmount}
              title={AmountToRefundType.CustomAmount}
              subtitle="Enter custom amount"
            />
          </div>
        </div>
      )}

      {!isFullAmount && (
        <Controller
          control={control}
          name="refundAmount"
          defaultValue=""
          rules={{
            ...validation('Custom amount'),
            min: { value: 0, message: 'Amount must be greater than 0' },
            max: { value: subscriptionDetails?.subscriptionPayment || 0, message: 'Amount cannot exceed full payment' },
          }}
          render={({ field, fieldState: { error } }) => (
            <div>
              <div className="relative">
                <Common.Input
                  {...field}
                  type="number"
                  placeholder="Enter custom amount"
                  size="md"
                  color="blue"
                  className="w-full"
                  inputClassName="pl-6"
                  errors={error}
                  helper={error?.message}
                  onFocus={() => setIsAmountInFocus(true)}
                  onBlur={() => setIsAmountInFocus(false)}
                />
                <span className="absolute left-0 top-[9px] flex items-center pl-3 text-gray-500">$</span>
                <span
                  className={classNames(
                    'absolute top-2 flex items-center pr-3 text-gray-500',
                    error && !isAmountInFocus ? 'right-6' : 'right-0',
                  )}
                >
                  USD
                </span>
              </div>
              <span className="mt-2 text-sm font-medium text-gray-500">
                Refunds are only available for membership fees, and cannot exceed amount paid.
              </span>
            </div>
          )}
        />
      )}

      {isCancelAccountAndRefundPatient && (
        <Controller
          control={control}
          name="receivedManagerApproval"
          defaultValue={false}
          rules={validation('Confirmation')}
          render={({ field }) => (
            <div>
              <Common.Checkbox
                checked={field.value}
                color="blue"
                size="md"
                name={field.name}
                value={field.value}
                onChange={(event) => field.onChange(event.target.checked)}
              >
                I’ve received manager approval for this refund.
              </Common.Checkbox>
            </div>
          )}
        />
      )}
    </div>
  );
};

export default CancelPlanConfirmation;
