import HealthMetricsModal from 'components/modals/HealthMetrics';
import UpdateWaist from 'components/modals/HealthMetrics/Waist';
import { HealthMetricTypes } from 'enums/chart';
import { useAppDispatch } from 'hooks/redux';
import { openModal } from 'store/modal/modalSlice';

import { WaistProps } from './waist.types';
import HealthMetric from '../../HealthMetric/HealthMetric';

const Waist: React.FC<WaistProps> = ({ waistCircumference }) => {
  const dispatch = useAppDispatch();

  const openCreateMetricModal = () => {
    dispatch(
      openModal({
        size: 'base',
        hideClose: true,
        modalContent: (
          <HealthMetricsModal type={HealthMetricTypes.WaistCircumference}>
            <UpdateWaist />
          </HealthMetricsModal>
        ),
      }),
    );
  };

  const waistSubtitle = waistCircumference ? `${waistCircumference} in` : '';

  return (
    <HealthMetric
      type={HealthMetricTypes.WaistCircumference}
      value={waistCircumference}
      metricSubtitle={waistSubtitle}
      openMetricModal={openCreateMetricModal}
    />
  );
};

export default Waist;
