import { useMemo } from 'react';

import queryString, { ParsedQuery } from 'query-string';
import { useSearchParams } from 'react-router-dom';

/**
 * @description
 * return parsed url search params
 */
export const useParsedURLSearchParams = () => {
  const [searchParams] = useSearchParams();

  const queryParams: ParsedQuery<string> = useMemo(() => {
    return queryString.parse(searchParams.toString());
  }, [searchParams]);

  return { queryParams };
};
