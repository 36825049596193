import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';

import { getIssueDescriptionsText, getIssueTypeText } from './staffFeedbackTable.settings';
import { StaffFeedbackTableProps } from './staffFeedbackTable.types';
import Header from '../Header';

const columnHelper = createColumnHelper<StaffFeedbackTableProps>();

export const columns = [
  columnHelper.accessor('providerInfo', {
    id: 'providerName',
    header: () => <Header text="Provider" sortField="providerName" />,
    cell: (info) => <p className="truncate text-sm font-medium">{info.row.original.providerInfo?.name}</p>,
  }),
  columnHelper.accessor('status', {
    header: () => <Header text="Status" sortField="status" />,
    cell: (info) => <p>{info.getValue() === 'good' ? '👍' : '👎'}</p>,
  }),
  columnHelper.accessor('createdAt', {
    header: () => <Header text="Date" sortField="createdAt" />,
    cell: (info) => (dayjs(info.getValue()).isToday() ? 'Today' : dayjs(info.getValue()).format(DateFormat.MMM_D_YYYY)),
  }),
  columnHelper.accessor('patientInfo', {
    header: () => <Header text="Patient name" sortField="patientName" />,
    id: 'patientName',
    size: 300,
    cell: (info) => <span className="max-w-[300px] font-medium">{info.row.original.patientInfo?.name}</span>,
  }),
  columnHelper.accessor('issueType', {
    header: () => <Header text="Issue type" sortField="issueType" />,
    cell: (info) => {
      const issueType = getIssueTypeText(info.getValue());
      return <p className="truncate text-sm font-medium">{issueType}</p>;
    },
  }),
  columnHelper.accessor('issueDescriptions', {
    header: () => <Header text="Issue descriptions" sortField="issueDescriptions" noSort />,
    cell: (info) => {
      const issueDescriptions = getIssueDescriptionsText(info.getValue());
      return <p className="flex flex-col gap-1 truncate text-sm font-medium">{issueDescriptions}</p>;
    },
  }),
  columnHelper.accessor('comment', {
    header: () => <Header text="Review content" sortField="comment" noSort />,
    cell: (info) => {
      const reviewContent = info.getValue() || '--';
      return <p className="truncate text-sm font-medium">{reviewContent}</p>;
    },
    size: 250,
  }),
  columnHelper.accessor('roomName', {
    header: () => <Header text="Room name" sortField="roomName" noSort />,
    cell: (info) => <p className="truncate text-sm font-medium">{info.getValue()}</p>,
    size: 250,
  }),
];
