import { Common } from '@thecvlb/design-system';

const EditButtons: React.FC<{ isEditing: boolean; isLoadingEdit: boolean; setIsEditing: (value: boolean) => void }> = ({
  isEditing,
  isLoadingEdit,
  setIsEditing,
}) => (
  <div className="mb-4">
    {isEditing && (
      <div className="flex">
        <Common.Button
          color="white-alt"
          size="sm"
          className="mr-2"
          onClick={() => setIsEditing(false)}
          preIconClassName="shrink-0"
        >
          <span className="whitespace-normal text-left font-semibold">Cancel</span>
        </Common.Button>
        <Common.Button disabled={isLoadingEdit} color="blue" size="sm" className="" preIconClassName="shrink-0">
          <span className="whitespace-normal text-left font-semibold">Save</span>
        </Common.Button>
      </div>
    )}
    {!isEditing && (
      <div>
        <Common.Button
          color="green"
          size="sm"
          preIcon="pencil"
          className=""
          onClick={() => setIsEditing(true)}
          preIconClassName="shrink-0"
        >
          <span className="whitespace-normal text-left font-semibold">Edit</span>
        </Common.Button>
      </div>
    )}
  </div>
);

export default EditButtons;
