import { Common } from '@thecvlb/design-system';
import dayjs from 'dayjs';
import { CancelAppointmentReason } from 'enums/appointments';
import { MarkCompleteReasons } from 'enums/markCompleteOption';
import { SearchUserTypes, TaskDetailsProps } from 'models/tasks.types';

export const getCancelationReason = (reason: string) => {
  switch (reason) {
    case CancelAppointmentReason.NoLongerAvailable:
      return 'No longer available';
    case CancelAppointmentReason.NoLongerNeeded:
      return 'No longer needed';
    case CancelAppointmentReason.NeedsInPersonCare:
      return 'Needs in person care';
    case CancelAppointmentReason.ProviderUnavailable:
      return 'Provider Unavailable';
    case CancelAppointmentReason.OnboardingIncomplete:
      return 'Onboarding Incomplete';
    default:
      return reason;
  }
};

export const getDisposition = (disposition?: MarkCompleteReasons, notPrescribingReason?: string) => {
  switch (disposition) {
    case MarkCompleteReasons.Prescribed:
      return 'Prescribed';
    case MarkCompleteReasons.AdditionalInfoRequired:
      return 'Additional info required';
    case MarkCompleteReasons.NoRx:
      return 'Complete, no RX';
    default:
      return notPrescribingReason ? 'Complete, no RX' : disposition;
  }
};

export const getCancelledByInfo = (cancelledBy?: SearchUserTypes, isCancelledBySystem?: boolean) => {
  if (isCancelledBySystem) {
    return 'system';
  }
  switch (cancelledBy) {
    case 'Patient':
      return 'patient';
    case 'Physician':
      return 'provider';
    case 'Medical Assistant':
      return 'MA';
    case 'Customer Service Rep':
      return 'MSR';
    default:
      return '';
  }
};

export const getQueueTimeText = (taskDetails: TaskDetailsProps) => {
  const seenAfterTime = dayjs
    .duration(dayjs(taskDetails.assignedAt).diff(taskDetails.createdAt, 'minute'), 'minutes')
    .humanize();

  return taskDetails.addedToQueueAt ? (
    <span className="flex flex-row items-center gap-1 text-lg font-bold">
      <Common.Icon name="checkout" className="size-4" />
      {dayjs(taskDetails.createdAt).fromNow(true)}
      <div className="h-4 w-px bg-black opacity-10" />
      <Common.Icon name="check" className="size-4" />
      {dayjs(taskDetails.addedToQueueAt).fromNow(true)}
    </span>
  ) : taskDetails.assignedAt ? (
    `Seen after ${seenAfterTime}`
  ) : (
    `Waiting ${dayjs(taskDetails.createdAt).fromNow(true)}`
  );
};

export const getNumberWithOrdinal = (number: number) => {
  const s = ['th', 'st', 'nd', 'rd'],
    v = number % 100;
  return number + (s[(v - 20) % 10] || s[v] || s[0]);
};
