import { PaymentMethod } from 'enums/crossSell';
import { PaymentProfile } from 'store/patients/patients.types';

export const wrapperVariants = {
  exit: {
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

export const getCardTypeName = (data: PaymentProfile) => {
  const isPayPal = data.paymentMethod === PaymentMethod.Paypal;
  if (isPayPal) {
    return 'PayPal';
  }
  if (data.cardType === 'master card') {
    return 'Mastercard';
  }
  return data.cardType;
};

export const otherPaymentVariants = (delayIndex: number) => ({
  exit: {
    height: 0,
    opacity: 0,
  },
  hidden: {
    opacity: 0,
    rotateZ: -5,
    transformY: 50,
  },
  visible: {
    opacity: 1,
    rotateZ: 0,
    transformY: 0,
    transition: {
      delay: delayIndex * 0.1 + 0.2,
      duration: 0.5,
    },
  },
});
