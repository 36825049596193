import { useCallback } from 'react';

import { fetchAuthSession } from 'aws-amplify/auth';
import { Socket } from 'socket.io-client';

/**
 * `useSocketConnection` is a custom hook that manages a Socket.IO connection with authentication.
 * It provides two functions `socketInit` and `socketClose` to manage the connection.
 *
 * @param {Socket} socket - The Socket.IO client instance.
 *
 * @returns {Object} The return object contains two methods.
 * @returns {Function} socketInit - This function initializes the socket connection. It fetches the auth session, extracts the access token and id token, sets them in the socket auth object, and then connects the socket.
 * @returns {Function} socketClose - This function disconnects the socket connection and clears the authorization from the socket auth object.
 *
 * @example
 * const { socketInit, socketClose } = useSocketConnection({ socket });
 */
export const useSocketConnection = ({ socket }: { socket: Socket }) => {
  const socketInit = useCallback(async () => {
    const session = await fetchAuthSession({ forceRefresh: true }).catch((error: unknown) => console.error(error));

    if (!session) return;

    const accessToken = session?.tokens?.accessToken?.toString();
    const idToken = session?.tokens?.idToken?.toString();

    if (accessToken && idToken) {
      socket.auth = {
        authorization: `Bearer ${accessToken}`,
        idToken,
      };
      socket.connect();
    }
  }, [socket]);

  const socketClose = useCallback(() => {
    socket.auth = { authorization: '' };
    socket.disconnect();
    console.info('close socket connection');
  }, [socket]);

  return { socketInit, socketClose };
};
