import React from 'react';

import { nanoid } from '@reduxjs/toolkit';

import { AvailabilityTableTypes } from './availability.types';
import AvailabilityScrollbar from './AvailabilityScrollbar';
import Header from './Header';

const AvailabilityTable: React.FC<AvailabilityTableTypes> = ({
  days,
  users,
  selectedDay,
  onSetSelectedDay,
  isLoading,
  isLoadingCombined,
  shiftTypes,
}) => {
  return (
    <div className="w-full">
      <Header />
      {days.map((day, index) => (
        <AvailabilityScrollbar
          key={nanoid()}
          index={index}
          day={day}
          users={users}
          selectedDay={selectedDay}
          onSetSelectedDay={onSetSelectedDay}
          shiftTypes={shiftTypes}
          isLoading={isLoading}
          isLoadingCombined={isLoadingCombined}
        />
      ))}
    </div>
  );
};

export default AvailabilityTable;
