import { Common } from '@thecvlb/design-system';
import { PARequestStatus } from 'enums/taskDetailsStatus';

// For task view
export const getPARequestStatus = (status: PARequestStatus) => {
  switch (status) {
    case PARequestStatus.CREATED:
      return <Common.ColorTag color="yellow" text="PA requested" />;
    case PARequestStatus.SENT:
      return <Common.ColorTag color="yellow" text="Sent" />;
    case PARequestStatus.AWAITING_RESULTS:
      return <Common.ColorTag color="gray" text="Awaiting outcome" />;
    case PARequestStatus.APPROVED:
      return <Common.ColorTag color="green" text="Approved" />;
    case PARequestStatus.DENIED:
      return <Common.ColorTag color="red" text="Denied" />;
    case PARequestStatus.ERROR:
      return <Common.ColorTag color="red" text="Error" />;
    case PARequestStatus.MISSING_INFORMATION:
      return <Common.ColorTag color="red" text="Missing information" />;
    case PARequestStatus.PA_REQUEST_PATIENT_CANCELLED:
      return <Common.ColorTag color="gray" text="Patient canceled" />;
  }
};

// For insurance view
export const getPARequestStyle = (status: PARequestStatus) => {
  switch (status) {
    case PARequestStatus.CREATED:
      return <Common.ColorTag color="yellow" text="PA requested" />;
    case PARequestStatus.SENT:
      return <Common.ColorTag color="yellow" text="PA sent" />;
    case PARequestStatus.AWAITING_RESULTS:
      return <Common.ColorTag color="yellow" text="Awaiting outcome" />;
    case PARequestStatus.APPROVED:
      return <Common.ColorTag color="green" text="PA approved" icon="check" />;
    case PARequestStatus.DENIED:
      return <Common.ColorTag color="red" text="PA denied" icon="close" />;
    case PARequestStatus.ERROR:
      return <Common.ColorTag color="red" text="PA error" />;
    case PARequestStatus.MISSING_INFORMATION:
      return <Common.ColorTag color="red" text="Missing information" />;
    case PARequestStatus.PA_REQUEST_PATIENT_CANCELLED:
      return <Common.ColorTag color="gray" text="Patient canceled" />;
  }
};
