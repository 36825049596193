import { InsuranceLabels } from 'enums/orderLabels';
import { Insurance } from 'store/orders/orders.types';

import { InsuranceInfoItem, OrderFormStateProps } from './insuranceInfo.types';

export const getItems = (insurance: Insurance): InsuranceInfoItem[] => {
  return [
    { label: InsuranceLabels.MemberId, property: 'memberId', value: insurance?.memberId || '' },
    { label: InsuranceLabels.RxGroup, property: 'rxGroup', value: insurance?.rxGroup || '' },
    { label: InsuranceLabels.RxBIN, property: 'rxBin', value: insurance?.rxBin || '' },
    { label: InsuranceLabels.PCN, property: 'pcn', value: insurance?.pcn || '' },
    { label: InsuranceLabels.Relationship, property: 'relationship', value: insurance?.relationship || '' },
  ];
};

export const getSubmitOrderData = (formData: OrderFormStateProps) => {
  return {
    insurance: {
      memberId: formData.memberId,
      rxGroup: formData?.rxGroup,
      rxBin: formData.rxBin,
      pcn: formData?.pcn,
      relationship: formData.relationship.value,
    },
  };
};
