import { Common } from '@thecvlb/design-system';
import Loader from 'components/common/Loader';
import InputField from 'components/forms/controlled/InputField';
import { useAppDispatch } from 'hooks/redux';
import { useForm } from 'react-hook-form';
import { closeModal } from 'store/modal/modalSlice';
import { InsuranceProps } from 'store/patients/patients.types';
import { useSubmitInsuranceMutation } from 'store/patients/patientsSlice';
import { validation } from 'utils/helpers';
import { TRIMMED_REGEXP } from 'utils/regExp';

import { InsuranceFormProps } from './insuranceForm.types';

const InsuranceForm = ({ insuranceInfo, patientId, hideCancelBtn = false, closeForm, isModal }: InsuranceFormProps) => {
  const dispatch = useAppDispatch();
  const [submitInsurance, { isLoading }] = useSubmitInsuranceMutation();
  const { handleSubmit, control, formState } = useForm<InsuranceProps>();

  const handleCancel = () => {
    if (isModal) {
      dispatch(closeModal());
    } else {
      closeForm();
    }
  };

  const onSubmit = (formData: InsuranceProps) => {
    submitInsurance({ body: formData, patientId })
      .unwrap()
      .then(() => {
        closeForm && closeForm();
      });
  };

  const actionBtnLabel = insuranceInfo ? 'Update insurance' : 'Check benefits';

  return (
    <>
      <Loader isVisible={isLoading} />
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
        <InputField
          name="memberId"
          label="Member ID"
          control={control}
          labelDirection="col"
          defaultValue={insuranceInfo?.memberId}
          errors={formState.errors}
          rules={validation('Member ID', TRIMMED_REGEXP)}
        />
        <InputField
          name="rxBIN"
          label="RX BIN"
          control={control}
          labelDirection="col"
          defaultValue={insuranceInfo?.rxBIN}
          errors={formState.errors}
          rules={validation('RX BIN', TRIMMED_REGEXP)}
        />
        <InputField
          name="rxGroupId"
          label="RX Group"
          control={control}
          labelDirection="col"
          defaultValue={insuranceInfo?.rxGroupId}
          errors={formState.errors}
          rules={{
            required: {
              value: false,
              message: 'RX Group is optional',
            },
          }}
        />
        <InputField
          name="rxPCN"
          label="PCN"
          control={control}
          labelDirection="col"
          defaultValue={insuranceInfo?.rxPCN}
          errors={formState.errors}
          rules={{
            required: {
              value: false,
              message: 'PCN is optional',
            },
          }}
        />

        <div className="mt-2 flex justify-end gap-x-2">
          {!hideCancelBtn && (
            <Common.Button color="white-alt" type="button" onClick={handleCancel} size="sm">
              Cancel
            </Common.Button>
          )}
          <Common.Button type="submit" color="blue" size="sm" disabled={isLoading}>
            {actionBtnLabel}
          </Common.Button>
        </div>
      </form>
    </>
  );
};

export default InsuranceForm;
