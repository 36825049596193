import styled from 'styled-components';

export const SummaryContent = styled.div<{ $isOpen: boolean }>`
  // max-height: 700px;
  height: ${({ $isOpen }) => ($isOpen ? 'auto' : '20px')};
  width: ${({ $isOpen }) => ($isOpen ? 'auto' : '311px')};
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;

  overflow: ${({ $isOpen }) => ($isOpen ? 'auto' : 'hidden')};
  white-space: ${({ $isOpen }) => ($isOpen ? 'wrap' : 'nowrap')};
  text-overflow: ellipsis;
`;
