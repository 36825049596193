import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import { DateFormat } from 'enums/dateFormats';
import { RepeatOptions } from 'models/event.types';
import { datetime, RRule } from 'rrule';
import type { ObjectValues } from 'utils/common/types';

import { GetRruleProps } from './manageAvailability.types';

dayjs.extend(weekday);

const formatTime = (start: string, end: string) => {
  const [startHours, startMinutes] = start.split(':').map(Number);
  const [endHours, endMinutes] = end.split(':').map(Number);

  const startTime = dayjs().set('hour', startHours).set('minute', startMinutes).format(DateFormat.hh_mm_a);
  const endTime = dayjs().set('hour', endHours).set('minute', endMinutes).format(DateFormat.hh_mm_a);

  return `${startTime} - ${endTime}`;
};

/**
 * Converts a day number to its corresponding RRule constant.
 *
 * @param {number} day - The day number, where 0 is Sunday and 6 is Saturday.
 * @returns {RRule} The corresponding RRule constant for the given day number.
 */
export const getRruleDay = (day: number) => {
  const RRULE_DAYS = [RRule.SU, RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA];

  return RRULE_DAYS[day];
};

const getDateWithDatetime = (startTime: Date, endTime?: Date) => {
  const utcStartTime = dayjs(startTime).utc();
  const utcEndDate = dayjs(endTime).utc();
  const utcTime = endTime ? utcEndDate : utcStartTime;

  // datetime function takes time in UTC
  return datetime(
    utcTime.get('year'),
    utcTime.get('month') + 1,
    utcTime.get('date'),
    utcStartTime.get('hours'),
    // add 1 minute to the end time to make it inclusive
    utcStartTime.get('minutes') + (endTime ? 1 : 0),
  );
};

/**
 * @description Generate rrule object based on user inputs
 * @param repeats selected `repeat` option
 * @param day selected `date`
 * @param timezone calendar timezone
 * @param endTime selected `on` option time
 * @param count selected `after` how many times it should repeat
 * @returns rrule object
 */
const getRrule = ({ repeats, day, endTime, count }: GetRruleProps) => {
  const BI_WEEKLY_INTERVAL = 2;
  const dateStart = dayjs(day).utc();

  const eventEndDateString = endTime && dayjs(endTime).isValid() ? getDateWithDatetime(day, endTime) : null;

  const selectedDay = getRruleDay(dateStart.get('day'));

  const isUntil = repeats !== RepeatOptions.DOES_NOT_REPEAT && Boolean(eventEndDateString);

  const dstart = getDateWithDatetime(day);

  const baseRule = {
    dtstart: dstart,
    // set local timezone here, because datetime returns only local timezone
    tzid: dayjs.tz?.guess(),
    ...(count && { count }),
    ...(isUntil && eventEndDateString && { until: eventEndDateString }),
  };

  switch (repeats) {
    case RepeatOptions.EVERY_WEEKDAY:
      return {
        freq: RRule.WEEKLY,
        // setting byweekday to Monday to Friday according to utc time
        byweekday: [
          getRruleDay(dayjs(day).weekday(1).utc().get('day')),
          getRruleDay(dayjs(day).weekday(2).utc().get('day')),
          getRruleDay(dayjs(day).weekday(3).utc().get('day')),
          getRruleDay(dayjs(day).weekday(4).utc().get('day')),
          getRruleDay(dayjs(day).weekday(5).utc().get('day')),
        ],
        ...baseRule,
      };
    case RepeatOptions.WEEKLY:
      return {
        freq: RRule.WEEKLY,
        byweekday: selectedDay,
        ...baseRule,
      };
    case RepeatOptions.BI_WEEKLY:
      return {
        freq: RRule.WEEKLY,
        interval: BI_WEEKLY_INTERVAL,
        byweekday: selectedDay,
        ...baseRule,
      };
    case RepeatOptions.MONTHLY:
      return {
        freq: RRule.MONTHLY,
        byweekday: selectedDay,
        bysetpos: 1,
        ...baseRule,
      };
    default:
      return null;
  }
};

const convertEventTimeToDate = (time: string, timeZone: string, calendarTimezone: string) => {
  // Set the time to the timezone that BE side send as current
  const timeWithCurrentTimeZone = dayjs.tz(time, timeZone);
  // Convert the time to the calendar timezone
  const startRecurringTimeInCalendarTimeZone = timeWithCurrentTimeZone.tz(calendarTimezone);

  // toDate() is used to convert the date to the local timezone, so we set the timezone to the calendar timezone
  return dayjs
    .tz(startRecurringTimeInCalendarTimeZone.format(DateFormat.YYYY_MM_DDTHH_mm_ss_sss), dayjs.tz?.guess())
    .toDate();
};

/**
 * Format date and time for create shift/time off mutation.
 *
 * @param {Date} date - The date to use.
 * @param {string} time - The time to use, in the format 'HH:mm'.
 * @param {string} dateTimeformat - The format to return the DateTime string in.
 * @returns {string} return date and time string in provided format.
 */
const getDateTime = (date: Date, time: string, dateTimeformat: string) => {
  const [startHours, startMinutes] = time.split(':').map(Number);

  return dayjs(date).set('hour', startHours).set('minute', startMinutes).format(dateTimeformat);
};

/**
 * Returns an array of days of the week based on the repeat option.
 *
 * @param {ObjectValues<typeof RepeatOptions>} repeats - The repeat option.
 * @param {Date} day - The date to use for weekly, bi-weekly, and monthly options.
 * @returns {number[]} An array of day numbers, where 0 is Sunday and 6 is Saturday.
 */
const getDaysOfWeek = (repeats: ObjectValues<typeof RepeatOptions>, day: Date): number[] => {
  if (repeats === RepeatOptions.EVERY_WEEKDAY) {
    return [1, 2, 3, 4, 5];
  } else if (
    repeats === RepeatOptions.WEEKLY ||
    repeats === RepeatOptions.BI_WEEKLY ||
    repeats === RepeatOptions.MONTHLY
  ) {
    return [day.getDay()];
  } else {
    return [];
  }
};

const getRecurringOptions = (date?: Date) => {
  const day = dayjs(date).format(DateFormat.dddd);

  return [
    { value: RepeatOptions.DOES_NOT_REPEAT, label: 'Does not repeat' },
    { value: RepeatOptions.EVERY_WEEKDAY, label: 'Every weekday (Monday to Friday)' },
    { value: RepeatOptions.WEEKLY, label: `Weekly on ${day}` },
    { value: RepeatOptions.BI_WEEKLY, label: `Bi-weekly on ${day}` },
    // { value: RepeatOptions.MONTHLY, label: `Monthly on first ${dayjs(date).format(DateFormat.dddd)}` }, Hide this option until it's implemented on the backend
  ];
};

export {
  formatTime,
  getRrule,
  getDateTime,
  getDaysOfWeek,
  getRecurringOptions,
  convertEventTimeToDate,
  getDateWithDatetime,
};
