import React, { MouseEvent } from 'react';

import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import Loader from 'components/common/Loader';
import SMSModal from 'components/modals/smsModal';
import TaskCategoryLabel from 'components/tables/TaskTable/TaskCategoryLabel';
import { TaskCategoryLabelProps } from 'components/tables/TaskTable/TaskCategoryLabel/taskCategoryLabel.types';
import dayjs from 'dayjs';
import { NotificationTab } from 'enums/notifications';
import { PathName } from 'enums/pathName';
import { motion } from 'framer-motion';
import useOpenTask from 'hooks/tasks/useOpenTask/useOpenTask';
import parseHTML from 'html-react-parser';
import { getQueueCategoryText } from 'pages/Tasks/Tasks.settings';
import { useDispatch } from 'react-redux';
import { useMatch } from 'react-router-dom';
import { openModal } from 'store/modal/modalSlice';
import { useClearNotificationMutation } from 'store/notifications/notificationsSlice';
import { useLazyGetPatientQuery } from 'store/patients/patientsSlice';
import { sanitizeHTML } from 'utils/common/parseHTML';
import { getValidRole } from 'utils/common/permissions/roles';
import { dateTimeFormatter } from 'utils/datesAndTime';

import { getNotificationText, parseNotificationMessage } from './notificationItem.settings';
import { NotificationItemProps } from './notificationItem.types';

const NotificationItem = React.forwardRef<HTMLDivElement, NotificationItemProps>(
  ({ notification, isModal, currentTab }, ref) => {
    const { handleOpenTask } = useOpenTask();
    const dispatch = useDispatch();
    const [clearNotification] = useClearNotificationMutation();
    const [getPatient, { isLoading, isFetching }] = useLazyGetPatientQuery();
    const isActivityFeedPage = !!useMatch(PathName.ActivityFeed);

    const isInboxTab = currentTab === NotificationTab.Inbox;
    const isSMS = notification.notificationType === 'SMS';
    const notificationButtonsInfo = notification.buttons[0];
    const notificationAdditionalInfo = notificationButtonsInfo?.additionalInfo;
    const createAtTime = dayjs().isSame(notification.createdAt, 'day')
      ? dayjs(notification.createdAt).fromNow()
      : dateTimeFormatter(notification.createdAt);

    const notificationMessage =
      notificationAdditionalInfo?.notificationDetails?.message &&
      sanitizeHTML(notificationAdditionalInfo?.notificationDetails?.message);

    const parsedNotificationMessage =
      notificationMessage &&
      parseHTML(notificationMessage, {
        replace: parseNotificationMessage,
      });

    const notificationItemClassName = classNames('flex relative border-b p-4 hover:bg-gray-200', {
      'bg-blue-50': !notification.notificationSeen,
    });

    const taskCategoryType =
      notificationAdditionalInfo?.taskSubCategory?.toLowerCase() === 'queue'
        ? 'queue'
        : (notificationAdditionalInfo?.taskCategory?.toLowerCase() as TaskCategoryLabelProps['type']);

    const role = getValidRole(notificationAdditionalInfo?.staffShortCode);

    const renderMessage = () => {
      if (Array.isArray(parsedNotificationMessage)) {
        return (
          <p className="w-fit truncate rounded-r-2xl rounded-bl-2xl bg-gray-800/5 px-4 py-2 text-sm">
            {parsedNotificationMessage[0].props.children}...
          </p>
        );
      } else {
        return parsedNotificationMessage;
      }
    };

    const handleViewTask = () => {
      const referenceId = notification.buttons[0].referenceId;
      const assignTo = notificationButtonsInfo?.assignedToInfo;

      if (!isSMS) {
        handleOpenTask({
          taskId: referenceId,
          assignToId: assignTo?._id,
          assignToName: assignTo?.name,
          shouldUseUrlOnlyInTaskPage: !isActivityFeedPage,
          isStaffNote: notification.isStaffNote,
        });
      } else {
        getPatient({ id: referenceId })
          .unwrap()
          .then((patient) => dispatch(openModal({ size: 'lg', modalContent: <SMSModal patientDetails={patient} /> })));
      }
    };

    const handleClearNotification = (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      clearNotification(notification._id).unwrap();
    };

    return (
      <motion.div
        ref={ref}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        onClick={() => handleViewTask()}
        className={notificationItemClassName}
        data-testid="notification_item"
      >
        <Loader isVisible={isLoading || isFetching} />
        <div className="relative flex h-fit">
          {!notification.notificationSeen && (
            <div className="absolute left-0 size-1.5 rounded-lg bg-blue-500" style={{ top: 'calc(50% - 3px)' }} />
          )}
          <Common.ProfileImage
            {...(role && { role })}
            src={notificationAdditionalInfo?.senderImage}
            size={isModal ? 'sm' : 'default'}
            className="ml-2 mr-3.5"
          />
        </div>

        <div className="flex w-full flex-col gap-4">
          <div data-testid="notification_content" className="flex justify-between">
            {getNotificationText({
              notificationType: notification.notificationType,
              actionType: notification.notificationTitle,
              notificationContent: notification.notificationContent,
              notificationAdditionalInfo: notificationAdditionalInfo,
              buttons: notification.buttons,
            })}
            <div className="flex items-center gap-2">
              {!isModal && taskCategoryType && (
                <TaskCategoryLabel
                  type={taskCategoryType}
                  appointmentStatus={
                    notificationAdditionalInfo?.taskSubCategory?.toLowerCase() === 'queue'
                      ? getQueueCategoryText(notificationAdditionalInfo?.taskCategory)
                      : (notificationAdditionalInfo?.appointmentCategoryInfo ?? '')
                  }
                />
              )}

              <span data-testid="time" className="whitespace-nowrap text-right text-xs text-gray-500">
                {createAtTime}
              </span>

              {isInboxTab && (
                <Common.Button
                  style="pill"
                  color="white-alt"
                  size="sm"
                  data-testid={`delete-${notification._id}`}
                  className="!p-1"
                  onClick={handleClearNotification}
                >
                  <Common.Icon name="close" className="size-3 text-gray" />
                </Common.Button>
              )}
            </div>
          </div>
          <div className={`flex ${isModal ? 'w-[318px]' : 'w-[560px]'}`}>{renderMessage()}</div>
        </div>
      </motion.div>
    );
  },
);

export default NotificationItem;
