import React from 'react';

import * as HoverCard from '@radix-ui/react-hover-card';
import Avatar from '@thecvlb/design-system/lib/src/assets/images/avatar.png';
import classNames from 'classnames';
import type { Member } from 'store/teams/teams.types';

const Members: React.FC<{ members: Member[] }> = ({ members }) => {
  return (
    <div className="relative flex max-w-sm overflow-hidden">
      {members.map((member, idx) => {
        return (
          <div
            className={classNames('-ml-2 flex-shrink-0', {
              'first:ml-0': idx === 0,
            })}
          >
            <HoverCard.Root openDelay={300} closeDelay={300}>
              <HoverCard.Trigger>
                <div className={'relative flex-none rounded-full border border-white'}>
                  <img
                    alt="profile image"
                    className="size-5 rounded-full object-cover"
                    data-testid="profile_image"
                    src={member.profileImage || Avatar}
                  />
                </div>
              </HoverCard.Trigger>

              <HoverCard.Portal>
                <HoverCard.Content className="combineTimeHint">
                  <p className="text-sm">
                    {member.firstName} {member.lastName}
                  </p>
                </HoverCard.Content>
              </HoverCard.Portal>
            </HoverCard.Root>
          </div>
        );
      })}
      <div className="absolute right-0 top-0 size-5 bg-gradient-to-l from-white" />
    </div>
  );
};

export default Members;
