import { HeaderTextProps } from './headerText.types';

const HeaderText: React.FC<HeaderTextProps> = ({ title, description }) => {
  return (
    <div className="mb-4 flex flex-col gap-1">
      <h2 className="hidden text-lg font-bold md:block">{title}</h2>
      <h3 className="text-gray-700">{description}</h3>
    </div>
  );
};

export default HeaderText;
