import React from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import { twMerge } from 'tailwind-merge';

import { UserProps } from './user.types';

const User: React.FC<UserProps> = ({ name, role, src, textClassname = 'text-sm' }) => {
  const wrapperClassName = 'flex items-center text-gray-700';
  const imageClassName = 'mr-2';
  const nameClassName = twMerge('mr-1 font-medium', textClassname);

  return (
    <div data-testid="user_container" className={wrapperClassName}>
      <Common.ProfileImage role={role} src={src} size="sm" className={imageClassName} />

      <span data-testid="user_name" className={nameClassName}>
        {name}
      </span>
    </div>
  );
};

export default User;
