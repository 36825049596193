import { CATEGORY_OPTION } from 'constants/article';
import { AUDIENCE } from 'constants/staff';
import { STATUS_OPTIONS } from 'constants/status';

import { useState } from 'react';

import classNames from 'classnames';
import DatePickerInput from 'components/common/DatePickerInput';
import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import InputField from 'components/forms/controlled/InputField';
import Footer from 'components/modals/Footer';
import dayjs from 'dayjs';
import { Role } from 'enums/role';
import { useTableFiltersData } from 'hooks/filters/useTableFiltersData';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import isEqual from 'lodash/isEqual';
import queryString from 'query-string';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { closeModal } from 'store/modal/modalSlice';
import { selectUser } from 'store/user/userSlice';
import { getAppliedFilterValues, handleReset } from 'utils/filters/filters';

import { ArticlesFilterFormProps } from './articlesFilterForm.types';
import { defaultValues } from './articlesFiltersForm.settings';

const ArticlesFiltersForm = () => {
  const { control, handleSubmit, reset, setValue, watch, getValues } = useForm<ArticlesFilterFormProps>({
    reValidateMode: 'onChange',
  });
  const [formData, setFormData] = useTableFiltersData('articles', defaultValues, reset);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { userType } = useAppSelector(selectUser);
  const [selected, setSelected] = useState<Date>();
  const isADUser = userType.name == Role.AD;

  const leftSideClasses = classNames('flex flex-col gap-4', isADUser ? 'pr-7 border-r border-gray-20' : 'col-span-2');
  const rightSideClasses = classNames('flex flex-col gap-4', isADUser ? 'pl-7' : 'mt-4 col-span-2');

  const onSubmit = (data: ArticlesFilterFormProps) => {
    setFormData({ ...defaultValues, ...data });
    const parsedUrl = queryString.parse(location.search);
    const filters = {
      ...parsedUrl,
      pageNo: '0',
      ...(data?.title && { title: data.title }),
      ...(data?.category && { category: data?.category?.value }),
      ...(data?.createdAt && { createdAt: data.createdAt }),
      ...(data?.uniqueId && { uniqueId: data.uniqueId }),
      ...(data?.author && { 'author.name': data.author }),
      ...(data?.audience && { 'audience.shortCode': data.audience?.value }),
      ...(data?.status && { status: data?.status?.value }),
    };

    const appliedFilterValues = getAppliedFilterValues(filters);

    if (!isEqual(appliedFilterValues, parsedUrl)) {
      navigate({ search: queryString.stringify(appliedFilterValues) });
    }
    dispatch(closeModal());
  };

  watch();

  return (
    <>
      <form className="w-[410px]" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2">
          <div className={leftSideClasses}>
            {isADUser && (
              <InputField
                name="title"
                label="Title"
                type="text"
                placeholder="Enter article title"
                control={control}
                value={formData?.title}
              />
            )}
            {!isADUser && (
              <ControlledSelect
                control={control}
                labelDirection="row"
                options={CATEGORY_OPTION}
                placeholder="Select category"
                label="Category:"
                name="category"
                defaultValue={formData?.category}
              />
            )}
            <InputField
              name="author"
              label="Author"
              labelDirection={isADUser ? 'col' : 'row'}
              type="text"
              placeholder="Enter author"
              control={control}
              value={formData?.author}
            />
            {isADUser && (
              <ControlledSelect
                control={control}
                labelDirection="col"
                options={AUDIENCE}
                placeholder="Select audience..."
                label="Audience"
                name="audience"
                defaultValue={formData?.audience}
              />
            )}
          </div>
          <div className={rightSideClasses}>
            {isADUser && (
              <InputField
                name="uniqueId"
                label="Unique ID"
                type="text"
                placeholder="Enter unique ID"
                control={control}
                value={formData?.uniqueId}
              />
            )}
            <DatePickerInput
              placeholder="Select date"
              label="Creation date"
              size="sm"
              labelDirection={isADUser ? 'col' : 'row'}
              inputValue={getValues('createdAt')}
              setInputValue={(value) => setValue('createdAt', value)}
              selectedDate={selected}
              setSelectedDate={setSelected}
              startAllowedDate={dayjs().subtract(100, 'year').toDate()}
              lastAllowedDate={new Date()}
              captionLayout={'dropdown'}
              wrapperClasses="w-full relative mr-14"
              isRtL
            />

            {isADUser && (
              <ControlledSelect
                control={control}
                labelDirection="col"
                options={STATUS_OPTIONS}
                placeholder="Select status..."
                label="Status"
                name="status"
                defaultValue={formData?.status}
              />
            )}
          </div>
        </div>
        <Footer onClick={() => handleReset(dispatch, reset, defaultValues, navigate)} />
      </form>
    </>
  );
};

export default ArticlesFiltersForm;
