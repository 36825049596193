import InputField from 'components/forms/controlled/InputField';
import { useAppDispatch } from 'hooks/redux';
import { useForm } from 'react-hook-form';
import { closeModal } from 'store/modal/modalSlice';
import { validation } from 'utils/helpers';
import { EMAIL_REGEXP } from 'utils/regExp';

import { UpdateEmailFormProps } from '../updateEmail.types';

type FormValues = {
  email: string;
};

const UpdateEmailForm = ({ onSubmit, isLoading }: UpdateEmailFormProps) => {
  const dispatch = useAppDispatch();
  const { control, formState, handleSubmit } = useForm<FormValues>({
    mode: 'onChange',
  });

  const isDisabled = !formState.isValid || isLoading;

  const handleCancel = () => {
    dispatch(closeModal());
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputField
        name="email"
        label="New email address"
        control={control}
        labelDirection="col"
        placeholder="yourname@lifemd.com"
        type="email"
        size="md"
        errors={formState.errors.email}
        rules={validation('email', EMAIL_REGEXP)}
        hint="We’ll send you an email to confirm the change."
        preIcon="email"
      />
      <div className="mt-6 flex justify-between gap-2">
        <button
          className="w-full rounded-lg bg-gray-100 py-[7.5px] text-sm font-bold text-gray-700 disabled:bg-gray-200 disabled:text-gray"
          onClick={handleCancel}
          type="button"
        >
          Cancel
        </button>
        <button
          className="w-full rounded-lg bg-primary py-[7.5px] text-sm font-bold text-white disabled:bg-gray-200 disabled:text-gray"
          disabled={isDisabled}
          type="submit"
        >
          Send confirmation
        </button>
      </div>
    </form>
  );
};

export default UpdateEmailForm;
