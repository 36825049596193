import React from 'react';

const NoteCell: React.FC<{
  note: string;
  maxioPath?: string;
}> = ({ maxioPath, note }) => {
  return (
    <div className="truncate">
      {maxioPath ? (
        <a target="_blank" href={maxioPath} className="text-sm font-semibold underline">
          {note}
        </a>
      ) : (
        <span className="text-sm font-semibold underline" data-testid="note-text">
          {note}
        </span>
      )}
    </div>
  );
};

export default NoteCell;
