import React, { useCallback, useEffect, useState } from 'react';

import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import { notifyError } from 'components/common/Toast/Toast';
import dayjs from 'dayjs';
import { PlanCodesProps } from 'enums/appointmentStatus';
import { useAppSelector } from 'hooks/redux';
import { selectPatient } from 'store/patients/patientsSlice';
import { convertNumToWord, formatDateForScheduling } from 'utils/appointment';

import { findPlan } from './membership.settings';
import { Props } from './membership.types';
import PlanCard from './PlanCard';
import { PlanType } from '../../CreateAppointmentModal.types';

const Membership: React.FC<Props> = ({
  onSelect,
  membershipPlans,
  handleBack,
  handleNext,
  freeAppointmentInfo,
  isLoading,
  planIncludeAppointment,
}) => {
  const [selectedPlan, setSelectedPlan] = useState('');
  const { patientInfo } = useAppSelector(selectPatient);

  const activePlanId = patientInfo?.planInfo?._id ?? '';
  const activePlanCode = patientInfo?.planInfo?.planCode ?? '';
  const activePricePoint = patientInfo?.activePricePoint ?? '';

  const currentPlan = findPlan(activePlanCode as PlanCodesProps, membershipPlans);
  const currentPricePoints = currentPlan?.pricePoints.find((pp) => pp.planPricePointId === activePricePoint);

  const unlimitedPlan = findPlan(PlanCodesProps.UnlimitedMembership, membershipPlans);
  const unlimitedPricePoints = unlimitedPlan?.pricePoints.find((pp) => pp.isDefault);

  const isFree = freeAppointmentInfo && freeAppointmentInfo.subscriptionHasFree && freeAppointmentInfo.isFree;
  const isTotalCarePlan = currentPlan?.planCode === PlanCodesProps.TotalCareMembership;

  const planContentClassName = 'mt-8 flex w-full flex-col gap-4 md:flex-row md:gap-8';
  const getPlanClassName = (planID = '') =>
    classNames(
      'cursor-pointer flex flex-col justify-start text-left w-full md:w-1/2 rounded-xl p-6 border border-gray-200',
      {
        'bg-blue-50 border-2 border-sky-400': selectedPlan && selectedPlan === planID,
      },
    );

  const onSelectPlanType = (type: PlanType.OneTime | PlanType.Unlimited) => {
    if (!currentPricePoints || !currentPlan || !unlimitedPlan || !unlimitedPricePoints) {
      return notifyError('Please try again later');
    }
    if (type === PlanType.OneTime) {
      onSelect({ planID: currentPlan._id, pp: currentPricePoints, type: PlanType.OneTime });
      setSelectedPlan(currentPlan._id);
    } else {
      onSelect({ planID: unlimitedPlan._id, pp: unlimitedPricePoints, type: PlanType.Unlimited });
      setSelectedPlan(unlimitedPlan?._id);
    }
  };

  const onInit = useCallback(() => {
    if (currentPlan) {
      setSelectedPlan(currentPlan._id);
    }
  }, [currentPlan]);

  useEffect(onInit, [onInit]);

  return (
    <>
      {(isTotalCarePlan || planIncludeAppointment) && (
        <div className="mt-8 flex flex-col items-center gap-4 text-primary-700 md:gap-8">
          <Common.Illustration name="calendar" />
          {isFree && (
            <p className="text-center" data-testid="free-notification">
              {`${patientInfo?.firstName || 'Patient'} has ${convertNumToWord(
                freeAppointmentInfo?.freeAppointmentsAmount ?? 0,
              )} free appointment available to be scheduled between `}
              <span className="font-bold">{formatDateForScheduling(freeAppointmentInfo.periodStart)}</span> and{' '}
              <span className="font-bold">{formatDateForScheduling(freeAppointmentInfo.periodEnd)}</span>.
            </p>
          )}

          {planIncludeAppointment && patientInfo?.planInfo && (
            <p className="text-center">
              {`Thanks to being a ${patientInfo.planInfo.planName}, the patient has `}
              <span className="font-bold">unlimited free appointments</span>
              {` and will not need to pay for this appointment`}
            </p>
          )}

          {!isFree && !!freeAppointmentInfo?.bookedFreeAppointmentDate && (
            <>
              <Common.Alert type="info">
                <span className="text-primary-700">
                  Their free appointment for the period from
                  <span className="font-bold">{` ${formatDateForScheduling(freeAppointmentInfo.periodStart)} `}</span>
                  to
                  <span className="font-bold">{` ${formatDateForScheduling(freeAppointmentInfo.periodEnd)} `}</span>
                  {dayjs(freeAppointmentInfo.bookedFreeAppointmentDate).isBefore(dayjs())
                    ? 'took place on'
                    : 'is scheduled for'}
                  <span className="font-bold">
                    {` ${formatDateForScheduling(freeAppointmentInfo.bookedFreeAppointmentDate || '')}`}
                  </span>
                  .
                </span>
              </Common.Alert>
              <p className="text-base text-primary-700">
                Please confirm with the patient how they’d like to proceed with appointment payment.
              </p>
            </>
          )}
        </div>
      )}

      {!planIncludeAppointment && (!isTotalCarePlan || (isTotalCarePlan && !isFree)) && (
        <div className={planContentClassName}>
          <button
            data-testid="one-time-plan"
            type="button"
            className={getPlanClassName(activePlanId)}
            onClick={() => onSelectPlanType(PlanType.OneTime)}
          >
            {currentPlan && currentPricePoints && (
              <PlanCard
                activePlanId={currentPlan._id}
                plan={currentPlan}
                planName={isTotalCarePlan ? 'Pay for this appointment' : 'Pay per appointment'}
                price={currentPricePoints.subsequentAppointmentCost}
                selectedPlan={selectedPlan}
                {...(freeAppointmentInfo?.bookedFreeAppointmentDate && {
                  isUsedFreeVisit: dayjs(freeAppointmentInfo.bookedFreeAppointmentDate).isBefore(dayjs()),
                })}
              />
            )}
          </button>
          <button
            data-testid="unlimited-plan"
            type="button"
            className={getPlanClassName(unlimitedPlan?._id)}
            onClick={() => onSelectPlanType(PlanType.Unlimited)}
          >
            {unlimitedPlan && (
              <PlanCard
                activePlanId={activePlanId}
                plan={unlimitedPlan}
                planName={isTotalCarePlan ? `Update to ${unlimitedPlan.planName}` : unlimitedPlan.planName}
                price={unlimitedPricePoints?.totalCost || '99'}
                selectedPlan={selectedPlan}
              />
            )}
          </button>
        </div>
      )}

      <div className="mt-8 flex items-center justify-center gap-3">
        <Common.Button color="white-alt" preIcon="arrow-left" onClick={handleBack} type="button">
          Back
        </Common.Button>
        <Common.Button
          color="blue"
          postIcon="arrow-right"
          onClick={handleNext}
          type="button"
          disabled={!selectedPlan || isLoading}
          isLoading={isLoading}
        >
          Next
        </Common.Button>
      </div>
    </>
  );
};

export default Membership;
