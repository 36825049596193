import { Common } from '@thecvlb/design-system';
import InputField from 'components/forms/controlled/InputField';
import { FieldValues, useForm } from 'react-hook-form';
import { validation } from 'utils/helpers';
import { NUMBER_GREATER_THAN_ZERO_REGEXP } from 'utils/regExp';

import { EditTargetWeightProps } from './editTargetWeight.types';

const EditTargetWeight: React.FC<EditTargetWeightProps> = ({ isOpen, onClose, patientName, defaultValue }) => {
  const { handleSubmit, control } = useForm<FieldValues>({
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const onSubmit = ({ goalWeight }: FieldValues) => {
    if (!goalWeight) {
      return;
    }
    onClose(+goalWeight);
  };

  return (
    <Common.Modal
      isOpen={isOpen}
      size="sm"
      title="Edit Target Weight"
      description={`for ${patientName ?? 'Patient'}`}
      zIndex={120}
    >
      <form className="flex flex-col gap-6" onSubmit={handleSubmit(onSubmit)}>
        <InputField
          type="number"
          control={control}
          name="goalWeight"
          postText="lbs"
          className="px-0.5"
          label="New target weight"
          defaultValue={defaultValue}
          rules={validation('Lbs', NUMBER_GREATER_THAN_ZERO_REGEXP)}
        />
        <div className="flex gap-2">
          <Common.Button
            className="w-full justify-center"
            color="white-alt"
            type="button"
            size="sm"
            onClick={() => onClose()}
          >
            Cancel
          </Common.Button>
          <Common.Button className="w-full justify-center" color="blue" size="sm">
            Save
          </Common.Button>
        </div>
      </form>
    </Common.Modal>
  );
};

export default EditTargetWeight;
