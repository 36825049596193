import { Editor } from '@tinymce/tinymce-react/node_modules/tinymce';

/**
 * @description
 * This function will put cursor at the end of the editor
 * Here more detailed explanation: https://www.tiny.cloud/blog/set-and-get-cursor-position/
 */
const handleEditorFocus = (editor: Editor) => {
  editor.selection.select(editor.getBody(), true);
  editor.selection.collapse(false);
  editor.focus();
};

export { handleEditorFocus };
