import {
  AppointmentType,
  GetUsersResponseProps,
  LicensedIn,
  Permissions,
  PlanRecommendationOption,
  SpecialtyArea,
  TimeZone,
  UserType,
  Variable,
} from 'store/lookup/lookup.types';

import { apiSlice } from '../api/apiSlice';

export const lookupApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getSpecialtyAreas: build.query<SpecialtyArea[], void>({
      query: () => `/lookup/specialty-areas`,
      transformResponse: (response: { data: SpecialtyArea[] }) => response.data,
      providesTags: ['Lookup'],
    }),
    getTaskCategories: build.query<string[], void>({
      query: () => `/lookup/task-categories`,
      transformResponse: (response: { data: string[] }) => response.data,
      providesTags: ['Lookup'],
    }),
    getPayTypes: build.query<string[], void>({
      query: () => `/lookup/pay-types`,
      transformResponse: (response: { data: string[] }) => response.data,
      providesTags: ['Lookup'],
    }),
    getLicensedIn: build.query<{ label: string; value: string }[], void>({
      query: () => `/lookup/state-list`,
      transformResponse: (response: { data: LicensedIn[] }) =>
        response.data.map((item) => ({ label: item.name, value: item.stateCode })),
      keepUnusedDataFor: 300000, // 5 minutes
      providesTags: ['Lookup'],
    }),
    getTimeZone: build.query<TimeZone[], void>({
      query: () => `/lookup/time-zones`,
      transformResponse: (response: { data: TimeZone[] }) => response.data,
      providesTags: ['Lookup'],
      keepUnusedDataFor: 300000, // 5 minutes
    }),
    getUsers: build.query<
      GetUsersResponseProps[],
      { userType?: string; limit?: number; pageNo?: number; searchKey?: string }
    >({
      query: ({ userType, limit, pageNo, searchKey }) => ({
        url: '/lookup/users',
        params: { userType, limit, pageNo, searchKey },
      }),
      transformResponse: (response: { data: GetUsersResponseProps[] }) => response.data,
    }),
    getPermissions: build.query<Permissions, void>({
      query: () => '/lookup/permissions',
      transformResponse: (response: { data: Permissions }) => {
        const columns = 3;

        const sortedPagePermission = response.data.pagePermissions.sort((a, b) => a.label.localeCompare(b.label));
        const sortedEditingPermission = response.data.editPermissions.sort((a, b) => a.label.localeCompare(b.label));

        const pagePermissionItemsInCol = Math.ceil(response.data.pagePermissions.length / columns);
        const editPermissionItemsInCol = Math.ceil(response.data.editPermissions.length / columns);

        return {
          editPermissions: sortedEditingPermission,
          pagePermissions: sortedPagePermission,
          pagePermissionItemsInCol,
          editPermissionItemsInCol,
        };
      },
      providesTags: ['Lookup'],
    }),
    getUserTypes: build.query<UserType[], string | void>({
      query: (taskCategory: string) => ({ url: '/lookup/user-type-access', params: { taskCategory } }),
      transformResponse: (response: { data: UserType[] }) => {
        return response.data.filter((item) => item.shortCode !== 'NP');
      },
      providesTags: ['Lookup'],
    }),
    getVariablesAndButtons: build.query({
      query: (type) => ({ url: '/lookup/variables-buttons', params: type }),
      transformResponse: (response: { data: { buttons: Variable[]; variables: Variable[] } }) => response.data,
    }),
    getEventTypes: build.query<{ name: string; category: string }[], void>({
      query: () => `/lookup/event-types`,
      providesTags: ['Lookup'],
      transformResponse: (response: { data: { name: string; category: string }[] }) => response.data,
    }),
    getPlanRecommendationOptions: build.query<PlanRecommendationOption[], { patientId: string }>({
      query: ({ patientId }) => `/lookup/${patientId}/plan-recommendations`,
      providesTags: ['Lookup'],
      transformResponse: (response: { data: PlanRecommendationOption[] }) => response.data,
    }),
    getLookupAppointmentTypes: build.query<AppointmentType[], { params: string }>({
      query: ({ params }) => `/lookup/appointment-types?${params}`,
      transformResponse: (response: { data: AppointmentType[] }) => response.data,
    }),
  }),
});

export const {
  useGetSpecialtyAreasQuery,
  useGetTaskCategoriesQuery,
  useGetLicensedInQuery,
  useGetTimeZoneQuery,
  useLazyGetTimeZoneQuery,
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useGetPermissionsQuery,
  useGetVariablesAndButtonsQuery,
  useLazyGetVariablesAndButtonsQuery,
  useLazyGetUserTypesQuery,
  useGetPayTypesQuery,
  useGetEventTypesQuery,
  useGetPlanRecommendationOptionsQuery,
  useGetLookupAppointmentTypesQuery,
} = lookupApi;
