import { SocketResponseProps } from 'models/socket.types';
import socketFrontDesk from 'socket/socketFrontDesk';
import socketMessages from 'socket/socketMessages';

const eventCallBack = (event: string, res: SocketResponseProps) => {
  if ('status' in res && res.status) {
    console.info(`socket ${event} => ${res.message}`);
  } else {
    console.error(`socket ${event} error => ${res.message}`);
  }
};

const closeAllSockets = () => {
  socketFrontDesk.auth = { authorization: '' };
  socketFrontDesk.disconnect();
  socketFrontDesk.close();

  socketMessages.auth = { authorization: '' };
  socketMessages.disconnect();
  socketMessages.close();
};

const logStyles = {
  connectMsgStyles: 'background: #00B167; color: #FFF;',
  errorMsgStyles: 'background: #F2355B; color: #FFF',
  disconnectMsgStyles: 'background: #FFBA38; color: #FFF',
};

export { eventCallBack, closeAllSockets, logStyles };
