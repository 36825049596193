import { StatusFilterData } from 'constants/filters';

import { useMemo } from 'react';

import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Common } from '@thecvlb/design-system';
import Card from 'components/common/Card';
import AppliedFiltersTags from 'components/filters/AppliedFiltersTags/AppliedFiltersTags';
import FilterButtons from 'components/filters/FilterTabs';
import SearchForm from 'components/filters/SearchForm';
import AppointmentCategoryVideos from 'components/modals/AppointmentCategoryVideos';
import AppointmentLengthDefaults from 'components/modals/AppointmentLengthDefaults';
import { AgingAlertsTableProps } from 'components/tables/AgingAlertsTable/agingAlertsTable.types';
import { columns } from 'components/tables/AppointmentTypesTable/columns';
import BasicTable from 'components/tables/BasicTable';
import { useGetDefaultBasicTableValues } from 'hooks/common/useGetDefaultBasicTableValues';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { AppointmentTypesQueryParams } from 'store/appointments/appointments.types';
import { useGetAppointmentTypesQuery } from 'store/appointmentTypes/appointmentTypesSlice';
import { openModal } from 'store/modal/modalSlice';

const AppointmentTypes: React.FC = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const queryParams: AppointmentTypesQueryParams = useMemo(() => {
    return queryString.parse(searchParams.toString());
  }, [searchParams]);
  const { data, isFetching, isUninitialized } = useGetAppointmentTypesQuery({ params: queryParams });

  const [tableData, tableColumns] = useGetDefaultBasicTableValues({
    isFetching: isFetching || isUninitialized,
    columns,
    data: data?.appointmentTypes,
  });

  const table = useReactTable({
    data: tableData,
    columns: tableColumns as ColumnDef<AgingAlertsTableProps, unknown>[],
    getCoreRowModel: getCoreRowModel(),
  });

  const onEditCategoryVideos = () => {
    dispatch(openModal({ size: 'lg', modalContent: <AppointmentCategoryVideos /> }));
  };

  const onEditLengthDefaults = () => {
    dispatch(openModal({ size: 'lg', modalContent: <AppointmentLengthDefaults /> }));
  };

  return (
    <>
      <div className="relative my-5 flex items-center justify-between">
        <FilterButtons filterData={StatusFilterData} queryParam="status" />
        <div className="flex items-center gap-2">
          <Common.Button
            dataTestId="edit_video_urls_btn"
            onClick={onEditCategoryVideos}
            color="green-alt"
            style="pill"
            size="sm"
          >
            Edit video urls
          </Common.Button>
          <Common.Button
            dataTestId="edit_length_btn"
            onClick={onEditLengthDefaults}
            color="green-alt"
            style="pill"
            size="sm"
          >
            Edit length defaults
          </Common.Button>
          <SearchForm showSearchBar />
        </div>
      </div>
      <AppliedFiltersTags />
      <Card>
        <BasicTable table={table} totalCount={data?.count || 0} isFetching={isFetching} />
      </Card>
    </>
  );
};

export default AppointmentTypes;
