import parse, { Element } from 'html-react-parser';

export const MessageCell: React.FC<{ title: string }> = ({ title }) => {
  return (
    <span className="text-sm">
      {parse(title, {
        replace: (domNode) => {
          if (domNode instanceof Element && domNode.type === 'tag' && (domNode.name === 'p' || domNode.name === 'a')) {
            domNode.attribs.className = 'text-sm';
            return domNode;
          }
        },
      })}
    </span>
  );
};
