import styled from 'styled-components';
import tw from 'twin.macro';

import { Rating, ScoreRating } from './suggestionScore.types';

const getRatingColor = (rating: ScoreRating) => {
  switch (rating) {
    case Rating.excellent:
      return '#00D17A';
    case Rating.good:
      return '#FFBA38';
    case Rating.fair:
      return '#FF863B';
    case Rating.poor:
      return '#FE6B89';
  }
};

export const ScoreContainer = styled.div<{ $rating: ScoreRating }>`
  ${tw`flex justify-center items-center w-fit h-full py-1.5 px-2 rounded-[50px]`}
  border: 1px solid ${({ $rating }) => getRatingColor($rating)};
`;

export const ScoreText = styled.span<{ $rating: ScoreRating }>`
  ${tw`text-xs font-semibold`}
  color: ${({ $rating }) => getRatingColor($rating)};
`;
