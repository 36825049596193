import { useEffect, useMemo } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';
import Alert from 'components/common/Alert';
import Loader from 'components/common/Loader';
import ControlledMultiSelect from 'components/forms/controlled/ControlledMultiSelect';
import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import type { PrescribeCompoundFormData } from 'components/modals/PrescribeCompound/prescribeCompound.types';
import { Controller, ControllerRenderProps, FieldValues, useFormContext } from 'react-hook-form';
import { useGetMedicationsQuery } from 'store/weightManagement/weightManagementSlice';
import { validation } from 'utils/helpers';

import {
  getFirstFreeMedication,
  getFormSuccessState,
  getPreviousPrescriptionData,
  matchWithNotAllowedStates,
  matchWithWarningStates,
} from './prescribeCompoundForm.settings';
import type { PrescribeCompoundFormProps } from './prescribeCompoundForm.types';

const PrescribeCompoundForm: React.FC<PrescribeCompoundFormProps> = ({
  patientId,
  patientState,
  isMaintenance,
  defaultPrescription,
  setSendSupplyKit,
}) => {
  const { control, resetField, setValue, formState, setError, reset, watch } =
    useFormContext<PrescribeCompoundFormData>();
  const { data: medications, isFetching } = useGetMedicationsQuery(
    { state: patientState, userId: patientId },
    { refetchOnMountOrArgChange: true },
  );

  const formData = watch();

  const isMedicationIncludedPlan = medications?.info?.hasMedicationDiscounts;
  const previousPrescription = medications?.info?.latestPrescribedCompound;
  const showSupplyKitCheckbox = formData.medication?.data?.hasSupplements;

  const autoPopulatedData = useMemo(() => {
    if (isMedicationIncludedPlan && medications?.data) {
      return getFirstFreeMedication(medications?.data, patientState);
    }
    if (!!previousPrescription) {
      return getPreviousPrescriptionData(medications?.data, previousPrescription);
    }
    return null;
  }, [isMedicationIncludedPlan, medications?.data, previousPrescription, patientState]);

  const totalPriceAmount = formData?.qty?.data?.totalPrice;
  const isFreeMedication = totalPriceAmount === 0;
  const isAllFieldsFilled =
    (!!formData?.medication?.value && !!formData.sig?.value && !!formData.qty?.value && !!formData.pharmacy?.value) ||
    !!isFreeMedication;
  const totalPrice = isFreeMedication ? 'Included' : `$${totalPriceAmount}`;

  const isError = !!Object.entries(formState.errors).length;

  const medicationOptions = useMemo(() => {
    return medications?.data
      ? medications?.data.map((medication) => ({ label: medication.name, value: medication._id, data: medication }))
      : [];
  }, [medications?.data]);

  const icd10CodeOptions = useMemo(() => {
    const medicationValue = formData?.medication?.data;

    return medicationValue?.icd10Codes
      ? medicationValue?.icd10Codes?.map((icd10Code) => ({
          label: `${icd10Code.code} ${icd10Code.description}`,
          value: icd10Code.code,
        }))
      : [];
    // should be triggered when medication is changed. Check it by id instead of whole object
  }, [formData?.medication?.data]);

  const sigOptions = useMemo(() => {
    const medicationValue = formData?.medication?.data;
    return medicationValue?.sigs
      ? medicationValue?.sigs?.map((sig) => ({ label: sig.name || '', value: sig._id || '', data: sig }))
      : [];
    // should be triggered when medication is changed. Check it by id instead of whole object
  }, [formData?.medication?.data]);

  const qtyOptions = useMemo(() => {
    const sigValue = formData?.sig?.data;
    return sigValue?.qty
      ? [
          {
            label: `${sigValue.qty} ${sigValue.unit}`,
            value: `${sigValue.qty}` || '',
            data: { qty: sigValue?.qty, unit: sigValue?.unit, totalPrice: sigValue?.totalPrice },
          },
        ]
      : [];
    // should be triggered when sig is changed. Check it by id instead of whole object
  }, [formData?.sig?.data?._id]);

  const pharmacyOptions = useMemo(() => {
    const medicationValue = formData?.medication?.data;
    return medicationValue?.pharmacies
      ? medicationValue?.pharmacies?.map((pharmacy) => ({
          label: pharmacy.name || '',
          value: pharmacy._id || '',
          data: pharmacy,
        }))
      : [];
    // should be triggered when medication is changed. Check it by id instead of whole object
  }, [formData?.medication?.data?._id]);

  const isCoverState = useMemo(() => {
    const pharmacyValue = formData?.pharmacy?.data;
    return !!patientState && pharmacyValue?.states?.includes(patientState);
    // should be triggered when pharmacy is changed. Check it by id instead of whole object
  }, [formData?.pharmacy?.data?._id, patientState]);

  const defaultPharmacy = useMemo(
    () =>
      patientState &&
      !matchWithNotAllowedStates(patientState) &&
      pharmacyOptions.find((pharmacyItem) => pharmacyItem.data.states?.includes(patientState)),
    [patientState, pharmacyOptions],
  );

  const handleOnChangeMedication = () => {
    setValue('icd10Codes', []);
    setValue('sig', null);
    setValue('qty', null);
  };

  useEffect(() => {
    setSendSupplyKit(!!showSupplyKitCheckbox);
  }, [setSendSupplyKit, showSupplyKitCheckbox]);

  useEffect(() => {
    if (formData?.sig?.value) {
      resetField('qty', { defaultValue: qtyOptions[0] || null });
    }
    // should be triggered when sig is changed. Check it by id instead of whole object
  }, [formData?.sig?.data?._id, qtyOptions, resetField]);

  useEffect(() => {
    if (formData.medication && matchWithNotAllowedStates(patientState)) {
      setError('pharmacy', {
        type: 'custom',
        message: `Patient is located in a state that Wells does not cover.`,
      });
    }
    if (formData?.medication?.value) {
      resetField('pharmacy', { defaultValue: defaultPharmacy || null });
    }
    // should be triggered when medication is changed. Check it by id instead of whole object
  }, [formData.medication?.data?._id, matchWithNotAllowedStates, setError, defaultPharmacy]);

  useEffect(() => {
    if (defaultPrescription && medications?.data) {
      const defaultMedication = medications?.data?.find(
        (medication) => medication.name.trim() === defaultPrescription.name.trim(),
      );
      const defaultSIG = defaultMedication?.sigs?.find((sig) => sig.name === defaultPrescription.sig);
      const defaultGTY = defaultSIG?.qty === defaultPrescription.qty;

      reset({
        ...(defaultMedication && {
          medication: { label: defaultMedication.name, value: defaultMedication._id, data: defaultMedication },
        }),
        ...(defaultSIG && { sig: { label: defaultSIG.name, value: defaultSIG._id, data: defaultSIG } }),
        ...(defaultSIG &&
          defaultGTY && {
            qty: {
              label: `${defaultSIG.qty} ${defaultSIG.unit}`,
              value: `${defaultSIG.qty}`,
              data: {
                qty: defaultSIG.qty,
                unit: defaultSIG.unit,
                totalPrice: defaultSIG.totalPrice,
              },
            },
          }),
        needSupplyKit: true,
      });
    }
  }, [defaultPrescription, medications?.data, reset]);

  useEffect(() => {
    if (autoPopulatedData && medications?.data && !formData.medication) {
      const defaultMedication = medications?.data?.find(
        (medication) => medication.name.trim() === autoPopulatedData.medication.name.trim(),
      );
      const defaultSIG = defaultMedication?.sigs?.find((sig) => sig.name === autoPopulatedData?.sig?.name);
      const defaultGTY = defaultSIG?.qty === autoPopulatedData?.qty?.qty;

      reset({
        ...(defaultMedication && {
          medication: { label: defaultMedication.name, value: defaultMedication._id, data: defaultMedication },
        }),
        ...(defaultSIG && { sig: { label: defaultSIG.name, value: defaultSIG._id, data: defaultSIG } }),
        ...(defaultSIG &&
          defaultGTY && {
            qty: {
              label: `${defaultSIG.qty} ${defaultSIG.unit}`,
              value: `${defaultSIG.qty}`,
              data: {
                qty: defaultSIG.qty,
                unit: defaultSIG.unit,
                totalPrice: defaultSIG.totalPrice,
              },
            },
          }),
        needSupplyKit: true,
        ...(autoPopulatedData.pharmacy && {
          pharmacy: {
            label: autoPopulatedData.pharmacy.name,
            value: autoPopulatedData.pharmacy._id,
            data: autoPopulatedData.pharmacy,
          },
        }),
      });
    }
  }, [autoPopulatedData, medications?.data, reset, formData.medication, defaultPharmacy]);

  const isNotIncludeInPlanForAsync = isAllFieldsFilled && !isFreeMedication && isMedicationIncludedPlan;
  const isNotIncludeInPlanForMaintenance = isAllFieldsFilled && !isFreeMedication && !!isMaintenance;
  const notIncludeInPlanAlertClassNames = classNames('mb-6 shadow-none', {
    'bg-yellow-100': !isNotIncludeInPlanForMaintenance,
  });

  const formSuccessStates = useMemo(
    () => getFormSuccessState(formData, autoPopulatedData),
    [autoPopulatedData, formData],
  );

  return (
    <div className="flex flex-col gap-4">
      {isFetching && <Loader isVisible />}
      <ControlledSelect
        dataTestId="medication_field"
        control={control}
        labelDirection="col"
        options={medicationOptions}
        rules={validation('Medication')}
        placeholder="Select..."
        label="Medication"
        name="medication"
        isSuccess={formSuccessStates.medications}
        onChange={handleOnChangeMedication}
      />
      {(isNotIncludeInPlanForAsync || isNotIncludeInPlanForMaintenance) && (
        <Alert
          type={isNotIncludeInPlanForMaintenance ? 'error' : 'warning'}
          containerClasses={notIncludeInPlanAlertClassNames}
          children={<p className="ml-1 text-base font-medium">This medication is not included in patient’s plan.</p>}
        />
      )}
      <ControlledMultiSelect
        dataTestId="icd_10_code_field"
        control={control}
        labelDirection="col"
        options={icd10CodeOptions}
        rules={validation('icd10Codes')}
        placeholder="Enter input..."
        label="ICD-10 code"
        name="icd10Codes"
        isDisabled={!formData?.medication}
        helperText={
          matchWithWarningStates(patientState)
            ? `In patient’s state (${patientState}), GLP-1s cannot be prescribed for obesity or BMI.`
            : ''
        }
        isSuccess={formSuccessStates.icd10Codes}
      />
      <ControlledSelect
        dataTestId="sig_field"
        control={control}
        labelDirection="col"
        options={sigOptions}
        placeholder="Select..."
        disabled={!formData?.medication}
        rules={validation('Sig')}
        label="Sig"
        name="sig"
        isSuccess={formSuccessStates.sig}
      />
      <ControlledSelect
        dataTestId="qty_field"
        control={control}
        labelDirection="col"
        options={qtyOptions}
        placeholder="Select..."
        disabled={!formData?.sig}
        rules={validation('Qty')}
        label="Qty"
        name="qty"
        isSuccess={formSuccessStates.qty}
      />
      {showSupplyKitCheckbox && (
        <Controller
          control={control}
          defaultValue={true}
          name="needSupplyKit"
          render={({ field }) => (
            <div data-testid="need_supply_kit_checkbox" className="flex gap-2">
              <Common.Checkbox
                // TODO: Fix the type casting on design system
                {...(field as unknown as ControllerRenderProps<FieldValues, 'needSupplyKit'>)}
                disabled={!formData?.qty}
                checked={field.value}
                color="blue"
                onChange={field.onChange}
                size="sm"
              >
                Needs supply kit
              </Common.Checkbox>
            </div>
          )}
        />
      )}

      <ControlledSelect
        dataTestId="pharmacy_field"
        control={control}
        labelDirection="col"
        options={pharmacyOptions}
        placeholder="Select..."
        disabled={!formData?.medication}
        rules={validation('Pharmacy')}
        label="Pharmacy"
        name="pharmacy"
        isSuccess={formSuccessStates.pharmacy}
      />
      {isAllFieldsFilled && isCoverState && !isError && (
        <div className="flex gap-1 rounded-lg border border-secondary-200 bg-secondary-50 p-4 text-base text-primary-700">
          <Common.Icon name="prescription-outline" />
          <span className="font-bold">Price to patient</span>
          <span data-testid="total_price" className="ml-auto font-medium">
            {totalPrice}
          </span>
        </div>
      )}
    </div>
  );
};

export default PrescribeCompoundForm;
