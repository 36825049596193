import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { UserTypes } from 'enums/userTypes';
import { Dictionary } from 'lodash';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import { isImageByFilename } from 'utils/files';

import { FormattedMessagesProps, MessageProps } from './messagesContext.types';

/**
 * Normalizes a message object by extracting and renaming certain properties.
 *
 * @param {MessageProps} message - The message object to normalize.
 * @param {boolean} [isGrouped] - Optional flag indicating if the message is part of a group.
 *
 * @returns {FormattedMessagesProps} The normalized message object.
 *
 * @example
 * const normalizedMessage = normalizeMessage(message, true);
 */
const normalizeMessage = (message: MessageProps, isGrouped?: boolean) => {
  return {
    author: message?.senderDetails?.name,
    channelId: message.channelId,
    deletedBy: message.deletedBy,
    deletedDate: message.deletedDate,
    date: new Date(message?.messageStatus.sent).getTime(),
    fileName: message?.fileName,
    filePath: message?.filePath,
    history: message.history,
    id: message._id,
    isBlurred: message.isBlurred,
    isGrouped,
    message: message.message,
    profileImage: message?.senderDetails?.profileImage,
    seen: message.receiverDetails.find((el) => el.messageStatus?.seen)?.messageStatus?.seen,
    seenPatient: message.receiverDetails.find((el) => el.userType?.name === UserTypes.PA && el.messageStatus?.seen)
      ?.messageStatus?.seen,
    sentBy: message?.senderDetails?.userType,
    userId: message?.senderDetails?.id,
    ...(message?.system && { system: message.system }),
    ...(message?.event && { event: message.event }),
    ...(message?.appointmentType && { appointmentType: message.appointmentType }),
    ...(message?.appointmentDate && { appointmentDate: message.appointmentDate }),
    ...(message?.sentViaSms && { sentViaSms: message.sentViaSms }),
  };
};

/**
 * Formats an array of message objects by sorting them by sent date and normalizing each message.
 * Messages from the same sender within a 5 minute interval are grouped together.
 *
 * @param {MessageProps[]} messages - The array of message objects to format.
 *
 * @returns {FormattedMessagesProps[]} The array of formatted message objects.
 *
 * @example
 * const formattedMessages = formatMessages(messages);
 */
const formatMessages = (messages: MessageProps[]) => {
  const result: FormattedMessagesProps[] = messages
    .sort((a, b) => new Date(a.messageStatus.sent).getTime() - new Date(b.messageStatus.sent).getTime())
    .map((message, index) => {
      const nextMessage = messages[index + 1];
      let isGrouped;
      if (nextMessage) {
        const diff = dayjs(nextMessage.messageStatus.sent).diff(message.messageStatus.sent, 'minutes');
        isGrouped = nextMessage?.senderDetails?.id === message?.senderDetails?.id && diff < 5; // 5 min
      }
      return normalizeMessage(message, isGrouped);
    });
  return result;
};

/**
 * Groups an array of message objects by their sent date.
 *
 * @param {MessageProps[]} messages - The array of message objects to group.
 *
 * @returns {Dictionary<FormattedMessagesProps[]>} An object where each key is a date and the value is an array of messages sent on that date.
 *
 * @example
 * const groupedMessages = groupMessagesByDate(messages);
 */
const groupMessagesByDate = (messages: MessageProps[]) => {
  const formattedMessages = formatMessages(messages);
  const grouped = groupBy(formattedMessages, (message) => dayjs(message.date).format(DateFormat.MM_DD_YYYY));

  return grouped;
};

/**
 * Extracts the file paths of all images from an array of message objects.
 *
 * @param {MessageProps[]} notes - The array of message objects to extract images from.
 *
 * @returns {string[]} An array of file paths for all images in the messages.
 *
 * @example
 * const imagePaths = getImagesFromMessages(messages);
 */
const getImagesFromMessages = (notes: MessageProps[]) => {
  const images: string[] = [];
  notes.forEach((note) => {
    if (note.fileName && note.filePath && isImageByFilename(note.fileName)) {
      images.push(note.filePath);
    }
  });
  return images;
};

/**
 * Retrieves the latest message from a dictionary of grouped messages.
 *
 * @param {Dictionary<[FormattedMessagesProps, ...FormattedMessagesProps[]]> | Dictionary<FormattedMessagesProps[]>} messages - The dictionary of grouped messages.
 *
 * @returns {FormattedMessagesProps | null} The latest message object, or null if no messages are provided.
 *
 * @example
 * const latestMessage = getLatestMessage(groupedMessages);
 */
const getLatestMessage = (
  messages: Dictionary<[FormattedMessagesProps, ...FormattedMessagesProps[]]> | Dictionary<FormattedMessagesProps[]>,
) => {
  const messagesFlat = Object.values(messages).flat();
  if (!isEmpty(messagesFlat)) {
    return messagesFlat.reduce((latestMessage, currentMessage) =>
      currentMessage.date > (latestMessage?.date || 0) ? currentMessage : latestMessage,
    );
  }
  return null;
};

export { formatMessages, groupMessagesByDate, getImagesFromMessages, getLatestMessage };
