import InputField from 'components/forms/controlled/InputField';
import { useFormContext } from 'react-hook-form';
import { HealthMetrics } from 'store/patientChart/patientChart.types';
import { validation } from 'utils/helpers';
import { DECIMAL_NUMBER_REGEXP } from 'utils/regExp';

const UpdateWaist: React.FC<{ metric?: HealthMetrics }> = ({ metric }) => {
  const { control, formState } = useFormContext();

  return (
    <div className="flex flex-col gap-4">
      <InputField
        name="waistCircumference"
        control={control}
        type="number"
        maxLength={10}
        errors={formState.errors.waistCircumference}
        rules={validation('Waist', DECIMAL_NUMBER_REGEXP)}
        postText="in"
        label="Waist circumference"
        labelDirection="row"
        defaultValue={metric?.waistCircumference ? metric.waistCircumference : ''}
      />
    </div>
  );
};

export default UpdateWaist;
