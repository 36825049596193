import { createRef, useCallback } from 'react';

import ActionButton from 'components/common/ActionButton';
import ViewAsButton from 'components/common/ViewAsButton';
import { getModalType } from 'components/tasks/slidingPane/Actions/actions.settings';
import Dropdown from 'components/tasks/slidingPane/Dropdown';
import { usePhoneCall } from 'contexts/phoneCallContext/phoneCallContext';
import { ActionsModalTypes } from 'enums/actionsModalTypes';
import useZoomCall from 'hooks/common/useZoomCall';
import { useDispatch } from 'react-redux';
import { openModal } from 'store/modal/modalSlice';
import { useLazyLoginLikePatientQuery, useLazyViewEMRQuery, useLazyViewMaxioQuery } from 'store/patients/patientsSlice';

import { ContactButtonsProps } from './contactButtons.types';

const ContactButtons: React.FC<ContactButtonsProps> = ({ patient }) => {
  const { isZoomCallOpen: zoomCallInProgress } = useZoomCall();
  const { showCallCard, hasGlobalInstance, hasSlidingPanelInstance, initiatePhoneCallProps } = usePhoneCall();
  const emailRef = createRef<HTMLAnchorElement>();
  const [loginLikePatient] = useLazyLoginLikePatientQuery();
  const [viewMaxio] = useLazyViewMaxioQuery();
  const [viewEMR] = useLazyViewEMRQuery();
  const dispatch = useDispatch();

  const patientFullName = patient.firstName + ' ' + patient.lastName;

  const handleViewEMR = () => viewEMR({ id: patient._id });

  const handleViewMaxio = useCallback(() => {
    patient?._id && viewMaxio({ id: patient._id });
  }, [viewMaxio, patient._id]);

  const handleLoginAsPatient = useCallback(() => {
    loginLikePatient({ id: patient?._id });
  }, [loginLikePatient, patient._id]);

  const handleOpenModal = useCallback(
    (type: ActionsModalTypes) => () => {
      dispatch(openModal(getModalType(type, patient._id, patientFullName, patient)));
    },
    [dispatch, patient, patientFullName],
  );

  // NOTE: we shouldn't show the call card if it's already shown
  // NOTE: we have separated the Phone call pop-up instance for the sliding panel and the global instance
  const handleCall = () => {
    if (!hasGlobalInstance && !hasSlidingPanelInstance) {
      const isSlidingPanel = false;
      initiatePhoneCallProps({
        isSlidingPanel,
      });
    }
  };

  return (
    <div className="flex items-center gap-4">
      <a ref={emailRef} href={`mailto:${patient.email}`} />
      <Dropdown
        handleLoginAsAPatient={handleLoginAsPatient}
        handleCreateNewTask={handleOpenModal(ActionsModalTypes.CreateNewTask)}
        handleCreateReminder={handleOpenModal(ActionsModalTypes.CreateReminder)}
        handleViewMaxio={handleViewMaxio}
        handleStopReceivingTasks={handleOpenModal(ActionsModalTypes.StopReceivingTasks)}
        handlePrescribeCompound={handleOpenModal(ActionsModalTypes.PrescribeCompound)}
        handleRemoveBillingBlockModal={handleOpenModal(ActionsModalTypes.RemoveBillingBlock)}
      />
      <ViewAsButton type="emr" onClick={handleViewEMR} />
      <ActionButton
        dataTestId="phone"
        type="phone"
        onClick={handleCall}
        disabled={zoomCallInProgress || !patient.phone || showCallCard}
      />
    </div>
  );
};

export default ContactButtons;
