import React from 'react';

import { twMerge } from 'tailwind-merge';

const SelectedItemCounter: React.FC<{
  count?: number;
  hideZero?: boolean;
  className?: string;
}> = ({ count, hideZero, className }) => {
  if (!count && hideZero) {
    return <></>;
  }

  return (
    <p className={twMerge('mt-[-10px] text-sm font-medium text-gray-500', className)}>
      {count} state{count && count > 1 ? 's' : ''} selected
    </p>
  );
};

export default SelectedItemCounter;
