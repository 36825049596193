import { PAY_TYPE_TO_OPTIONS, ROLE_OPTIONS } from 'constants/staff';
import { STATUS_OPTIONS } from 'constants/status';

import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import InputField from 'components/forms/controlled/InputField';
import Footer from 'components/modals/Footer';
import { useTableFiltersData } from 'hooks/filters/useTableFiltersData';
import { useAppDispatch } from 'hooks/redux';
import isEqual from 'lodash/isEqual';
import queryString from 'query-string';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { closeModal } from 'store/modal/modalSlice';
import { getAppliedFilterValues, handleReset } from 'utils/filters/filters';

import { defaultValues } from './staffCostsFiltersForm.settings';
import { StaffCostsFiltersFormProps } from './staffCostsFiltersForm.types';

const StaffCostsFiltersForm = () => {
  const { control, watch, handleSubmit, reset } = useForm<StaffCostsFiltersFormProps>({
    reValidateMode: 'onChange',
  });
  const [formData, setFormData] = useTableFiltersData('staffCosts', defaultValues, reset);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const onSubmit = (data: StaffCostsFiltersFormProps) => {
    setFormData({ ...defaultValues, ...data });
    const parsedUrl = queryString.parse(location.search);
    const filters = {
      ...parsedUrl,
      pageNo: '0',
      ...data,
      payType: data?.payType?.value,
      status: data?.status?.value,
      staff: data?.staff?.value,
    };

    const appliedFilterValues = getAppliedFilterValues(filters);

    if (!isEqual(appliedFilterValues, parsedUrl)) {
      navigate({ search: queryString.stringify(appliedFilterValues) });
    }
    dispatch(closeModal());
  };

  watch();

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="flex w-[312px] flex-col gap-4 2xl:w-[410px]">
        <ControlledSelect
          control={control}
          labelDirection="col"
          options={ROLE_OPTIONS}
          placeholder="Select staff"
          label="Staff"
          name="staff"
          defaultValue={formData?.staff}
        />
        <ControlledSelect
          control={control}
          labelDirection="col"
          options={PAY_TYPE_TO_OPTIONS}
          placeholder="Select pay type"
          label="Pay type"
          name="payType"
          defaultValue={formData?.payType}
        />
        <InputField
          name="payAmount"
          label="Pay amount"
          type="number"
          placeholder="Enter pay amount"
          hint="In USD ($)"
          control={control}
          defaultValue={formData?.payAmount}
        />
        <ControlledSelect
          control={control}
          labelDirection="col"
          options={STATUS_OPTIONS}
          placeholder="Select status"
          label="Status"
          name="status"
          defaultValue={formData?.status}
        />
        <Footer onClick={() => handleReset(dispatch, reset, defaultValues, navigate)} />
      </form>
    </>
  );
};

export default StaffCostsFiltersForm;
