import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';
import EditTargetWeight from 'components/modals/EditTargetWeight';
import HealthMetricsModal from 'components/modals/HealthMetrics/HealthMetrics';
import UpdateWeight from 'components/modals/HealthMetrics/Weight/Weight';
import dayjs from 'dayjs';
import { HealthMetricTypes } from 'enums/chart';
import { useDispatch } from 'react-redux';
import { useToggle } from 'react-use';
import { openModal } from 'store/modal/modalSlice';

import { WeightMetricsProps } from './weightDetails.types';

const Weight = ({
  weightManagement,
  latestMetrics,
  target,
  weights,
  isWMDevices,
  updateGoalWeight,
  patientName,
}: WeightMetricsProps) => {
  const dispatch = useDispatch();
  const [isOpenEditTargetWeight, toggleIsOpenEditTargetWeight] = useToggle(false);
  const currentWeight = latestMetrics?.weight;
  const initialWeight = weights?.[0]?.value;
  const currentWeightDate = weights?.[weights.length - 1]?.date;
  const initialWeightDate = weights?.[0]?.date;
  const currentDateDuration = dayjs.duration(dayjs(currentWeightDate).diff(), 'milliseconds').humanize();

  const isPatientLostWeight = currentWeight && initialWeight && initialWeight - currentWeight >= 0;
  const weightLostText = isPatientLostWeight
    ? `-${weightManagement.weightLost} lbs`
    : `+${Math.abs(weightManagement.weightLost)} lbs`;
  const weightLostColor = isPatientLostWeight ? 'green' : 'red';

  const isBmiUnder25 = latestMetrics?.bmi && +latestMetrics.bmi <= 25;

  const editIconClasses = 'cursor-pointer h-4 w-4 text-gray-500 hover:text-primary-500';

  const editTargetWeight = () => (
    <span data-testid="edit_target_weight_icon" onClick={toggleIsOpenEditTargetWeight}>
      <Common.Icon name="pencil" color="gray" className={editIconClasses} />
    </span>
  );

  const editWeightMetric = (type: string, date?: string, weight?: number) => (
    <button
      data-testid={`edit_${type}_weight_icon`}
      onClick={() =>
        dispatch(
          openModal({
            size: 'base',
            hideClose: true,
            modalContent: (
              <HealthMetricsModal metricDate={date} type={HealthMetricTypes.Weight} isEdit>
                <UpdateWeight metric={weight} />
              </HealthMetricsModal>
            ),
          }),
        )
      }
    >
      <Common.Icon name="pencil" className={editIconClasses} />
    </button>
  );

  const editCurrentWeight = !isWMDevices && editWeightMetric('current', currentWeightDate, currentWeight);
  const editInitialWeight = !isWMDevices && editWeightMetric('initial', initialWeightDate, initialWeight);

  return (
    <>
      <div className="mt-6 grid grid-cols-6 gap-2 text-left">
        <div className="">
          <p className="text-base font-bold">Weight</p>
          <p data-testid="current_weight_date" className="text-sm font-bold text-gray-700">
            {currentDateDuration ? `${currentDateDuration} ago` : '-'}
          </p>
        </div>
        <div className="flex flex-col">
          <p className="text-sm text-gray-700">Current</p>
          <p data-testid="current_weight" className="flex items-center gap-1 text-sm font-bold text-gray-700">
            {latestMetrics?.weight ? `${latestMetrics?.weight} lbs` : '-'}
            <Common.ColorTag
              size="sm"
              data-testid="lost_weight"
              dataTestId="lost_weight"
              color={weightLostColor}
              text={weightLostText}
            />
            {editCurrentWeight}
          </p>
        </div>

        <div className="flex flex-col">
          <p className="text-sm text-gray-700">Last renewal</p>
          <p data-testid="last_renewal_weight" className="text-sm font-bold text-gray-700">
            {weightManagement?.weightPrevious ? `${weightManagement?.weightPrevious} lbs` : '-'}
          </p>
        </div>

        <div className="flex flex-col">
          <p className={classNames('text-sm', isBmiUnder25 ? 'text-red-500' : 'text-gray-700')}>BMI</p>
          <p
            data-testid="bmi"
            className={classNames('text-sm font-bold', isBmiUnder25 ? 'text-red-500' : 'text-gray-700')}
          >
            {latestMetrics?.bmi ?? '-'}
          </p>
        </div>
        <div className="flex flex-col">
          <p className="text-sm text-gray-700">Goal</p>
          <p data-testid="target_weight" className="flex items-center gap-1 text-sm font-bold text-gray-700">
            {target?.value ? `${target.value} lbs` : '-'}
            {editTargetWeight()}
          </p>
        </div>
        <div className="flex flex-col">
          <p className="text-sm text-gray-700">Initial</p>
          <p data-testid="initial_weight" className="flex items-center gap-1 text-sm font-bold text-gray-700">
            {weights?.[0]?.value ? `${weights?.[0]?.value} lbs` : '-'}
            {editInitialWeight}
          </p>
        </div>
      </div>
      {isWMDevices && (
        <div className="mt-1 grid grid-cols-6 gap-2 text-left">
          <div className="w-[120px]"></div>
          <div className="flex flex-col">
            <p className="text-sm text-gray">Visceral fat</p>
            <p data-testid="visceral_fat" className="text-sm text-gray">
              {latestMetrics?.visceralFat ? `${latestMetrics?.visceralFat} lbs` : '-'}
            </p>
          </div>

          <div className="flex flex-col">
            <p className="text-sm text-gray">Body fat</p>
            <p data-testid="body_fat" className="text-sm text-gray">
              {latestMetrics?.bodyFat ? `${latestMetrics?.bodyFat} %` : '-'}
            </p>
          </div>

          <div className="flex flex-col">
            <p className="text-sm text-gray">Total water</p>
            <p data-testid="total_water" className="text-sm text-gray">
              {latestMetrics?.totalWater ? `${latestMetrics?.totalWater} %` : '-'}
            </p>
          </div>
          <div className="flex flex-col">
            <p className="text-sm text-gray">Muscle mass</p>
            <p data-testid="muscle_mass" className="text-sm text-gray">
              {latestMetrics?.muscleMass ? `${latestMetrics?.muscleMass} lbs` : '-'}
            </p>
          </div>
          <div className="flex flex-col">
            <p className="text-sm text-gray">Bone mass</p>
            <p data-testid="bone_mass" className="text-sm text-gray">
              {latestMetrics?.boneMass ? `${latestMetrics?.boneMass} lbs` : '-'}
            </p>
          </div>
        </div>
      )}
      <EditTargetWeight
        isOpen={isOpenEditTargetWeight}
        onClose={(goalWeight) => updateGoalWeight(toggleIsOpenEditTargetWeight, goalWeight)}
        patientName={patientName}
        defaultValue={target?.value}
      />
    </>
  );
};

export default Weight;
