import { FormattedMessagesProps } from 'contexts/MessagesContext/messagesContext.types';

export const getLatestPatientQuestions = (messages: Record<string, FormattedMessagesProps[]>) => {
  // Flatten and sort messages by date in descending order
  const sortedMessages = Object.values(messages)
    .flat()
    .sort((a, b) => b.date - a.date);

  // Find the index of the first message not sent by a patient
  const firstNonPatientIndex = sortedMessages.findIndex((message) => message.sentBy?.shortCode !== 'PA');

  // If no non-patient message is found, set the index to the length of the array
  const endIndex = firstNonPatientIndex === -1 ? sortedMessages.length : firstNonPatientIndex;

  // Get the latest patient messages up to the first non-patient message
  const latestPatientMessages = sortedMessages.slice(0, endIndex);

  // Combine the messages into a single string in a chronological order
  const combinedMessages = latestPatientMessages
    .reverse()
    .map((message) => message.message)
    .join('. ');

  return combinedMessages;
};
