export interface ExceptionProps {
  startTime: string;
  endTime: string;
  queue: boolean;
  appointments: boolean;
}

export interface AvailabilityProps {
  nonWorkingDays?: number[];
  timezone: string;
  userId: string;
  workHours?: AvailabilityWorkHours;
  overRide: { [date: string]: ExceptionProps[] };
}

export type AvailabilityWorkHours = {
  [unit: string]: { startTime: string; endTime: string; id: string; queue: boolean; appointments: boolean }[];
};

export const initialState: { isChanged: boolean; data: AvailabilityProps; availableDays: { [key: string]: boolean } } =
  {
    isChanged: false,
    data: {
      nonWorkingDays: [],
      overRide: {},
      timezone: '',
      userId: '',
      workHours: {
        Sunday: [],
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
      },
    },
    availableDays: {
      Sunday: false,
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
    },
  };
