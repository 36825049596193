import { CalendarProps, EventProps } from '../calendar.types';

export enum AppointmentStatus {
  Completed = 'completed',
  Cancelled = 'cancelled',
  Pending = 'pending',
  Missed = 'missed',
}

export type TooltipProps = {
  eventInfo: EventProps;
  events: CalendarProps['events'];
  timezone: string;
};
