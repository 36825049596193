import { STATUS_OPTIONS } from 'constants/status';

import { useEffect } from 'react';

import Loader from 'components/common/Loader';
import { notifySuccess } from 'components/common/Toast/Toast';
import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import InputField from 'components/forms/controlled/InputField';
import PopupFooter from 'components/modals/components/PopupFooter';
import PopupHeader from 'components/modals/components/PopupHeader';
import { useAppDispatch } from 'hooks/redux';
import { useForm } from 'react-hook-form';
import {
  useCreateBillingConfigMutation,
  useDeleteBillingConfigMutation,
  useLazyGetBillingQuery,
  useUpdateBillingConfigMutation,
} from 'store/billing/billingSlise';
import { closeModal } from 'store/modal/modalSlice';
import { validation } from 'utils/helpers';
import { ANY_CHARACTER_REGEXP } from 'utils/regExp';

import { BILLING_PLAN_A, BILLING_PLAN_B } from './billingConfigurationDtandard.settings';
import { BillingConfigurationStandardProps, BillingFormProps } from './BillingConfigurationStandard.types';

const BillingConfigurationStandard: React.FC<BillingConfigurationStandardProps> = ({ id, status }) => {
  const dispatch = useAppDispatch();
  const [getBilling, { data: billingId, isLoading }] = useLazyGetBillingQuery();
  const [addBillingConfiguration, { isLoading: loadingCreate }] = useCreateBillingConfigMutation();
  const [updateBillingConfiguration, { isLoading: loadingEdit }] = useUpdateBillingConfigMutation();
  const [deleteBilling, { isLoading: loadingDelete }] = useDeleteBillingConfigMutation();
  const { handleSubmit, control, formState, reset } = useForm<BillingFormProps>({ reValidateMode: 'onChange' });

  const isDisabled = isLoading || loadingCreate || loadingEdit || loadingDelete;
  const selectedStatusOption = STATUS_OPTIONS.find((option) => option.value === status);

  const onSuccess = (message: string) => {
    notifySuccess(message);
    dispatch(closeModal());
  };

  const onSubmitForm = (formData: BillingFormProps) => {
    if (formData) {
      const body = {
        category: formData.category,
        subCategory: formData.subCategory,
        planA: formData.planA.value,
        planB: formData.planB.value,
        status: formData.status.value ?? status,
      };
      const request = id ? updateBillingConfiguration({ id, body }) : addBillingConfiguration(body);
      request.unwrap().then((res) => onSuccess(res.message));
    }
  };

  const onDeleteBilling = () => {
    if (id) {
      deleteBilling({ id })
        .unwrap()
        .then((res) => onSuccess(res.message));
    }
  };

  useEffect(() => {
    if (billingId) {
      reset({
        category: billingId.category,
        subCategory: billingId.subCategory,
        planA: { value: billingId.planA.toString(), label: billingId.planA.toString() },
        planB: { value: billingId.planB.toString(), label: billingId.planB.toString() },
        status: selectedStatusOption,
      });
    }
  }, [selectedStatusOption, billingId, reset]);

  useEffect(() => {
    if (id) {
      getBilling({ id });
    }
  }, [getBilling, id]);

  return (
    <div data-testid="billing_configuration_popup" className="p-8">
      <PopupHeader title="Billing configuration" id={id} />
      <Loader isVisible={isDisabled} />
      <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmitForm)}>
        <InputField
          name="category"
          label="Category"
          placeholder="Category"
          control={control}
          labelDirection="row"
          type="text"
          errors={formState.errors.category}
          rules={validation('Category', ANY_CHARACTER_REGEXP)}
        />
        <InputField
          name="subCategory"
          label="Sub-category"
          placeholder="Sub-category"
          control={control}
          labelDirection="row"
          type="text"
          errors={formState.errors.subCategory}
          rules={validation('Sub-category', ANY_CHARACTER_REGEXP)}
        />
        <ControlledSelect
          control={control}
          labelDirection="row"
          options={BILLING_PLAN_A}
          placeholder="Select plan A..."
          label="Plan A price"
          name="planA"
          rules={validation('Plan A price')}
        />
        <ControlledSelect
          control={control}
          labelDirection="row"
          options={BILLING_PLAN_B}
          placeholder="Select plan B..."
          label="Plan B price"
          name="planB"
          rules={validation('Plan B price')}
        />
        <ControlledSelect
          control={control}
          labelDirection="row"
          options={STATUS_OPTIONS}
          placeholder="Select status..."
          label="Status"
          name="status"
          rules={validation('Status')}
        />

        <PopupFooter hiddenDeleteButton={!id} onRemove={onDeleteBilling} disabled={isDisabled} />
      </form>
    </div>
  );
};

export default BillingConfigurationStandard;
