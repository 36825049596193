import { Common } from '@thecvlb/design-system/lib/src';

import { InsuranceInfoProps } from './insuranceInfo.types';

const InsuranceInfo = ({ insuranceInfo, onClick }: InsuranceInfoProps) => {
  const insuranceInfoData = {
    'Member ID': insuranceInfo?.memberId,
    'RX BIN': insuranceInfo?.rxBIN,
    'RX Group': insuranceInfo?.rxGroupId,
    PCN: insuranceInfo?.rxPCN,
  };

  return (
    <div>
      {Object.entries(insuranceInfoData).map(([label, value]) => (
        <div className="mb-2 flex" key={label}>
          <p className="mr-1 min-w-24 text-base font-medium text-gray">{label}</p>
          <p className="whitespace-normal text-base font-medium text-gray-700">{value}</p>
        </div>
      ))}
      <Common.Button color="white-alt" className="mt-4" onClick={onClick} size="sm">
        Update insurance
      </Common.Button>
    </div>
  );
};

export default InsuranceInfo;
