import { useEffect } from 'react';

import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import classNames from 'classnames';
import Card from 'components/common/Card';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useParams } from 'react-router-dom';
import { resetCalendarState, setCalendarTimezone } from 'store/calendar/calendarSlice';
import { useLazyGetStaffQuery } from 'store/staffs/staffsSlice';
import { selectUser } from 'store/user/userSlice';

import MonthlyCalendar from './MonthlyCalendar';
import TimezoneHeader from '../TimezoneHeader';

const MonthlyAvailabilityTab: React.FC<{
  shouldShowCardWrapper?: boolean;
}> = ({ shouldShowCardWrapper }) => {
  /**
   * @description
   * If user is MA get id of selected provider from URL
   * If user is PH get id of current user
   */
  const { id: physicianId = '' } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const { timezone: loggedInUserTimezone, userType, _id: id } = useAppSelector(selectUser);

  const [getStaffDetails, { data: staffDetails, isLoading }] = useLazyGetStaffQuery();

  const viewAvailabilityForStaff = userType.shortCode === 'MA' || userType.shortCode === 'AD';

  const staffId = viewAvailabilityForStaff ? physicianId : id;

  const getTabClassNames = (active: boolean) =>
    classNames('text-gray text-sm font-semibold py-2 px-4', {
      'text-secondary bg-secondary-100 rounded-full': active,
    });

  useEffect(() => {
    return () => {
      dispatch(resetCalendarState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (viewAvailabilityForStaff && !isLoading && staffDetails?.timezone) {
      dispatch(setCalendarTimezone(staffDetails?.timezone));
    } else if (!isLoading && !viewAvailabilityForStaff) {
      dispatch(setCalendarTimezone(loggedInUserTimezone));
    }
  }, [isLoading, staffDetails?.timezone, loggedInUserTimezone, viewAvailabilityForStaff, dispatch]);

  useEffect(() => {
    if (staffId) {
      getStaffDetails({ staffId: staffId }, true);
    }
  }, [getStaffDetails, staffId]);

  const content = (
    <>
      <TimezoneHeader viewAvailabilityForStaff={viewAvailabilityForStaff} staffId={staffId} />
      <TabGroup>
        <TabList className="my-4 px-6">
          <Tab className={({ selected }) => getTabClassNames(selected)}>Monthly</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <MonthlyCalendar staffId={staffId} />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </>
  );
  return shouldShowCardWrapper ? <Card className="flex flex-col rounded-b-none">{content}</Card> : content;
};

export default MonthlyAvailabilityTab;
