import { IMAGE_EXTENSIONS } from 'constants/extensions';

import { notifyError } from 'components/common/Toast/Toast';

export const validateAndConvertProfileImage = (
  e: React.ChangeEvent<HTMLInputElement>,
  onSuccess: (file: File) => void,
  onError: (errorMessage: string) => void,
): void => {
  const fileList = e.target.files;
  if (!fileList) {
    onError('No file provided');
    return;
  }
  const file = fileList[0];
  if (!file.name.match(/\.(png|jpg|jpeg|gif|svg|heic|heif)$/i)) {
    onError('This file type cannot be used as profile image');
    return;
  }
  const extensionIndex = file.name.lastIndexOf('.');
  const fileExtension = file.name.substring(extensionIndex).toLowerCase();
  if (fileExtension === '.heic' || fileExtension === '.heif') {
    notifyError(`We aren't support ${fileExtension} format please chose supported one`);
    return;
  }
  onSuccess(file);
};

export const isImageByFilename = (fileName: string) => {
  const extensionIndex = fileName.lastIndexOf('.');
  const fileExtension = fileName.substring(extensionIndex).toLowerCase();
  return IMAGE_EXTENSIONS.includes(fileExtension);
};
