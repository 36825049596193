import { useEffect, useMemo, useState } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import Loader from 'components/common/Loader';
import { NotificationTab } from 'enums/notifications';
import { PathName } from 'enums/pathName';
import { AnimatePresence } from 'framer-motion';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import isEqual from 'lodash/isEqual';
import queryString from 'query-string';
import { useInView } from 'react-intersection-observer';
import { useLocation, useSearchParams } from 'react-router-dom';
import { usePrevious } from 'react-use';
import { NotificationQueryParamsProps } from 'store/notifications/notifications.types';
import {
  notificationsSelectors,
  resetNotifications,
  selectNotifications,
  useLazyGetNotificationsQuery,
} from 'store/notifications/notificationsSlice';

import { NotificationsListProps } from './notificationsList.types';
import NotificationItem from '../NotificationItem';

const selectNotificationsListState = createSelector(
  [selectNotifications, notificationsSelectors.selectAll],
  (notifications, allNotifications) => ({
    totalNotificationsCount: notifications.totalNotificationsCount,
    allNotifications,
  }),
);

const NotificationsList: React.FC<NotificationsListProps> = ({ dataTestId, currentTab, isModal }) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { totalNotificationsCount, allNotifications } = useAppSelector(selectNotificationsListState);
  const [getNotifications, { isFetching, isUninitialized }] = useLazyGetNotificationsQuery();
  const [searchParams] = useSearchParams();
  const isLoading = isFetching || isUninitialized;

  const isActivityFeed = location.pathname.includes(PathName.ActivityFeed);

  const [page, setPage] = useState(isActivityFeed ? 0 : 1);
  const { ref, inView } = useInView();
  const prevCurrentTab = usePrevious(currentTab);

  const queryParams: NotificationQueryParamsProps = useMemo(() => {
    const parsedQueryParams = queryString.parse(searchParams.toString());
    // remove taskModalOpenId from request payload
    if (parsedQueryParams?.taskModalOpenID) delete parsedQueryParams?.taskModalOpenID;
    return parsedQueryParams;
  }, [searchParams]);

  const prevQueryParams = usePrevious(queryParams);

  const shouldResetNotifications = useMemo(
    () => (prevCurrentTab && prevCurrentTab != currentTab) || !isEqual(prevQueryParams, queryParams),
    [currentTab, queryParams, prevCurrentTab, prevQueryParams],
  );

  useEffect(() => {
    if (shouldResetNotifications) {
      setPage(0);
      dispatch(resetNotifications());
    }
  }, [shouldResetNotifications, dispatch]);

  useEffect(() => {
    getNotifications({
      params: { ...queryParams, pageNo: page, limit: 10, isCleared: currentTab === NotificationTab.Archived },
    });
  }, [page, getNotifications, currentTab, queryParams]);

  useEffect(() => {
    if (totalNotificationsCount !== allNotifications.length && inView) {
      setPage((prevState) => prevState + 1);
    }
  }, [inView, allNotifications.length, totalNotificationsCount]);

  const notifications = useMemo(
    () =>
      currentTab === NotificationTab.Archived
        ? allNotifications.filter((item) => item.isCleared)
        : allNotifications.filter((item) => !item.isCleared),
    [allNotifications, currentTab],
  );

  return (
    <>
      {isLoading && <Loader isVisible />}
      {notifications.length ? (
        <AnimatePresence>
          {notifications.map((notification) => (
            <NotificationItem
              dataTestId={dataTestId}
              isModal={isModal}
              notification={notification}
              key={notification._id}
              currentTab={currentTab}
              ref={ref}
            />
          ))}
        </AnimatePresence>
      ) : (
        notifications &&
        !isLoading && (
          <div className="w-full flex-col content-center px-8 py-16 text-center">
            <p className="text-lg font-bold">🎉 All clear!</p>
            <p className="text-sm text-gray-600">
              No {currentTab === NotificationTab.Inbox ? 'new' : 'archived'} notifications
            </p>
          </div>
        )
      )}
    </>
  );
};

export default NotificationsList;
