import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import parse, { Element } from 'html-react-parser';
import { PatientProps } from 'store/patients/patients.types';
import { sanitizeHTML } from 'utils/common/parseHTML';

export const LastInteractionCell: React.FC<{ lastInteraction: PatientProps['lastInteraction'] }> = ({
  lastInteraction,
}) => {
  let indexOfTags = 0;
  const sanitizedString = lastInteraction?.message && sanitizeHTML(lastInteraction.message);
  return (
    <span className="flex max-w-[150px] truncate text-sm font-medium">
      {lastInteraction ? (
        <>
          {dayjs(lastInteraction.createdAt).format(DateFormat.MMM_D)} 
          {lastInteraction.createdAt && sanitizedString && ' • '}&nbsp;
          {parse(sanitizedString || '', {
            replace: (domNode, index) => {
              if (
                domNode instanceof Element &&
                domNode.type === 'tag' &&
                (domNode.name === 'p' || domNode.name === 'a')
              ) {
                indexOfTags = index;
                if (!index) {
                  domNode.attribs.className = 'text-sm truncate';
                  return domNode;
                } else return <span></span>;
              }
            },
          })}
          {!!indexOfTags && <span className="ml-1 text-sm font-bold">+{indexOfTags} more</span>}
        </>
      ) : (
        'N/A'
      )}
    </span>
  );
};
