import type {
  ElationMedication,
  Pharmacies,
  PreviousPrescription,
} from 'store/weightManagement/weightManagement.types';

import type { AutoPopulatedData, FormSuccessState } from './prescribeCompoundForm.types';
import type { PrescribeCompoundFormData } from '../prescribeCompound.types';

const notAllowedStates = ['Alabama'];
const warningICDCodeStates: string[] = [];

export const matchWithNotAllowedStates = (patientState?: string): boolean =>
  !!patientState && notAllowedStates?.includes(patientState);
export const matchWithWarningStates = (patientState?: string): boolean =>
  !!patientState && warningICDCodeStates?.includes(patientState);

export const getDefaultPharmacy = (pharmacies: Pharmacies[], patientState?: string) => {
  if (!patientState || !pharmacies || matchWithNotAllowedStates(patientState)) return null;

  const defaultPharmacy =
    !!patientState && pharmacies.find((pharmacyItem) => pharmacyItem.states.includes(patientState));

  return defaultPharmacy ? defaultPharmacy : null;
};

export const hasAnyDiscount = (medicines: ElationMedication[]) => {
  for (const medicine of medicines) {
    for (const sig of medicine.sigs) {
      if (sig.isDiscounted) {
        return true;
      }
    }
  }
  return false;
};

export const getFirstFreeMedication = (
  medications: ElationMedication[],
  patientState?: string,
): AutoPopulatedData | null => {
  for (const medication of medications) {
    for (const sig of medication.sigs) {
      const freeQty = sig.totalPrice === 0;

      if (freeQty) {
        return {
          medication,
          sig,
          qty: {
            qty: sig.qty,
            unit: sig.unit,
            totalPrice: sig.totalPrice,
          },
          pharmacy: getDefaultPharmacy(medication.pharmacies, patientState),
        };
      }
    }
  }
  return null;
};

export const getPreviousPrescriptionData = (
  medications: ElationMedication[],
  previousPrescription: PreviousPrescription,
): AutoPopulatedData | null => {
  const medication = medications.find((med) => med._id === previousPrescription.medicationId);
  if (!medication) return null;
  const sig = medication.sigs.find((sigItem) => sigItem._id === previousPrescription.sigId);
  const qty = sig && {
    qty: sig.qty,
    unit: sig.unit,
    totalPrice: sig.totalPrice,
  };
  const pharmacy = medication.pharmacies.find((pharmacyItem) => pharmacyItem._id === previousPrescription.pharmacyId);

  return { medication, sig, qty, pharmacy };
};

export const getFormSuccessState = (
  form: PrescribeCompoundFormData,
  prePopulatedData: AutoPopulatedData | null,
): FormSuccessState => {
  const medications = !!form && !!prePopulatedData && form.medication?.data?._id === prePopulatedData?.medication._id;
  const sig = form.sig?.data?._id === prePopulatedData?.sig?._id && medications;
  const qty = form.qty?.data?.qty === prePopulatedData?.qty?.qty && sig;
  const icd10Codes = !!form.icd10Codes?.length && medications && qty;
  const pharmacy = !!form.pharmacy?.data?._id && qty;
  return { medications, sig, qty, icd10Codes, pharmacy };
};
