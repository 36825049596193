import { MembershipPlanProps, PricePointProps } from 'components/crossSell/PlanType/planType.types';
import { PatientProps } from 'store/patients/patients.types';

import { ChoosePlanTab } from './ChoosePlan/choosePlan.types';

export interface ChangePlanProps {
  patientInfo?: PatientProps;
  currentPlan?: MembershipPlanProps;
  activePP?: PricePointProps;
}

export enum ChangePlanSteps {
  ChoosePlan = 'Choose plan',
  ConfirmPayment = 'Confirm payment',
}

export interface ChangePlanFormData {
  confirmation: boolean;
  activePeriodsTab: ChoosePlanTab;
  startTime?: string;
  displayName?: string;
  randomProviderName?: string;
  couponCode?: { label: string; value: string };
}
