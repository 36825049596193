import { ROLE_OPTIONS } from 'constants/staff';
import { STATUS_OPTIONS } from 'constants/status';

import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import InputField from 'components/forms/controlled/InputField';
import { validation } from 'utils/helpers';

import { RoleInformationProps } from './roleInformation.types';

export const RoleInformation: React.FC<RoleInformationProps> = ({ control, formState }) => {
  return (
    <>
      <div className="mb-2 flex">
        <ControlledSelect
          dataTestId="role_template_field"
          control={control}
          labelDirection="row"
          options={ROLE_OPTIONS}
          placeholder="Select role"
          rules={validation('Role')}
          label="Role Template"
          name="role"
          className="md:mr-20 lg:mr-10 lg:w-3/4 xl:mr-20 xl:w-1/2"
        />
      </div>
      <div className="flex flex-wrap gap-x-20 border-y border-gray-200">
        <div className="mb-2 mt-5 flex flex-col gap-4">
          <InputField
            name="name"
            placeholder="Enter name"
            label="Name"
            control={control}
            labelDirection="row"
            type="text"
            errors={formState.errors.name}
            rules={validation('Name')}
          />
          <InputField
            name="color"
            placeholder="Enter color"
            label="Color"
            control={control}
            labelDirection="row"
            type="text"
            errors={formState.errors.color}
            rules={validation('Color')}
          />
        </div>
        <div className="mb-2 mt-5 flex flex-col gap-4">
          <InputField
            name="shortName"
            placeholder="Enter shortname"
            label="Shortname"
            control={control}
            labelDirection="row"
            type="text"
            errors={formState.errors.shortName}
            rules={validation('Shortname')}
          />
          <ControlledSelect
            control={control}
            labelDirection="row"
            options={STATUS_OPTIONS}
            placeholder="Select status"
            rules={validation('Status')}
            label="Status"
            name="status"
          />
        </div>
      </div>
    </>
  );
};
