import { MouseEvent } from 'react';

import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import Loader from 'components/common/Loader';
import { ReviewStatuses } from 'store/reviews/reviews.types';
import { useModerateReviewsMutation } from 'store/reviews/reviewsSlice';

const ActionsCell: React.FC<{ status: string; id: string }> = ({ status, id }) => {
  const [moderateReviews, { isLoading }] = useModerateReviewsMutation();
  const isHidden = status === ReviewStatuses.Hidden;
  const isPublished = status === ReviewStatuses.Published;

  const handlePublisReview = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (!id) return;
    const body = {
      reviewIds: [id],
      status: isPublished ? ReviewStatuses.New : ReviewStatuses.Published,
    };
    moderateReviews(body);
  };

  const handleHideReview = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (!id) return;
    const body = {
      reviewIds: [id],
      status: isHidden ? ReviewStatuses.New : ReviewStatuses.Hidden,
    };
    moderateReviews(body);
  };

  const hiddenStatusClassNames = classNames('h-4 w-4 ', isHidden ? 'text-red-700' : 'text-gray-700', {
    'hover:text-primary-500': !isHidden,
  });
  const iconTooltipGroupClassName = 'group relative h-5';
  const tooltipWrapperClassName = 'absolute hidden whitespace-nowrap top-[-56px] right-[-40px] z-50 group-hover:flex';

  return (
    <>
      <Loader isVisible={isLoading} />
      <div className="flex justify-center gap-2">
        <div className={iconTooltipGroupClassName}>
          {!isPublished && (
            <div className={tooltipWrapperClassName}>
              <Common.Tooltip arrow="bottom">Publish review</Common.Tooltip>
            </div>
          )}
          <button
            data-testid={`${isPublished ? 'active' : 'inactive'}_publish_review_btn`}
            onClick={handlePublisReview}
            disabled={isLoading}
          >
            <Common.Icon
              name={isPublished ? 'thumb-up-filled' : 'thumb-up'}
              className="size-4 hover:text-primary-500"
            />
          </button>
        </div>
        <div className={iconTooltipGroupClassName}>
          {!isHidden && (
            <div className={tooltipWrapperClassName}>
              <Common.Tooltip arrow="bottom">{isHidden ? 'Hidden' : 'Hide review'}</Common.Tooltip>
            </div>
          )}
          <button
            data-testid={`${isHidden ? 'active' : 'inactive'}_hide_review_btn`}
            onClick={handleHideReview}
            disabled={isLoading}
          >
            <Common.Icon name="not-available" className={hiddenStatusClassNames} />
          </button>
        </div>
      </div>
    </>
  );
};

export default ActionsCell;
