import { UpdateEmailMutationParamsProps } from './myProfileSlice.types';
import { apiSlice } from '../api/apiSlice';

const myProfileApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    changeEmail: builder.mutation<UpdateEmailMutationParamsProps, { email: string }>({
      query: ({ email }) => ({
        url: '/my-profile/change-email',
        method: 'POST',
        body: { email },
      }),
    }),
    confirmEmail: builder.mutation<UpdateEmailMutationParamsProps, { code: string }>({
      query: ({ code }) => ({
        url: '/my-profile/confirm-email',
        method: 'POST',
        body: { code },
      }),
    }),
    updateTimezone: builder.mutation<{ message: string }, { timezone: string }>({
      query: ({ timezone }) => ({
        url: '/my-profile/timezone',
        method: 'PATCH',
        body: { timezone },
      }),
      invalidatesTags: ['Appointment'],
    }),
    setAiAttributes: builder.mutation<{ message: string }, { messageTaskAutoRoutingRead: boolean }>({
      query: ({ messageTaskAutoRoutingRead }) => ({
        url: '/my-profile/set-ai-attributes',
        method: 'POST',
        body: { messageTaskAutoRoutingRead },
      }),
    }),
  }),
});

export const {
  useChangeEmailMutation,
  useConfirmEmailMutation,
  useUpdateTimezoneMutation,
  useSetAiAttributesMutation,
} = myProfileApiSlice;
