import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { AppointmentNotificationType, NotificationType } from 'enums/notifications';
import { DOMNode, Element, Text } from 'html-react-parser';
import { Link } from 'react-router-dom';

import { NotificationTextProps } from './notificationItem.types';

const replaceWithLink = (str: string, reference: string) => {
  const parts = str.split('[viewTask]');
  const url = window.location.pathname + window.location.search + `&taskModalOpenID=${reference}`;

  return (
    <>
      <Link to={url} className="text-sm text-gray-700 underline">
        View task
      </Link>{' '}
      {parts[1]}
    </>
  );
};

const getNotificationText = ({
  notificationType,
  actionType,
  notificationContent,
  notificationAdditionalInfo,
  buttons,
}: NotificationTextProps) => {
  const hasViewTaskButton =
    buttons?.some((button) => button.variable === 'viewTask') &&
    notificationType === 'Task' &&
    notificationContent.includes('[viewTask]');

  if (!notificationAdditionalInfo && hasViewTaskButton && buttons?.[0].referenceId) {
    return (
      <span data-testid="notification_title" className="text-sm text-gray-700">
        <b>{actionType}</b> {replaceWithLink(notificationContent, buttons?.[0].referenceId)}
      </span>
    );
  }

  if (!notificationAdditionalInfo) {
    return (
      <span data-testid="notification_title" className="text-sm text-gray-700">
        <b>{actionType}</b> {notificationContent}
      </span>
    );
  }

  if (notificationType === NotificationType.Task) {
    return actionType === 'New message' ? (
      <span data-testid="notification_title" className="text-sm text-gray-700">
        <b>{notificationAdditionalInfo?.staffNickName || notificationAdditionalInfo?.staffName}</b> replied to your{' '}
        <b>{notificationAdditionalInfo?.taskCategory}</b> for{' '}
        <b>{notificationAdditionalInfo?.patientNickName || notificationAdditionalInfo?.patientName}</b>{' '}
      </span>
    ) : (
      <span data-testid="notification_title" className="text-sm text-gray-700">
        <b>{notificationAdditionalInfo?.staffNickName || notificationAdditionalInfo?.staffName}</b> completed{' '}
        <b>{notificationAdditionalInfo?.taskCategory}</b> for{' '}
        <b>{notificationAdditionalInfo?.patientNickName || notificationAdditionalInfo?.patientName}</b>{' '}
      </span>
    );
  }
  if (notificationAdditionalInfo?.appointmentNotificationType) {
    if (
      notificationAdditionalInfo?.appointmentNotificationType.toLowerCase() === AppointmentNotificationType.CreatedAt
    ) {
      return (
        <span data-testid="notification_title" className="text-sm text-gray-700">
          <b>{notificationAdditionalInfo?.taskCategory}</b> with <b>{notificationAdditionalInfo?.patientName}</b>.{' '}
          {notificationContent}
        </span>
      );
    }
    if (notificationAdditionalInfo?.appointmentNotificationType.toLowerCase() === AppointmentNotificationType.After) {
      return (
        <span data-testid="notification_title" className="text-sm text-gray-700">
          <b>{notificationAdditionalInfo?.taskCategory}</b> for{' '}
          <b>{notificationAdditionalInfo?.patientNickName || notificationAdditionalInfo?.patientName}</b>{' '}
          <span className="font-semibold uppercase text-red-500">overdue</span>
        </span>
      );
    }
    if (notificationAdditionalInfo?.appointmentNotificationType.toLowerCase() === AppointmentNotificationType.Before) {
      return (
        <span data-testid="notification_title" className="text-sm text-gray-700">
          <b>{notificationAdditionalInfo?.appointmentType}</b> with{' '}
          <b>{notificationAdditionalInfo?.patientNickName || notificationAdditionalInfo?.patientName}</b> begins at{' '}
          <b>{dayjs(notificationAdditionalInfo?.correctDueDateFormat).format(DateFormat.h_mm_a_z)}</b>.
        </span>
      );
    }
  }

  return (
    <span data-testid="notification_title" className="text-sm text-gray-700">
      <b>{actionType}</b> {notificationContent}
    </span>
  );
};

const parseNotificationMessage = (domNode: DOMNode) => {
  const testId = 'last_channel_msg';

  // Check whether DOMnode is an element or regular text
  if (domNode instanceof Element) {
    const isLineBreak = domNode.children.some((child) => child instanceof Element && child.tagName === 'br');

    if (domNode.tagName === 'p') {
      domNode.attribs['data-test'] = testId;
      domNode.attribs.className = `rounded-b-2xl rounded-r-2xl bg-gray-800/5 px-4  text-sm w-fit ${
        isLineBreak ? 'line-clamp-1 py-1' : 'truncate py-2'
      }`;
    } else if (domNode.tagName === 'a') {
      domNode.attribs.className = 'text-sm underline text-primary';
    }
    // Check whether text has any line breaks
    // Apply overflow styles for long text
    return domNode;
  } else if (/\n/g.test(domNode.data)) {
    return <br />;
  } else if (domNode instanceof Text && domNode.parent === null && domNode.data.length) {
    return (
      <span className="truncate rounded-r-2xl rounded-bl-2xl bg-gray-800/5 px-4 py-2 text-sm" data-test={testId}>
        {domNode.data}
      </span>
    );
  }
};

export { getNotificationText, parseNotificationMessage };
