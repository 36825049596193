import React, { ChangeEvent, useState } from 'react';

import { Common } from '@thecvlb/design-system';
import { useController } from 'react-hook-form';
import { PASSWORD_REGEXP } from 'utils/regExp';

import { PasswordInputProps } from './PasswordInput.types';

const PasswordInput: React.FC<PasswordInputProps> = React.forwardRef<HTMLInputElement, PasswordInputProps>(
  (
    {
      name,
      labelTitle,
      placeholder,
      wrapperClasses,
      labelClasses = 'w-52 text-2xl mr-4',
      control,
      isRequired = true,
      value = '',
      onChange,
    },
    ref,
  ) => {
    const {
      field,
      fieldState: { error },
    } = useController({
      name,
      control,
      rules: {
        required: {
          value: isRequired,
          message: 'Field is required',
        },
        pattern: {
          value: PASSWORD_REGEXP,
          message: 'Please enter a valid data',
        },
      },
    });
    const [isFocus, setIsFocus] = useState(false);
    const [val, setVal] = useState(value);

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
      field.onChange(event);
      setVal(event.target.value);
      onChange && onChange(event);
    };

    const handleToggleFocus = () => setIsFocus(!isFocus);

    return (
      <div className={wrapperClasses}>
        <label htmlFor={name} className="flex justify-between">
          {labelTitle && <span className={labelClasses}>{labelTitle}</span>}
          <div className="relative w-full">
            <Common.Input
              id={name}
              {...field}
              size="sm"
              placeholder={placeholder}
              type="password"
              onChange={handleOnChange}
              onFocus={handleToggleFocus}
              onBlur={handleToggleFocus}
              value={val}
              errors={error}
              helper={error?.message}
              ref={ref}
            />
          </div>
        </label>
      </div>
    );
  },
);

export default PasswordInput;
