import { Tags } from 'enums/tagTypes';

import { DisplayNameProps, PatientProps, Tag } from './patients.types';

export const getDisplayName = (data?: DisplayNameProps, isTasksList?: boolean, isPatientsList?: boolean): string => {
  let fullName = '';
  switch (true) {
    case isTasksList:
      fullName = `${data?.name}`;
      break;
    case isPatientsList:
      fullName = `${data?.patient}`;
      break;
    default:
      fullName = `${data?.firstName} ${data?.lastName}`;
  }

  return data?.nickName ? `${data.nickName} (${fullName})` : fullName;
};

export const transformPatientsResponse = (patients: PatientProps[]) =>
  patients?.map((patient) => ({
    ...patient,
    patient: getDisplayName(patient, false, true),
  }));

export const isStringArray = (tags: Tag[] | string[]): tags is string[] => {
  return typeof (tags as string[])[0] === 'string';
};

export const isTagArray = (tags: Tag[] | string[]): tags is Tag[] => {
  return (tags as Tag[])[0]?._id !== undefined;
};

export const checkIsPatientWMorPrevWM = (tags?: Tag[] | string[]) => {
  if (!tags) return false;

  if (isStringArray(tags)) {
    return tags.includes(Tags.WeightLossPlan) || tags.includes(Tags.WeightLossPrevPlan);
  }

  if (isTagArray(tags)) {
    return (
      tags.map((tag) => tag._id).includes(Tags.WeightLossPlan) ||
      tags.map((tag) => tag._id).includes(Tags.WeightLossPrevPlan)
    );
  }
};
