import { FRONTEND_DATE_FORMAT } from 'constants/dateFormat';

import { useEffect, useState } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';
import DatePickerInput from 'components/common/DatePickerInput';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';

import { TitrationItemProps } from './titrationItem.types';

const TitrationItem: React.FC<TitrationItemProps> = ({ titration, onChange, label }) => {
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [inputValue, setInputValue] = useState<string>('');
  const error = !Number(titration.qty) || titration.qty <= 0;

  const qtyUnitsClassnames = classNames(
    'absolute bottom-2 text-sm font-medium text-gray-500',
    error ? 'right-8' : 'right-3',
  );

  const handleChangeAdjustedDate = (newDate: string) => {
    onChange(
      dayjs(newDate, FRONTEND_DATE_FORMAT).format(DateFormat.YYYY_MM_DDTHH_mm_ss_ss_Z),
      titration._id || '',
      'adjustedAt',
    );
  };

  const handleChangeQTY = (value: string) => {
    if ((value && value.match(/^[0-9]{1,}([,.]([0-9]{1,})?)?$/)) || !value) {
      onChange(value?.replace(',', '.'), titration._id || '', 'qty');
    }
  };

  useEffect(() => {
    if (titration.adjustedAt) {
      setSelectedDate(dayjs(titration.adjustedAt).toDate());
      setInputValue(dayjs(titration.adjustedAt).format(DateFormat.MM_DD_YYYY));
    }
  }, [titration]);

  return (
    <div className="flex gap-3">
      <div className="relative">
        <Common.Input
          label={label}
          type="text"
          labelDirection={'col'}
          value={titration.qty}
          placeholder={'--'}
          onChange={(event) => handleChangeQTY(event.currentTarget.value)}
          size={'sm'}
          {...(error && { errors: { qty: 'Enter a valid data' } })}
        />
        <span className={qtyUnitsClassnames}>{titration.units}</span>
      </div>
      <div className="flex flex-col gap-1">
        <span className="min-w-[120px] text-sm font-semibold text-gray-700">Adjusted on</span>
        <DatePickerInput
          placeholder="Select renews"
          size="sm"
          inputValue={inputValue}
          setInputValue={handleChangeAdjustedDate}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          startAllowedDate={dayjs().subtract(20, 'year').toDate()}
          lastAllowedDate={dayjs().add(100, 'year').toDate()}
          captionLayout="dropdown"
          wrapperClasses="w-full relative"
          labelClassNames="text-gray-500"
        />
      </div>
    </div>
  );
};

export default TitrationItem;
