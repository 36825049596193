import { ParsedQuery } from 'query-string';
import { useGetProspectsQuery } from 'store/patients/patientsSlice';

/**
 * @description
 * select prospect user by id from query cache
 */
export const useSelectProspectPatientDetails = ({
  queryParams,
  patientId,
}: {
  queryParams: ParsedQuery<string>;
  patientId: string;
}) => {
  const { prospects } = useGetProspectsQuery(
    { params: queryParams },
    {
      selectFromResult: ({ data }) => ({
        prospects: data?.prospects || [],
      }),
    },
  );

  const result = prospects.find((prospect) => prospect._id === patientId);

  return { result };
};
