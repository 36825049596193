import { useFilterAdminPageLinks } from 'hooks/filters/featureToggles/useFilterAdminPageLinks';
import { useGetTriggersQuery } from 'store/triggers/triggersSlice';

import { renderCard } from './admin.settings';

const Admin: React.FC = () => {
  useGetTriggersQuery({ params: { status: 'active' } });
  const adminLinks = useFilterAdminPageLinks();

  return (
    <div data-testid="admin_section">
      {Object.keys(adminLinks).map((item) => (
        <div className="mb-10" key={item}>
          <h2 data-testid="heading" className="mb-5 font-bold capitalize text-black">
            {item}
          </h2>
          <div className="flex flex-wrap gap-x-6 gap-y-1">
            {adminLinks[item].filter((link) => link.tag === item).map(renderCard)}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Admin;
