import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';

import { WeekSwitchProps } from './weekSwitch.types';

const WeekSwitch: React.FC<WeekSwitchProps> = ({ week, onChange }) => {
  const dateWeek = dayjs().week(week);
  const currentWeek = dayjs().week();
  const firstDay = dateWeek.startOf('week').format(DateFormat.MMM_D);
  const lastDay = dateWeek.endOf('week').format(DateFormat.MMM_D);
  const year = dateWeek.format(DateFormat.YYYY);

  const isDisabledTodayButton = week === currentWeek;

  const nextWeek = () => {
    onChange(week + 1);
  };
  const prevWeek = () => {
    onChange(week - 1);
  };

  const setToday = () => {
    onChange(currentWeek);
  };

  const iconClassName = 'text-gray-700 size-4';
  const timeClassName = 'text-xl font-bold text-gray-700';
  const buttonClassName = 'h-8 w-12 py-1 px-4 rounded-lg bg-white border border-gray-200';
  const todayButtonClassName = classNames(
    'h-8 py-1 px-4 rounded-lg border border-gray-200 text-gray-700 text-sm font-bold',
    { 'bg-gray-200 opacity-50': isDisabledTodayButton, 'bg-white': !isDisabledTodayButton },
  );

  return (
    <div className="flex items-center gap-4">
      <button className={buttonClassName} onClick={prevWeek} data-testid="prev-week">
        <Common.Icon name="arrow-left" className={iconClassName} />
      </button>
      <p data-testid="date_range" className={timeClassName}>
        {firstDay} - {lastDay} • {year}
      </p>
      <button className={buttonClassName} onClick={nextWeek} data-testid="next-week">
        <Common.Icon name="arrow-right" className={iconClassName} />
      </button>
      <button className={todayButtonClassName} onClick={setToday} data-testid="today" disabled={isDisabledTodayButton}>
        Today
      </button>
    </div>
  );
};

export default WeekSwitch;
