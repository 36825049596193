import { Common } from '@thecvlb/design-system';
import isEmpty from 'lodash/isEmpty';
import { EventMultiSelectValue, EventTypes } from 'models/event.types';
import { components, MultiValueRemoveProps, StylesConfig } from 'react-select';
import { ShiftTypesResponseProps } from 'store/calendar/calendar.types';
import tw from 'twin.macro';

import { TimeSlotTypeMultiselectProps } from './timeSlotTypeMultiSelect.types';

export const shiftTypesColors: { [key: string]: { bgColor: string; textColor: string } } = {
  appointments: { bgColor: '#FFF4E0', textColor: '#A16900' },
  specialized: { bgColor: '#F8E5FF', textColor: '#8111AB' },
  floater: { bgColor: '#E0F4F3', textColor: '#088C88' },
};

export const timeOffTypes = [
  { value: EventTypes.TIME_OFF, label: 'Day off', bgColor: '#F4F7F8', textColor: '#424647' },
  { value: EventTypes.BREAK, label: 'Break', bgColor: '#F4F7F8', textColor: '#424647' },
];

export const getOptions = (eventType: TimeSlotTypeMultiselectProps['type'], shiftTypes?: ShiftTypesResponseProps[]) => {
  if (eventType === EventTypes.TIME_OFF || eventType === EventTypes.BREAK) {
    return timeOffTypes;
  } else if (!isEmpty(shiftTypes) && Array.isArray(shiftTypes)) {
    return shiftTypes.map((item) => ({
      value: item._id,
      label: item.title,
      bgColor: shiftTypesColors[item.name]?.bgColor,
      textColor: shiftTypesColors[item.name]?.textColor,
      isFiltersAllowed: item.filtersAllowed,
      isMultiSelectAllowed: item.multiSelectAllowed,
    }));
  }
  return [];
};

const MultiValueRemove = (props: MultiValueRemoveProps<EventMultiSelectValue>) => {
  return (
    <components.MultiValueRemove {...props}>
      <Common.Icon name="close" className="size-3 cursor-pointer" />
    </components.MultiValueRemove>
  );
};

const colorStyles: StylesConfig<EventMultiSelectValue> = {
  control: (styles) => ({ ...styles, border: 'none', borderRadius: '6px' }),
  container: (styles) => ({ ...styles, border: '1px solid #C4CACC', borderRadius: '6px' }),
  input: (styles) => ({
    ...styles,
    fontSize: '12px',
    fontFamily: 'Greycliff CF',
    lineHeight: '140%',
  }),
  valueContainer: (styles) => ({ ...styles, '&:focus': tw`ring-0` }),
  indicatorSeparator: () => ({ display: 'none' }),
  option: (styles) => ({ ...styles, fontSize: '12px' }),
  placeholder: (styles) => ({ ...styles, fontSize: '12px' }),
  multiValue: (styles, { data }) => ({
    ...styles,
    display: 'flex',
    flexDirection: 'row-reverse',
    backgroundColor: data.bgColor,
    borderRadius: '4px',
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.textColor,
    fontSize: '12px',
    padding: '2px 8px 2px',
    paddingLeft: '4px',
    fontFamily: 'Greycliff CF',
  }),
  multiValueRemove: (styles, { data }) => ({ ...styles, color: data.textColor, paddingLeft: '8px', paddingRight: '0' }),
  clearIndicator: (styles) => ({ ...styles, display: 'none' }),
};

export { MultiValueRemove, colorStyles };
