import React, { useEffect, useState } from 'react';

import momentTimezonePlugin from '@fullcalendar/luxon3';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Common } from '@thecvlb/design-system';
import CalendarHint from 'components/appointments/Calendar/CalendarHint';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';

import { DayHeaderContent, eventOrder, MoreLinkContent } from './calendar.settings';
import { Wrapper } from './calendar.styles';
import { CalendarProps, EventProps, GlobalCalendarEvent } from './calendar.types';
import GlobalEventCell from './GlobalEventCell';
import NowIndicator from './NowIndicator';
import Tooltip from './Tooltip/Tooltip';

const Calendar: React.FC<CalendarProps> = ({ onUpdate, events, global, timezone }) => {
  const calendarWrapperRef = React.createRef<HTMLDivElement>();
  const [constraintsElement, setConstraintsElement] = useState<HTMLDivElement | null>(null);

  const CustomEvent = (eventInfo: EventProps) => <Tooltip events={events} eventInfo={eventInfo} timezone={timezone} />;
  const CustomGlobalEvent = (eventInfo: GlobalCalendarEvent) => (
    <GlobalEventCell events={events} eventInfo={eventInfo} constraintsElement={constraintsElement} />
  );

  const eventContent = global ? CustomGlobalEvent : CustomEvent;

  useEffect(() => {
    if (calendarWrapperRef.current) setConstraintsElement(calendarWrapperRef.current);
  }, [calendarWrapperRef]);

  return (
    <div data-testid="calendar_container" className="relative">
      <div className="absolute right-6 top-2">
        <div
          data-testid="key_icon"
          className="group relative flex cursor-pointer items-center justify-start hover:text-primary-500"
        >
          <Common.Icon name="info" className="mr-1 size-5 cursor-pointer text-gray-500 hover:text-primary-500" />
          <span className="text-base font-bold text-black">Key</span>
          <CalendarHint />
        </div>
      </div>
      <Wrapper ref={calendarWrapperRef}>
        <FullCalendar
          key={timezone}
          eventMaxStack={1}
          titleFormat={({ end, start }) => {
            let endDate;
            if (end) {
              endDate = end?.month + 1;
            }
            return (
              dayjs(`${start.year}-${start.month + 1}-${start.day}`).format(DateFormat.MMM_DD) +
              ' - ' +
              dayjs(`${end?.year}-${endDate}-${end?.day}`).format(DateFormat.MMM_DD__YYYY)
            );
          }}
          headerToolbar={{
            left: 'prev title next today',
            center: '',
            right: '',
          }}
          slotDuration={{ hour: 1 }}
          eventContent={eventContent}
          datesSet={(info) => onUpdate(info.startStr, info.endStr)}
          dayHeaderFormat={{ weekday: 'short', day: 'numeric', omitCommas: false }}
          dayHeaderContent={DayHeaderContent}
          contentHeight={600}
          nowIndicator
          nowIndicatorContent={() => <NowIndicator timezone={timezone} />}
          timeZone={timezone}
          eventMinHeight={34}
          allDaySlot={false}
          moreLinkContent={MoreLinkContent}
          plugins={[timeGridPlugin, momentTimezonePlugin]}
          events={events}
          dayPopoverFormat={{
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          }}
          {...(!global && { eventOrder })}
        />
      </Wrapper>
    </div>
  );
};

export default Calendar;
