import { useMemo } from 'react';

import queryString from 'query-string';
import { useSearchParams } from 'react-router-dom';

const useFilteredParams = () => {
  const [searchParams] = useSearchParams();
  const allowedParams = useMemo(() => new Set(['limit', 'pageNo', 'sortField', 'sortOrder']), []);

  return useMemo(() => {
    const params = queryString.parse(searchParams.toString());

    // Filter the parameters using the Set of allowed parameter names.
    return Object.fromEntries(Object.entries(params).filter(([key]) => allowedParams.has(key)));
  }, [searchParams, allowedParams]);
};

export default useFilteredParams;
