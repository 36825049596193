import { useMemo, useState } from 'react';

import * as Popover from '@radix-ui/react-popover';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import groupBy from 'lodash/groupBy';

import { GlobalTooltipProps } from './globalEventCell.types';
import { GlobalCalendarEvent } from '../calendar.types';
import GlobalEventButton from '../GlobalEventButton';
import GlobalEventTooltip from '../GlobalEventTooltip';

const GlobalEventCell: React.FC<GlobalTooltipProps> = ({ eventInfo, events, constraintsElement }) => {
  const [show, setShow] = useState(false);
  const currentEvent = events.find((event) => dayjs(event.start).isSame(dayjs(eventInfo.event?.start)));
  const globalEvent = currentEvent as GlobalCalendarEvent;
  const groupedAppointments = groupBy(globalEvent?.appointments, 'staffName');
  const sortedAppointments = [];

  for (const doctor in groupedAppointments) {
    const doctorAppointments = groupedAppointments[doctor];
    sortedAppointments.push({
      doctor,
      list: doctorAppointments.map((appointment) => {
        return {
          time: dayjs(appointment.appointmentTime?.startTime).format(DateFormat.h_mm_a),
          patient: appointment.patientData?.[0]?.name,
          doctorId: appointment.doctorId,
          staffName: appointment.staffName,
          taskId:
            appointment.appointmentStatus === 'completed'
              ? appointment?.postConsultTasks?.[1] || appointment?.postConsultTasks?.[0]
              : appointment.taskId,
        };
      }),
    });
  }

  const collisionBoundary = useMemo(() => constraintsElement || [], [constraintsElement]);

  return (
    <Popover.Root modal open={show} onOpenChange={setShow}>
      <GlobalEventButton globalEvent={globalEvent} />
      <GlobalEventTooltip
        setShow={setShow}
        sortedAppointments={sortedAppointments}
        collisionBoundary={collisionBoundary}
      />
    </Popover.Root>
  );
};

export default GlobalEventCell;
