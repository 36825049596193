import { Suspense, useCallback, useState } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import Alert from 'components/common/Alert';
import EmptyChatPlaceholder from 'components/common/Chat/EmptyChatPlaceholder';
import { skeletonChatList } from 'components/common/Chat/Message/message.settings';
import Loader from 'components/common/Loader';
import RetryBlock from 'components/common/RetryBlock';
import { MessageType, Status } from 'enums/messages';
import { useAlert } from 'hooks/common/messages/useAlert';
import { useRetryLoadMessages } from 'hooks/common/messages/useRetryLoadMessages';
import { useClientMessages } from 'hooks/common/useClientMessages';
import { useAppSelector } from 'hooks/redux';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import { useNetworkState } from 'react-use';
import { selectChannels } from 'store/channels/channelsSlice';
import { selectChat } from 'store/chat/chatSlice';
import { selectPatient } from 'store/patients/patientsSlice';
import { selectTask } from 'store/tasks/tasksSlice';
import { lazyWithRetries } from 'utils/common/lazy';

import MessagesList from './MessagesList';

const loadImageGallery = () => import('components/modals/TaskPopover/TaskDetails/ImageGallery');
const ImageGallery = lazyWithRetries(loadImageGallery);

const selectMessagesState = createSelector(
  [selectTask, selectChat, selectChannels, selectPatient],
  (task, chat, channels, patient) => ({
    personalInfo: task.taskDetails?.personalInfo,
    totalMessagesCount: chat.totalCount,
    channelsStatus: channels.loadingChannelsStatus,
    messagesStatus: chat.loadingMessagesStatus,
    images: chat.images,
    channels: channels.data,
    currentChannel: channels.currentChannel,
    patientId: patient.patientInfo?._id || task.taskDetails.patientId,
  }),
);

const Messages = ({
  type,
  isTaskModal,
}: {
  type: Exclude<MessageType, MessageType.StaffNote | MessageType.SMS>;
  isTaskModal?: boolean;
}) => {
  const useMessages = useClientMessages({ type });
  const { online } = useNetworkState();
  const { channelDetails } = useMessages();
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const {
    personalInfo,
    totalMessagesCount,
    images,
    messagesStatus,
    channelsStatus,
    channels,
    currentChannel,
    patientId,
  } = useAppSelector(selectMessagesState);
  const [handleRetry] = useRetryLoadMessages({ type, patientId: personalInfo._id, channelId: channelDetails?._id });

  const isLoadingChannels = channelsStatus === Status.Pending;
  const isLoadingMessages = messagesStatus === Status.Pending || (!!currentChannel && messagesStatus === Status.Idle);
  const isRejected = messagesStatus === Status.Rejected || channelsStatus === Status.Rejected;

  const isAvailableChannel = !isLoadingChannels && channelsStatus === Status.Fulfilled && !isEmpty(currentChannel);
  const isAvailableMessages = !isLoadingMessages && messagesStatus === Status.Fulfilled && isNumber(totalMessagesCount);

  const isPatientChannel = currentChannel?.patientId === patientId;

  const noMessages = isAvailableMessages && totalMessagesCount === 0;
  const noChannels = channelsStatus === Status.Fulfilled && channels.ids.length === 0;

  const showEmptyChatPlaceholder = online && ((!noChannels && noMessages) || noChannels);
  const showSkeletonChatList = online && !isLoadingChannels && isLoadingMessages;

  const { handleClose, alertMessage, showSupportAlert, showMedicalAlert, showAddChannelAlert } = useAlert({
    type,
    isAvailableChannel: Boolean(channelDetails),
    isLoading: isLoadingMessages,
  });

  const showCareMessageAlert = online && !isRejected && showMedicalAlert;
  const showSupportMessageAlert = online && !isRejected && showSupportAlert;
  const showAddSupportChannelAlert =
    online && showAddChannelAlert && !isRejected && (!isLoadingChannels || !isLoadingChannels) && noChannels;

  const handleImageClick = useCallback(
    (filePath: string) => {
      const index = images.indexOf(filePath);
      setPhotoIndex(index);
      setIsOpen(true);
    },
    [images],
  );

  /**
   * This function is responsible for rendering the messages based on the current state of the application.
   * It checks the online status, whether there was an error during the fetch operation, and the availability of the channel.
   *
   * @returns {JSX.Element | null} Returns a RetryBlock component if the application is offline or if there was an error during the fetch operation.
   * If the channel is available and it's a patient channel, it returns the MessagesList component.
   * If the channel is available but it's not a patient channel, it returns a RetryBlock component.
   * If the channel is not available, it returns null.
   */
  const renderMessages = () => {
    if (!online || isRejected) {
      // Render retry block if offline or rejected
      return (
        <div className="absolute inset-x-0 top-1/3">
          <RetryBlock handleClick={handleRetry} />
        </div>
      );
    }

    if (isAvailableChannel) {
      if (isPatientChannel) {
        // Render message list if channel is available
        return <MessagesList handleImageClick={handleImageClick} type={type} />;
      } else {
        <div className="absolute inset-x-0 top-1/3">
          <RetryBlock handleClick={handleRetry} />
        </div>;
      }
    }
    // Render nothing if channel is unavailable
    return null;
  };

  return (
    <>
      {showSkeletonChatList && <div className="my-3 w-full">{skeletonChatList()}</div>}

      {showEmptyChatPlaceholder && <EmptyChatPlaceholder isChannelAvailable={isAvailableChannel} />}

      <div className={`${isTaskModal ? '' : 'mb-6'}`}>
        {renderMessages()}

        {showAddSupportChannelAlert ? (
          <Alert type="warning" handleClose={handleClose} containerClasses="m-2">
            <p data-testid="alert_msg" className="text-base">
              Press the <strong>Add channel</strong> button to create a Support channel.
            </p>
          </Alert>
        ) : null}

        {showSupportMessageAlert ? (
          <Alert type="info" handleClose={handleClose}>
            <p data-testid="alert_msg" className="text-base">
              <strong>Patient message:</strong> {personalInfo && alertMessage}
            </p>
          </Alert>
        ) : null}

        {showCareMessageAlert ? (
          <Alert type="info" handleClose={handleClose}>
            <p data-testid="alert_msg" className="text-base">
              <strong>Patient message:</strong> {personalInfo && alertMessage}
            </p>
          </Alert>
        ) : null}
      </div>

      <Suspense fallback={<Loader isVisible />}>
        {images?.length ? (
          <ImageGallery
            images={images}
            photoIndex={photoIndex}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            setPhotoIndex={setPhotoIndex}
          />
        ) : null}
      </Suspense>
    </>
  );
};

export default Messages;
