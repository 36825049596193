export const WEBHOOK_TYPE_OPTIONS = [
  { value: 'Campaign', label: 'Campaign' },
  { value: 'Canvas', label: 'Canvas' },
];

export const EVENT_TYPES_WITHOUT_TIMING = [
  { value: 'Appointment Confirmation', label: 'Appointment Confirmation' },
  { value: 'Appointment Reschedule', label: 'Appointment Reschedule' },
  { value: 'Appointment Cancelation', label: 'Appointment Cancelation' },
];

export const EVENT_TYPES_OPTIONS = [
  ...EVENT_TYPES_WITHOUT_TIMING,
  { value: 'Appointment Missed', label: 'Appointment Missed' },
  { value: 'Appointment Reminder', label: 'Appointment Reminder' },
  { value: 'Message: Responded', label: 'Message: Responded' },
  { value: 'Front Desk: Responded', label: 'Front Desk: Responded' },
];
