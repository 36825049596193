import { Common } from '@thecvlb/design-system/lib/src';
import { Controller, FieldValues, Path, PathValue } from 'react-hook-form';

import { notifications } from './notifications.settings';
import { AppointmentNotifyProps } from './notifications.types';

const Notifications = <TFieldValues extends FieldValues>({ control }: AppointmentNotifyProps<TFieldValues>) => {
  const renderNotifications = () => {
    return notifications.map((notification) => (
      <div className="flex justify-between py-2.5" key={notification.name}>
        <p className="text-sm text-gray-700">{notification.label}</p>
        <div className="flex gap-12">
          <Controller
            control={control}
            defaultValue={false as PathValue<TFieldValues, Path<TFieldValues>>}
            name={`notificationPreferences.emailNotification.${notification.name}` as Path<TFieldValues>}
            render={({ field: { value, name, onChange } }) => (
              <Common.Checkbox name={name} checked={value} onChange={onChange} size="sm" />
            )}
          />
          <Controller
            control={control}
            defaultValue={false as PathValue<TFieldValues, Path<TFieldValues>>}
            name={`notificationPreferences.textNotification.${notification.name}` as Path<TFieldValues>}
            render={({ field: { value, name, onChange } }) => (
              <Common.Checkbox name={name} checked={value} onChange={onChange} size="md" />
            )}
          />
        </div>
      </div>
    ));
  };

  return (
    <div className="col-span-2">
      <div className="flex w-full flex-col">
        <div className="flex items-center justify-between">
          <h2 className="font-bold text-gray-700">Notifications</h2>

          <div className="flex flex-col items-center">
            <div className="mb-4 flex gap-8">
              <h3 className="text-sm font-bold text-gray-700">Email</h3>
              <h3 className="text-sm font-bold text-gray-700">SMS</h3>
            </div>
          </div>
        </div>
        <div className="divide-y divide-gray-100">{renderNotifications()}</div>
      </div>

      <div className="mb-4 mt-3 w-full rounded-lg bg-secondary-50 py-3 pl-2.5">
        <Controller
          control={control}
          defaultValue={false as PathValue<TFieldValues, Path<TFieldValues>>}
          name={'notificationPreferences.notifyWhenOnline' as Path<TFieldValues>}
          render={({ field: { value, name, onChange } }) => (
            <Common.Checkbox name={name} checked={value} onChange={onChange} size="md">
              <span className="text-sm font-bold text-gray-700">
                Send SMS and Email notifications when I’m actively working in the LifeMD system
              </span>
            </Common.Checkbox>
          )}
        />
      </div>
    </div>
  );
};

export default Notifications;
