import { StyledDayPicker } from 'components/common/Calendar/calendar.styled';
import Loader from 'components/common/Loader';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import { DateFormat } from 'enums/dateFormats';

import { CalendarProps } from './calendar.types';

dayjs.extend(localeData);

const Calendar: React.FC<CalendarProps> = ({ isLoading, onDayClick, selected, disabled, onMonthChange, fromMonth }) => (
  <div className="relative mx-auto">
    <Loader isVisible={isLoading} />
    <StyledDayPicker
      showOutsideDays
      formatters={{
        formatWeekdayName: (date) => dayjs(date).format(DateFormat.ddd),
      }}
      fromMonth={fromMonth}
      onMonthChange={onMonthChange}
      onDayClick={onDayClick}
      selected={selected}
      disabled={disabled}
    />
  </div>
);

export default Calendar;
