import dayjs from 'dayjs';

import { SMSProps } from './sms.types';

const normalizeSMS = (sms: SMSProps, isGrouped?: boolean) => {
  return {
    id: sms._id,
    date: new Date(sms.createdAt).getTime(),
    author: sms.senderDetails.name,
    userId: sms.senderDetails._id,
    message: sms.message,
    profileImage: sms.senderDetails.profileImage,
    sentBy: sms.senderDetails.userType,
    isGrouped,
  };
};

export const formatSMSs = (SMSs: SMSProps[]) => {
  return SMSs.map((SMS, index) => {
    const nextSMS = SMSs[index + 1];
    let isGrouped;
    if (nextSMS) {
      const diff = dayjs(nextSMS.createdAt).diff(SMS.createdAt, 'minutes');
      isGrouped = nextSMS.senderDetails._id === SMS.senderDetails._id && diff < 5; // 5 min
    }
    return normalizeSMS(SMS, isGrouped);
  });
};
