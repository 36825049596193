import { Common } from '@thecvlb/design-system';
import { FieldValues, Path, PathValue, useController } from 'react-hook-form';

import { InputFieldProps } from './inputField.types';

const InputField = <TFieldValues extends FieldValues>({
  control,
  name,
  rules,
  label,
  labelDirection,
  type = 'text',
  placeholder,
  defaultValue = '' as PathValue<TFieldValues, Path<TFieldValues>>,
  size = 'sm',
  onChange,
  ...otherProps
}: InputFieldProps<TFieldValues>) => {
  const {
    field,
    fieldState: { error },
  } = useController({ control, name, rules, defaultValue });

  return (
    <Common.Input
      {...otherProps}
      {...field}
      name={name}
      size={size}
      label={label}
      helper={error?.message}
      errors={error}
      labelDirection={labelDirection}
      type={type}
      placeholder={placeholder}
      onChange={(event) => {
        field.onChange(event);
        onChange && onChange(event);
      }}
    />
  );
};

export default InputField;
