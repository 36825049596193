import { ManualVerifyStatusProps } from 'models/patientIdentity.types';
import { apiSlice } from 'store/api/apiSlice';

export const patientIdentityApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    updateVerifyIdentity: build.mutation<
      { message: string },
      { patientId: string; manualVerifyStatus: ManualVerifyStatusProps | null }
    >({
      query: ({ patientId, manualVerifyStatus }) => ({
        url: `/patients/${patientId}/verify-identity`,
        method: 'PATCH',
        body: { manualVerifyStatus },
      }),
    }),
    downloadIdentityJobs: build.query<Blob, { jobId: string }>({
      query: ({ jobId }) => ({
        url: `/verify-identity/jobs/${jobId}/download`,
        responseHandler: (response: Response) => {
          return response.blob().then((res) => res);
        },
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: true,
});

export const { useUpdateVerifyIdentityMutation, useLazyDownloadIdentityJobsQuery } = patientIdentityApiSlice;
