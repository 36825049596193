import classNames from 'classnames';
import { SymptomCheckerStatus } from 'enums/taskCategories';
import { InsuranceStatuses, MIFStatus, OnboardingDetailsLabel } from 'enums/taskDetailsStatus';
import { TaskDetailsProps } from 'models/tasks.types';
import { PatientProps } from 'store/patients/patients.types';
import { QueueTasksProps } from 'store/queue/queueSlice.types';

import { OnboardingStatus, StatusDetailsProps } from './onboardingDetails.types';
import { TasksTableColumnsProps } from '../tasksTable.types';

export const getOnboardingStatuses = (
  details: TasksTableColumnsProps | QueueTasksProps | TaskDetailsProps | PatientProps,
) => {
  let preConsultTaskCompletedBy: { role: string; profileImage: string } = { role: '', profileImage: '' };

  const addressStatus: StatusDetailsProps = {
    status: OnboardingStatus.Ok,
    iconName: 'map-point',
    label: 'Address',
    value: 'Verified',
  };

  const insuranceStatus: StatusDetailsProps = {
    status: OnboardingStatus.Ok,
    iconName: 'card',
    label: 'Insurance',
    value: 'Complete - Not Covered',
  };

  const intakeFormStatus: StatusDetailsProps = {
    status: OnboardingStatus.Ok,
    iconName: 'list-view',
    label: 'MIF',
    value: 'Normal',
  };

  const identityStatus: StatusDetailsProps = {
    status: OnboardingStatus.Ok,
    iconName: 'tag-person',
    label: 'Identity',
    value: 'Verified',
  };

  const symptomsStatus: StatusDetailsProps = {
    status: OnboardingStatus.NotApplicable,
    iconName: 'stethoscope',
    label: 'Symptoms',
    value: 'N/A',
  };

  const bodyPhoto: StatusDetailsProps = {
    status: OnboardingStatus.NotApplicable,
    iconName: 'accessibility',
    label: 'Body photo',
    value: 'Not required',
  };

  if (
    'preConsultTaskCompletedBy' in details &&
    details?.preConsultTaskCompletedBy &&
    details?.preConsultTaskCompletedBy?.userType?.shortCode &&
    details?.preConsultTaskCompletedBy?.profileImage
  ) {
    preConsultTaskCompletedBy = {
      profileImage: details.preConsultTaskCompletedBy.profileImage,
      role: details.preConsultTaskCompletedBy.userType.shortCode,
    };
  }

  switch (details?.insuranceBenefitsStatus) {
    case InsuranceStatuses.INSURANCE_COVERED:
      insuranceStatus.status = OnboardingStatus.Ok;
      insuranceStatus.value = OnboardingDetailsLabel[InsuranceStatuses.INSURANCE_COVERED];
      break;
    case InsuranceStatuses.INSURANCE_NOT_COVERED:
      insuranceStatus.status = OnboardingStatus.Ok;
      insuranceStatus.value = OnboardingDetailsLabel[InsuranceStatuses.INSURANCE_NOT_COVERED];
      break;
    case InsuranceStatuses.INSURANCE_NOT_PROVIDED:
      insuranceStatus.status = OnboardingStatus.Ok;
      insuranceStatus.value = OnboardingDetailsLabel[InsuranceStatuses.INSURANCE_NOT_PROVIDED];
      break;
    case InsuranceStatuses.INSURANCE_WAITING_BENFITS:
      insuranceStatus.status = OnboardingStatus.Warning;
      insuranceStatus.value = OnboardingDetailsLabel[InsuranceStatuses.INSURANCE_WAITING_BENFITS];
      break;
    case InsuranceStatuses.INSURANCE_PA_REQUIRED:
      insuranceStatus.status = OnboardingStatus.Warning;
      insuranceStatus.value = OnboardingDetailsLabel[InsuranceStatuses.INSURANCE_PA_REQUIRED];
      break;
    case InsuranceStatuses.INSURANCE_PA_REQUESTED:
      insuranceStatus.status = OnboardingStatus.Warning;
      insuranceStatus.value = OnboardingDetailsLabel[InsuranceStatuses.INSURANCE_PA_REQUESTED];
      break;
    case InsuranceStatuses.INSURANCE_INCOMPLETE_OR_MISSING_INSURANCE:
      insuranceStatus.status = OnboardingStatus.Error;
      insuranceStatus.value = OnboardingDetailsLabel[InsuranceStatuses.INSURANCE_INCOMPLETE_OR_MISSING_INSURANCE];
      break;
    case InsuranceStatuses.INSURANCE_PLAN_INACTIVE:
      insuranceStatus.status = OnboardingStatus.Error;
      insuranceStatus.value = OnboardingDetailsLabel[InsuranceStatuses.INSURANCE_PLAN_INACTIVE];
      break;
  }

  if (details?.mifDetails?.status === MIFStatus.Warnings) {
    intakeFormStatus.status = OnboardingStatus.Warning;
    intakeFormStatus.value = `${details?.mifDetails?.warningCount}  warnings`;
  } else if (details?.mifDetails?.status === MIFStatus.Normal) {
    intakeFormStatus.status = OnboardingStatus.Ok;
    intakeFormStatus.value = 'Normal';
  } else {
    intakeFormStatus.status = OnboardingStatus.Error;
    intakeFormStatus.value = 'Incomplete';
  }

  if (
    ('patientInfo' in details && details?.patientInfo?.isValidAddress) ||
    ('isValidAddress' in details && details?.isValidAddress)
  ) {
    addressStatus.status = OnboardingStatus.Ok;
    addressStatus.value = 'Verified';
  } else {
    addressStatus.status = OnboardingStatus.Error;
    addressStatus.value = 'Not Verified';
  }

  switch (details?.symptomCheckerStatus) {
    case SymptomCheckerStatus.SC_COMPLETE:
      symptomsStatus.status = OnboardingStatus.Ok;
      symptomsStatus.value = 'Complete';
      break;
    case SymptomCheckerStatus.SC_INCOMPLETE:
      symptomsStatus.status = OnboardingStatus.Warning;
      symptomsStatus.value = 'Incomplete';
      break;
    case SymptomCheckerStatus.SC_SKIPPED:
      symptomsStatus.status = OnboardingStatus.Warning;
      symptomsStatus.value = 'Skipped';
      break;
    case SymptomCheckerStatus.SC_URGENT:
      symptomsStatus.status = OnboardingStatus.Error;
      symptomsStatus.value = 'Urgent';
      break;
  }

  if (
    ('patientInfo' in details && details?.patientInfo?.isIdentityVerified) ||
    ('isIdentityVerified' in details && details?.isIdentityVerified)
  ) {
    identityStatus.status = OnboardingStatus.Ok;
    identityStatus.value = 'Verified';
  } else {
    identityStatus.status = OnboardingStatus.Error;
    identityStatus.value = 'Not Verified';
  }

  if ('bodyImage' in details) {
    if (details?.bodyImage?.manualStatus === 'accepted') {
      bodyPhoto.status = OnboardingStatus.Ok;
      bodyPhoto.value = 'Complete';
    } else if (details?.bodyImage?.uploadRequired) {
      bodyPhoto.status = OnboardingStatus.Error;
      bodyPhoto.value = 'Not uploaded';
    } else if (details?.bodyImage?.manualStatus === null) {
      bodyPhoto.status = OnboardingStatus.Warning;
      bodyPhoto.value = 'Not reviewed';
    }
  }

  return {
    preConsultTaskCompletedBy,
    statuses: [addressStatus, insuranceStatus, intakeFormStatus, identityStatus, bodyPhoto, symptomsStatus],
  };
};

export const getStatusIconColor = (status: OnboardingStatus) => {
  const colorMap: Record<OnboardingStatus, string> = {
    [OnboardingStatus.Ok]: 'text-gray-400',
    [OnboardingStatus.Warning]: 'text-yellow-600',
    [OnboardingStatus.Error]: 'text-red',
    [OnboardingStatus.NotApplicable]: 'text-gray-100',
  };

  return colorMap[status] || 'text-gray-400';
};

export const getTooltipStatusColor = (status: OnboardingStatus) => {
  const colorMap: Record<OnboardingStatus, string> = {
    [OnboardingStatus.Ok]: 'text-green-300',
    [OnboardingStatus.Warning]: 'text-yellow-300',
    [OnboardingStatus.Error]: 'text-red-300',
    [OnboardingStatus.NotApplicable]: 'text-gray-500',
  };

  return colorMap[status] || 'text-green-300';
};

export const getIconClassName = (status: OnboardingStatus, size: 'sm' | 'md') => {
  const color = getStatusIconColor(status);
  const iconSize = size === 'md' ? 'w-5 h-5' : 'w-4 h-4';
  return classNames(iconSize, color);
};
