import React from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import { notifySuccess } from 'components/common/Toast/Toast';
import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import { useAppDispatch } from 'hooks/redux';
import { useForm } from 'react-hook-form';
import { closeModal } from 'store/modal/modalSlice';
import { useSetPAStatusMutation } from 'store/tasks/tasksSlice';

import { PA_STATUSES } from './updatePAStatus.settings';
import { UpdatePAStatusFomProps, UpdatePAStatusProps } from './updatePAStatus.types';
import { getPARequestStyle } from '../TaskPopover/RequestDetails/requestDetails.settings';

const UpdatePAStatus: React.FC<UpdatePAStatusProps> = ({
  requestStatus,
  medicationName,
  requestId,
  drugName,
  insuranceName,
  patientId,
}) => {
  const dispatch = useAppDispatch();
  const [setPAStatus] = useSetPAStatusMutation();
  const { control, watch } = useForm<UpdatePAStatusFomProps>({
    defaultValues: { paStatus: PA_STATUSES.find((status) => status.value === requestStatus) },
  });
  const paStatus = watch('paStatus');
  const disabledUpdatePAStatus = !paStatus || paStatus.value === requestStatus;

  const handleUpdateStatus: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    const body = {
      status: paStatus.value,
      requestId,
      drugName,
      insuranceName,
      patientId,
    };
    setPAStatus(body)
      .unwrap()
      .then((response) => {
        notifySuccess(response.message);
        dispatch(closeModal());
      });
  };

  return (
    <form className="flex flex-col gap-4 p-6">
      <h2 className="mb-2 text-xl font-bold text-gray-700">Prior authorization status</h2>
      {!!requestStatus && (
        <div className="mb-2 flex justify-center gap-2">
          <p className="text-base font-bold text-gray-700">{medicationName} PA status</p>
          <div className="flex w-[92px] items-center text-nowrap">{getPARequestStyle(requestStatus)}</div>
        </div>
      )}
      <ControlledSelect
        control={control}
        labelDirection="col"
        options={PA_STATUSES}
        placeholder="Select..."
        label="Update PA status to"
        name="paStatus"
      />

      <div className="border-t border-gray-200" />

      <div className="mt-2 flex justify-end gap-2">
        <Common.Button onClick={() => dispatch(closeModal())} color="white-alt" size="sm" type="button">
          Cancel
        </Common.Button>
        <Common.Button color="blue" size="sm" onClick={handleUpdateStatus} disabled={disabledUpdatePAStatus}>
          Update status
        </Common.Button>
      </div>
    </form>
  );
};

export default UpdatePAStatus;
