import { useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import { useGetShiftTypesQuery } from 'store/calendar/calendarSlice';

import { getTotalHours, getWeeksSize } from './weekTotal.settings';
import { WeekTotalProps } from './weekTotal.types';
import EventIcon from '../../components/EventIcon';
import { getStartWeekDates } from '../MonthlyCalendar/monthlyCalendar.settings';

const WeekTotal: React.FC<WeekTotalProps> = ({
  start = new Date(),
  end = new Date(),
  totalDurationBreakdownByWeek,
  timezone,
  calendarWrapperRef,
}) => {
  const { data: shiftTypes } = useGetShiftTypesQuery();

  const [weeksHeight, setWeeksHeight] = useState<{ [key: number]: string } | null>(null);

  const totalHours = !isEmpty(shiftTypes)
    ? getTotalHours({
        timezone,
        totalDurationBreakdownByWeek,
        startWeekDates: getStartWeekDates(start, end),
        shiftTypes,
      })
    : [];

  /**
   * @description
   * This effect is responsible for observing changes in the size of the calendar rows.
   * When the size of a row changes, it updates the state `weeksHeight` with the new size.
   * This is done in order to keep the week total rows size in sync with the calendar rows.
   */
  useEffect(() => {
    if (calendarWrapperRef.current) {
      const ro = new ResizeObserver((entries) => {
        const weekRowsSize = getWeeksSize(entries[0].target);

        if (weekRowsSize) setWeeksHeight(weekRowsSize);
      });

      ro.observe(calendarWrapperRef.current);

      return () => ro.disconnect();
    }
  }, [calendarWrapperRef]);

  return (
    <table className="mt-[60px] w-[100px] flex-none">
      <thead>
        <tr className="h-8 border-y border-r border-gray-100">
          <td className="p-2 text-base font-medium text-gray-600">Week total</td>
        </tr>
      </thead>
      <tbody className="overflow-y-scroll border-r border-gray-100">
        {totalHours?.map((items, index) => {
          const weekHeight = !!weeksHeight?.[index] ? weeksHeight[index] : 110;

          return (
            <tr
              className="border-b border-gray-100"
              style={{ height: weekHeight }}
              key={index}
              data-testid="week_total_row"
            >
              <td className="flex flex-col items-start pl-2 pt-2">
                <ul>
                  {items?.map(
                    (item, idx) =>
                      item && (
                        <li
                          className="flex items-center gap-1"
                          key={idx}
                          data-testid={item.shiftType.name === 'time-off' ? 'time-off-total' : 'shift-total'}
                        >
                          <EventIcon title={item?.shiftType?.title} />{' '}
                          {item?.parsedDuration && (
                            <span className="inline-block text-xs font-semibold text-gray-700">
                              {item.parsedDuration}
                            </span>
                          )}
                        </li>
                      ),
                  )}
                </ul>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default WeekTotal;
