import { useEffect, useState } from 'react';

import { nanoid } from '@reduxjs/toolkit';
import Loader from 'components/common/Loader';
import MIFDetails from 'components/tasks/slidingPane/MIFDetails';
import dayjs from 'dayjs';
import { HealthProfileNavItem } from 'enums/chart';
import { DateFormat } from 'enums/dateFormats';
import { SexAtBirth } from 'enums/gender';
import isEqual from 'lodash/isEqual';
import { useDebounce } from 'react-use';
import {
  AllergyItemProps,
  ChronicDiseasesItemProps,
  HealthConditionsItemProps,
  HealthMetrics as HealthMetricsProps,
  MedicationItemProps,
  MifProps,
  RequireOnlyOne,
  SocialHistoryItemProps,
  WomenHealthProps,
} from 'store/patientChart/patientChart.types';
import { useGetHealthProfileQuery, useUpdateHealthMetricMutation } from 'store/patientChart/patientChartSlice';
import { handleRequestCatch } from 'utils/helpers';
import { AT_LEAST_ONE_LETTER_REGEXP } from 'utils/regExp';

import Allergies from './Allergies';
import { chartTabs } from './chart.settings';
import { ChartProps, ChartTab } from './chart.types';
import ChronicDiseases from './ChronicDiseases';
import HeaderText from './HeaderText';
import HealthConditions from './HealthConditions';
import HealthMetrics from './HealthMetrics';
import Medications from './Medications';
import SideBar from './SideBar';
import Skeleton from './Skeleton';
import SocialHistory from './SocialHistory';
import WomensHealth from './WomensHealth';

const Chart: React.FC<ChartProps> = ({ patient }) => {
  const [tab, setTab] = useState<ChartTab>(chartTabs[0]);
  const [healthConditionsData, setHealthConditionsData] = useState<HealthConditionsItemProps[]>([]);
  const [chronicDiseasesData, setChronicDiseasesData] = useState<ChronicDiseasesItemProps[]>([]);
  const [womenHealthData, setWomenHealthData] = useState<WomenHealthProps>({
    approxStartDate: '',
    name: '0',
    startDate: '',
  });
  const [socialHistoryData, setSocialHistoryData] = useState<SocialHistoryItemProps[]>([]);
  const [medicationsData, setMedicationsData] = useState<
    (MedicationItemProps & { id: string; isPrescription: boolean })[]
  >([]);
  const [allergyListData, setAllergyListData] = useState<(AllergyItemProps & { id: string })[]>([]);
  const [medicalIntake, setMedicalIntake] = useState<MifProps>();
  const [tabs, setTabs] = useState<ChartTab[]>(
    chartTabs.filter((el) =>
      el.label === HealthProfileNavItem.WomenHealth ? patient?.sexAtBirth === SexAtBirth.Female : true,
    ),
  );

  const { data: healthProfileData, isLoading } = useGetHealthProfileQuery({ patientId: patient?._id ?? '' });
  const [updateHealthMetrics, { isLoading: updateHealthMetricsLoading }] = useUpdateHealthMetricMutation();

  const mifDetails = healthProfileData?.data?.mifDetails;

  const handleUpdateHealthProfile = (metrics: RequireOnlyOne<HealthMetricsProps>) => {
    const body = {
      patientId: patient?._id ?? '',
      metrics,
      collectedDate: dayjs().format(DateFormat.YYYY_MM_DD),
    };
    updateHealthMetrics(body)
      .unwrap()
      .then(() => {
        medicalIntake &&
          setMedicalIntake({
            ...medicalIntake,
            ...metrics,
          });
      })
      .catch(handleRequestCatch);
  };

  const handleUpdatedHealthConditionsData = () => {
    if (!isEqual(healthConditionsData, medicalIntake?.healthConditions)) {
      handleUpdateHealthProfile({ healthConditions: healthConditionsData });
    }
  };

  const handleUpdateChronicDiseases = () => {
    if (!isEqual(chronicDiseasesData, medicalIntake?.chronicDiseases)) {
      handleUpdateHealthProfile({ chronicDiseases: chronicDiseasesData });
    }
  };

  const handleUpdatedWomensHealthData = () => {
    if (!isEqual(womenHealthData, medicalIntake?.womenHealth)) {
      handleUpdateHealthProfile({ womenHealth: womenHealthData });
    }
  };

  const handleUpdatedSocialHistoryData = () => {
    if (!isEqual(socialHistoryData, medicalIntake?.socialHistory)) {
      handleUpdateHealthProfile({ socialHistory: socialHistoryData });
    }
  };

  const handleUpdatePrescriptionData = () => {
    const medications = {
      prescription: medicationsData
        .filter((el) => el.isPrescription)
        .map(({ id, isPrescription, ...restItem }) => restItem),
      supplements: medicationsData
        .filter((el) => !el.isPrescription)
        .map(({ id, isPrescription, ...restItem }) => restItem),
    };
    if (
      (!isEqual(medications.prescription, medicalIntake?.medications?.prescription) ||
        !isEqual(medications.supplements, medicalIntake?.medications?.supplements)) &&
      !medicationsData.find(
        (el) => el.name === '' || el.name.trim().length < 3 || !el.name.match(AT_LEAST_ONE_LETTER_REGEXP),
      )
    ) {
      handleUpdateHealthProfile({
        medications,
      });
    }
  };

  const handleUpdateAllergyList = () => {
    const mappedAllergyList = allergyListData.map(({ id, ...restItem }) => restItem);
    if (
      !isEqual(mappedAllergyList, medicalIntake?.allergyList) &&
      !mappedAllergyList.find(
        (el) => el.name === '' || el.name.trim().length < 3 || !el.name.match(AT_LEAST_ONE_LETTER_REGEXP),
      )
    ) {
      handleUpdateHealthProfile({ allergyList: mappedAllergyList });
    }
  };

  const handleUpdatedMedicalIntake = () => {
    const data = healthProfileData?.data;
    if (!data?.medicalIntake) {
      return;
    }
    setMedicalIntake(data.medicalIntake);
    setWomenHealthData(data.medicalIntake.womenHealth);
    setSocialHistoryData(data.medicalIntake.socialHistory);
    setChronicDiseasesData(data.medicalIntake.chronicDiseases);
    setHealthConditionsData(data.medicalIntake.healthConditions);
    const medications = data?.medicalIntake?.medications;

    if (medications?.prescription.length || medications?.supplements.length) {
      setMedicationsData(
        medications?.prescription
          .map((el) => ({
            ...el,
            id: nanoid(),
            isPrescription: true,
          }))
          .concat(
            medications?.supplements.map((el) => ({
              ...el,
              id: nanoid(),
              isPrescription: false,
            })),
          ),
      );
    }
  };

  useEffect(handleUpdatedMedicalIntake, [healthProfileData?.data]);

  useEffect(() => {
    const data = healthProfileData?.data;
    if (!data?.medicalIntake) {
      return;
    }
    if (!allergyListData.length && data.medicalIntake.allergyList.length) {
      setAllergyListData(data.medicalIntake.allergyList.map((el) => ({ ...el, id: nanoid() })));
    }
  }, [healthProfileData?.data, allergyListData.length]);

  useDebounce(handleUpdatedHealthConditionsData, 1500, [healthConditionsData, medicalIntake]);

  useDebounce(handleUpdateChronicDiseases, 1500, [chronicDiseasesData]);

  useDebounce(handleUpdatedWomensHealthData, 1500, [womenHealthData]);

  useDebounce(handleUpdatedSocialHistoryData, 1500, [socialHistoryData]);

  useDebounce(handleUpdatePrescriptionData, 1500, [medicationsData, medicalIntake]);

  useDebounce(handleUpdateAllergyList, 1500, [allergyListData, medicalIntake]);

  useEffect(() => {
    if (!medicalIntake) {
      return;
    }
    setTabs((prev) =>
      prev.map((el) => {
        switch (el.label) {
          case HealthProfileNavItem.Allergies:
            return { ...el, count: medicalIntake.allergyList.length };
          case HealthProfileNavItem.Medications:
            return {
              ...el,
              count: medicalIntake.medications?.supplements.length + medicalIntake.medications?.prescription.length,
            };
          default:
            return el;
        }
      }),
    );
  }, [medicalIntake]);

  return (
    <div data-testid="chart_block" className="flex px-6 pb-6 pt-4">
      <Loader isVisible={updateHealthMetricsLoading}></Loader>
      <div className="w-[262px]">
        <SideBar onClick={setTab} currentTab={tab} tabs={tabs} />
      </div>
      <div className="max-w-screen-sm flex-1 p-4">
        {isLoading ? (
          <Skeleton />
        ) : (
          <div className="flex flex-col gap-2">
            {tab.label == HealthProfileNavItem.MIF && <MIFDetails mifDetails={mifDetails} />}
            {tab.label == HealthProfileNavItem.HealthMetrics && <HealthMetrics patient={patient} />}
            {tab.label == HealthProfileNavItem.HealthConditions && (
              <>
                <HeaderText
                  description="Have you had any of the following health conditions?"
                  title="Health conditions"
                />
                <HealthConditions
                  healthConditions={healthConditionsData}
                  loading={updateHealthMetricsLoading}
                  onUpdate={setHealthConditionsData}
                />
              </>
            )}
            {tab.label == HealthProfileNavItem.ChronicDiseases && (
              <>
                <HeaderText
                  description="Have you had any of the following medical conditions?"
                  title="Chronic diseases"
                />
                <ChronicDiseases
                  chronicDiseases={chronicDiseasesData}
                  loading={updateHealthMetricsLoading}
                  onUpdate={setChronicDiseasesData}
                />
              </>
            )}
            {tab.label == HealthProfileNavItem.WomenHealth && (
              <>
                <HeaderText description="Which of the following applies to you?" title="Women's health" />
                <WomensHealth onUpdate={setWomenHealthData} {...womenHealthData} loading={updateHealthMetricsLoading} />
              </>
            )}
            {tab.label == HealthProfileNavItem.SocialHistory && (
              <>
                <HeaderText
                  description="Have you used any of the following? Your information will only be used for medical purposes."
                  title="Social history"
                />
                <SocialHistory
                  loading={updateHealthMetricsLoading}
                  values={socialHistoryData}
                  onUpdate={setSocialHistoryData}
                />
              </>
            )}
            {tab.label == HealthProfileNavItem.Medications && (
              <>
                <HeaderText
                  description="Do you have any medications or supplements that you are currently taking?"
                  title="Medications"
                />
                <Medications
                  loading={updateHealthMetricsLoading}
                  medications={medicationsData}
                  onUpdate={setMedicationsData}
                />
              </>
            )}
            {tab.label == HealthProfileNavItem.Allergies && (
              <>
                <HeaderText description="Please list all allergies." title="Allergies" />
                <Allergies
                  allergyList={allergyListData}
                  loading={updateHealthMetricsLoading}
                  onUpdate={setAllergyListData}
                />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Chart;
