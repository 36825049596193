import { useCallback, useEffect, useRef, useState } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import Alert from 'components/common/Alert';
import DateLabel from 'components/common/Chat/DateLabel';
import EmptyChatPlaceholder from 'components/common/Chat/EmptyChatPlaceholder';
import Message from 'components/common/Chat/Message';
import { skeletonChatList } from 'components/common/Chat/Message/message.settings';
import NoPhoneNumberPlaceholder from 'components/common/Chat/NoPhoneNumberPlaceholder';
import RetryBlock from 'components/common/RetryBlock';
import SMSForm from 'components/modals/smsModal/SMSForm';
import { TemplatesProvider } from 'contexts/TemplatesContext/TemplatesContext';
import { MessageTemplates, MessageType, Status } from 'enums/messages';
import { useAlert } from 'hooks/common/messages/useAlert';
import { useChatScroll } from 'hooks/common/useChatScroll';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import isEmpty from 'lodash/isEmpty';
import { useInView } from 'react-intersection-observer';
import { useNetworkState } from 'react-use';
import {
  resetSMSs,
  selectFormattedSMSs,
  selectSMS,
  smsSelectors,
  useLazyGetSMSQuery,
  useSendSMSMutation,
} from 'store/sms/smsSlice';
import { UserTypeProps } from 'store/user/user.types';
import { selectUser } from 'store/user/userSlice';

import { MessagesSectionProps } from './messagesSection.types';

const SMSsLimit = 10;
const selectMessagesSectionState = createSelector(
  [selectUser, selectFormattedSMSs, selectSMS, smsSelectors.selectTotal],
  (user, SMSs, sms, SMSsQuantity) => ({
    userId: user._id,
    SMSs,
    isIdle: sms.status === Status.Idle,
    isLoading: sms.status === Status.Pending,
    isRejected: sms.status === Status.Rejected,
    SMSsQuantity,
    SMSsTotalCount: sms.totalCount,
  }),
);

const MessagesSection: React.FC<MessagesSectionProps> = ({ patientId, patientPhone, isTaskModal }) => {
  const dispatch = useAppDispatch();
  const { online } = useNetworkState();
  const { userId, SMSs, SMSsQuantity, SMSsTotalCount, isIdle, isLoading } = useAppSelector(selectMessagesSectionState);
  const { handleClose, isSMSAlert } = useAlert({ type: MessageType.SMS });
  const page = useRef(Math.ceil(SMSsQuantity / 10));
  const [getSms] = useLazyGetSMSQuery({ refetchOnReconnect: true });
  const [, { isUninitialized, data }] = useSendSMSMutation({ fixedCacheKey: 'shared-send-sms' });
  const [message, setMessage] = useState<string>('');
  const { inView, ref } = useInView({ threshold: 1 });
  const [isManualInViewChange, setIsManualInViewChange] = useState(false);

  const containerRef = useChatScroll(message);

  const showEmptyChatPlaceholder = !isIdle && !isLoading && !isEmpty(patientPhone);
  const showNoPhoneNumberPlaceholder = isEmpty(patientPhone);
  const showSMSAlert = online && isSMSAlert && !isEmpty(patientPhone);
  const showSkeletonChatList = online && (isLoading || isIdle);

  const messagesListClasses = classNames(
    'flex flex-col-reverse overflow-y-auto overflow-x-hidden relative w-full text-sm',
    {
      'h-72': isTaskModal,
      'grow border-gray-200 px-4 py-0': !isTaskModal,
    },
  );

  const alertContainerClasses = !isTaskModal ? 'mb-4 z-10' : '';
  const formWrapperClasses = !isTaskModal ? 'px-4 pb-4' : '';

  const loadSMSs = useCallback(() => {
    page.current += 1;

    if (SMSsTotalCount !== SMSsQuantity && !isLoading) {
      getSms({ patientId, limit: SMSsLimit, pageNo: page.current });
    }
  }, [SMSsQuantity, SMSsTotalCount, getSms, isLoading, patientId]);

  const handleRetry = useCallback(() => {
    getSms({ patientId, limit: SMSsLimit, pageNo: page.current });
  }, [getSms, patientId]);

  useEffect(() => {
    if (patientId) getSms({ patientId, limit: SMSsLimit, pageNo: page.current });
    return () => {
      dispatch(resetSMSs());
    };
  }, [dispatch, getSms, patientId, page]);

  useEffect(() => {
    if (!isUninitialized && data?.message) setMessage(data.message);
  }, [data, isUninitialized]);

  useEffect(() => {
    if (inView) {
      if (isManualInViewChange) {
        loadSMSs();
        setIsManualInViewChange(false);
      } else {
        setIsManualInViewChange(true);
      }
    }
  }, [inView]);

  // Add scroll event listener to set isManualInViewChange to true
  // each time user scrolls the chat list
  useEffect(() => {
    const handleScroll = () => {
      setIsManualInViewChange(true);
    };

    const scrollElement = containerRef.current;
    if (scrollElement) {
      scrollElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener('scroll', handleScroll);
      }
    };
  });

  return (
    <>
      <div data-testid="scroll_area" className={messagesListClasses} ref={containerRef}>
        {showSMSAlert && (
          <Alert type="error" handleClose={handleClose} containerClasses={alertContainerClasses}>
            <span data-testid="alert_msg" className="text-base">
              Please use SMS only if you can’t get ahold of the patient via Patient messages tab.
            </span>
          </Alert>
        )}
        <div className="flex flex-col">
          {showSkeletonChatList && <div className="my-3 w-full">{skeletonChatList()}</div>}

          {online && !isLoading ? (
            <>
              {!isEmpty(SMSs)
                ? Object.entries(SMSs).map(([key, value], index) => {
                    if (index === 0) {
                      return (
                        <div key={key}>
                          <DateLabel date={key} key={key} />
                          {value.map((sms, smsIndex) => {
                            if (smsIndex === 0) {
                              return (
                                <Message
                                  text={sms.message}
                                  self={userId === sms.userId}
                                  name={sms.author}
                                  date={new Date(sms.date)}
                                  key={sms.id}
                                  isGrouped={sms.isGrouped}
                                  profileImage={sms.profileImage}
                                  ref={ref}
                                  userType={sms.sentBy as UserTypeProps}
                                  id={sms.id}
                                  type={MessageType.SMS}
                                />
                              );
                            }
                            return (
                              <Message
                                text={sms.message}
                                self={userId === sms.userId}
                                name={sms.author}
                                date={new Date(sms.date)}
                                key={sms.id}
                                isGrouped={sms.isGrouped}
                                profileImage={sms.profileImage}
                                userType={sms.sentBy as UserTypeProps}
                                id={sms.id}
                                type={MessageType.SMS}
                              />
                            );
                          })}
                        </div>
                      );
                    } else {
                      return (
                        <div key={key}>
                          <DateLabel date={key} />
                          {value.map((sms) => {
                            return (
                              <Message
                                text={sms.message}
                                self={userId === sms.userId}
                                name={sms.author}
                                date={new Date(sms.date)}
                                key={sms.id}
                                isGrouped={sms.isGrouped}
                                profileImage={sms.profileImage}
                                userType={sms.sentBy as UserTypeProps}
                                id={sms.id}
                                type={MessageType.SMS}
                              />
                            );
                          })}
                        </div>
                      );
                    }
                  })
                : showEmptyChatPlaceholder && <EmptyChatPlaceholder isChannelAvailable />}
              {showNoPhoneNumberPlaceholder && <NoPhoneNumberPlaceholder />}
            </>
          ) : !isLoading && !isIdle ? (
            <div className="absolute inset-x-3 bottom-24">
              <RetryBlock handleClick={handleRetry} />
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>

      {!isTaskModal && <hr />}
      {!!patientPhone && (
        <div className={formWrapperClasses}>
          <TemplatesProvider templatesType={MessageTemplates.Patient}>
            <SMSForm patientId={patientId} doctorId={userId} patientPhone={patientPhone} />
          </TemplatesProvider>
        </div>
      )}
    </>
  );
};

export default MessagesSection;
