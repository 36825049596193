import styled from 'styled-components';
import tw from 'twin.macro';

const MonthlyCalendarWrapper = styled.div<{ showWeekTotal?: boolean }>`
  ${tw`w-full pt-2`}

  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0;
    width: ${(props) => (props.showWeekTotal ? 'calc(100% + 100px)' : '100%')};
  }

  .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
    ${tw`px-6`}
  }

  .fc-toolbar-chunk {
    ${tw`flex items-center`}
  }

  /* Previous/Next buttons */
  .fc-next-button.fc-button.fc-button-primary,
  .fc-prev-button.fc-button.fc-button-primary {
    ${tw`px-4 py-2 bg-white w-12 h-8 rounded-lg border border-gray-200 flex items-center justify-center`}
  }
  .fc-icon.fc-icon-chevron-right,
  .fc-icon.fc-icon-chevron-left {
    ${tw`text-gray-700 text-lg`}
  }

  .fc-toolbar-title {
    ${tw`text-xl text-gray-700 font-bold`}
  }

  /* Today button */
  .fc-today-button.fc-button.fc-button-primary {
    ${tw`bg-transparent text-gray-700 border border-gray-200 rounded-lg px-4 py-2 h-8 text-sm font-bold capitalize`}
  }
  .fc-today-button.fc-button.fc-button-primary:focus {
    ${tw`outline-none ring-2 ring-blue-500/50 ring-offset-2 ring-offset-blue-500/50`}
  }
  .fc-today-button.fc-button.fc-button-primary:disabled {
    ${tw`text-gray bg-gray-200 px-6 md:px-5`}
  }

  /* Add time off button */
  .fc-AddTimeOff-button {
    ${tw`py-1 px-4 bg-gray-800/5 text-gray-700 rounded-lg border-none text-sm font-bold h-8 outline-none`}
  }
  .fc-AddTimeOff-button:hover {
    ${tw`bg-gray-800/10 text-gray-800`}
  }
  .fc-AddTimeOff-button.fc-button.fc-button-primary:focus {
    ${tw`outline-none ring-1 ring-gray-800/10 ring-offset-1 ring-offset-gray-800/10 bg-gray-800/10 text-gray-800`}
  }
  .fc-AddTimeOff-button.fc-button.fc-button-primary:active {
    ${tw`bg-gray-800/10 text-gray-800 outline-none ring-1 ring-primary-800/10 ring-offset-1 ring-offset-gray-800/10`}
  }

  /* Add shift button */
  .fc-AddShift-button {
    ${tw`py-1 px-4 bg-primary text-white rounded-lg border-none text-sm font-bold h-8 outline-none`}
  }
  .fc-AddShift-button:hover {
    ${tw`bg-primary-600 text-white`}
  }
  .fc-AddShift-button.fc-button.fc-button-primary:focus {
    ${tw`outline-none bg-primary-600 text-white ring-1 ring-primary-500/50 ring-offset-1 ring-offset-primary-500/50`}
  }
  .fc-AddShift-button.fc-button.fc-button-primary:active {
    ${tw`bg-primary-600 text-white outline-none ring-1 ring-primary-500/50 ring-offset-1 ring-offset-primary-500/50`}
  }

  /* Borders */
  .fc-scrollgrid.fc-scrollgrid-liquid {
    ${tw`border-t border-gray-100`}
  }

  .fc-theme-standard td {
    ${tw`border-b border-gray-100`}
  }

  .fc-theme-standard .fc-scrollgrid {
    ${tw`border-t border-gray-100`}
  }

  .fc-theme-standard th {
    ${tw`border-r border-gray-100`}
  }

  /* Column header */
  .fc-col-header {
    ${tw`border-b-0`}
  }
  .fc-col-header-cell.fc-day {
    ${tw`border-x border-gray-100`}
  }

  .fc-scrollgrid-sync-inner {
    ${tw`py-1.5`}
  }

  .fc-col-header-cell-cushion {
    ${tw`text-base text-gray-600 font-medium`}
  }

  /* Day grid */
  .fc-scrollgrid-sync-inner {
    ${tw`ml-2`}
  }

  .fc .fc-daygrid-day.fc-day-today {
    background-color: transparent;
  }

  .fc-daygrid-day-top {
    flex-direction: row;
  }

  .fc-daygrid-day-number {
    ${tw`text-sm`}
  }

  .time-off-day .fc-daygrid-day-number {
    ${tw`rounded-full border border-gray-600 px-1.5 py-1 `}
  }

  .fc-day-today .fc-daygrid-day-number {
    ${tw`rounded-full bg-secondary px-1.5 py-1 text-white`}
  }

  // Days on month view
  .fc-day-other.fc-daygrid-day {
    ${tw`bg-gray-100`}
  }

  .fc .fc-day-other .fc-daygrid-day-top {
    ${tw`opacity-100`}
  }
`;

export { MonthlyCalendarWrapper };
