import LabelCheckbox from 'components/common/LabelCheckbox';
import isEmpty from 'lodash/isEmpty';
import { handlePermissions } from 'pages/ManageRole/manageRole.settings';
import { StyledDiv } from 'pages/ManageRole/manageRole.styles';

import { PermissionListProps } from './permissionList.types';

export const PermissionList: React.FC<PermissionListProps> = ({
  permissions,
  pagePermissionItemsInCol,
  postPermissions,
  setPermissions,
  dataTestId,
}) => {
  return !isEmpty(permissions) ? (
    <StyledDiv data-testid={dataTestId} $repeat={pagePermissionItemsInCol ?? 0}>
      {permissions?.map((permission) => {
        const isChecked = postPermissions.includes(permission.name);
        return (
          <LabelCheckbox
            key={permission.name}
            id={permission.name}
            label={permission.label}
            checked={isChecked}
            onCheckedChange={() => handlePermissions(permission.name, postPermissions, setPermissions)}
          />
        );
      })}
    </StyledDiv>
  ) : null;
};
