import { Common } from '@thecvlb/design-system';
import CreateTeam from 'components/modals/CreateTeam';
import { useDispatch } from 'react-redux';
import { openModal } from 'store/modal/modalSlice';

import DeleteTeam from '../../modals/DeleteTeam';

const EditCell: React.FC<{ id: string; name: string }> = ({ id, name }) => {
  const dispatch = useDispatch();

  return (
    <div className="flex gap-1">
      <button
        data-testid="edit_bth"
        onClick={() =>
          dispatch(
            openModal({
              modalContent: <CreateTeam teamId={id} />,
              hideCloseButton: true,
              size: 'lg',
            }),
          )
        }
      >
        <Common.Icon name="pencil" className="size-4 hover:text-primary-500" />
      </button>

      <button
        data-testid="delete_btn"
        onClick={() =>
          dispatch(openModal({ modalContent: <DeleteTeam id={id} name={name} />, hideClose: true, size: 'sm' }))
        }
      >
        <Common.Icon name="trash" className="size-4 hover:text-primary-500" />
      </button>
    </div>
  );
};

export default EditCell;
