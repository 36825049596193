import { useCallback } from 'react';

import { notifyError, notifySuccess } from 'components/common/Toast/Toast';
import { useAppDispatch } from 'hooks/redux';
import { useCancelPendingAppointmentsMutation } from 'store/appointments/appointmentsSlice';
import { closeModal } from 'store/modal/modalSlice';
import { useDeactivatePatientMutation, useGetPatientQuery } from 'store/patients/patientsSlice';

import DeactivatePatient from './DeactivatePatient';
import { FormData } from './deactivatePatient.types';

const ConnectedDeactivatePatient = ({ patientId }: { patientId: string }) => {
  const dispatch = useAppDispatch();
  const [deactivatePatient, { isLoading }] = useDeactivatePatientMutation();
  const [cancelPendingAppointments, { isLoading: isLoadingCancelPendingAppointments }] =
    useCancelPendingAppointmentsMutation();

  const { patient } = useGetPatientQuery(
    { id: patientId },
    {
      selectFromResult: ({ data }) => ({
        patient: data,
      }),
    },
  );

  const handleDeactivatePatient = useCallback(
    (formData: FormData) => {
      deactivatePatient({
        patientId: patient?._id || '',
        subscriptionCancelReasonCode: formData.reason,
        chargifyCancelMessage: formData.details,
      })
        .unwrap()
        .then((data) => {
          notifySuccess(data.message);
          dispatch(closeModal());
        })
        .catch((error) => notifyError(error.message));
    },
    [deactivatePatient, dispatch, patient?._id],
  );

  const onSubmit = useCallback(
    (formData: FormData) => {
      if (patient?._id) {
        cancelPendingAppointments({ patientId: patient._id })
          .unwrap()
          .then(() => handleDeactivatePatient(formData));
      }
    },
    [cancelPendingAppointments, handleDeactivatePatient, patient?._id],
  );

  return (
    <DeactivatePatient
      onSubmit={onSubmit}
      patientId={patientId}
      isLoading={isLoading || isLoadingCancelPendingAppointments}
    />
  );
};

export default ConnectedDeactivatePatient;
