export enum VideoCallIssueType {
  PatientIssue = 'patient-issue',
  ProviderIssue = 'provider-issue',
  Both = 'both',
}

export enum VideoCallProblemType {
  AudioProblem = 'audio-problem',
  VideoProblem = 'video-problem',
  FrozeComputer = 'froze-computer',
}
