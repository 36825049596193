import { Common } from '@thecvlb/design-system/lib/src';
import EditTitrations from 'components/modals/EditTitrations';
import { useAppDispatch } from 'hooks/redux';
import { openModal } from 'store/modal/modalSlice';
import { getDisplayName } from 'store/patients/patients.settings';

import { TitrationCellProps } from './titrationCell.types';

const TitrationCell: React.FC<TitrationCellProps> = ({ titration, titrations, prescriptionId, patientInfo }) => {
  const dispatch = useAppDispatch();
  const patientName = getDisplayName(patientInfo);
  const titrationText = titration?.qty ? `${titration?.qty} ${titration?.units?.toLowerCase()}` : '--';

  const handleEditTitrations = () => {
    dispatch(
      openModal({
        size: 'sm',
        hideClose: true,
        modalContent: (
          <EditTitrations titrations={titrations} prescriptionId={prescriptionId} patientName={patientName} />
        ),
      }),
    );
  };

  return (
    <span className="flex max-w-[300px] items-center justify-between gap-2.5 font-medium">
      <span className="whitespace-nowrap">{titrationText}</span>
      <Common.Button
        data-testid="edit_titrations"
        preIcon="pencil"
        size="sm"
        color="white"
        onClick={handleEditTitrations}
      />
    </span>
  );
};

export default TitrationCell;
