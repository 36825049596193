import Overview from 'components/order/Overview';
import { PatientOverviewTab } from 'enums/patientOverviewTabs';
import { OrderProps } from 'store/orders/orders.types';

export const renderContentList = (tab: PatientOverviewTab, order: OrderProps) => {
  if (order) {
    switch (tab) {
      case 'Overview':
        return <Overview order={order} />;
      default:
        return <></>;
    }
  }
};
