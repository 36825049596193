import { RoleShortName } from 'enums/role';
import { UserRole } from 'models/roles.types';

const getValidRole = (userRole?: string): UserRole | null => {
  const systemRoles = [
    RoleShortName.Admin,
    RoleShortName.CustomerService,
    RoleShortName.MedicalAssistant,
    RoleShortName.NursePractitioner,
    RoleShortName.Physician,
    RoleShortName.MasterPhysician,
  ];

  if (userRole && systemRoles.includes(userRole as RoleShortName)) {
    return userRole as UserRole;
  } else {
    return null;
  }
};

export { getValidRole };
