import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import capitalize from 'lodash/capitalize';
import type { PaymentTransactionType } from 'store/patients/patients.types';

import { getBalanceClasses, getBalanceTitle, getStatusClasses } from './billingHistoryTransactions.settings';
import NoteCell from './NoteCell';
import Header from '../Header';

const columnHelper = createColumnHelper<PaymentTransactionType>();

export const columns = (maxioPath?: string) => {
  return [
    columnHelper.accessor('id', {
      header: () => <Header text="Transaction ID" sortField="id" noSort />,
      cell: (info) => <span className="font-semibold">{info.getValue()}</span>,
    }),
    columnHelper.accessor('createdAt', {
      header: () => <Header text="Date" sortField="createdAt" />,
      cell: (info) => <span>{info.getValue() && dayjs(info.getValue()).format(DateFormat.MMM_DD_YYYY)}</span>,
    }),
    columnHelper.accessor('receivedOn', {
      header: () => <Header text="Received on" sortField="receivedOn" />,
      cell: (info) => <span>{info.getValue() && dayjs(info.getValue()).format(DateFormat.MMM_DD_YYYY)}</span>,
    }),
    columnHelper.accessor('note', {
      header: () => <Header text="Note" sortField="title" noSort />,
      cell: (info) => {
        const transactionId = info.row.original.id;
        return (
          <NoteCell
            note={info.getValue()}
            {...(maxioPath && { maxioPath: `${maxioPath}/transactions/${transactionId}` })}
          />
        );
      },
    }),
    columnHelper.accessor('status', {
      header: () => <Header text="Status" sortField="status" />,
      cell: (info) => (
        <div className={getStatusClasses(info.getValue())}>
          <h4 className="text-sm font-semibold first-letter:uppercase">{capitalize(info.getValue())}</h4>
        </div>
      ),
    }),
    columnHelper.accessor('amount', {
      header: () => <Header text="Amount" sortField="amount" />,
      cell: (info) => {
        const amount = Number(info.getValue());
        return <span className={getBalanceClasses(amount)}>{getBalanceTitle(amount)}</span>;
      },
    }),
    columnHelper.accessor('subBalance', {
      header: () => <Header text="Sub Balance" sortField="subBalance" />,
      cell: (info) => {
        const amount = Number(info.getValue());
        return <span className={getBalanceClasses(amount)}>{getBalanceTitle(amount)}</span>;
      },
    }),
  ];
};
