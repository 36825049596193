import { INSURANCE_RELATIONSHIP_OPTIONS } from 'constants/order';

import { useEffect, useState } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import Loader from 'components/common/Loader';
import { notifyError, notifySuccess } from 'components/common/Toast/Toast';
import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import InputField from 'components/forms/controlled/InputField';
import { FormProvider, SubmitHandler, useForm, useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { closeModal } from 'store/modal/modalSlice';
import { useUpdateOrderMutation } from 'store/orders/ordersSlice';

import { getSubmitOrderData } from './editOrderForm.settings';
import { defaultValues, EditOrderFormProps, OrderFormStateProps } from './editOrderForm.types';

const EditInsuranceForm: React.FC = () => {
  const { control } = useFormContext();

  return (
    <>
      <InputField name="memberId" label="Member Id" control={control} labelDirection="row" type="text" />
      <InputField
        name="rxGroup"
        label="Rx Group"
        control={control}
        labelDirection="row"
        type="text"
        rules={{
          required: {
            value: false,
            message: 'Rx Group is optional',
          },
        }}
      />
      <InputField name="rxBin" label="Rx BIN" control={control} labelDirection="row" type="text" />
      <InputField
        name="pcn"
        label="PCN"
        control={control}
        labelDirection="row"
        type="text"
        rules={{
          required: {
            value: false,
            message: 'PCN is optional',
          },
        }}
      />
      <ControlledSelect
        dataTestId="relationship_dropdown"
        control={control}
        labelDirection="row"
        options={INSURANCE_RELATIONSHIP_OPTIONS}
        placeholder="Select relationship..."
        label="Relationship"
        name="relationship"
      />
    </>
  );
};

const EditOrderForm: React.FC<EditOrderFormProps> = ({
  orderDetails,
  onFormSubmit,
  onUpdateOrder,
  isFetching,
  orderId = '',
}) => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const [updateOrder, { isLoading: isLoadingEdit }] = useUpdateOrderMutation();

  const methods = useForm<OrderFormStateProps>({
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    mode: 'onChange',
    defaultValues,
  });

  const onSuccess = (message: string) => {
    notifySuccess(message || 'Successfully updated order');
    onUpdateOrder && onUpdateOrder();
    onFormSubmit && onFormSubmit();
    dispatch(closeModal());
  };

  const onError = (errorMessage: string) => {
    notifyError(errorMessage);
    setDisabled(false);
  };

  const handleFormSubmit: SubmitHandler<OrderFormStateProps> = async (formData) => {
    const hasRequiredData = (o: { memberId: string; rxBin: string }) => {
      if (!o.memberId) return false;
      if (!o.rxBin) return false;
      return true;
    };

    setDisabled(true);

    if (hasRequiredData(formData)) {
      const order = getSubmitOrderData(formData);

      if (orderId) {
        // update existing order
        updateOrder({ orderId, order })
          .unwrap()
          .then((data) => onSuccess(data.message))
          .catch((error) => onError(error.data?.message));
      }
    } else {
      onError('Missing required data');
    }
    return;
  };

  useEffect(() => {
    if (orderDetails && !isFetching) {
      if (Object.keys(orderDetails.insurance).length !== 0) {
        const relationship = orderDetails.insurance?.relationship
          ? INSURANCE_RELATIONSHIP_OPTIONS.find((c) => c.value === orderDetails.insurance.relationship)
          : { label: '', value: '' };
        const flatOrderDetails = {
          ...orderDetails.insurance,
          relationship,
        };
        methods.reset(flatOrderDetails);
      } else {
        methods.reset(defaultValues);
      }
    }
  }, [orderDetails, isFetching, methods.reset, methods]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleFormSubmit)} className="mt-5 grid grid-cols-2 gap-x-10 gap-y-3">
        <Loader isVisible={isLoadingEdit} />

        <EditInsuranceForm />
        <div className={'col-span-2'}>
          <div className={'mb-5 mt-2 flex content-end justify-between'}>
            <div></div>
            <div className="flex gap-2">
              <Common.Button
                dataTestId="cancel_btn"
                type="button"
                color="white-alt"
                onClick={() => dispatch(closeModal())}
                size="sm"
              >
                Cancel
              </Common.Button>
              <Common.Button
                dataTestId="save_btn"
                onClick={methods.handleSubmit(handleFormSubmit)}
                type="submit"
                disabled={disabled}
                color="blue"
                size="sm"
              >
                Save
              </Common.Button>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default EditOrderForm;
