import { useCallback, useMemo, useState } from 'react';

import { signOut } from 'aws-amplify/auth';
import { BroadcastChannel } from 'broadcast-channel';
import { notifyError } from 'components/common/Toast/Toast';
import { usePhoneCall } from 'contexts/phoneCallContext/phoneCallContext';
import { apiSlice } from 'store/api/apiSlice';
import { closeModal } from 'store/modal/modalSlice';
import { clearAllNotifications } from 'store/notifications/notificationsSlice';
import { resetQueueState } from 'store/queue/queueSlice';
import { setStartLocation } from 'store/system/systemSlice';
import { resetTasks } from 'store/tasks/tasksSlice';
import { clearUser } from 'store/user/userSlice';
import { closeAllSockets } from 'utils/socket';

import useZoomCall from './useZoomCall';
import { useAppDispatch } from '../redux';

export const useLogout = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { handleEndVideoCall } = useZoomCall();
  const { resetPhoneCallProps } = usePhoneCall();
  const logoutChannel = useMemo(() => new BroadcastChannel('logout'), []);

  const logout = useCallback(() => {
    setIsLoading(true);

    // Send logout message to all tabs via BroadcastChannel
    if (!logoutChannel.isClosed) logoutChannel.postMessage('Logout');

    signOut()
      .then(() => {
        dispatch(apiSlice.util.resetApiState());
        dispatch(clearUser());
        dispatch(clearAllNotifications());
        dispatch(resetQueueState());
        closeAllSockets();
        setIsLoading(false);
        dispatch(closeModal());
        resetPhoneCallProps();
        handleEndVideoCall();
        dispatch(setStartLocation(null));
        dispatch(resetTasks());
      })
      .catch((error) => {
        if (error) {
          notifyError(JSON.stringify(error));
        }
        setIsLoading(false);
      });
  }, [dispatch, handleEndVideoCall, logoutChannel]);

  const logoutAllTabs = () => {
    logoutChannel.onmessage = (broadcastChannelMessage) => {
      if (broadcastChannelMessage === 'Logout') {
        logout();
        logoutChannel.close();
      }
    };
  };

  return { isLoading, logout, logoutAllTabs };
};
