import React from 'react';

import { nanoid } from '@reduxjs/toolkit';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useSearchParams } from 'react-router-dom';

import { AvailabilityScrollbarProps } from './availabilityScrollbar.types';
import HoursCell from '../../Components/HoursCell';
import UserTimeEmptyMessage from '../../Components/UserTimeEmptyMessage';
import UserTimeSkeleton from '../../Components/UserTimeSkeleton';
import CombineTimeSlot from '../CombineTimeSlot';
import Header from '../Header';
import UserTimeInfo from '../UserTimeInfo';

const AvailabilityScrollbar: React.FC<AvailabilityScrollbarProps> = ({
  day,
  index,
  onSetSelectedDay,
  selectedDay,
  users,
  isLoading,
  isLoadingCombined,
  shiftTypes,
}) => {
  const [searchParams] = useSearchParams();
  const activeShiftTypesParam = searchParams.get('active-shift-types');

  const isSelectedDay = selectedDay === index;
  const isLoadingState = isLoadingCombined || isLoading;
  const hasUsers = users.length > 0;

  const selectedShiftType = shiftTypes && shiftTypes?.find((item) => item._id === activeShiftTypesParam)?.title;
  const selectedFilterTitle = !activeShiftTypesParam
    ? 'overview'
    : selectedShiftType
      ? selectedShiftType.toLowerCase()
      : activeShiftTypesParam;
  const emptyMessage =
    isSelectedDay && !isLoadingState && !hasUsers ? `There are no ${selectedFilterTitle} shifts scheduled` : '';

  const userTimeInfoComponent = () => {
    if (isLoadingState) {
      return <UserTimeSkeleton />;
    }

    if (emptyMessage) {
      return <UserTimeEmptyMessage emptyMessage={emptyMessage} />;
    }

    return users?.map((item) => <UserTimeInfo item={item} key={nanoid()} />);
  };

  return (
    <div data-testid={`${dayjs(day.date).format(DateFormat.dddd_MMM_DD)}_availability_table`}>
      <Scrollbars
        autoHeight
        autoHeightMin={28}
        autoHeightMax={300}
        className={classNames({ 'border-b': !emptyMessage })}
      >
        <button
          name="availabilityScrollBar"
          data-testid="availabilityScrollBar"
          onClick={() => onSetSelectedDay(isSelectedDay ? null : index)}
          className="sticky top-0 z-10 flex w-full bg-white"
        >
          <div
            data-testid="date"
            className={classNames('flex h-7 w-44 flex-none items-center justify-between px-3 text-sm font-medium', {
              'border-b': !!emptyMessage,
            })}
          >
            {isLoadingCombined ? (
              <>
                <div data-testid="date-skeleton" className="h-5 w-12 animate-pulse rounded bg-slate-200" />
                <div className="h-5 w-7 animate-pulse rounded bg-slate-200" />
              </>
            ) : (
              <>
                <b>{dayjs(day.date).format(DateFormat.dddd)}</b>
                <span>{dayjs(day.date).format(DateFormat.MMM_DD)}</span>
              </>
            )}
          </div>
          <div className="relative flex h-7 w-full">
            {emptyMessage ? <div className="border-l" /> : <HoursCell />}
            {day.timeSlots.map((timeSlot) => (
              <CombineTimeSlot
                timeSlot={timeSlot}
                key={nanoid()}
                isLoading={isLoadingCombined}
                isSelected={isSelectedDay}
              />
            ))}
          </div>
        </button>
        {isSelectedDay && userTimeInfoComponent()}
      </Scrollbars>
      {isSelectedDay && selectedDay !== 6 && <Header />}
    </div>
  );
};

export default AvailabilityScrollbar;
