import { createSelector } from '@reduxjs/toolkit';
import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';
import Loader from 'components/common/Loader';
import { useAppendScript } from 'hooks/common/useAppendScript';
import { useAppSelector } from 'hooks/redux';
import { FieldValues, useForm } from 'react-hook-form';
import { useTimeout } from 'react-use';
import { PaymentFormFields } from 'store/patients/patients.types';
import { selectPatient } from 'store/patients/patientsSlice';

import { PaymentMethodFormProps } from './addPaymentMethod.types';
import ChargifyForm from './ChargifyForm';

const selectPaymentCardState = createSelector([selectPatient], (patient) => ({
  paymentProfiles: patient.paymentProfiles,
}));

const AddPaymentMethod: React.FC<PaymentMethodFormProps> = ({
  action = 'add',
  isLoading,
  onConfirm,
  onDelete,
  onGoBack,
}) => {
  // custom loader for chargify form
  const [isReady] = useTimeout(3000);
  const { scriptLoading } = useAppendScript('https://js.chargify.com/latest/chargify.js');
  const { paymentProfiles } = useAppSelector(selectPaymentCardState);
  const isPayPalConnected = paymentProfiles?.some((paymentData) => paymentData.paymentMethod === 'paypal_account');
  const chargifyLoading = !isReady();

  const { handleSubmit, formState, control, getValues } = useForm<FieldValues>({
    criteriaMode: 'all',
    mode: 'onBlur',
    defaultValues: {
      cvc: '',
      expDate: '',
      fullNumber: '',
    },
    reValidateMode: 'onChange',
    shouldFocusError: false,
  });

  const onFormSubmit = (data: PaymentFormFields | string) => {
    onConfirm(data);
  };

  return (
    <div className="p-4">
      <Loader isVisible={chargifyLoading || scriptLoading} />
      <div>
        <h3 className="mb-6 mt-2 text-xl font-bold text-gray-700">Add payment method</h3>

        {!isPayPalConnected && !scriptLoading && (
          <div className={classNames({ 'invisible hidden': chargifyLoading })}>
            <ChargifyForm onGetPaypalToken={onFormSubmit} />
            <div className="flex items-center gap-4 py-4">
              <div className="h-px grow bg-gray-200"></div>
              <span className="text-xs uppercase text-gray">or</span>
              <div className="h-px grow bg-gray-200"></div>
            </div>
          </div>
        )}

        <form className="mb-4 flex-wrap">
          <Common.CreditCardForm control={control} getValues={getValues} />

          <div className="mt-6 flex flex-row-reverse gap-2">
            <Common.Button
              color="blue"
              size="sm"
              disabled={!!Object.keys(formState.errors).length || isLoading}
              isLoading={isLoading}
              type="submit"
              onClick={handleSubmit((data) => onFormSubmit(data as PaymentFormFields))}
            >
              <span className="first-letter:uppercase">{action} payment method</span>
            </Common.Button>
            <Common.Button color="white-alt" size="sm" disabled={isLoading} type="button" onClick={onGoBack}>
              Cancel
            </Common.Button>
            {action === 'edit' && !!onDelete && (
              <Common.Button color="red" disabled={isLoading} size="sm" onClick={onDelete} type="button">
                Remove payment method
              </Common.Button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddPaymentMethod;
