import { FieldValues, useController } from 'react-hook-form';

import { ControlledMaskInputProps } from './controlledMaskInput.types';
import MaskInput from '../MaskInput';

const ControlledMaskInput = <TFieldValues extends FieldValues>({
  control,
  name,
  rules,
  placeholder,
  format,
  className,
  wrapperClasses,
  isError,
  inputClassName = '',
  labelClassName = '',
  placeholderClassName = '',
}: ControlledMaskInputProps<TFieldValues>) => {
  const { field, formState } = useController({
    name,
    control,
    rules,
  });

  return (
    <MaskInput
      {...field}
      isSubmitted={formState.isSubmitted}
      value={field.value}
      onValueChange={(v) => field.onChange(v.formattedValue)}
      placeholder={placeholder}
      format={format}
      errors={formState.errors}
      isError={isError}
      className={className}
      wrapperClasses={wrapperClasses}
      inputClassName={inputClassName}
      labelClassName={labelClassName}
      placeholderClassName={placeholderClassName}
    />
  );
};

export default ControlledMaskInput;
