import { useState } from 'react';

import { Common } from '@thecvlb/design-system';
import { DataItemProps } from '@thecvlb/design-system/lib/src/common/Tabs/tabs.types';
import { useFlag } from '@unleash/proxy-client-react';
import ContactButtons from 'components/patient/Overview/ContactButtons/ContactButtons';
import {
  getShowBodyImageBadge,
  getShowIdentityBadge,
} from 'components/patient/Overview/PatientInfo/IdentityField/identityField.settings';
import OnboardingDetails from 'components/tables/TaskTable/OnboardingDetails';
import StuckOrderStatus from 'components/tasks/slidingPane/StuckOrderStatus';
import Tags from 'components/tasks/slidingPane/Tags';
import dayjs from 'dayjs';
import { PatientOverviewTab } from 'enums/patientOverviewTabs';
import { useAppSelector } from 'hooks/redux';
import { useNavigate } from 'react-router-dom';
import { getDisplayName } from 'store/patients/patients.settings';
import { PatientProps } from 'store/patients/patients.types';
import { selectUser } from 'store/user/userSlice';

import { HeaderProps } from './header.types';

const getButtons = (
  isHistory: boolean,
  isWeightManagement: boolean,
  pagePermissions?: string[],
  editingPermissions?: string[],
  patient?: PatientProps,
) => {
  const showPrescriptionsList = pagePermissions?.find((val) => val === 'PRESCRIPTION_LIST_VIEW');
  const showIdentity = editingPermissions?.includes('IDENTITY_EDIT');
  const badgeOnOverview =
    (!!patient?.identity && getShowIdentityBadge(patient?.identity)) ||
    (!!patient?.bodyImage && getShowBodyImageBadge(patient?.bodyImage));

  return [
    { label: PatientOverviewTab.Overview, ...(showIdentity && { badge: !!badgeOnOverview }) },
    { label: PatientOverviewTab.Messages },
    { label: PatientOverviewTab.StaffNotes },
    { label: PatientOverviewTab.Appointments },
    { label: PatientOverviewTab.Documents },
    { label: PatientOverviewTab.Insurance },
    ...(isWeightManagement && showPrescriptionsList ? [{ label: PatientOverviewTab.Prescriptions }] : []),
    { label: PatientOverviewTab.Chart },
    { label: PatientOverviewTab.Tasks },
    { label: PatientOverviewTab.Billing },
    { label: PatientOverviewTab.Parameters },
    ...(isHistory ? [{ label: PatientOverviewTab.History }] : []),
  ];
};

const Header: React.FC<HeaderProps> = ({ activeTab, setActiveTab, patient }) => {
  const { userRoleInfo } = useAppSelector(selectUser);

  const [isPrevPlanWM, setIsPrevPlanWM] = useState(false);
  const pagePermissions = userRoleInfo?.pagePermissions;
  const editingPermissions = userRoleInfo?.editingPermissions;
  const isEnabled = useFlag('patient-history-tab');
  const navigate = useNavigate();
  const isWeightManagement = patient?.planInfo?.planCode === 'weight-management' || isPrevPlanWM;
  const buttons: DataItemProps[] = getButtons(
    isEnabled,
    isWeightManagement,
    pagePermissions,
    editingPermissions,
    patient,
  );

  const onClickTab = (button: DataItemProps) => {
    setActiveTab && setActiveTab(button.label as PatientOverviewTab);

    const activeTabParam = `active-tab=${button.label}`;
    const historyParams = button.label === 'History' ? '&limit=10&pageNo=0' : '';
    const billingParams = button.label === 'Billing' ? '&historyTable=Invoices' : '';

    const searchParams = `?${activeTabParam}${historyParams}${billingParams}`;

    navigate({ search: searchParams }, { replace: true });
  };

  const stuckOrder = patient?.orderWarning;

  const shouldShowStuckOrderStatusIcon = stuckOrder?.orderId && (stuckOrder?.status || stuckOrder?.exception);
  const tooltipWrapperClasses = 'bottom-[200%] -ml-[121px]';

  return (
    <header className="px-6 pt-4">
      <div className="flex items-center justify-between">
        <div className="flex min-w-0 items-center gap-3 px-2">
          <Common.ProfileImage src={patient?.profileImage} size="lg" />
          <div className="ml-3 w-full">
            <div className="flex cursor-pointer">
              <p data-testid="patient_first+last_name" className="text-gray-700">
                <b className="text-xl">{getDisplayName(patient)}</b> {dayjs().diff(patient?.dob, 'year')} •{' '}
                {patient?.stateShortCode}
              </p>
              {shouldShowStuckOrderStatusIcon && (
                <StuckOrderStatus stuckOrder={stuckOrder} tooltipWrapperClasses={tooltipWrapperClasses} />
              )}
            </div>

            {patient?._id && (
              <div className="flex flex-col gap-2">
                <div className="overflow-hidden">
                  <Tags
                    patientId={patient._id}
                    size="lg"
                    isPatientInactive={patient.status === 'inactive'}
                    planRecommendationStatus={patient.planRecommendations?.[0]?.status}
                    planRecommendationName={patient.planRecommendations?.[0]?.medicationName}
                    setIsPrevPlanWM={setIsPrevPlanWM}
                  />
                </div>
                <OnboardingDetails details={patient} iconSize="md" />
              </div>
            )}
          </div>
        </div>
        {patient && (
          <div className="ml-5 flex min-w-[360px] justify-end">
            <ContactButtons patient={patient} />
          </div>
        )}
      </div>
      <Common.Tabs onChange={onClickTab} data={buttons} defaultSelected={[activeTab]} type="line" />
    </header>
  );
};

Header.displayName = 'Patient Overview Header';

export default Header;
