import { GENDER_OPTIONS, SEX_AT_BIRTH } from 'constants/user';

import { useCallback, useMemo } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { Common } from '@thecvlb/design-system/lib/src';
import BodyImageField from 'components/patient/Overview/PatientInfo/BodyImageField';
import EmailInfo from 'components/patient/Overview/PatientInfo/EmailInfo';
import IdentityField from 'components/patient/Overview/PatientInfo/IdentityField';
import {
  getShowBodyImageBadge,
  getShowIdentityBadge,
} from 'components/patient/Overview/PatientInfo/IdentityField/identityField.settings';
import ProviderSelect from 'components/patient/Overview/ProviderSelect';
import { usePhoneCall } from 'contexts/phoneCallContext/phoneCallContext';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import useZoomCall from 'hooks/common/useZoomCall';
import { useAppSelector } from 'hooks/redux';
import upperFirst from 'lodash/upperFirst';
import { PatientProps } from 'store/patients/patients.types';
import { useGetPatientQuery } from 'store/patients/patientsSlice';
import { selectUser } from 'store/user/userSlice';
import { addDashesToPhoneNumber } from 'utils/helpers';

export const getAddress = (patient: PatientProps) => {
  const address = [];
  if (patient.address) {
    address.push(patient.address);
  }
  if (patient.city) {
    address.push(patient.city);
  }
  if (patient.state) {
    address.push(patient.state);
  }
  if (patient.zipCode) {
    address.push(patient.zipCode);
  }
  return address.join(', ');
};

const selectUserState = createSelector(selectUser, (user) => ({
  userRole: user.userType.name,
  editingPermissions: user.userRoleInfo.editingPermissions,
}));

export interface GetPatientItemsProps {
  patientId: string;
  isSlidingPanel: boolean | undefined;
  isTaskDetails?: boolean;
}

export const useGetPatientItems = ({ patientId, isSlidingPanel, isTaskDetails }: GetPatientItemsProps) => {
  const { editingPermissions } = useAppSelector(selectUserState);
  const { showCallCard, hasGlobalInstance, hasSlidingPanelInstance, setShowCallCard, initiatePhoneCallProps } =
    usePhoneCall();
  const { isZoomCallOpen: zoomCallInProgress } = useZoomCall();
  const { patient, isFetching } = useGetPatientQuery(patientId ? { id: patientId } : skipToken, {
    selectFromResult: ({ data, isFetching: isFetchingPatient }) => ({
      patient: data,
      isFetching: isFetchingPatient,
    }),
  });

  // NOTE: we shouldn't show the call card if it's already shown
  const handleCall = useCallback(() => {
    const shouldShowPhoneCallCard = patient && !showCallCard && !hasGlobalInstance && !hasSlidingPanelInstance;

    if (shouldShowPhoneCallCard) {
      initiatePhoneCallProps({
        isSlidingPanel,
      });
    }
  }, [setShowCallCard, showCallCard, patient?._id, initiatePhoneCallProps]);

  const hasEditIdentityPermission = !!patient?.identity && !!editingPermissions?.includes('IDENTITY_EDIT');
  const hasEditBodyImagePermission = !!patient?.bodyImage && !!editingPermissions?.includes('UPDATE_BODY_IMAGE_STATUS');
  const showIdentityBadge = !!patient?.identity && getShowIdentityBadge(patient?.identity);
  const showBodyImageBadge = !!patient?.bodyImage && getShowBodyImageBadge(patient?.bodyImage);

  const items = useMemo(() => {
    if (patient)
      return [
        ...(patient?.identity
          ? [
              {
                label: 'Identity',
                value: (
                  <IdentityField
                    identity={patient.identity}
                    hasEditIdentityPermission={hasEditIdentityPermission}
                    patientId={patient._id}
                  />
                ),
                showBadge: showIdentityBadge,
              },
            ]
          : []),
        {
          label: 'Age',
          value: patient?.dob && (
            <span className="text-base">
              {dayjs.utc().diff(dayjs.utc(patient.dob), 'year')}
              <span className="text-gray-500"> ({dayjs.utc(patient.dob).format(DateFormat.MM_DD_YYYY)})</span>
            </span>
          ),
        },
        ...(patient?.bodyImage
          ? [
              {
                label: 'Body image',
                value: (
                  <BodyImageField
                    bodyImage={patient.bodyImage}
                    hasEditBodyImagePermission={hasEditBodyImagePermission}
                    patientId={patient._id}
                  />
                ),
                showBadge: showBodyImageBadge,
              },
            ]
          : []),
        { label: 'Address', value: getAddress(patient) },
        {
          label: 'Sex at birth',
          value: SEX_AT_BIRTH.find((item) => item.value === patient.sexAtBirth)?.label || '-',
        },
        {
          label: 'Email',
          value: <EmailInfo email={patient.email} emailStatus={patient.emailStatus} patientId={patient._id} />,
        },
        {
          label: 'Gender',
          value: GENDER_OPTIONS.find((state) => state.value === patient.gender)?.label || '-',
        },
        {
          label: 'Phone',
          isUnderline: true,
          value: patient.phone && (
            <Common.Button
              form="text-only"
              className="!p-0 !font-normal underline"
              onClick={handleCall}
              disabled={zoomCallInProgress || showCallCard}
            >
              {addDashesToPhoneNumber(patient.phone.internationalFormat)}
            </Common.Button>
          ),
        },
        { label: 'Membership', value: patient.membership },
        { label: 'Patient ID', value: patient._id },
        {
          label: 'Provider',
          value: isTaskDetails ? (
            patient.doctor
          ) : (
            <ProviderSelect doctorId={patient.doctorId} patientState={patient.state} patientId={patient._id} />
          ),
        },
        { label: '', value: '' },
        {
          label: 'Status',
          value: (
            <div data-testid="patient_status" className="flex items-center justify-center gap-10 text-base">
              {upperFirst(patient.status)}
            </div>
          ),
        },
      ];
    else if (isFetching) {
      return [
        'Age',
        'Address',
        'Sex at birth',
        'Email',
        'Gender',
        'Phone',
        'Membership',
        'Patient ID',
        'Provider',
        '',
        'Status',
      ].map((field) => ({ label: field, value: <div className="h-5 w-32 animate-pulse bg-slate-200" /> }));
    }
  }, [
    patient,
    hasEditIdentityPermission,
    showIdentityBadge,
    hasEditBodyImagePermission,
    showBodyImageBadge,
    handleCall,
    isTaskDetails,
    isFetching,
    zoomCallInProgress,
    showCallCard,
  ]);

  return { items };
};
