import { Common } from '@thecvlb/design-system';

import { AddButtonProps } from './button.types';

const AddButton: React.FC<AddButtonProps> = ({ text, onClick }) => {
  const buttonClassName =
    'flex items-center gap-5 rounded-2xl w-full border border-dashed px-5 py-4 text-base font-semibold text-gray-700 transition-all hover:bg-secondary-50';
  const iconClassName = 'h-10 w-14 rounded-xl bg-secondary-100 py-3 px-5 text-secondary';

  return (
    <button className={buttonClassName} onClick={onClick}>
      <Common.Icon className={iconClassName} name="plus" />
      {text}
    </button>
  );
};

export default AddButton;
