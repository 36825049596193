import { Common } from '@thecvlb/design-system/lib/src';

import { ChartTab } from '../chart.types';

const SideBar = ({
  onClick,
  currentTab,
  tabs,
}: {
  onClick: (tab: ChartTab) => void;
  currentTab: ChartTab;
  tabs: ChartTab[];
}) => {
  return (
    <div data-testid="chart_sidebar">
      {tabs.map((tab) => (
        <Common.MenuVertical
          active={currentTab?.label === tab.label}
          icon={tab.icon}
          key={tab.label}
          label={tab.label}
          size="lg"
          unread={tab.count}
          onClick={() => onClick(tab)}
        />
      ))}
    </div>
  );
};

export default SideBar;
