import { useEffect, useState } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import BodyImageModal from 'components/modals/BodyImageModal';
import { getBodyImageText } from 'components/modals/BodyImageModal/bodyImageModal.settings';
import { useAppDispatch } from 'hooks/redux';
import { BodyImageManualStatuses } from 'models/bodyImage.types';
import { BodyImage as BodyImageProps } from 'models/tasks.types';
import { openModal } from 'store/modal/modalSlice';

import { BodyImageFieldProps } from './bodyImageField.types';

const BodyImageField: React.FC<BodyImageFieldProps> = ({ hasEditBodyImagePermission, bodyImage, patientId }) => {
  const dispatch = useAppDispatch();
  const [bodyImageInfo, setBodyImageInfo] = useState<BodyImageProps>(bodyImage);

  const isVerified = bodyImageInfo.manualStatus === BodyImageManualStatuses.ACCEPTED;
  const isNotSubmitted = !bodyImageInfo.manualStatus && !bodyImageInfo.documents?.length;

  const text = getBodyImageText(isNotSubmitted, isVerified);
  const color = isNotSubmitted ? 'gray' : isVerified ? 'green' : 'red';
  const icon = isVerified ? 'check' : 'close';

  const handleChangeBodyImage = (newBodyImage: Partial<BodyImageProps>) => {
    setBodyImageInfo((prevBodyImage) => ({ ...prevBodyImage, ...newBodyImage }));
  };

  const openBodyImageModal = () => {
    dispatch(
      openModal({
        modalContent: (
          <BodyImageModal bodyImage={bodyImageInfo} patientId={patientId} onChangeBodyImage={handleChangeBodyImage} />
        ),
        size: 'sm',
      }),
    );
  };

  useEffect(() => {
    bodyImage && setBodyImageInfo(bodyImage);
  }, [bodyImage]);

  return (
    <span className="flex w-full items-center justify-between text-base">
      <Common.ColorTag color={color} text={text} {...(!isNotSubmitted && { icon })} />
      {hasEditBodyImagePermission && (
        <Common.Button color="white-alt" size="sm" onClick={openBodyImageModal}>
          Undo
        </Common.Button>
      )}
    </span>
  );
};

export default BodyImageField;
