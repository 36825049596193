import { useEffect } from 'react';

import { Common } from '@thecvlb/design-system';
import { Options } from 'components/patient/History/history.types';
import { FieldValues, useController } from 'react-hook-form';

import { AutocompleteMultiSelectProps } from './autocompleteMultiSelect.types';

const AutocompleteMultiSelect = <TFieldValues extends FieldValues>({
  name,
  control,
  rules,
  onInputChange,
  size = 'sm',
  label,
  helperText,
  options = [],
  placeholder,
  value,
  labelDirection,
  getOptionsFunc,
  ...otherProps
}: AutocompleteMultiSelectProps<TFieldValues>) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules });
  let prevInputValue = '';

  const handleInputChange = (inputValue: string) => {
    function getOptions() {
      if (inputValue === prevInputValue) {
        getOptionsFunc(inputValue);
      }
    }
    if (inputValue) {
      clearTimeout(1500);
      setTimeout(getOptions, 1500);
      prevInputValue = inputValue;
    }
  };

  useEffect(() => {
    if (value) {
      field.onChange(value);
    }
  }, [value]);

  return (
    <Common.MultiSelect
      {...otherProps}
      onInputChange={handleInputChange}
      placeholder={placeholder}
      label={label}
      size={size}
      name={field.name}
      value={field.value}
      onChange={field.onChange}
      helperText={helperText || error?.message}
      options={options?.length > 0 ? options : Array.isArray(value) ? (value as Options[]) : []}
      errors={error}
      labelDirection={labelDirection}
    />
  );
};

export default AutocompleteMultiSelect;
