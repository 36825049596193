import { Common } from '@thecvlb/design-system';
import { FieldValues, Path, PathValue, useController } from 'react-hook-form';

import { ControlledTextAreaProps } from './controlledTextArea.types';

const ControlledTextArea = <TFieldValues extends FieldValues>({
  name,
  control,
  rules,
  placeholder,
  label,
  helper,
  defaultValue = '' as PathValue<TFieldValues, Path<TFieldValues>>,
  size = 'sm',
  ...otherProps
}: ControlledTextAreaProps<TFieldValues>) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });
  return (
    <Common.TextArea
      {...otherProps}
      {...field}
      name={name}
      label={label}
      placeholder={placeholder}
      size={size}
      helper={error?.message || helper}
      errors={error}
    />
  );
};

export default ControlledTextArea;
