import { HistoryProps, Options } from 'components/patient/History/history.types';

export const defaultValues: HistoryProps = {
  searchKey: '',
  patientId: '',
  eventKey: [],
  eventType: [],
  startDate: '',
  endDate: '',
};

export const filterEventTypesByCategories = (
  selectedCategories: Options[],
  eventTypes: { name: string; category: string }[],
) => {
  // Ensure that selectedCategories is always an array
  if (!Array.isArray(selectedCategories)) {
    selectedCategories = [selectedCategories];
  }

  return eventTypes
    ?.filter((event) => {
      // Check if the event's category is in the selected categories
      return selectedCategories.some((category) => category.value === event.category);
    })
    .map((event) => {
      // Transform the event into the desired format
      return {
        value: event.name,
        label: event.name,
      };
    });
};
