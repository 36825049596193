import { ChangeEvent, forwardRef, useState } from 'react';

import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import { PatternFormat } from 'react-number-format';

import { MaskInputProps } from './maskInput.types';

const errorMessageTextClasses = 'text-red text-xs mt-1.5';

const MaskInput = forwardRef<HTMLInputElement, MaskInputProps>(
  (
    {
      name = '',
      id,
      placeholder,
      defaultValue,
      wrapperClasses,
      isError,
      value,
      format,
      errors,
      dataTestId,
      onValueChange,
      type = 'text',
      mask,
      prefix,
      renderText,
      inputClassName = '',
      labelClassName = '',
      placeholderClassName = '',
    },
    ref,
  ) => {
    const inputClasses = classNames(
      'px-3 text-black font-normal rounded-t-md border border-gray-200 w-full pr-8 outline-none text-base focus-within:border-gray-200',
      {
        'h-[42px] pt-3.5 pb-2.5': !inputClassName,
        'h-[32px] pt-3 pb-3': inputClassName,
      },
      inputClassName,
    );

    const [isFocus, setIsFocus] = useState(false);
    const isValid = !isError && value && !isFocus;
    const labelClasses = classNames(
      'bg-white block relative font-normal shadow rounded-t-md border-b-2 text-base focus-within:border-primary',
      isError ? 'border-red focus-within:border-red' : isValid ? 'border-secondary' : 'border-gray',
      labelClassName,
    );

    const placeholderClasses = classNames(
      'absolute left-0 px-4 my-auto transform transition-all text-gray pointer-events-none truncate w-full',
      {
        'text-primary': isFocus && !isError && !placeholderClassName,
        'text-base font-normal top-3': !isFocus && !value && !defaultValue && !placeholderClassName,
        'top-0 pl-3 text-[10px]': (isFocus || value || defaultValue) && !placeholderClassName,
        'text-red': isError && !placeholderClassName,
        'text-secondary': isValid,
        'px-2 text-sm font-medium text-gray-500 -ml-1 mt-2': placeholderClassName,
        hidden:
          (isFocus && (value || defaultValue) && placeholderClassName) ||
          (placeholderClassName && (value || defaultValue)),
      },
    );

    const handleToggleFocus = () => setIsFocus(!isFocus);

    const handleOnFocus = (event: ChangeEvent<HTMLInputElement>) => {
      handleToggleFocus();
      if (event.target.value.length) {
        setTimeout(() => event.target.select(), 5);
      }
    };

    return (
      <div className={wrapperClasses}>
        <label htmlFor={id} className={labelClasses} data-testid={dataTestId}>
          <PatternFormat
            getInputRef={ref}
            format={format}
            onValueChange={onValueChange}
            value={value}
            type={type}
            className={inputClasses}
            onFocus={handleOnFocus}
            onBlur={handleToggleFocus}
            mask={mask}
            prefix={prefix}
            renderText={renderText}
          />
          <span className={placeholderClasses}>{placeholder}</span>
        </label>
        {errors && (
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => <p className={errorMessageTextClasses}>{message}</p>}
          />
        )}
      </div>
    );
  },
);

export default MaskInput;
