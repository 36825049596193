import React from 'react';

import { twMerge } from 'tailwind-merge';

import type { AudienceProps } from './audiemce.types';
import { generateAudienceIcon, generateAudienceTitle } from '../../tables/TaskTable/Cells/dateCell.settings';

const Audience: React.FC<AudienceProps> = ({ specificAudience, textClassname = 'text-sm' }) => {
  const wrapperClassName = 'flex items-center text-gray-700';
  const nameClassName = twMerge('mr-1 font-medium', textClassname);

  return (
    <div data-testid="audience_container" className={wrapperClassName}>
      {generateAudienceIcon(specificAudience)}
      <span className={nameClassName}>{generateAudienceTitle(specificAudience)}</span>
    </div>
  );
};

export default Audience;
