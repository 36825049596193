import { PopupHeaderProps } from './popupHeader.types';

const PopupHeader: React.FC<PopupHeaderProps> = ({ title, id, subtitle }) => {
  return (
    <div className="mb-8">
      <div className="flex justify-between">
        <h1 className="text-xl font-bold text-gray-700">{title}</h1>
        {id && (
          <p data-testid="id" className="text-sm">
            ID: {id}
          </p>
        )}
      </div>
      {subtitle && <p className="mt-2 text-base font-medium">{subtitle}</p>}
    </div>
  );
};

export default PopupHeader;
