import styled from 'styled-components';
import tw from 'twin.macro';

export const DateInputWrapper = styled.div`
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    ${tw`z-10 bg-primary w-12 h-12 hover:bg-primary hover:opacity-90`};
  }

  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected) {
    ${tw`w-12 h-12 text-primary`};
  }

  .DayPicker-Day {
    ${tw`w-12 h-12 min-h-full text-sm`};
  }
`;
