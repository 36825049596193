import { useEffect } from 'react';

import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import BasicTable from 'components/tables/BasicTable';
import { useGetDefaultBasicTableValues } from 'hooks/common/useGetDefaultBasicTableValues';
import { useAppDispatch } from 'hooks/redux';
import { setNewBasicTablesSorting } from 'store/basicTable/basicTableSlice';

import { columns } from './columns';
import { HealthMetricTableProps } from './healthMetric.types';

const HealthMetricTable: React.FC<HealthMetricTableProps> = ({
  metricType,
  metrics,
  isEditAllowed,
  isWMDevices,
  totalCount = 0,
}) => {
  const dispatch = useAppDispatch();
  const [tableData, tableColumns] = useGetDefaultBasicTableValues({
    isFetching: false,
    columns: columns(metricType, isEditAllowed, isWMDevices),
    data: metrics,
  });

  const table = useReactTable({
    data: tableData,
    columns: tableColumns as ColumnDef<{ key: string; value: string }, unknown>[],
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    dispatch(
      setNewBasicTablesSorting({
        key: metricType,
        pageNo: 0,
        limit: [10],
        sortField: 'collectedDate',
        sortOrder: 'DESC',
      }),
    );
  }, []);

  return <BasicTable table={table} totalCount={totalCount} customSortingTableName={metricType} />;
};

export default HealthMetricTable;
