import { HealthMetricTypes } from 'enums/chart';
import capitalize from 'lodash/capitalize';
import { HealthMetricType } from 'store/patients/patients.types';

export const getHealthMetricTitle = (metricType: HealthMetricType) => {
  switch (metricType) {
    case HealthMetricTypes.BMI:
      return 'Body Mass Index (BMI)';
    case HealthMetricTypes.BloodPressure:
      return 'Blood Pressure';
    case HealthMetricTypes.WaistCircumference:
      return 'Waist circumference';
    default:
      return capitalize(metricType);
  }
};
