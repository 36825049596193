import { createColumnHelper } from '@tanstack/react-table';

import EditCell from './EditCell';
import { StaffCostsTableProps } from './staffCostsTable.types';
import Header from '../Header';

const columnHelper = createColumnHelper<StaffCostsTableProps>();

export const columns = [
  columnHelper.accessor('staff', {
    header: () => <Header text="Staff" sortField="staff" />,
  }),
  columnHelper.accessor('payAmount', {
    header: () => <Header text="Pay amount" sortField="payAmount" />,
  }),
  columnHelper.accessor('payType', {
    header: () => <Header text="Pay type" sortField="payType" />,
  }),
  columnHelper.accessor('_id', {
    header: () => <Header text="Unique ID" sortField="uniqueId" />,
  }),
  columnHelper.accessor('status', {
    header: () => <Header text="Status" sortField="status" />,
    cell: (info) => (
      <span className={`capitalize ${info.getValue() === 'active' ? 'text-blue' : 'text-red'}`}>{info.getValue()}</span>
    ),
  }),
  columnHelper.accessor('actions', {
    header: () => <span className="text-sm font-bold">Actions</span>,
    cell: (info) => <EditCell id={info.row.original._id} />,
    enableSorting: false,
  }),
];
