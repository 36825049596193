import { useEffect, useState } from 'react';

export const useAppendScript = (url: string) => {
  const [scriptLoading, setLoading] = useState(true);
  const [error, setError] = useState<ErrorEvent>();

  const handleError = (err: ErrorEvent) => {
    setError(err);
  };

  const handleLoad = () => {
    setLoading(false);
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    document.body.appendChild(script);

    script.addEventListener('load', handleLoad);
    script.addEventListener('error', handleError);

    return () => {
      document.body.removeChild(script);
      script.removeEventListener('load', handleLoad);
      script.removeEventListener('error', handleError);
    };
  }, [url]);

  return { scriptLoading, error };
};
