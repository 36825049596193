import { useMemo } from 'react';

import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Common } from '@thecvlb/design-system/lib/src';
import ExclamationSvg from 'assets/icons/symbols/exclamation.svg?react';
import * as animationData from 'assets/lotties/airplane.json';
import Alert from 'components/common/Alert';
import BasicTable from 'components/tables/BasicTable';
import { BenefitTableProps } from 'components/tables/BenefitsTable/benefitsTable.types';
import { columns } from 'components/tables/BenefitsTable/columns';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { PathName } from 'enums/pathName';
import { useGetDefaultBasicTableValues } from 'hooks/common/useGetDefaultBasicTableValues';
import Lottie from 'react-lottie-player/dist/LottiePlayerLight';
import { Link } from 'react-router-dom';

import {
  benefitsAreLatest,
  benefitsMethodIsElation,
  generalErrorObject,
  getLastRequestDate,
  getTimeDifference,
  transformBenefitCopay,
} from './benefits.settings';
import { BenefitsProps } from './benefits.types';

const iconClassName = 'mx-auto text-primary-100 md:h-[140px] md:w-[140px]';

const Benefits: React.FC<BenefitsProps> = ({ data, isLoading, mostRecentDate, inOrderPanel }) => {
  const lastTPSBenefitsRequest = getLastRequestDate(data);
  const benefitsSoonerThanLastRequest =
    mostRecentDate && lastTPSBenefitsRequest ? benefitsAreLatest(mostRecentDate, lastTPSBenefitsRequest) : false;
  const isElationBenefits = benefitsMethodIsElation(data);
  const benefits = useMemo(
    () => (data?.benefits && data?.benefits.length > 0 ? transformBenefitCopay(data?.benefits) : data?.benefits),
    [data?.benefits],
  );
  const generalError = benefits && benefits.length == 1 ? generalErrorObject(benefits[0].outcome) : false;
  const requestSentMoreThanADay = mostRecentDate && getTimeDifference(mostRecentDate);
  const isToday = typeof lastTPSBenefitsRequest === 'string' ? dayjs(lastTPSBenefitsRequest).isToday() : false;
  const isNotFirstRequest = mostRecentDate && mostRecentDate.length;

  const insuranceData = data?.insurance;
  const requestDates =
    data?.tpsSubmitBenefitsInvestigationDates && data?.tpsSubmitBenefitsInvestigationDates.length > 0;
  const hasRequiredInsuranceFields = insuranceData?.memberId && insuranceData?.rxBIN;

  const hasMissing =
    benefits &&
    benefits.some((benefit) => {
      if (benefit?.outcome) {
        return benefit?.outcome && benefit.outcome.toLowerCase().includes('missing');
      }
    });

  const nonMatch = benefits && benefits.some((benefit) => benefit.insurance_name?.toLowerCase().includes('m/i group'));

  const [tableData, tableColumns] = useGetDefaultBasicTableValues({
    isFetching: false,
    columns,
    data: benefits,
  });

  const table = useReactTable({
    data: tableData,
    columns: tableColumns as ColumnDef<BenefitTableProps, unknown>[],
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      {benefits && benefits.length > 0 ? (
        <>
          {/* Covers all cases where outcome states there was missing information */}
          {isElationBenefits && generalError ? (
            <div className="flex h-full flex-col items-center">
              <div className="mr-[40px] mt-[20px] max-w-[540px]">
                <ExclamationSvg className={iconClassName} />
                <p className="py-1.5 pb-2 text-base font-medium">
                  Patients' benefit check was missing information so the request failed.
                </p>
                <p className="py-1.5 text-base font-medium">
                  <strong>Reason from Elation</strong>: {generalError.rejectReason}
                </p>
                {generalError.followUp && (
                  <p className="py-1.5 text-base font-medium">
                    <strong>Recommended follow-up</strong>: {generalError.followUp}
                  </p>
                )}
              </div>
            </div>
          ) : hasMissing ? (
            <div className="flex h-full flex-col items-center">
              <div className="items-center">
                <div className="my-4 flex flex-col items-center text-center">
                  {mostRecentDate && isNotFirstRequest ? (
                    <div className="max-w-[500px] text-base font-medium">
                      <ExclamationSvg className={iconClassName} />
                      The last <strong>completed</strong> benefit check was missing some information.
                      {nonMatch ? (
                        <p className="py-1.5 text-base font-medium">
                          <strong>Reason from TPS</strong>: The Member ID and Group ID did not match
                        </p>
                      ) : (
                        <p className="max-w-[500px] py-1.5 text-base font-medium">
                          Please review the patients' insurance and address information for possible missing or
                          incorrectly entered fields.
                        </p>
                      )}
                      {lastTPSBenefitsRequest && (
                        <>
                          <p className="pt-4 text-base font-medium">
                            The <strong>latest</strong> TPS request was sent out
                            <strong className="pl-1">
                              {isToday ? 'today at ' : 'on '}
                              {dayjs(lastTPSBenefitsRequest).format(DateFormat.MMM_DD_YYYY) +
                                ` at ` +
                                dayjs(lastTPSBenefitsRequest).format(DateFormat.h_mma_z)}
                            </strong>
                            {requestSentMoreThanADay ? (
                              <>, which is more then a day ago so you may want to send a new request.</>
                            ) : (
                              <>
                                . Since that was less then a day ago, <strong>it may still be in progress.</strong>
                                <Alert
                                  type="info"
                                  containerClasses="mb-5 mt-5"
                                  children={
                                    <>
                                      <p className="text-left text-base font-medium">
                                        While a request is in progress, please do not update their insurance information
                                        (unless you know that it is incorrect) as that will replace the last request
                                        with a new request - restarting the request process.
                                      </p>
                                    </>
                                  }
                                />
                              </>
                            )}
                          </p>
                        </>
                      )}
                    </div>
                  ) : (
                    <div className="max-w-[540px]">
                      <ExclamationSvg className={iconClassName} />
                      <p className="py-1.5 pb-2 text-base font-medium">
                        {' '}
                        Patients' first benefit check was missing information so the request failed.
                      </p>
                      {nonMatch ? (
                        <p className="py-1.5 text-base font-medium">
                          <strong>Reason from TPS</strong>: The Member ID and Group ID did not match
                        </p>
                      ) : (
                        <p className="max-w-[500px] py-1.5 text-base font-medium">
                          <strong>Reason from TPS</strong>: Missing information
                          <br />
                          Please review the patients' insurance and address information for possible missing or
                          incorrectly entered fields.
                        </p>
                      )}
                    </div>
                  )}
                </div>
                {inOrderPanel && (
                  <>
                    <p className="mt-2 text-center text-base font-medium">
                      To update this patients' benefits, please update their insurance on their{' '}
                      <Link
                        className="mt-4 text-center text-base font-medium underline"
                        to={`${PathName.Patient}/${data?.userId}?active-tab=Insurance`}
                      >
                        patient page.
                      </Link>
                    </p>
                    <Alert
                      type="info"
                      containerClasses="mt-4 mb-5"
                      children={
                        <p className="text-base font-medium">
                          The insurance form on this panel is particular to the insurance used on the order and doesn’t
                          run a benefits check.
                        </p>
                      }
                    />
                  </>
                )}
              </div>
            </div>
          ) : (
            <div className="flex h-full flex-col">
              {lastTPSBenefitsRequest && (
                <Alert
                  type="info"
                  containerClasses="text-base my-2"
                  children={
                    <p className="text-base">
                      The last <strong>TPS</strong> benefits request was sent
                      <strong className="pl-1">
                        {isToday ? 'today at ' : 'on '}
                        {dayjs(lastTPSBenefitsRequest).format(DateFormat.MMM_DD_YYYY) +
                          ` at ` +
                          dayjs(lastTPSBenefitsRequest).tz(dayjs.tz.guess()).format(DateFormat.h_mma_z)}
                      </strong>
                    </p>
                  }
                />
              )}
              <p className="my-4 justify-center text-base font-medium">
                {benefits[0]?.insurance_name} &#x2022;{' '}
                {benefitsSoonerThanLastRequest ? (
                  <>
                    {mostRecentDate
                      ? `Benefits from ` +
                        dayjs(mostRecentDate).format(DateFormat.MMM_DD_YYYY) +
                        ` at ` +
                        dayjs(mostRecentDate).tz(dayjs.tz.guess()).format(DateFormat.h_mma_z)
                      : ''}
                  </>
                ) : (
                  <>
                    Benefits below are from
                    <strong>
                      {mostRecentDate &&
                        ` ${
                          dayjs(mostRecentDate).format(DateFormat.MMM_DD_YYYY) +
                          ` at ` +
                          dayjs(mostRecentDate).tz(dayjs.tz.guess()).format(DateFormat.h_mma_z)
                        }`}
                    </strong>
                  </>
                )}
              </p>
              <BasicTable table={table} totalCount={0} isFetching={isLoading} />
            </div>
          )}
        </>
      ) : (
        // benefits.length = 0 or undefined, check array past tps requests
        <div className="flex h-full items-center justify-center">
          {hasRequiredInsuranceFields && requestDates ? (
            <div className="mt-[-40px] flex flex-col items-center">
              <Lottie animationData={animationData} loop play style={{ height: '170px', width: '300px' }} />
              <p className="mt-[-16px] pb-1">Request sent for TPS patient benefits </p>
              {lastTPSBenefitsRequest && (
                <p>
                  on
                  <strong className="pl-1">
                    {dayjs(lastTPSBenefitsRequest ?? '').format(DateFormat.dddd_MMMM_DD_YYYY)} at{' '}
                    {dayjs(lastTPSBenefitsRequest).tz(dayjs.tz.guess()).format(DateFormat.h_mma_z)}
                  </strong>
                </p>
              )}
              <p className="pb-12 pt-1">Awaiting results arrival</p>
            </div>
          ) : (
            <>
              {inOrderPanel ? (
                <div>
                  <Common.Icon name="lifemd" className="m-auto size-8" />
                  <p className="mt-4 text-center text-base font-medium">
                    This patient hasn’t had a benefits check run. To see their benefits, please update the insurance on
                    their{' '}
                    <Link
                      className="mt-4 text-center text-base font-medium underline"
                      to={`${PathName.Patient}/${data?.userId}?active-tab=Insurance`}
                    >
                      patient page.
                    </Link>
                  </p>
                  <Alert
                    type="info"
                    containerClasses="mt-4 mb-5"
                    children={
                      <p className="text-base font-medium">
                        The insurance form on this panel is particular to the insurance used on the order and doesn’t
                        run a benefits check.
                      </p>
                    }
                  />
                </div>
              ) : (
                <div className="m-auto">
                  <Common.Icon name="lifemd" className="m-auto size-8" />
                  <p className="mt-4 text-center text-base font-medium">
                    Enter insurance information to run a benefits check
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Benefits;
