import { useState } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import {
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  Row,
  useReactTable,
} from '@tanstack/react-table';
import classNames from 'classnames';
import Card from 'components/common/Card';
import { usePhoneCall } from 'contexts/phoneCallContext/phoneCallContext';
import { Role } from 'enums/role';
import { useAppSelector } from 'hooks/redux';
import isNumber from 'lodash/isNumber';
import { onClickTaskRow, showAssignedToTaskPopup } from 'pages/Tasks/Tasks.settings';
import { useLocation, useSearchParams } from 'react-router-dom';
import { selectQueue } from 'store/queue/queueSlice';
import { QueueTasksProps } from 'store/queue/queueSlice.types';
import { selectUser } from 'store/user/userSlice';

import RefreshTasks from './RefreshTasks';
import { TasksTableColumnsProps, TasksTableProps } from './tasksTable.types';
import { getCellClasses, getQueueRowStyles, getRowStyles, getThClasses } from './taskTable.settings';
import Pagination from '../Pagination';
import SortingBanner from './SortingBanner';

const selectUserState = createSelector(selectUser, (user) => ({ userRole: user.userType.name, userId: user._id }));

const TasksTable: React.FC<TasksTableProps> = ({
  regularTasksData,
  regularTasksColumns,
  sortFields,
  showQueue,
  queueTableData,
  queueTableColumns,
  isFetching,
  totalCount,
  showingFrom,
  showingTo,
  handleOpenTask,
  showRefreshTasksButton,
  newTasksCount,
  handleRefreshTasks,
}) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const { isPhoneCallActive, setIsPhoneCallActive } = usePhoneCall();

  const getRegularTableRowClasses = (row: Row<TasksTableColumnsProps>, taskId: string) =>
    classNames('cursor-pointer hover:bg-gray-50', getRowStyles(row, searchParams.get('taskModalOpenID') === taskId));

  const getQueueRowClasses = (row: Row<QueueTasksProps>, queueTaskId?: string) =>
    classNames(
      'hover:bg-gray-50',
      getQueueRowStyles(true, row.getCanExpand(), searchParams.get('taskModalOpenID') === queueTaskId),
    );

  const { userRole, userId } = useAppSelector(selectUserState);
  const { loading } = useAppSelector(selectQueue);

  const table = useReactTable({
    data: regularTasksData,
    columns: regularTasksColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  const queueTable = useReactTable({
    data: queueTableData,
    columns: queueTableColumns,
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  const handleClickRegularTask = (row: Row<QueueTasksProps | TasksTableColumnsProps>) => {
    onClickTaskRow(row, location, setSearchParams, showAssignedToTaskPopup(row, userId) ? handleOpenTask : null);

    // NOTE: some logic is build upon the value of isPhoneCallActive
    // initial value is 'null' to avoid unnecessary re-renders
    if (isPhoneCallActive === null) {
      setIsPhoneCallActive(false);
    }
  };

  const handleClickQueueTask = (row: Row<QueueTasksProps | TasksTableColumnsProps>) => {
    userRole !== Role.AD && userRole !== Role.MA
      ? handleOpenTask(row.original as QueueTasksProps)
      : onClickTaskRow(row, location, setSearchParams, showAssignedToTaskPopup(row, userId) ? handleOpenTask : null);
  };

  const showTable = table.getRowModel().rows.length || (showQueue && queueTable.getRowModel().rows.length);
  const showPagination = isNumber(totalCount) && totalCount > 0;
  const showSortingBanner = sortFields.length > 1;
  const colSpan = table
    .getHeaderGroups()
    .map((headerGroup) => headerGroup.headers.length)
    .reduce((a, b) => a + b, 0);

  return (
    <Card>
      <div data-testid="table_container" className="relative h-4/5 min-h-fit w-full max-w-full">
        {showTable ? (
          <table data-testid="data_table" className="w-full rounded-lg">
            <thead className="sticky top-24 z-10">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} data-testid="heading_summary_row">
                  {headerGroup.headers.map((header) => {
                    return (
                      <th key={header.id} className={getThClasses(header.column.id, sortFields?.[0])}>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </th>
                    );
                  })}
                </tr>
              ))}
              {showRefreshTasksButton && <RefreshTasks amount={newTasksCount} handleOnClick={handleRefreshTasks} />}
            </thead>
            <tbody className="max-w-full bg-white">
              {showSortingBanner && (
                <tr>
                  <td colSpan={colSpan} className="text-center">
                    <SortingBanner sortFields={sortFields} />
                  </td>
                </tr>
              )}
              {/* Render queue tasks */}
              {showQueue
                ? queueTable.getRowModel().rows.map((row) => {
                    const queueTaskData = row.original;
                    return (
                      <tr
                        data-testid="queue_row"
                        className={getQueueRowClasses(row as Row<QueueTasksProps>, queueTaskData._id)}
                        key={row.id}
                        onClick={() => handleClickQueueTask(row as Row<QueueTasksProps | TasksTableColumnsProps>)}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td
                            key={cell.id}
                            data-testid="queue_td"
                            className={getCellClasses(Number(cell.column.getSize))}
                          >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        ))}
                      </tr>
                    );
                  })
                : null}
              {/* Render regular tasks */}
              {table.getRowModel().rows.map((row) => {
                const taskData: TasksTableColumnsProps = row.original as TasksTableColumnsProps;
                return (
                  <tr
                    key={row.id}
                    className={getRegularTableRowClasses(row as Row<TasksTableColumnsProps>, taskData._id)}
                    onClick={() => handleClickRegularTask(row as Row<QueueTasksProps | TasksTableColumnsProps>)}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id} className={getCellClasses(cell.column.getSize())}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p className="my-2 p-4 text-center">
            {isFetching || loading ? `Loading${loading ? ' queue data' : ''}...` : 'No data available'}
          </p>
        )}
      </div>
      <div
        className={classNames('flex max-w-full items-center justify-between', {
          'p-4': (showPagination && !isFetching && !loading) || isFetching,
        })}
      >
        {showPagination && (
          <Pagination loading={isFetching} showingFrom={showingFrom} showingTo={showingTo} totalCount={totalCount} />
        )}
      </div>
    </Card>
  );
};

export default TasksTable;
