import { AUDIENCE_OPTIONS, CATEGORY_OPTION } from 'constants/article';
import { INIT_EDITOR } from 'constants/editor';
import { DEFAULT_SEARCH_PARAMS } from 'constants/tables';

import { useEffect, useState } from 'react';

import { Common } from '@thecvlb/design-system';
import { Editor } from '@tinymce/tinymce-react';
import classNames from 'classnames';
import Card from 'components/common/Card';
import Loader from 'components/common/Loader';
import { notifyError, notifySuccess } from 'components/common/Toast/Toast';
import AutocompleteSelect from 'components/forms/controlled/AutocompleteSelect';
import { AutocompleteOption } from 'components/forms/controlled/AutocompleteSelect/autocompleteSelect.types';
import ControlledMultiSelect from 'components/forms/controlled/ControlledMultiSelect';
import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import ControlledTextArea from 'components/forms/controlled/ControlledTextArea';
import InputField from 'components/forms/controlled/InputField';
import { HelperTextForTextArea } from 'components/modals/Automation/AutomationForm/automationForm.settings';
import { PathName } from 'enums/pathName';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useAddArticleImageMutation,
  useAddArticleMutation,
  useDeleteArticleMutation,
  useLazyGetArticleQuery,
  useUpdateArticleMutation,
} from 'store/articles/articlesSlice';
import { useLazyGetStaffsQuery } from 'store/staffs/staffsSlice';
import { validation } from 'utils/helpers';

import { CreateArticleProps, InputsTypes } from './CreateArticle.types';

const CreateArticle: React.FC<CreateArticleProps> = () => {
  const { handleSubmit, control, formState, reset, setValue, watch } = useForm<InputsTypes>({
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    mode: 'onChange',
  });
  const navigate = useNavigate();
  const { articleId = '' } = useParams<{ articleId: string }>();
  const [getArticle, { data, isLoading }] = useLazyGetArticleQuery();
  const [addArticleImage] = useAddArticleImageMutation();
  const [getStaffs, { data: staffsData, isLoading: isLoadingGetStaffs, isFetching: isFetchingGetStaffs }] =
    useLazyGetStaffsQuery();
  const [addArticle] = useAddArticleMutation();
  const [changeArticle] = useUpdateArticleMutation();
  const [deleteArticle] = useDeleteArticleMutation();

  const [authorOptions, setAuthorOptions] = useState<AutocompleteOption[]>([]);

  const defaultValue = articleId && data?.author ? data.author.name : undefined;

  const handleDeleteArticle = () => {
    if (articleId)
      deleteArticle({ id: articleId })
        .unwrap()
        .then((res) => {
          navigate({ pathname: PathName.Articles, search: DEFAULT_SEARCH_PARAMS });
          notifySuccess(res.message);
        })
        .catch((error) => notifyError(error.message));
  };
  const handleAddArticle = (editData: InputsTypes) => {
    if (editData) {
      const body = {
        title: editData.title,
        description: editData.description,
        category: editData.category.value,
        authorId: editData.author?.value,
        audience: editData.audience.map((audience) => audience.label),
        status: editData.status,
        content: editData.content,
      };
      if (articleId) {
        changeArticle({ id: articleId, body })
          .unwrap()
          .then((result) => notifySuccess(result.message));
      } else {
        addArticle({ body })
          .unwrap()
          .then((result) => {
            navigate({ pathname: PathName.Articles, search: DEFAULT_SEARCH_PARAMS });
            notifySuccess(result.message);
          });
      }
    }
  };

  const handleClickSave = (status: string) => {
    setValue('status', status);
  };

  useEffect(() => {
    if (data) {
      setAuthorOptions([{ label: data.author.name, value: data.author.userId, id: data.author.userId }]);
    }
  }, [data]);

  useEffect(() => {
    if (articleId) getArticle({ id: articleId }, true);
  }, [articleId, getArticle]);

  useEffect(() => {
    if (data) {
      reset({
        category: { label: data.category, value: data.category },
        audience: data.audience.map((audience) => ({ label: audience.name, value: audience.shortCode })),
        author: { label: data.author.name, value: data.author.userId },
        title: data.title,
        description: data.description,
        content: data.content,
      });
    }
  }, [data, reset]);

  useEffect(() => {
    if (staffsData?.staffs) {
      setAuthorOptions(
        staffsData?.staffs?.map(
          (staff): AutocompleteOption => ({ label: staff.name, value: staff._id, id: staff._id }),
        ),
      );
    }
  }, [staffsData]);

  return (
    <>
      <Loader isVisible={isLoading} />
      <Card dataTestId="edit_create_article_page" className="h-auto px-8 py-6">
        <form onSubmit={handleSubmit(handleAddArticle)} className="flex flex-col gap-3">
          <InputField
            dataTestId="title_field"
            name="title"
            placeholder="Enter the title"
            label="Title:"
            control={control}
            labelDirection="row"
            type="text"
            className="w-1/2"
            errors={formState.errors.title}
            rules={validation('Title')}
          />
          <ControlledTextArea
            dataTestId="description_field"
            control={control}
            placeholder="Description"
            className="w-1/2"
            label="Description:"
            labelDirection="row"
            rows={3}
            helper={<HelperTextForTextArea />}
            name="description"
            errors={formState.errors.description}
            rules={validation('Description')}
          />
          <ControlledSelect
            dataTestId="category_dropdown"
            control={control}
            labelDirection="row"
            options={CATEGORY_OPTION}
            placeholder="Select category..."
            label="Category:"
            name="category"
            className="w-1/2"
            rules={validation('Category')}
          />
          <AutocompleteSelect
            control={control}
            labelDirection="row"
            options={authorOptions}
            defaultValue={defaultValue}
            placeholder="Select author..."
            label="Author:"
            name="author"
            className="w-1/2"
            rules={validation('Author')}
            isLoading={isLoadingGetStaffs || isFetchingGetStaffs}
            getOptionsFunc={(value) => value && getStaffs({ params: { searchKey: value } })}
          />
          <div className="w-1/2">
            <ControlledMultiSelect
              dataTestId="audience_dropdown"
              control={control}
              label="Audience:"
              name="audience"
              placeholder="Select audience..."
              options={AUDIENCE_OPTIONS}
              labelDirection="row"
              rules={validation('Audience')}
            />
          </div>
          <div data-testid="article_body_area" className="mt-2 w-full">
            <Controller
              control={control}
              name="content"
              render={({ field: { onChange } }) => (
                <Editor
                  initialValue={data?.content ? data.content : ''}
                  init={{
                    ...INIT_EDITOR,
                    toolbar_mode: 'sliding',
                    tinycomments_author: watch('author')?.label,
                    paste_data_images: false,
                    images_upload_handler: (blobInfo, progress) =>
                      new Promise(async (resolve, reject) => {
                        const file = new File([blobInfo.blob()], blobInfo.filename(), { type: 'image/png' });
                        if (blobInfo.base64() && !blobInfo.uri()) {
                          progress(0);
                          addArticleImage({ body: { articleImage: file } })
                            .unwrap()
                            .then(({ data: fileData }) => resolve(fileData.imageUrl))
                            .catch((error) => reject(error?.data?.message))
                            .finally(() => progress(100));
                        }
                      }),
                  }}
                  onEditorChange={onChange}
                />
              )}
            />
          </div>

          <div className={classNames('mt-8 flex items-center', data ? 'justify-between' : 'justify-end')}>
            {data && (
              <Common.Button onClick={handleDeleteArticle} preIcon="trash" color="red-alt" size="sm" type="button">
                Delete article
              </Common.Button>
            )}
            <div className="flex gap-5">
              <Common.Button onClick={() => handleClickSave('inactive')} color="white-alt" size="sm">
                Save draft
              </Common.Button>
              <Common.Button
                onClick={() => handleClickSave('active')}
                color="blue"
                size="sm"
                disabled={!formState.isValid}
              >
                Publish
              </Common.Button>
            </div>
          </div>
        </form>
      </Card>
    </>
  );
};

export default CreateArticle;
