import { AgingFiltersProps } from './agingFiltersForm.types';

export const defaultValues: AgingFiltersProps = {
  searchKey: '',
  name: '',
  taskType: { value: '', label: '' },
  criterionField: { value: '', label: '' },
  alertUnit: { value: '', label: '' },
  audience: { value: '', label: '' },
  triggerUnit: { label: '', value: '' },
  occurenceOrder: '',
  uniqueId: '',
  alertAmount: '',
  status: { value: '', label: '' },
};
