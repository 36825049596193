import { DataItemProps } from '@thecvlb/design-system/lib/src/common/Tabs/tabs.types';

import { DataItemValueProps } from './filterTabs.types';

export const getDefaultValues = (data: DataItemProps[], query: URLSearchParams, queryParam: string) =>
  data
    .filter((item) => {
      const { name } = item.value as DataItemValueProps;
      return query.get(queryParam)?.split(',').includes(name);
    })
    .reduce((a: string[], b) => [...a, b.label], []);

export const customButtonClassNames =
  'flex items-center gap-2 whitespace-nowrap text-sm px-4 py-1.5 rounded-full font-semibold';
