import { Outlet, RouteObject } from 'react-router-dom';
import { lazyWithRetries, SuspenseWrapper } from 'utils/common/lazy';

import ErrorPage from './ErrorPage/ErrorPage';
import { protectedRoutesConfig } from './protectedRoutes.settings';

const ProtectedRoute = lazyWithRetries(() => import('./ProtectedRoute'));
const Login = lazyWithRetries(() => import('../pages/Login'));

export const routerConfig: RouteObject[] = [
  { index: true, element: <SuspenseWrapper children={<Login />} />, errorElement: <ErrorPage /> },
  {
    path: '',
    element: (
      <SuspenseWrapper
        children={
          <ProtectedRoute>
            <Outlet />
          </ProtectedRoute>
        }
      />
    ),
    errorElement: <ErrorPage />,
    children: protectedRoutesConfig,
  },
];
