import {
  DEFAULT_APPOINTMENT_TYPE,
  DEFAULT_OPTAVIA_APPOINTMENT_TYPE,
  DEFAULT_WEIGHT_MANAGEMENT_ONBOARDING_TYPE,
} from 'constants/appointmentType';

import { useEffect, useState } from 'react';

import ControlledSelect from 'components/common/form/ControlledSelect';
import ControlledTextArea from 'components/common/form/ControlledTextArea';
import { useFormContext } from 'react-hook-form';
import { PatientAppointmentType } from 'store/crossSell/crossSell.types';
import { useLazyGetPatientAppointmentTypesQuery } from 'store/crossSell/crossSellSlice';

import { AppointmentTypeFormDataProps } from './appointmentType.types';

const AppointmentType: React.FC<{ isConfirmed: boolean; isWMPlan: boolean; isOptaviaPatient: boolean }> = ({
  isConfirmed,
  isWMPlan,
  isOptaviaPatient,
}) => {
  const { control, setValue, watch } = useFormContext<AppointmentTypeFormDataProps>();
  const [fetchPaginatedData] = useLazyGetPatientAppointmentTypesQuery();
  const [allApptTypes, setAllApptTypes] = useState<PatientAppointmentType[]>([]);

  useEffect(() => {
    const fetchAllData = async () => {
      let currentData: PatientAppointmentType[] = [];
      const pageLimit = 5;

      for (let pageNo = 0; pageNo < pageLimit; pageNo++) {
        const pageData = await fetchPaginatedData({ params: `limit=50&pageNo=${pageNo}&partner=Optavia` }).unwrap();

        if (!pageData || pageData.length === 0) {
          break;
        }

        currentData = [...currentData, ...pageData];
      }

      setAllApptTypes(currentData);
    };

    fetchAllData();
  }, [fetchPaginatedData]);

  const formattedAppointmentTypes =
    allApptTypes?.map((item) => ({
      label: item.displayName,
      value: item._id,
    })) ?? [];

  const appointmentTypes = formattedAppointmentTypes;

  const currentAppointmentType = watch('appointmentType');
  const showAppointmentDesc =
    currentAppointmentType?.label !== DEFAULT_WEIGHT_MANAGEMENT_ONBOARDING_TYPE &&
    currentAppointmentType?.label !== DEFAULT_OPTAVIA_APPOINTMENT_TYPE;

  useEffect(() => {
    if (!appointmentTypes?.length) return;
    const defaultItem = appointmentTypes.find((appointment) => {
      const appointmentType = isOptaviaPatient
        ? DEFAULT_OPTAVIA_APPOINTMENT_TYPE
        : isWMPlan
          ? DEFAULT_WEIGHT_MANAGEMENT_ONBOARDING_TYPE
          : DEFAULT_APPOINTMENT_TYPE;
      return appointment.label.toLowerCase().trim() === appointmentType.trim().toLowerCase();
    });

    if (defaultItem) {
      return setValue('appointmentType', defaultItem);
    }
  }, [isWMPlan, allApptTypes]);

  useEffect(() => {
    if (isWMPlan) {
      setValue('appointmentDesc', '');
    }
  }, [isWMPlan, setValue]);

  return (
    <>
      {isConfirmed && (
        <div data-testid="appointment_type_section" className="mt-4">
          <ControlledSelect
            control={control}
            options={appointmentTypes}
            rules={{
              required: {
                value: true,
                message: 'Appointment type is required',
              },
            }}
            label="Type"
            name="appointmentType"
            className="mb-4"
          />
          {showAppointmentDesc && (
            <ControlledTextArea
              control={control}
              name="appointmentDesc"
              label="Please describe the reason for your visit..."
              cols={10}
              rows={5}
            />
          )}
        </div>
      )}
    </>
  );
};

export default AppointmentType;
