import { styled } from 'styled-components';
import tw from 'twin.macro';

export const Wrapper = styled.div`
  .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
    ${tw`ml-6 mb-0`}
  }

  .fc-toolbar-chunk {
    ${tw`flex items-center`}
  }

  .fc-next-button.fc-button.fc-button-primary {
    ${tw`mx-4 outline-none`}
  }
  .fc-prev-button.fc-button.fc-button-primary {
    ${tw`mr-4`}
  }

  .fc-next-button.fc-button.fc-button-primary,
  .fc-prev-button.fc-button.fc-button-primary {
    ${tw`px-4 py-2 bg-white w-12 h-8 rounded-lg border border-gray-200 flex items-center justify-center`}
  }

  .fc-next-button.fc-button.fc-button-primary:focus,
  .fc-prev-button.fc-button.fc-button-primary:focus {
    ${tw`outline-none ring-2 ring-blue-500/50 ring-offset-2 ring-offset-blue-500/50`}
  }

  .fc-icon.fc-icon-chevron-right,
  .fc-icon.fc-icon-chevron-left {
    ${tw`text-gray-700 w-4 h-4 text-base`}
  }

  .fc-toolbar-title {
    ${tw`text-xl text-gray-700 font-bold min-w-[210px] text-center !ml-0`}
  }

  .fc-today-button.fc-button.fc-button-primary {
    ${tw`bg-transparent text-gray-700 border border-gray-200 rounded-lg px-4 py-2 h-8 text-sm font-bold capitalize`}
  }

  .fc-today-button.fc-button.fc-button-primary:focus {
    ${tw`outline-none ring-2 ring-blue-500/50 ring-offset-2 ring-offset-blue-500/50`}
  }

  .fc-today-button.fc-button.fc-button-primary:disabled {
    ${tw`text-gray bg-gray-200 px-6 md:px-5`}
  }

  /* Calendar date header */
  .fc-scrollgrid-section.fc-scrollgrid-section-header {
    ${tw`border border-gray-200`}
  }

  .fc-col-header-cell.fc-day {
    ${tw`border-x h-11 border-gray-100`}
  }

  .fc-scrollgrid-sync-inner {
    ${tw`mt-3`}
  }

  .fc-col-header-cell-cushion {
    ${tw`text-sm font-medium`}
  }

  /* Time Grid */
  .fc-timegrid-now-indicator-container {
    ${tw`text-sm font-medium text-gray`}
  }

  /* Borders */
  .fc-theme-standard td,
  .fc-theme-standard th {
    ${tw`border border-gray-100`}
  }

  .fc-theme-standard .fc-scrollgrid {
    ${tw`border border-gray-100`}
  }

  /* Slots */
  .fc-timegrid-slot.fc-timegrid-slot-label.fc-scrollgrid-shrink {
    ${tw`h-28 w-14`}
  }

  .fc-timegrid-slot-label-cushion.fc-scrollgrid-shrink-cushion {
    ${tw`text-sm font-medium text-gray`}
  }

  /*  */
  .fc-timegrid-now-indicator-container .fc-timegrid-now-indicator-line #now-indicator {
    display: none;
  }

  /* Current time Indicator */
  .fc-timegrid-now-indicator-container {
    overflow: visible;
  }

  /* Today Background-color */
  .fc .fc-timegrid-col.fc-day-today {
    background: none;
  }

  /* More link */
  .fc-timegrid-more-link {
    ${tw`shadow-none bg-transparent right-1 h-fit`}
  }
  .fc-timegrid-more-link-inner.fc-sticky {
    ${tw`-mt-5`}
  }

  .fc-popover {
    ${tw`rounded-xl p-6 pb-14 w-[360px]`}
  }
  .fc-popover .fc-popover-header {
    ${tw`bg-transparent mb-6`}
  }
  .fc-popover .fc-popover-body {
    ${tw`p-0`}
  }
  .fc-popover .fc-popover-body .fc-event-main {
    ${tw`mb-3`}
  }

  //TODO: Remove it when component will be change
  .fc-popover .fc-popover-body .fc-event-main div button {
    ${tw`h-8 items-baseline`}
  }

  .fc-popover .fc-popover-header .fc-popover-title {
    ${tw`text-xl text-gray-700 font-bold`}
  }
  .fc-popover .fc-popover-header .fc-popover-close {
    ${tw`absolute w-auto bottom-6 left-6 right-6 rounded-lg h-8 bg-gray-800 flex justify-center items-center opacity-100 bg-opacity-5`}
  }
  .fc-popover-close.fc-icon.fc-icon-x::before {
    content: 'Close';
    ${tw`text-gray-700 text-sm font-bold font-['Greycliff CF']`}
  }

  .fc-timegrid-event-harness-inset .fc-timegrid-event,
  .fc-timegrid-event.fc-event-mirror,
  .fc-timegrid-more-link {
    box-shadow: none;
  }

  .fc-timegrid-now-indicator-arrow {
    ${tw`border-0`}
  }
`;
