import { useState } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import Alert from 'components/common/Alert';
import Loader from 'components/common/Loader';
import { notifySuccess } from 'components/common/Toast/Toast';
import { useGetBenefitsQuery, useUpdateBenefitsMutation } from 'store/patients/patientsSlice';

import Benefits from './Benefits';
import { getDateOfBenefits } from './Benefits/benefits.settings';
import { InsuranceProps } from './insurance.types';
import InsuranceForm from './InsuranceForm';
import InsuranceInfo from './InsuranceInfo';

const Insurance: React.FC<InsuranceProps> = ({ patientId, planCode }) => {
  const { data, isFetching } = useGetBenefitsQuery({ patientId: patientId }, { refetchOnMountOrArgChange: true });
  const [updateBenefits, { isLoading }] = useUpdateBenefitsMutation();
  const isWeightManagement = planCode?.toLowerCase() === 'weight-management';
  const insuranceData = data?.insurance;
  const isInsurance = !!insuranceData;

  const [isUpdateInsurance, setIsUpdateInsurance] = useState(false);

  const showInsuranceForm = !insuranceData?.memberId || isUpdateInsurance;
  const showInsuranceInfo = insuranceData?.memberId && !isUpdateInsurance;

  const showUpdateBenefits = insuranceData?.memberId;

  const mostRecentDate = getDateOfBenefits(data);

  const handleUpdateBenefits = () => {
    updateBenefits({ patientId })
      .unwrap()
      .then(() => notifySuccess('Benefits updated successfully'));
  };

  return (
    <>
      <Loader isVisible={isFetching} />
      <div className="flex min-h-[240px]">
        <div className="w-[350px] border-r p-6">
          <h2 className="mb-2 font-bold text-gray-700">Insurance</h2>
          {!isFetching && !isInsurance && (
            <Alert
              type="info"
              containerClasses="mb-5"
              children={
                <p className="text-base font-medium">
                  {isWeightManagement
                    ? 'Patient has indicated they do not want to use insurance to pay for their prescriptions'
                    : 'Patient is not on a weight management plan'}
                </p>
              }
            />
          )}
          {!isFetching && showInsuranceForm && (
            <InsuranceForm
              insuranceInfo={insuranceData}
              closeForm={() => setIsUpdateInsurance(false)}
              patientId={patientId}
              hideCancelBtn={!isUpdateInsurance}
            />
          )}
          {showInsuranceInfo && (
            <InsuranceInfo insuranceInfo={insuranceData} onClick={() => setIsUpdateInsurance(true)} />
          )}
        </div>
        <div className="flex-1 p-6">
          <div className="flex items-center justify-between">
            <h2 className="mb-2 font-bold text-gray-700">Benefits</h2>
            {showUpdateBenefits && (
              <Common.Button color="blue" onClick={handleUpdateBenefits} isLoading={isLoading} disabled={isLoading}>
                Update benefits
              </Common.Button>
            )}
          </div>

          {!isFetching && <Benefits data={data} mostRecentDate={mostRecentDate} isLoading={isFetching} />}
        </div>
      </div>
    </>
  );
};

export default Insurance;
