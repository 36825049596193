import { ChangeEvent, Dispatch, MutableRefObject, SetStateAction } from 'react';

import { notifyError } from 'components/common/Toast/Toast';
import { validateAndConvertProfileImage } from 'utils/files';

export const handleClick = ({ containerRef }: { containerRef: MutableRefObject<HTMLInputElement | null> }) => {
  if (containerRef && containerRef.current) {
    containerRef.current.click();
  }
};

export const onUploadSuccess = ({
  file,
  setSelectedFile,
  handleLogoUpdate,
}: {
  file: File;
  setSelectedFile: Dispatch<SetStateAction<File | undefined>>;
  handleLogoUpdate: (file: File) => void;
}): void => {
  setSelectedFile(file);
  handleLogoUpdate(file);
};

export const onUploadError = (errorMessage: string) => notifyError(errorMessage);

export const handleAvatarChange = ({
  event,
  setSelectedFile,
  handleLogoUpdate,
}: {
  event: ChangeEvent<HTMLInputElement>;
  setSelectedFile: Dispatch<SetStateAction<File | undefined>>;
  handleLogoUpdate: (file: File) => void;
}) => {
  validateAndConvertProfileImage(
    event,
    (file) => onUploadSuccess({ file, setSelectedFile, handleLogoUpdate }),
    onUploadError,
  );
};
