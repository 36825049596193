import { forwardRef } from 'react';

import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import { sanitizeHTML, stripHTML } from 'utils/common/parseHTML';

import { TemplateItemProps } from './templateItem.types';

const TemplateItem = forwardRef<HTMLDivElement, TemplateItemProps>(
  ({ template, onTemplateListClick, isSelected }, ref) => {
    const sanitizedString = sanitizeHTML(template.message);

    const handleClick = () => onTemplateListClick(template._id);

    const itemClasses = classNames('group mb-2 flex cursor-pointer flex-col items-start px-3 py-2 hover:bg-blue-500', {
      'bg-blue-500 outline-none': isSelected,
    });
    const iconClasses = classNames('self-center text-gray-500 group-hover:text-white', {
      'text-white': isSelected,
    });
    const shortCodeClasses = classNames('text-sm font-bold text-primary group-hover:text-white', {
      'text-white': isSelected,
    });
    const templateMsgClasses = classNames(
      'max-w-[530px] truncate text-sm font-normal text-gray-700 group-hover:text-white',
      {
        'text-white': isSelected,
      },
    );

    return (
      <div ref={ref} key={template.shortCode} role="button" className={itemClasses} onClick={handleClick} tabIndex={0}>
        <div className="flex">
          <Common.Icon name="document" className={iconClasses} />
          <div className="ml-2">
            <p data-testid="template_shortcode" className={shortCodeClasses}>
              {template.shortCode}
            </p>
            <p data-testid="template_msg" className={templateMsgClasses}>
              {stripHTML(sanitizedString)}
            </p>
          </div>
        </div>
      </div>
    );
  },
);

export default TemplateItem;
