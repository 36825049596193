import { TASK_CATEGORIES_FILTER_POPUP } from 'constants/taskFilter';

import { TasksFiltersFormProps } from 'components/modals/TasksFilters/tasksFiltersForm.types';
import { Options } from 'components/patient/History/history.types';
import { TaskTags } from 'enums/taskCategories';
import { UseFormSetValue } from 'react-hook-form';

import { Category } from './taskCategories.types';

export const handleChangeTaskTags = (
  setValue: UseFormSetValue<TasksFiltersFormProps>,
  tags: Options[],
  categories: Category[],
  isSelectedAppointment: boolean,
) => {
  const name = 'taskTags';
  const NutritionCategory = TASK_CATEGORIES_FILTER_POPUP.find((category) => category.value === TaskTags.Nutrition);
  const WMCategory = TASK_CATEGORIES_FILTER_POPUP.find((category) => category.value === TaskTags.WM);

  // Check if 'Nutrition' or 'WM' tags are selected in the list of selected task tags
  const selectedWMTag = tags.some((item) => item.value === TaskTags.WM); //isSelectedWMTaskTag
  const selectedNutritionTag = tags.some((item) => item.value === TaskTags.Nutrition); //isSelectedNutritionTaskTag

  // Check if 'Nutrition' or 'WM' tags are selected in the list of selected categories
  const selectWMInCategory = categories.some((item) => item.value === TaskTags.WM); //isSelectedWM
  const selectNutritionInCategory = categories.some((item) => item.value === TaskTags.Nutrition); //isSelectedNutrition

  // If selected 'Appointment' need remove 'Nutrition' and 'WM' tags
  if (isSelectedAppointment) {
    return setValue(
      name,
      tags.filter((item) => item.value !== TaskTags.Nutrition && item.value !== TaskTags.WM),
    );
  }

  // If 'Nutrition' is selected in the list of categories but not selected in the list of task tags, then add it to the tags
  if (selectNutritionInCategory && !selectedNutritionTag) {
    NutritionCategory &&
      setValue(name, [...tags, { label: NutritionCategory?.label, value: NutritionCategory?.value }]);
  }

  // If 'Nutrition' is selected in the list of task tags but not selected in the list of categories, then remove it from the tags
  if (!selectNutritionInCategory && selectedNutritionTag) {
    setValue(
      name,
      tags.filter((item) => item.value !== TaskTags.Nutrition),
    );
  }

  // If 'WM' is selected in the list of categories but not selected in the list of task tags, then add it to the tags
  if (selectWMInCategory && !selectedWMTag) {
    WMCategory && setValue(name, [...tags, { label: WMCategory?.label, value: WMCategory?.value }]);
  }

  // If 'WM' is selected in the list of task tags but not selected in the list of categories, then remove it from the tags
  if (!selectWMInCategory && selectedWMTag) {
    setValue(
      name,
      tags.filter((item) => item.value !== TaskTags.WM),
    );
  }
};

export const handleUpdateCategory = (
  setValue: UseFormSetValue<TasksFiltersFormProps>,
  handleCategoryChange: (category: Category) => void,
  currentCategory: Category[],
  selectedCategory: string | string[],
) => {
  const name = 'category';
  const NutritionCategory = TASK_CATEGORIES_FILTER_POPUP.find((category) => category.value === TaskTags.Nutrition);
  const WMCategory = TASK_CATEGORIES_FILTER_POPUP.find((category) => category.value === TaskTags.WM);

  // Check if 'Nutrition' tag is selected in the state or in the list of selected categories
  const isSelectedNutrition = currentCategory.some((item) => item.value === TaskTags.Nutrition);
  const isSelectedNutritionInSelect = selectedCategory.includes(TaskTags.Nutrition);

  // Check if 'WM' tag is selected in the state or in the list of selected categories
  const isSelectedWM = currentCategory.some((item) => item.value === TaskTags.WM);
  const isSelectedWMInSelect = selectedCategory.includes(TaskTags.WM);

  // Check if 'Appointment' tag is selected in the list of selected categories
  const isSelectedAppointment = selectedCategory.includes('Appointment');

  /**
   * This part is only required for the first load, for correct recording of all categories
   */
  // If 'Nutrition' tag is selected in the state but not selected in the list of selected categories. We add 'Nutrition' tag to the categories
  if ((!selectedCategory || selectedCategory.length === 0 || isSelectedAppointment) && isSelectedNutrition) {
    if (typeof selectedCategory === 'string') {
      return setValue(name, [TaskTags.Nutrition]);
    }

    if (selectedCategory && Array.isArray(selectedCategory)) {
      const newCategoryList = selectedCategory.filter((item) => item !== 'Appointment');
      return setValue(name, [...newCategoryList, TaskTags.Nutrition]);
    }
  }

  // If 'WM' tag is selected in the state but not selected in the list of selected categories. We add 'WM' tag to the categories
  if ((!selectedCategory || selectedCategory.length === 0 || isSelectedAppointment) && isSelectedWM) {
    if (typeof selectedCategory === 'string') {
      return setValue(name, [TaskTags.WM]);
    }
    if (selectedCategory && Array.isArray(selectedCategory)) {
      const newCategoryList = selectedCategory.filter((item) => item !== 'Appointment');
      return setValue(name, [...newCategoryList, TaskTags.WM]);
    }
  }

  /**
   * This part is responsibility for selecting the category after adding them in the 'Task Tags' select input
   */
  if ((isSelectedNutritionInSelect && !isSelectedNutrition) || (!isSelectedNutritionInSelect && isSelectedNutrition)) {
    NutritionCategory && handleCategoryChange(NutritionCategory);
  }
  if ((isSelectedWMInSelect && !isSelectedWM) || (!isSelectedWMInSelect && isSelectedWM)) {
    WMCategory && handleCategoryChange(WMCategory);
  }
};
