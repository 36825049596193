import HealthMetricsModal from 'components/modals/HealthMetrics';
import UpdateCholesterol from 'components/modals/HealthMetrics/Cholesterol/Cholesterol';
import { HealthMetricTypes } from 'enums/chart';
import { useAppDispatch } from 'hooks/redux';
import { openModal } from 'store/modal/modalSlice';

import { CholesterolProps } from './cholesterol.types';
import HealthMetric from '../../HealthMetric/HealthMetric';

const Cholesterol: React.FC<CholesterolProps> = ({ cholesterol }) => {
  const dispatch = useAppDispatch();

  const openCreateMetricModal = () => {
    dispatch(
      openModal({
        size: 'base',
        hideClose: true,
        modalContent: (
          <HealthMetricsModal type={HealthMetricTypes.Cholesterol}>
            <UpdateCholesterol />
          </HealthMetricsModal>
        ),
      }),
    );
  };

  const subtitle = cholesterol ? cholesterol.total.toString() : '';

  return (
    <HealthMetric
      type={HealthMetricTypes.Cholesterol}
      value={cholesterol?.total}
      metricSubtitle={subtitle}
      openMetricModal={openCreateMetricModal}
    />
  );
};

export default Cholesterol;
