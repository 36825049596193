import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store';

import { BulkEditState } from './bulkEdit.types';

const initialBulkEditState: BulkEditState = { bulkEdits: [] };

const bulkEditSlice = createSlice({
  name: 'bulkEdit',
  initialState: initialBulkEditState,
  reducers: {
    setBulkEdit: (state, action) => {
      state.bulkEdits.push(action.payload);
    },
    setAllBulkEdits: (state, action) => {
      state.bulkEdits = action.payload;
    },
    removeBulkEdit: (state, action) => {
      state.bulkEdits = state.bulkEdits.filter((item) => item !== action.payload);
    },
    resetBulkEdits: (state) => {
      state.bulkEdits = [];
    },
  },
});

export const selectBulkEdit = (state: RootState) => state.bulkEdit;

export const { resetBulkEdits, removeBulkEdit, setBulkEdit, setAllBulkEdits } = bulkEditSlice.actions;

export default bulkEditSlice.reducer;
