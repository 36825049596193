import { SubscriptionDetailsResponse } from 'store/patients/patients.types';

import { CancellationType } from '../cancelPlan.types';

export interface CancelPlanConfirmationProps {
  cancellationType?: CancellationType;
  subscriptionDetails?: SubscriptionDetailsResponse;
}
export enum AmountToRefundType {
  FullPayment = 'Full payment',
  CustomAmount = 'Custom amount',
}
