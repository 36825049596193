import Skeleton from 'react-loading-skeleton';

const DocumentSkeleton = () => {
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-3 p-4">
        <Skeleton containerClassName="h-[42px] w-[42px] rounded-lg" className="h-full" />
        <div className="flex flex-col">
          <Skeleton containerClassName="w-[200px]" />
          <Skeleton containerClassName="w-[100px]" />
        </div>
      </div>
      <div className="flex gap-3 pr-4">
        <Skeleton containerClassName="h-[32px] w-[100px]" className="h-full" />
        <Skeleton containerClassName="h-[32px] w-[100px]" className="h-full" />
      </div>
    </div>
  );
};

export default DocumentSkeleton;
