import { useController } from 'react-hook-form';

import { ControlledPhoneInputProps } from './controlledPhoneInput.types';
import MaskInput from '../maskInput';
import { formatPhone } from '../PhoneInput/phoneInput.settings';

const ControlledPhoneInput: React.FC<ControlledPhoneInputProps> = ({ control, rules, className }) => {
  const { field, formState } = useController({
    name: 'phone',
    control,
    rules,
  });

  return (
    <MaskInput
      {...field}
      isSubmitted={formState.isSubmitted}
      value={formatPhone(field.value)}
      onValueChange={(v) => field.onChange(v.formattedValue)}
      placeholder="Phone"
      format="+# ### ### ####"
      errors={formState.errors}
      isError={!!formState.errors?.phone}
      className={className}
      type="tel"
    />
  );
};

export default ControlledPhoneInput;
