export const EventCategoryFieldNames = {
  FILTERS: 'categoryFilters',
  EXCLUSIONS: 'categoryExclusions',
} as const;

export type CategoryFiltersProps = {
  name: EventCategoryNameType;
};

export type EventCategoryNameType = typeof EventCategoryFieldNames.FILTERS | typeof EventCategoryFieldNames.EXCLUSIONS;

export type CategoryFilterOption = {
  value: string;
  label: string;
  bgColor: string;
  textColor: string;
};
