import { useEffect } from 'react';

import RadioButtonCard from 'components/crossSell/RadioButtonCard';

import { getCancellationTypes } from './cancelPlanType.settings';
import { CancelPlanTypeProps } from './cancelPlanType.types';
import { CancellationType } from '../cancelPlan.types';

const CancelPlanType: React.FC<CancelPlanTypeProps> = ({
  isPatientInactive,
  cancellationType,
  setCancelationType,
  subscriptionDetails,
}) => {
  const cancellationTypes = subscriptionDetails && getCancellationTypes(isPatientInactive, subscriptionDetails);

  useEffect(() => {
    if (!cancellationType && subscriptionDetails) {
      if (!subscriptionDetails.cancelSubscriptionAutoRenewComplete) {
        setCancelationType(CancellationType.CancelAutoRenew);
      } else if (!isPatientInactive) {
        setCancelationType(CancellationType.CancelAccountAndRefundPatient);
      }
    }
  }, [cancellationType, isPatientInactive, setCancelationType, subscriptionDetails]);

  return (
    <div className="flex flex-col gap-3">
      <p className="text-base font-bold">When should this change take effect?</p>
      {cancellationTypes?.map((type) => (
        <RadioButtonCard
          {...type}
          color="blue"
          key={type.value}
          isSelected={type.value === cancellationType && !type.disabled}
          handleChange={() => setCancelationType(type.value)}
        />
      ))}
    </div>
  );
};

export default CancelPlanType;
