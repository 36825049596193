import AgingFiltersForm from 'components/modals/AgingFilters';
import AppointmentTypesFiltersForm from 'components/modals/AppointmentTypesFiltersForm';
import ArticlesFiltersForm from 'components/modals/ArticlesFilters';
import BillingConfigurationFilter from 'components/modals/BillingConfigurationFilter';
import CustomerFeedback from 'components/modals/CustomerFeedbackFilters';
import FrontDeskRequestTypesFiltersForm from 'components/modals/FrontDeskRequestTypesFiltersForm';
import HistoryForm from 'components/modals/HistoryFilter';
import InternalAutomationsFiltersForm from 'components/modals/InternalAutomationsFilters';
import InternalNoteTemplatesFiltersForm from 'components/modals/InternalNoteTemplatesFiltersForm';
import NotificationsFiltersForm from 'components/modals/NotificationsFiltersForm';
import OrdersFiltersForm from 'components/modals/OrdersFilters';
import PatientAutomationsFiltersForm from 'components/modals/PatientAutomationsFiltersForm';
import PatientFiltersForm from 'components/modals/PatientFilters';
import PatientMessageTemplatesFilterForm from 'components/modals/PatientMessageTemplatesFilterForm';
import ProspectFiltersForm from 'components/modals/ProspectFilterForm';
import RequestMessageTemplatesFilterForm from 'components/modals/RequestMessageTemplatesFiltersForms';
import ReviewsFiltersForm from 'components/modals/ReviewsFilters';
import RolesFiltersForm from 'components/modals/RolesFiltersForm';
import StaffCostsFiltersForm from 'components/modals/StaffCostsFiltersForm';
import StaffFeedbackFiltersForm from 'components/modals/StaffFeedbackFiltersForm';
import StaffsFiltersForm from 'components/modals/StaffsFiltersForm';
import TasksFiltersForm from 'components/modals/TasksFilters';
import { PathName } from 'enums/pathName';

const userRenderFilters = (pathName: string, search?: string) => {
  if (pathName.includes(PathName.Articles)) {
    return <ArticlesFiltersForm />;
  }
  if (pathName.includes(PathName.RequestMessageTemplates)) {
    return <RequestMessageTemplatesFilterForm />;
  }
  if (pathName.includes(PathName.PatientMessageTemplates)) {
    return <PatientMessageTemplatesFilterForm />;
  }
  if (pathName.includes(PathName.Prospect)) {
    return <ProspectFiltersForm />;
  }
  if (pathName.includes(PathName.PatientAutomations)) {
    return <PatientAutomationsFiltersForm />;
  }
  if (pathName.includes(PathName.Patients)) {
    return <PatientFiltersForm />;
  }
  if (pathName.includes(PathName.Orders)) {
    return <OrdersFiltersForm />;
  }
  if (pathName.includes(PathName.Tasks)) {
    return <TasksFiltersForm />;
  }
  if (pathName.includes(PathName.AgingConfiguration)) {
    return <AgingFiltersForm />;
  }
  if (pathName.includes(PathName.AppointmentTypes)) {
    return <AppointmentTypesFiltersForm />;
  }
  if (pathName.includes(PathName.InternalAutomations)) {
    return <InternalAutomationsFiltersForm />;
  }
  if (pathName.includes(PathName.ManageRoles)) {
    return <RolesFiltersForm />;
  }
  if (pathName.includes(PathName.Staffs)) {
    return <StaffsFiltersForm />;
  }
  if (pathName.includes(PathName.StaffCosts)) {
    return <StaffCostsFiltersForm />;
  }
  if (pathName.includes(PathName.BillingConfiguration)) {
    return <BillingConfigurationFilter />;
  }
  if (pathName.includes(PathName.FrontDeskRequestTypes)) {
    return <FrontDeskRequestTypesFiltersForm />;
  }
  if (pathName.includes(PathName.InternalNoteTemplates)) {
    return <InternalNoteTemplatesFiltersForm />;
  }
  if (pathName.includes(PathName.Patient)) {
    const urlParams = new URLSearchParams(search);
    const activeTab = urlParams.get('active-tab');
    return activeTab === 'Tasks' ? <TasksFiltersForm isSinglePatient /> : <HistoryForm />;
  }
  if (pathName.includes(PathName.ActivityFeed)) {
    return <NotificationsFiltersForm />;
  }
  if (pathName.includes(PathName.Reviews)) {
    return <ReviewsFiltersForm />;
  }
  if (pathName.includes(PathName.StaffFeedback)) {
    return <StaffFeedbackFiltersForm />;
  }
  if (pathName.includes(PathName.CustomerFeedback)) {
    return <CustomerFeedback />;
  }
};

export { userRenderFilters };
