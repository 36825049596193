import { FieldValues, useController } from 'react-hook-form';

import { ControlledTextAreaProps } from './controlledTextArea.types';
import TextArea from '../TextArea';
const ControlledTextArea = <TFieldValues extends FieldValues>({
  control,
  name,
  rules,
  label,
  cols,
  rows,
}: ControlledTextAreaProps<TFieldValues>) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  return (
    <TextArea
      {...field}
      name={name}
      value={field.value}
      label={label}
      helper={error?.message}
      errors={error}
      cols={cols}
      rows={rows}
    />
  );
};

export default ControlledTextArea;
