import { Role } from 'enums/role';
import { BenefitsStatus, MIFStatus, OnboardingStatus } from 'enums/taskDetailsStatus';

export const FUTURE_TASKS_FILTERS = [
  { label: 'Show all tasks', value: '0' },
  { label: 'Show tasks up to 1 day in the future', value: '1' },
  { label: 'Show tasks up to 3 days in the future', value: '3' },
  { label: 'Show tasks up to 7 days in the future', value: '7' },
  { label: 'Show tasks up to 14 days in the future', value: '14' },
  { label: 'Show tasks up to 30 days in the future', value: '30' },
];

export const ONBOARDING_STATUSES = [
  { label: 'Insurance complete', value: BenefitsStatus.BenefitsComplete },
  { label: 'Benefits incomplete', value: BenefitsStatus.BenefitsIncomplete },
  { label: 'Bad address', value: BenefitsStatus.IncorrectInsurance },
  { label: 'Missing insurance', value: BenefitsStatus.MissingInsurance },
  { label: 'MIF normal', value: MIFStatus.Normal },
  { label: 'MIF warnings', value: MIFStatus.Warnings },
];

export const ONBOARDING_STATUSES_EXTENDED = [
  { label: 'Only show problems', value: OnboardingStatus.TASK_ONLY_PROBLEMS, groupLabel: 'Onboarding' },
  {
    label: 'Onboarded by staff',
    options: [
      { label: 'True', value: OnboardingStatus.ON_BOARDED_BY_STAFF, groupLabel: 'Onboarded by staff' },
      { label: 'False', value: OnboardingStatus.NOT_ON_BOARDED_BY_STAFF, groupLabel: 'Onboarded by staff' },
    ],
  },
  {
    label: 'Address',
    options: [
      { label: 'Verified', value: OnboardingStatus.ADDRESS_VERIFIED, groupLabel: 'Address' },
      { label: 'Not verified', value: OnboardingStatus.ADDRESS_NOT_VERIFIED, groupLabel: 'Address' },
    ],
  },
  {
    label: 'Insurance',
    options: [
      { label: 'Complete • Covered', value: OnboardingStatus.INSURANCE_COVERED, groupLabel: 'Insurance' },
      { label: 'Complete • Not covered', value: OnboardingStatus.INSURANCE_NOT_COVERED, groupLabel: 'Insurance' },
      { label: 'Insurance not provided', value: OnboardingStatus.INSURANCE_NOT_PROVIDED, groupLabel: 'Insurance' },
      { label: 'Waiting for benefits', value: OnboardingStatus.INSURANCE_WAITING_BENEFITS, groupLabel: 'Insurance' },
      { label: 'PA required', value: OnboardingStatus.INSURANCE_PA_REQUIRED, groupLabel: 'Insurance' },
      { label: 'PA requested', value: OnboardingStatus.INSURANCE_PA_REQUESTED, groupLabel: 'Insurance' },
      {
        label: 'Incomplete',
        value: OnboardingStatus.INSURANCE_INCOMPLETE_OR_MISSING_INSURANCE,
        groupLabel: 'Insurance',
      },
      { label: 'Plan inactive', value: OnboardingStatus.INSURANCE_PLAN_INACTIVE, groupLabel: 'Insurance' },
    ],
  },
  {
    label: 'Medical intake form',
    options: [
      { label: 'Normal', value: OnboardingStatus.MIF_NORMAL, groupLabel: 'MIF' },
      { label: 'Warnings', value: OnboardingStatus.MIF_WARNING, groupLabel: 'MIF' },
      { label: 'Incomplete', value: OnboardingStatus.MIF_INCOMPLETE, groupLabel: 'MIF' },
    ],
  },
  {
    label: 'Identity',
    options: [
      { label: 'Verified', value: OnboardingStatus.IDENTITY_VERIFIED, groupLabel: 'Identity' },
      { label: 'Not verified', value: OnboardingStatus.IDENTITY_NOT_VERIFIED, groupLabel: 'Identity' },
    ],
  },
  {
    label: 'Symptoms',
    options: [
      { label: 'Complete', value: OnboardingStatus.SC_COMPLETE, groupLabel: 'Symptoms' },
      { label: 'Skipped', value: OnboardingStatus.SC_SKIPPED, groupLabel: 'Symptoms' },
      { label: 'Incomplete', value: OnboardingStatus.SC_INCOMPLETE, groupLabel: 'Symptoms' },
      { label: 'Urgent', value: OnboardingStatus.SC_URGENT, groupLabel: 'Symptoms' },
    ],
  },
];

// @Mike TODO: create enum for task categories
export const TASK_CATEGORIES_FILTER_POPUP = [
  {
    label: 'All',
    value: 'All',
    color: 'bg-gray-700',
    bgColor: 'bg-gray-200',
    roles: [Role.MA, Role.NP, Role.PH, Role.AD, Role.CS],
  },
  {
    label: 'Messages',
    value: 'Messages',
    color: 'bg-secondary-500',
    bgColor: 'bg-secondary-100',
    roles: [Role.MA, Role.NP, Role.PH, Role.AD, Role.CS],
  },
  {
    label: 'Onboarding',
    value: 'Onboarding',
    color: 'bg-orange-500',
    bgColor: 'bg-orange-100',
    roles: [Role.MA, Role.AD],
  },
  {
    label: 'Pre-consult',
    value: 'Pre-consult',
    color: 'bg-orange-500',
    bgColor: 'bg-orange-100',
    roles: [Role.AD, Role.MA],
  },
  {
    label: 'Appointment',
    value: 'Appointment',
    color: 'bg-yellow-500',
    bgColor: 'bg-yellow-100',
    roles: [Role.NP, Role.PH, Role.AD],
  },
  {
    label: 'Appointment • WM',
    value: 'weight-management',
    color: 'bg-yellow-500',
    bgColor: 'bg-yellow-100',
    roles: [Role.NP, Role.PH, Role.AD],
  },
  // TODO: The filter will be added in the next sprint
  /* {
    label: 'Appointment • Canceled',
    value: 'Appointment • Canceled',
    color: 'bg-yellow-500',
    bgColor: 'bg-yellow-100',
    roles: [Role.NP, Role.PH, Role.AD],
  },*/
  {
    label: 'Appointment • Nutrition',
    value: 'nutrition',
    color: 'bg-yellow-500',
    bgColor: 'bg-yellow-100',
    roles: [Role.NP, Role.PH, Role.AD],
  },
  {
    label: 'Queue',
    value: 'Queue',
    color: 'bg-orange-500',
    bgColor: 'bg-orange-100',
    roles: [Role.AD, Role.MA],
  },
  {
    label: 'Renewal',
    value: 'Renewal',
    color: 'bg-green-500',
    bgColor: 'bg-green-100',
    roles: [Role.NP, Role.PH, Role.AD, Role.MA, Role.CS],
  },
  {
    label: 'Async',
    value: 'Async',
    color: 'bg-warm-gray-500',
    bgColor: 'bg-warm-gray-100',
    roles: [Role.NP, Role.PH, Role.AD, Role.MA, Role.CS],
  },
  {
    label: 'Post-consult',
    value: 'Post-consult',
    color: 'bg-primary-700',
    bgColor: 'bg-primary-100',
    roles: [Role.NP, Role.PH, Role.AD],
  },
  {
    label: 'Review',
    value: 'Review',
    color: 'bg-blue-500',
    bgColor: 'bg-blue-100',
    roles: [Role.MA, Role.PH, Role.NP, Role.AD],
  },
  {
    label: 'Request • PA',
    value: 'Request • PA',
    color: 'bg-violet-500',
    bgColor: 'bg-violet-100',
    roles: [Role.MA, Role.PH, Role.NP, Role.AD, Role.CS],
  },
  {
    label: 'Requests',
    value: 'Requests',
    color: 'bg-violet-500',
    bgColor: 'bg-violet-100',
    roles: [Role.MA, Role.PH, Role.NP, Role.AD, Role.CS],
  },
  {
    label: 'Front desk',
    value: 'Front desk',
    color: 'bg-red-500',
    bgColor: 'bg-red-100',
    roles: [Role.CS, Role.AD, Role.MA, Role.PH],
  },
  {
    label: 'History',
    value: 'History',
    color: 'bg-gray-700',
    bgColor: 'bg-gray-200',
    roles: [Role.PH, Role.NP, Role.MA, Role.AD, Role.CS],
  },
];

export const SENT_REQUEST_FILTER_DATA = [
  {
    label: 'All',
    value: 'All',
    color: 'bg-gray-700',
    bgColor: 'bg-gray-200',
    roles: [Role.MA, Role.NP, Role.PH, Role.AD, Role.CS],
  },
];

export const AUDIENCE_OPTIONS = [
  { label: 'Physician', value: 'PH' },
  { label: 'Medical Assistant', value: 'MA' },
  { label: 'Admin', value: 'AD' },
  { label: 'Customer Service Rep', value: 'CS' },
];
