import PhysicianRequestTypes from 'components/modals/PhysicianRequestTypes';
import { PathName } from 'enums/pathName';

import ReviewsBulkEdit from '../bulkEditModals/ReviewsBulkEdit';
import StaffsBulkEdit from '../bulkEditModals/StaffsBulkEdit';

export const renderBulkEditContent = (pathName: string) => {
  if (pathName.includes(PathName.Reviews)) {
    return <ReviewsBulkEdit />;
  }
  if (pathName.includes(PathName.FrontDeskRequestTypes)) {
    return <PhysicianRequestTypes isBulkEdit />;
  }
  if (pathName.includes(PathName.Staffs)) {
    return <StaffsBulkEdit />;
  }
};

export const getModalSize = (pathName: string) => {
  if (pathName.includes(PathName.Reviews)) {
    return 'sm';
  }
  if (pathName.includes(PathName.FrontDeskRequestTypes)) {
    return 'lg';
  }
  if (pathName.includes(PathName.Staffs)) {
    return 'xl';
  }
  return 'base';
};

export const getHideClose = (pathName: string) => {
  if (pathName.includes(PathName.Reviews)) {
    return false;
  }
  return true;
};
