export const formatPhone = (value: string) => {
  let phone = value?.includes('+1') ? value.replace('+1', '') : value;
  if (phone && phone[0] !== '1') {
    phone = `1${phone}`;
  }
  return phone;
};

export const formatPhoneNumber = (number: string) => {
  if (number.length !== 10) {
    return number;
  }
  return `+1 ${number.slice(0, 3)} ${number.slice(3, 6)} ${number.slice(6)}`;
};
