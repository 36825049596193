const SettingsSkeleton = () => {
  return (
    <div className="flex bg-white p-6">
      <div className="mr-8 flex w-[240px] flex-none animate-pulse flex-col">
        <div className="my-4 h-5 w-20 rounded bg-slate-200" />
        <div className="h-4 w-40 rounded bg-slate-200" />

        <div className="mb-4 mt-12 h-5 w-28 rounded bg-slate-200" />
        <div className="h-10 rounded bg-slate-200" />

        <div className="my-4 h-5 w-36 rounded bg-slate-200" />
        <div className="h-4 w-48 rounded bg-slate-200" />

        <div className="my-4 mt-10 h-5 w-28 rounded bg-slate-200" />
        <div className="my-1 h-4 w-40 rounded bg-slate-200" />
      </div>
      <div className="flex w-full min-w-0 animate-pulse flex-col">
        <div className="my-4 h-5 w-20 rounded bg-slate-200" />

        <div className="mb-2 mt-4 h-16 w-1/2 rounded bg-slate-200" />
        <div className="h-2 w-1/3 rounded bg-slate-200" />
        <div className="my-2 h-3 w-24 rounded bg-slate-200" />

        <div className="my-4 h-5 w-20 rounded bg-slate-200" />
        <div className="my-1 h-4 w-1/2 rounded bg-slate-200" />
        <div className="h-4 w-1/2 rounded bg-slate-200" />
      </div>
    </div>
  );
};

export default SettingsSkeleton;
