import { ChangeEvent, useEffect, useRef, useState } from 'react';

import { Common } from '@thecvlb/design-system';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'react-use';

const SearchInput = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const inputRef = useRef<HTMLInputElement>(null);

  const [searchValue, setSearchValue] = useState<string>(searchParams.get('searchKey') || '');
  const [debouncedSearchKey, setDebouncedSearchKey] = useState<string>(searchValue);

  const [, cancel] = useDebounce(() => setDebouncedSearchKey(searchValue), 1000, [searchValue]);

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    // remove leading spaces
    const userInput = event.target.value.replace(/^\s+/g, '');

    setSearchValue(userInput);
  };

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();

    return () => {
      cancel();
    };
  }, [cancel]);

  useEffect(() => {
    if (debouncedSearchKey.length > 2) {
      // reset pagination
      searchParams.set('pageNo', '0');
      searchParams.set('searchKey', debouncedSearchKey.trim());

      setSearchParams(searchParams);
    }

    // @MikePrus TODO: add missing deps after fix reset filters functionality
  }, [debouncedSearchKey]);

  return (
    <div className="relative">
      {/*
        Add this input to fix autocomplete chrome issue
        read more about this issue: https://medium.com/paul-jaworski/turning-off-autocomplete-in-chrome-ee3ff8ef0908
      */}
      <input className="hidden" />

      <Common.Icon className="absolute left-2.5 top-2 size-4 text-gray" name="search" />
      <input
        name="search"
        data-testid="search_field"
        className="h-7 w-full rounded-full border-none py-4 pl-8 pr-3 text-sm outline-none ring-1 ring-gray-400 placeholder:text-sm focus:ring-2 focus:ring-blue"
        type="search"
        ref={inputRef}
        value={searchValue}
        onChange={changeHandler}
        placeholder="Search by name, date, or phone number..."
        autoComplete="off"
      />
    </div>
  );
};

export default SearchInput;
