import { FUTURE_TASKS_FILTERS, SENT_REQUEST_FILTER_DATA, TASK_CATEGORIES_FILTER_POPUP } from 'constants/taskFilter';

import { Options } from 'components/patient/History/history.types';
import { Role } from 'enums/role';
import { TaskTags } from 'enums/taskCategories';
import { UseFormSetValue } from 'react-hook-form';

import { TasksFiltersFormProps } from './tasksFiltersForm.types';

export const filtersByRoles = (userRole: string, isSentRequests?: boolean) =>
  (isSentRequests ? SENT_REQUEST_FILTER_DATA : TASK_CATEGORIES_FILTER_POPUP).filter((item) => {
    if (item.roles) {
      return item.roles.includes(userRole as Role);
    }
  });

export const defaultValues: TasksFiltersFormProps = {
  patientNames: [],
  searchKey: '',
  doctorName: { label: '', value: '', id: '' },
  dueDate: '',
  localTz: '',
  assignedToNames: [],
  category: [],
  startCreateDate: '',
  endCreateDate: '',
  createdAt: '',
  status: '',
  futureTasksRange: { value: '', label: '' },
  startDueDate: '',
  endDueDate: '',
  patientStatuses: '',
  dynamicStatus: '',
  onboardingStatuses: [],
  patientTags: [],
  taskTags: [],
  patientStates: [],
  escalated: false,
  audiences: [],
  teams: [],
};

export const getTasksFiltersValue = (
  key: string,
  val: string,
  getListPatientsName: () => { value: string; label: string }[],
) => {
  let currentValue;
  switch (key) {
    case 'patientNames':
      const list = getListPatientsName();
      if (!list.length) {
        break;
      }
      if (Array.isArray(val)) {
        currentValue = [];
        val.forEach((patientName) => {
          currentValue.push(list?.find((item) => item.value === patientName));
        });
      } else {
        currentValue = list?.find((item) => item.value === val);
      }
      break;
    case 'futureTasksRange':
      currentValue = FUTURE_TASKS_FILTERS.find((futureTaskItem) => futureTaskItem.value === val);
      break;
    default:
      currentValue = val;
  }
  return currentValue;
};

export const handleChangeCategory = (
  setValue: UseFormSetValue<TasksFiltersFormProps>,
  tags: Options[],
  categories: string | string[],
) => {
  const name = 'category';
  // Check if 'Nutrition' or 'WM' tags are selected in the list of selected task tags
  const selectedWMTag = tags?.some((item) => item.value === TaskTags.WM);
  const selectedNutritionTag = tags?.some((item) => item.value === TaskTags.Nutrition);

  // Check if 'Nutrition' or 'WM' tags are selected in the list of selected categories
  const selectWMInCategory = categories?.includes(TaskTags.WM);
  const selectNutritionInCategory = categories?.includes(TaskTags.Nutrition);

  // If 'WM' is selected in the list of categories but not selected in the list of task tags, then remove it from the categories
  if (!selectedWMTag && selectWMInCategory) {
    if (typeof categories === 'string') {
      setValue(name, '');
    }
    if (categories && Array.isArray(categories)) {
      setValue(name, categories?.filter((item) => item !== TaskTags.WM) || []);
    }
  }

  // If 'WM' is selected in the list of task tags but not selected in the list of categories, then add it to the categories
  if (selectedWMTag && !selectWMInCategory) {
    if (typeof categories === 'string') {
      setValue(name, [categories, TaskTags.WM]);
    }
    if (categories && Array.isArray(categories)) {
      setValue(name, [...categories, TaskTags.WM]);
    }
  }

  // If 'Nutrition' is selected in the list of categories but not selected in the list of task tags, then remove it from the categories
  if (!selectedNutritionTag && selectNutritionInCategory) {
    if (typeof categories === 'string') {
      setValue(name, '');
    }
    if (categories && Array.isArray(categories)) {
      setValue(name, categories?.filter((item) => item !== TaskTags.Nutrition) || []);
    }
  }

  // If 'Nutrition' is selected in the list of task tags but not selected in the list of categories, then add it to the categories
  if (selectedNutritionTag && !selectNutritionInCategory) {
    if (typeof categories === 'string') {
      setValue(name, [categories, TaskTags.Nutrition]);
    }
    if (categories && Array.isArray(categories)) {
      setValue(name, [...categories, TaskTags.Nutrition]);
    }
  }
};
