import { SEARCH_USER_TYPE } from 'constants/user';

import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';
import { useClickAway, useDebounce } from 'react-use';
import { GetUsersResponseProps } from 'store/lookup/lookup.types';
import { useLazyGetUsersQuery } from 'store/lookup/lookupSlice';

import { AutocompleteInputProps } from './autocompleteInput.types';
import Card from '../../Card';

const UNASSIGNED = 'Unassigned';

const AutocompleteInput: React.FC<AutocompleteInputProps> = ({
  name = '',
  placeholder,
  userType,
  label,
  wrapperClasses,
  setValue,
  isSearchInput,
  isDisabled,
  value = '',
  dataTestId,
  optionType = 'default',
  labelDirection = 'row',
  defaultValue = '',
}) => {
  const [getUsers, { data, isFetching }] = useLazyGetUsersQuery();

  const nodeRef = useRef(null);
  const [suggestions, setSuggestions] = useState<GetUsersResponseProps[] | undefined>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [inputValue, setInputValue] = useState(defaultValue);
  const [, cancel] = useDebounce(
    () => {
      if (inputValue) {
        getUsers({ userType, limit: 10, pageNo: 0, searchKey: inputValue.trim() ?? '' });
      } else {
        setSuggestions([]);
        setValue(name, inputValue);
      }
    },
    500,
    [inputValue],
  );
  useClickAway(nodeRef, () => {
    setShowSuggestions(false);
  });

  const labelClasses = classNames('min-w-[120px] font-semibold text-sm', isDisabled ? 'text-gray' : 'text-gray-700', {
    'pt-1.5': isSearchInput,
  });

  const inputClasses = classNames(
    'w-full outline-none ring-1 border-none rounded-md placeholder-gray text-gray-700 pr-8 py-[7.5px] text-sm ring-gray-400 shadow-sm disabled:ring-gray-200 disabled:bg-gray-200 focus:shadow-none focus:ring-2 focus:ring-blue',
    { 'pl-7': isSearchInput },
  );

  const handleChange = (event?: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event?.target.value ?? '');
    if (event?.target.value) setShowSuggestions(true);
  };

  const getUserLabel = (suggestion: GetUsersResponseProps) => {
    const result =
      suggestion.name && suggestion.name.toLowerCase().includes((inputValue || '').toLowerCase())
        ? suggestion.name
        : suggestion.displayName;
    return result ?? '';
  };

  const defineSearchValue = (suggestion: GetUsersResponseProps) => {
    return userType === SEARCH_USER_TYPE.physician || optionType === 'assignedTo'
      ? suggestion.displayName
      : suggestion.name;
  };

  const onClickOptionItem = (suggestion: GetUsersResponseProps) => {
    setInputValue(getUserLabel(suggestion));
    setValue(name, defineSearchValue(suggestion));
    setShowSuggestions(false);
  };

  const getDataList = useCallback(() => {
    return data?.reduce(
      (list: GetUsersResponseProps[], item) => (
        list.find((itemList) => item.name === itemList.name) || list.push(item), list
      ),
      [],
    );
  }, [data]);

  useEffect(() => {
    return () => cancel();
  }, [cancel]);

  useEffect(() => {
    if (data) {
      const filterData = getDataList();
      setSuggestions(filterData);
    }
  }, [data, getDataList]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <div
      data-testid={dataTestId}
      className={classNames(`flex gap-1 flex-${labelDirection}`, wrapperClasses)}
      ref={nodeRef}
    >
      {label && <label className={labelClasses}>{label}</label>}
      <div className="relative w-full">
        {isSearchInput && <Common.Icon name="search" className="absolute inset-y-0 left-2 my-auto size-4 text-gray" />}
        <input
          onChange={handleChange}
          onFocus={() => setShowSuggestions(!suggestions?.length)}
          name={name}
          placeholder={placeholder}
          type="search"
          disabled={isDisabled}
          autoComplete="off"
          value={inputValue}
          className={inputClasses}
        />
        <Card className="absolute inset-x-0 top-full z-20 max-h-20 w-full overflow-x-hidden overflow-y-scroll">
          {showSuggestions && isFetching ? (
            <span className="mx-auto p-2 text-sm">Searching...</span>
          ) : (
            showSuggestions && (
              <ul>
                {suggestions?.length ? (
                  suggestions?.map((suggestion) => {
                    return (
                      <li
                        data-testid="quick_search_item"
                        key={suggestion._id}
                        className="p-2 text-sm hover:bg-sky-200"
                        onClick={() => onClickOptionItem(suggestion)}
                      >
                        {getUserLabel(suggestion)}
                      </li>
                    );
                  })
                ) : optionType === 'assignedTo' ? (
                  <li
                    className="p-2 text-sm hover:bg-sky-200"
                    onClick={() => {
                      setInputValue(UNASSIGNED);
                      setValue(name, UNASSIGNED);
                      setShowSuggestions(false);
                    }}
                  >
                    {UNASSIGNED}
                  </li>
                ) : (
                  <li className="p-2 hover:bg-sky-200">No options</li>
                )}
              </ul>
            )
          )}
        </Card>
      </div>
    </div>
  );
};

export default AutocompleteInput;
