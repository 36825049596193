import { useCallback } from 'react';

import { Common } from '@thecvlb/design-system';
import { OptionProps } from '@thecvlb/design-system/lib/src/common';
import { FieldValues, useController } from 'react-hook-form';

import { ControlledSelectProps } from './controlledSelect.types';

const ControlledSelect = <TFieldValues extends FieldValues>({
  dataTestId,
  name,
  control,
  rules,
  label,
  options = [],
  helper,
  disabled,
  placeholder,
  labelDirection,
  onChange,
  value,
  type = 'text',
  size = 'sm',
  ...otherProps
}: ControlledSelectProps<TFieldValues>) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules });
  const onChangeValue = useCallback(
    (optionValue?: OptionProps) => {
      const isSelect = (field?.value as OptionProps)?.value === optionValue?.value;
      if (isSelect) {
        field.onChange();
      } else {
        field.onChange(optionValue);
        onChange && optionValue && onChange(optionValue);
      }
    },
    [field, onChange],
  );

  return (
    <Common.Select
      dataTestId={dataTestId}
      // dataTestId="select_input"
      {...otherProps}
      size={size}
      placeholder={placeholder}
      label={label}
      name={field.name}
      value={field.value}
      onChange={onChangeValue}
      options={options}
      helper={helper || error?.message}
      errors={error}
      disabled={disabled}
      labelDirection={labelDirection}
      type={type}
    />
  );
};

export default ControlledSelect;
