export const Skeleton = ({ isOpen }: { isOpen?: boolean }) => {
  return (
    <div className="animate-pulse">
      <div className="mb-2.5 h-2.5 rounded-full bg-white/30" />
      {isOpen && (
        <>
          <div className="mb-2.5 h-2.5 rounded-full bg-white/30" />
          <div className="mb-4 h-2.5 w-4/6 rounded-full bg-white/30" />
          <div className="mb-2.5 h-2.5 rounded-full bg-white/30" />
          <div className="h-2.5 w-2/6 rounded-full bg-white/30" />
        </>
      )}
    </div>
  );
};
