import React from 'react';

import { Common } from '@thecvlb/design-system/lib/src';

import { RangePickerProps } from './rangePicker.types';
import { StyledDayPicker } from '../calendar.styled';

const RangePicker: React.FC<RangePickerProps> = ({
  customCalendarVisible,
  disabledDays,
  selectedDays,
  onDayClick,
  onToggleVisible,
  selectDateRange,
}) => {
  return (
    <div
      className={`absolute z-20 flex w-fit flex-col items-end overflow-auto rounded-lg bg-white p-2 shadow-lg ${
        customCalendarVisible ? '' : 'hidden'
      }`}
    >
      <StyledDayPicker mode="range" disabled={disabledDays} selected={selectedDays} onDayClick={onDayClick} />
      <div className="flex">
        <Common.Button size="md" onClick={onToggleVisible}>
          Cancel
        </Common.Button>
        <Common.Button
          className="m-3"
          onClick={selectDateRange}
          color="blue"
          size="md"
          disabled={!(selectedDays && selectedDays?.from != selectedDays?.to)}
          style="pill"
        >
          Select
        </Common.Button>
      </div>
    </div>
  );
};

export default RangePicker;
