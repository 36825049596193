import { useState } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import DatePickerInput from 'components/common/DatePickerInput';
import TimeInput from 'components/common/form/timeInput';
import ControlledTextArea from 'components/forms/controlled/ControlledTextArea';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { FormProvider, useForm } from 'react-hook-form';
import { closeModal } from 'store/modal/modalSlice';
import { selectTask, useCreateReminderMutation } from 'store/tasks/tasksSlice';
import { validation } from 'utils/helpers';

import { CreateReminderFormDataProps } from './createReminder.types';

const CreateReminder = () => {
  const dispatch = useAppDispatch();
  const {
    taskDetails: { _id: taskId },
  } = useAppSelector(selectTask);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [createReminder] = useCreateReminderMutation();
  const methods = useForm<CreateReminderFormDataProps>({
    reValidateMode: 'onChange',
  });

  const { control, handleSubmit, formState, getValues, setValue } = methods;

  const cancelCreateRemind = () => dispatch(closeModal());

  const handleCreateReminder = (formData: CreateReminderFormDataProps) => {
    if (formData) {
      const [hours, minutes] = formData.remindTime.split(':');
      createReminder({
        taskId,
        note: formData.notes,
        dueDate: dayjs(formData.remindFormattedDate)
          .hour(+hours)
          .minute(+minutes)
          .local()
          .utc()
          .format(DateFormat.YYYY_MM_DDTHH_mm_ss_ss_Z),
      });
    }
  };

  return (
    <div className="p-6">
      <h2 className="mb-6 text-xl font-bold text-gray-700">Create reminder about this task</h2>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleCreateReminder)} className="flex flex-col gap-4">
          <ControlledTextArea
            control={control}
            data-testid="notes"
            name="notes"
            label="Notes:"
            labelDirection="col"
            placeholder="What do you need completed?"
            rows={4}
            errors={formState.errors.notes}
            rules={validation('Notes')}
          />

          <div className="relative grid w-full grid-cols-2 items-end gap-x-4">
            <DatePickerInput
              wrapperClasses="w-full relative"
              label="Remind me on"
              size="sm"
              placeholder="MM/DD/YYYY"
              inputValue={getValues('remindFormattedDate')}
              setInputValue={(value) => setValue('remindFormattedDate', value)}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />

            <TimeInput className="h-8 w-full" />
          </div>

          <div className="mt-2 grid grid-cols-2 gap-x-2">
            <Common.Button
              type="button"
              color="white-alt"
              className="!w-full justify-center"
              onClick={cancelCreateRemind}
              size="sm"
            >
              Cancel
            </Common.Button>
            <Common.Button className="!w-full justify-center" type="submit" color="blue" size="sm">
              Create reminder
            </Common.Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default CreateReminder;
