export interface OrderProps {
  id: string;
  email: string;
  status: string;
  orderStatus: string;
  shippingStatus?: string;
  pharmacyStatus?: string;
  trackingNumber?: string;
  createdAt: string;
  updatedAt?: string;
  type: string;
  insurance: Insurance;
  _id: string;
  patient: PatientProps | string;
  prescriptions: Array<Prescription>;
  isCompound?: boolean;
  tags?: Array<string>;
  metadata: {
    createdBy: string;
    createdAt: Date;
    updatedBy?: string;
    updatedAt?: string;
    version: number;
  };
  pharmacy?: PharmacyProps;
  additionalSupplies?: AdditionalSupply[];
}

export interface AdditionalSupply {
  RxNumber: string;
  ScriptNumber: string;
  Status: string; // example 'Received'
  StatusDate: string;
  StatusDetail: string;
  pharmacy: string; // example 'Wells'
}

export interface PatientProps {
  _id: string;
  externalId: string;
  status?: string;
  firstName: string;
  lastName: string;
  dob: string;
  gender: string; // enum
  sexAtBirth: string; // enum
  email: string;
  insurance?: Insurance;
  address: Address;
  phone: Phone;
  allergies: string;
  medications: Array<Medication>;
  conditions: string;
  elationId?: string;
}

export interface Prescription {
  status?: string;
  name: string;
  brandName: string;
  genericName: string;
  ndc: string;
  route: string;
  strength: string;
  form: string;
  refills: string;
  daysSupply: string;
  notes: string;
  quantity: string;
  quantityUnits: string;
  directions: string;
  startDate: string;
  prescribingPhysician: string;
  signedBy: string;
  signedDate: string;
  type: string;
}

export interface Medication {
  status?: string;
  name: string;
  brandName: string;
  genericName: string;
  ndc: string;
  ndcs: Array<string>;
  route: string;
  strength: string;
  form: string;
  refills: string;
  daysSupply: string;
  notes: string;
  quantity: string;
  quantityUnits: string;
  directions: string;
  startDate: string;
  prescribingPhysician: string;
  signedBy: string;
  signedDate: string;
  type: string;
}

export interface Phone {
  phoneNumber: string;
  internationalFormat: string;
  countryCode: string;
  countryPrefix: string;
}

export interface Address {
  line1: string;
  line2: string;
  city: string;
  state: string;
  country: string;
  postal: string;
}

export interface Insurance {
  memberId?: string;
  rxGroup?: string;
  rxBin?: string;
  pcn?: string;
  relationship?: string;
}

export interface PharmacyProps {
  [key: string]: unknown;
}

export const Statuses = {
  inProgress: 'in progress',
  pending: 'pending',
  placed: 'placed',
  onHold: 'on hold',
  shipping: 'shipping',
  closed: 'closed',
  received: 'Received',
} as const;

export type StatusOfOrders = keyof typeof Statuses;

export const OrdersTypes = {
  wells: 'Wells',
} as const;

export type OrdersType = keyof typeof OrdersTypes;

export interface ReleaseOrderFromHoldQueryParams {
  orderId: string;
  shouldExpedite: boolean;
  reason?: string;
}
