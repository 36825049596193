import CalendarTabs from 'components/appointments/CalendarTabs';
import Card from 'components/common/Card';

const Appointments = () => {
  return (
    <Card>
      <CalendarTabs />
    </Card>
  );
};

export default Appointments;
