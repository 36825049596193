import { ROLE_OPTIONS } from 'constants/staff';
import { STATUS_OPTIONS } from 'constants/status';

import { useEffect, useState } from 'react';

import { ReactMultiSelectProps } from '@thecvlb/design-system/lib/src/common';
import DatePickerInput from 'components/common/DatePickerInput';
import SelectedItemCounter from 'components/common/SelectedItemCounter';
import ControlledMultiSelect from 'components/forms/controlled/ControlledMultiSelect';
import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import InputField from 'components/forms/controlled/InputField';
import Footer from 'components/modals/Footer';
import dayjs from 'dayjs';
import { useTableFiltersData } from 'hooks/filters/useTableFiltersData';
import { useAppDispatch } from 'hooks/redux';
import isEqual from 'lodash/isEqual';
import { Option } from 'models/form.types';
import queryString from 'query-string';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetLicensedInQuery } from 'store/lookup/lookupSlice';
import { closeModal } from 'store/modal/modalSlice';
import { getAppliedFilterValues, handleReset } from 'utils/filters/filters';

import { defaultValues } from './staffsFiltersForm.settings';
import { StaffsFiltersFormProps } from './staffsFiltersForm.types';

const StaffsFiltersForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { control, watch, handleSubmit, reset, setValue, getValues } = useForm<StaffsFiltersFormProps>({
    reValidateMode: 'onChange',
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const { data: licensedInData } = useGetLicensedInQuery();

  const [lastLoginDate, setLastLoginDate] = useState<Date>();

  const [activeStates, setActiveStates] = useState<Option[]>([]);

  const [formData, setFormData] = useTableFiltersData('staffs', defaultValues, reset);

  const onSubmit = (data: StaffsFiltersFormProps) => {
    setFormData({ ...defaultValues, ...data });

    const parsedUrl = queryString.parse(searchParams.toString());

    const filters = {
      ...parsedUrl,
      pageNo: '0',
      name: data?.name,
      email: data?.email,
      lastLogin: data?.lastLogin,
      uniqueId: data?.uniqueId,
      activeStates: activeStates?.map((active) => active.label),
      status: data?.status?.value,
      'userType.name': data?.userType?.value,
    };

    const appliedFilterValues = getAppliedFilterValues(filters);

    if (!isEqual(appliedFilterValues, parsedUrl)) {
      setSearchParams(queryString.stringify(appliedFilterValues));
    }
    dispatch(closeModal());
  };

  const handleChange = (newValue: ReactMultiSelectProps['value']) => {
    setActiveStates(newValue as Option[]);
  };

  useEffect(() => {
    const selectedStates = searchParams.getAll('activeStates');

    const result = selectedStates.map((state) => {
      const selectedState = licensedInData?.find((item) => item.label === state);

      return selectedState;
    });

    if (result) setActiveStates(result as Option[]);
  }, [licensedInData, searchParams, setValue]);

  watch();

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="w-[540px]">
        <div className="grid grid-cols-2">
          <div className="flex flex-col gap-4 border-r border-gray-200 pr-7">
            <InputField name="name" label="Name" type="text" placeholder="Enter name" control={control} />
            <ControlledSelect
              control={control}
              labelDirection="col"
              options={ROLE_OPTIONS}
              placeholder="Select user type"
              label="User type"
              name="userType"
              defaultValue={formData?.userType}
            />
            <InputField
              name="email"
              label="Email"
              type="text"
              placeholder="Enter email"
              control={control}
              value={formData?.email}
            />
            <DatePickerInput
              dataTestId="last_active_field"
              placeholder="Select date"
              label="Last active"
              labelDirection="col"
              size="sm"
              inputValue={getValues('lastLogin')}
              setInputValue={(value) => setValue('lastLogin', value)}
              selectedDate={lastLoginDate}
              setSelectedDate={setLastLoginDate}
              startAllowedDate={dayjs().subtract(100, 'year').toDate()}
              lastAllowedDate={new Date()}
              captionLayout={'dropdown'}
              wrapperClasses="w-full relative"
            />
          </div>
          <div className="flex flex-col gap-4 pl-7">
            <InputField name="uniqueId" label="Unique ID" type="text" placeholder="Enter ID" control={control} />
            <ControlledSelect
              control={control}
              labelDirection="col"
              options={STATUS_OPTIONS}
              placeholder="Select status"
              label="Status"
              name="status"
              value={formData?.status}
            />
            <ControlledMultiSelect
              dataTestId="licensed_in_field"
              control={control}
              labelDirection="col"
              options={licensedInData}
              placeholder="Select licensed in"
              label="Licensed in"
              name="activeStates"
              value={activeStates}
              onChange={handleChange}
            />
            <SelectedItemCounter count={activeStates.length} />
          </div>
        </div>
        <Footer onClick={() => handleReset(dispatch, reset, defaultValues, navigate)} />
      </form>
    </>
  );
};

export default StaffsFiltersForm;
