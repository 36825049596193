import type { GroupFilterOptionsProps } from 'hooks/filters/useTableFiltersData.types';
import { Tag } from 'store/patients/patients.types';
import { TaskTagsType } from 'store/tasks/task.types';

export const getGroupedTags = (tagList: Tag[] | TaskTagsType[]): GroupFilterOptionsProps[] => {
  return tagList.reduce((acc: GroupFilterOptionsProps[], tag) => {
    const groupId = acc.findIndex((g) => g.label === tag.category);
    const tagObject = { label: tag.name, value: tag._id, groupLabel: tag.category };

    if (groupId !== -1) {
      acc[groupId].options.push(tagObject);
      return acc;
    } else {
      return [...acc, { label: tag.category, options: [tagObject] }];
    }
  }, []);
};
