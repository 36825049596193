import { useEffect, useState } from 'react';

import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import queryString from 'query-string';
import { useNavigate, useSearchParams } from 'react-router-dom';

import type { BillingHistoryProps, BillingHistoryTabTypes } from './billingHistory.types';
import { BILLING_HISTORY_TABS, BillingHistoryTabType } from './billingHistory.types';
import InvoiceTable from './InvoiceTable';
import TransactionsTable from './TransactionsTable';

const BillingHistory: React.FC<BillingHistoryProps> = ({ patientId }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [activeHistoryTab, setActiveHistoryTab] = useState<BillingHistoryTabTypes>(BillingHistoryTabType.Invoices);

  useEffect(() => {
    const currentActiveTab = queryString.parse(searchParams.toString())?.historyTable;
    currentActiveTab && setActiveHistoryTab(currentActiveTab as BillingHistoryTabTypes);
  }, [searchParams]);

  const handleChangeTab = (tab: Common.DataItemProps) => {
    if (tab.label !== activeHistoryTab) {
      navigate(`?active-tab=Billing&historyTable=${tab.label}`, { replace: true });
    }
  };

  return (
    <div>
      <h2 className="pb-4 pt-8 font-bold">Billing history</h2>
      <Common.Tabs
        data={BILLING_HISTORY_TABS}
        type="bar"
        onChange={handleChangeTab}
        defaultSelected={[activeHistoryTab]}
        className={classNames('my-4')}
      />

      {activeHistoryTab === BillingHistoryTabType.Invoices ? (
        <InvoiceTable patientId={patientId} />
      ) : (
        <TransactionsTable patientId={patientId} />
      )}
    </div>
  );
};

export default BillingHistory;
