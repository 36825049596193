import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import classNames from 'classnames';
import ProspectsParameters from 'components/patient/ProspectsParameters';
import { useParsedURLSearchParams } from 'hooks/common/useParsedSearchParams';
import { useSelectProspectPatientDetails } from 'hooks/components/ProspectDetails/useSelectProspectPatientDetails';

import { prospectTabs } from '../prospectDetails.settings';
import ProspectDetailsForm from '../ProspectDetailsForm';

const ProspectTabs: React.FC<{ patientId: string }> = ({ patientId }) => {
  const { queryParams } = useParsedURLSearchParams();
  const { result } = useSelectProspectPatientDetails({ queryParams, patientId });

  return (
    <TabGroup>
      <TabList className="border-b border-gray-200">
        {prospectTabs.map((tab) => (
          <Tab
            key={tab}
            className={({ selected }) =>
              classNames(
                'px-3 py-4 text-base font-semibold',
                selected ? 'border-b-2 border-secondary text-secondary' : 'text-gray',
              )
            }
          >
            {tab}
          </Tab>
        ))}
      </TabList>
      <TabPanels>
        <TabPanel key="Details">
          <ProspectDetailsForm
            _id={result?._id}
            prospectStatus={result?.prospectStatus}
            prospectNotes={result?.prospectNotes}
            isChargifyCreated={result?.isChargifyCreated}
            isElationCreated={result?.isElationCreated}
          />
        </TabPanel>
        <TabPanel key="Parameters">
          <ProspectsParameters
            flowQueryParams={result?.flowQueryParams}
            flowUrl={result?.flowUrl}
            referrerUrl={result?.referrerUrl}
            displayActions
          />
        </TabPanel>
      </TabPanels>
    </TabGroup>
  );
};

export default ProspectTabs;
