import { DataItemProps } from '@thecvlb/design-system/lib/src/common/Tabs/tabs.types';

export function removeAtIndex<T>(arr: T[], index: number): T[] {
  const copy = [...arr];
  copy.splice(index, 1);
  return copy;
}

export function toggle<T>(arr: T[], item: T, getValue = (value: T) => value): T[] {
  const index = arr.findIndex((i) => getValue(i) === getValue(item));
  if (index === -1) return [...arr, item];
  return removeAtIndex(arr, index);
}

export function getDefaultFilters(searchParams: URLSearchParams, categories: DataItemProps[]) {
  const selectedCategories = searchParams.getAll('category');

  return categories.filter((category) =>
    selectedCategories.includes((category.value as { name: string; param: string }).name),
  );
}
