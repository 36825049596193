import { TASK_CATEGORIES_FILTER_POPUP } from 'constants/taskFilter';

import React, { useEffect, useMemo, useState } from 'react';

import * as Checkbox from '@radix-ui/react-checkbox';
import { createSelector } from '@reduxjs/toolkit';
import { useFlag } from '@unleash/proxy-client-react';
import { toggle } from 'components/filters/Tasks/taskCategoriesFilter.settings';
import { PathName } from 'enums/pathName';
import { Role } from 'enums/role';
import { TaskCategories } from 'enums/taskCategories';
import { useAppSelector } from 'hooks/redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { selectTasks } from 'store/tasks/tasksSlice';
import { selectUser } from 'store/user/userSlice';
import { formatCategoryLabel } from 'utils/formatCategoryLabel';

import { Category, TaskCategoriesFilterProps } from './taskCategories.types';
import { handleChangeTaskTags, handleUpdateCategory } from './taskCategoriesFilter.settings';

const selectTaskCategoriesFilterState = createSelector([selectTasks, selectUser], (tasks, user) => ({
  asyncProviderEnabled: tasks.asyncProviderEnabled,
  userType: user.userType,
}));

const TaskCategoriesFilter: React.FC<TaskCategoriesFilterProps> = ({
  name,
  setValue,
  selectedCategory,
  selectedTaskTags,
}) => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const { userType, asyncProviderEnabled } = useAppSelector(selectTaskCategoriesFilterState);

  const isQueueDisabled = useFlag('disable-queue');
  const isTasksOnPatientPage =
    location.pathname.includes(PathName.Patient) && searchParams.get('active-tab') === 'Tasks';

  // Filter task categories by user role
  const categories = useMemo(
    () =>
      isTasksOnPatientPage
        ? TASK_CATEGORIES_FILTER_POPUP
        : TASK_CATEGORIES_FILTER_POPUP.filter((category) =>
            (category as Category).roles.includes(userType.name as Role),
          ),
    [isTasksOnPatientPage, userType.name],
  );

  // Filter task categories by extra conditions
  const taskCategories = useMemo(
    () =>
      categories.filter((category) => {
        if (
          !asyncProviderEnabled &&
          userType?.name === Role.PH &&
          category.label === TaskCategories.Async &&
          !isTasksOnPatientPage
        ) {
          return false; // Skip Async category if it is not enabled for PH
        }
        if (isQueueDisabled && category.label === TaskCategories.Queue) {
          return false; // Skip Queue category if it is disable by feature flag
        }
        return true;
      }),
    [asyncProviderEnabled, categories, userType?.name, isQueueDisabled, isTasksOnPatientPage],
  );

  // Get default categories from URL if they exist
  const defaultCategories = useMemo(() => {
    /**
     * We get the 'TaskTags' filter as a separate filter type.
     * But according to the UI, 'TaskTags' should be part of the 'Category' filter.
     * Therefore, we need to combine these two filters into one.
     */
    const defaultTaskTags = searchParams.getAll('taskTags').length
      ? taskCategories.filter((category) => searchParams.getAll('taskTags').includes(category.value))
      : [];

    /**
     * Need to exclude the 'Appointment' category if something is selected in 'TaskTags'
     */
    const shouldRemoveAppointmentCategory = defaultTaskTags.length > 0;
    const defaultCategory = searchParams.getAll('category').length
      ? taskCategories.filter(
          (category) =>
            searchParams.getAll('category').includes(category.value) &&
            (!shouldRemoveAppointmentCategory || category.value !== 'Appointment'),
        )
      : [];

    const combineList = [...defaultCategory, ...defaultTaskTags];

    return combineList.length ? combineList : [TASK_CATEGORIES_FILTER_POPUP[0]];
  }, [searchParams, taskCategories]);

  const [selected, setSelected] = useState<Category[]>(defaultCategories);

  const handleCheckedChange = (category: Category) => {
    // If 'All' category is selected, reset all categories
    if (category.value.toLowerCase() === 'all') {
      setValue(name, []);
      setSelected([TASK_CATEGORIES_FILTER_POPUP[0]]);

      setValue(
        'taskTags',
        selectedTaskTags.filter((item) => item.value !== 'nutrition' && item.value !== 'weight-management'),
      );
    } else {
      const isSelectedAppointment = category.value === 'Appointment';
      const isSelectedAppointmentTag = category.value === 'weight-management' || category.value === 'nutrition';

      const wasAppointmentSelectedBefore = selected.some((item) => item.value === 'Appointment');
      const wasAppointmentTagSelectedBefore = selected.some(
        (item) => item.value === 'weight-management' || item.value === 'nutrition',
      );

      const newValues = toggle(selected, category).filter((item) => {
        const value = item.value;

        const shouldExcludeValue =
          (isSelectedAppointment &&
            wasAppointmentTagSelectedBefore &&
            (value === 'weight-management' || value === 'nutrition')) ||
          (isSelectedAppointmentTag && wasAppointmentSelectedBefore && value === 'Appointment');

        return !shouldExcludeValue && value.toLowerCase() !== 'all';
      });

      setValue(
        name,
        newValues.map((item) => item.value),
      );
      setSelected(newValues);
      handleChangeTaskTags(setValue, selectedTaskTags, newValues, isSelectedAppointment);
    }
  };

  const isChecked = (categoryValue: string) => {
    return Boolean(selected.find((selectedCategory) => selectedCategory.value === categoryValue));
  };

  useEffect(() => {
    handleUpdateCategory(setValue, handleCheckedChange, selected || [], selectedCategory || []);
  }, [selectedCategory]);

  return (
    <>
      {taskCategories.map((category) => {
        const checked = isChecked(category.value);

        return (
          <div
            className={`${checked ? `${category.bgColor} rounded` : ''} m-2 flex px-2 py-1`}
            key={category.value}
            data-testid="category_filter_option"
          >
            <Checkbox.Root
              name={name}
              id={category.value}
              onCheckedChange={() => handleCheckedChange(category as Category)}
              checked={checked}
            >
              <Checkbox.Indicator></Checkbox.Indicator>
            </Checkbox.Root>
            <label className="flex items-center gap-1 text-sm font-semibold" htmlFor={category.value}>
              <div className={`size-2 rounded-full ${category.color} ${checked ? 'border border-white' : ''}`} />
              {formatCategoryLabel(category.label)}
            </label>
          </div>
        );
      })}
    </>
  );
};

export default TaskCategoriesFilter;
