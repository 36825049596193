import { Common } from '@thecvlb/design-system/lib/src';
import { PathName } from 'enums/pathName';
import { useNavigate } from 'react-router-dom';

const ActionsCell: React.FC<{ patientId: string; taskCount?: number }> = ({ patientId, taskCount }) => {
  const navigate = useNavigate();

  const handleOpenPatientTasks: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    navigate(`${PathName.Patient}/${patientId}?active-tab=Tasks`);
  };

  return !taskCount ? (
    <p className="text-center text-sm font-medium">None</p>
  ) : (
    <Common.Button onClick={handleOpenPatientTasks} size="sm" color="white-alt" className="mx-auto">
      {taskCount} task{taskCount > 1 ? 's' : ''}
    </Common.Button>
  );
};

export default ActionsCell;
