import {
  MyReportsLeaderboard,
  MyReportsTasks,
  TableProps,
} from 'store/medicalAssistantReports/medicalAssistantReports.types';

import { apiSlice } from '../api/apiSlice';

export const medicalAssistantReportsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getMyReportsTasks: build.query<{ myReportsTasks: MyReportsTasks }, { params: string }>({
      query: ({ params }) => `/medical-assistant-reports/my-reports/tasks${params}`,
      transformResponse: (response: { data: MyReportsTasks }) => ({
        myReportsTasks: response.data,
      }),
    }),

    getMyReportsLeaderboard: build.query<{ myReportsLeaderboard: MyReportsLeaderboard[] }, { params: string }>({
      query: ({ params }) => `/medical-assistant-reports/my-reports/leaderboard${params}`,
      transformResponse: (response: { data: MyReportsLeaderboard[] }) => ({
        myReportsLeaderboard: response.data,
      }),
    }),

    getTasksReport: build.query<{ tasksReport: TableProps[] }, { params: string }>({
      query: ({ params }) => `/medical-assistant-reports/tasks-report${params}`,
      transformResponse: (response: { data: TableProps[] }) => ({
        tasksReport: response.data,
      }),
    }),

    downloadMedicalAssistantReports: build.query<Blob, { params: string }>({
      query: ({ params }) => ({
        url: `/download-reports/medical-assistant${params}`,
        method: 'GET',
        responseHandler: (response: Response) => {
          return response.blob().then((res) => res);
        },
      }),
      transformResponse: (response: Blob) => response,
    }),
  }),
});

export const {
  useLazyDownloadMedicalAssistantReportsQuery,
  useLazyGetMyReportsTasksQuery,
  useLazyGetMyReportsLeaderboardQuery,
  useLazyGetTasksReportQuery,
} = medicalAssistantReportsApi;
