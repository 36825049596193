import { CustomFeedbackFilterData } from 'constants/filters';

import { useEffect, useMemo } from 'react';

import { ColumnDef, getCoreRowModel, RowData, useReactTable } from '@tanstack/react-table';
import Card from 'components/common/Card';
import AppliedFiltersTags from 'components/filters/AppliedFiltersTags/AppliedFiltersTags';
import FilterButtons from 'components/filters/FilterTabs';
import SearchForm from 'components/filters/SearchForm';
import CustomerFeedbackModal from 'components/modals/CustomerFeedback';
import BasicTable from 'components/tables/BasicTable';
import { columns } from 'components/tables/CustomerFeedbackTable/columns';
import { ReviewsTableProps } from 'components/tables/ReviewsTable/reviewsTable.types';
import { useGetDefaultBasicTableValues } from 'hooks/common/useGetDefaultBasicTableValues';
import { useAppDispatch } from 'hooks/redux';
import queryString from 'query-string';
import { useSearchParams } from 'react-router-dom';
import { FeedbackProps, FeedbackQueryParams } from 'store/customerFeedback/customerFeedback.types';
import { useLazyGetCustomerFeedbacksQuery } from 'store/customerFeedback/customerFeedbackSlice';
import { openModal } from 'store/modal/modalSlice';

const CustomerFeedback: React.FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const queryParams: FeedbackQueryParams = useMemo(() => {
    return queryString.parse(searchParams.toString());
  }, [searchParams]);

  const [getCustomerFeedbacks, { data, isFetching, isUninitialized }] = useLazyGetCustomerFeedbacksQuery();
  const [tableData, tableColumns] = useGetDefaultBasicTableValues({
    isFetching: isFetching || isUninitialized,
    columns,
    data: data?.data,
  });
  const table = useReactTable({
    data: tableData,
    columns: tableColumns as ColumnDef<ReviewsTableProps, unknown>[],
    getCoreRowModel: getCoreRowModel(),
  });

  const handleClickRow = (rowData: RowData) => {
    if (rowData)
      dispatch(
        openModal({
          size: 'lg',
          modalContent: <CustomerFeedbackModal id={(rowData as FeedbackProps)._id} />,
          hideClose: true,
        }),
      );
  };

  useEffect(() => {
    getCustomerFeedbacks({ params: queryParams });
  }, [getCustomerFeedbacks, queryParams]);

  return (
    <>
      <div className="relative my-5 flex items-center justify-between">
        <FilterButtons filterData={CustomFeedbackFilterData} queryParam="problemType" />
        <div className="flex gap-2">
          <SearchForm showSearchBar />
        </div>
      </div>
      <AppliedFiltersTags />
      <Card>
        <BasicTable
          table={table}
          totalCount={data?.info.totalCount || 0}
          isFetching={isFetching}
          onClickRow={handleClickRow}
        />
      </Card>
    </>
  );
};

export default CustomerFeedback;
