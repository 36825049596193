import { Common } from '@thecvlb/design-system';
import Period from 'components/availability/Period';
import { useAppDispatch } from 'hooks/redux';
import { addAvailabilityItem, toggleDay } from 'store/availability/availabilitySlice';

import { DayCardProps } from './dayCard.types';

const DayCard: React.FC<DayCardProps> = ({ day, workHours, isChecked }) => {
  const dispatch = useAppDispatch();
  const containerTestId = `${day.substring(0, 3)}_day_container`;

  return (
    <div data-testid={containerTestId} className="flex items-start py-5">
      <div data-testid="day_of_week_label" className="relative mt-3 w-20">
        <Common.Checkbox
          dataTestId={`day_status_${isChecked}`}
          onChange={() => dispatch(toggleDay({ day }))}
          checked={isChecked}
          size="sm"
        >
          {day.substring(0, 3)}
        </Common.Checkbox>
      </div>
      <div className="flex flex-1 flex-col gap-4">
        {workHours.length && isChecked ? (
          workHours.map((period) => {
            return (
              <Period
                id={period.id}
                day={day}
                key={period.id}
                start={period.startTime}
                end={period.endTime}
                appointments={period.appointments}
                queue={period.queue}
              />
            );
          })
        ) : (
          <span data-testid="day_unavailable_msg" className="my-3 text-sm text-gray">
            Unavailable
          </span>
        )}
      </div>
      <button
        type="button"
        onClick={() => dispatch(addAvailabilityItem({ day }))}
        className="mt-2.5"
        data-testid={`${day}-add_btn`}
      >
        <Common.Icon name="plus" className="text-primary" />
      </button>
    </div>
  );
};

export default DayCard;
