import React, { useCallback, useEffect } from 'react';

import Loader from 'components/common/Loader';
import { notifySuccess } from 'components/common/Toast/Toast';
import AutocompleteMultiSelect from 'components/forms/controlled/AutocompleteMultiSelect';
import InputField from 'components/forms/controlled/InputField';
import PopupHeader from 'components/modals/components/PopupHeader';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useLazyGetUsersQuery } from 'store/lookup/lookupSlice';
import { closeModal } from 'store/modal/modalSlice';
import { useCreateTeamMutation, useLazyGetTeamQuery, useUpdateTeamMutation } from 'store/teams/teamsSlice';
import { validation } from 'utils/helpers';
import { ANY_CHARACTER_REGEXP } from 'utils/regExp';

import type { TeamFormProps } from './CreateTeam.types';
import { Options } from '../../patient/History/history.types';
import PopupFooter from '../components/PopupFooter';

const CreateTeam: React.FC<{ teamId?: string }> = ({ teamId }) => {
  const dispatch = useDispatch();
  const { handleSubmit, control, formState, reset } = useForm<TeamFormProps>({ reValidateMode: 'onChange' });
  const [getTeam, { data: teamDetails, isLoading: isTeamLoading }] = useLazyGetTeamQuery();
  const [getStaffList, { data: staffList, isLoading: isStaffLoading }] = useLazyGetUsersQuery();
  const [createTeam, { isLoading: isCreateLoading }] = useCreateTeamMutation();
  const [updateTeam, { isLoading: isUpdateLoading }] = useUpdateTeamMutation();

  const isDisabled = isTeamLoading || isStaffLoading || isCreateLoading || isUpdateLoading;
  const isEdit = teamId && teamDetails?.name;

  const getValue = (data: Options[]) => {
    if (data && Array.isArray(data)) {
      return data?.map((item) => item?.value);
    }
    return [];
  };

  const onSubmitForm = (formData: TeamFormProps) => {
    const body = {
      name: formData.name,
      description: formData.description,
      members: getValue(formData?.members as []),
    };

    if (isEdit) {
      updateTeam({ teamId: teamId, team: body })
        .unwrap()
        .then((data) => {
          data?.message && notifySuccess(data.message);
          dispatch(closeModal());
        });
    } else {
      createTeam({ team: body })
        .unwrap()
        .then((data) => {
          data?.message && notifySuccess(data.message);
          dispatch(closeModal());
        });
    }
  };

  const getAssignedOptionsList = (value?: string) =>
    value && getStaffList({ userType: 'Staff', limit: 20, pageNo: 0, searchKey: value.trim() ?? '' });

  const getListAssignedToName = useCallback(() => {
    const uniquePatients = new Set([
      ...(staffList?.map((item) => JSON.stringify({ label: item?.name as string, value: item?._id as string })) ?? []),
    ]);

    return Array.from(uniquePatients, (item) => JSON.parse(item));
  }, [staffList]);

  useEffect(() => {
    if (teamDetails?._id) {
      reset({
        teamId: teamDetails?._id,
        name: teamDetails?.name,
        description: teamDetails?.description,
        members: teamDetails?.members.map((member) => ({
          value: member.userId,
          label: `${member.firstName} ${member.lastName}`,
        })),
      });
    }
  }, [teamDetails]);

  useEffect(() => {
    if (teamId) {
      getTeam({ teamId });
    }
  }, [teamId, getTeam]);

  return (
    <div data-testid="billing_configuration_popup" className="p-8">
      <PopupHeader
        title={isEdit ? teamDetails.name : 'Create team'}
        {...(isEdit && { subtitle: 'Edit team details' })}
      />
      <Loader isVisible={isDisabled} />

      <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmitForm)}>
        <InputField
          name="name"
          label="Name"
          placeholder="Enter name..."
          control={control}
          labelDirection="row"
          type="text"
          errors={formState.errors.name}
          helper={formState.errors.name?.message}
          rules={validation('Name', ANY_CHARACTER_REGEXP)}
          size="md"
        />
        <InputField
          name="description"
          label="Description"
          placeholder="Enter description..."
          control={control}
          labelDirection="row"
          type="text"
          errors={formState.errors.description}
          helper={formState.errors.description?.message}
          rules={validation('Description', ANY_CHARACTER_REGEXP)}
          size="md"
        />
        <AutocompleteMultiSelect
          control={control}
          label="Team members"
          name="members"
          placeholder="Search members..."
          labelDirection="row"
          options={getListAssignedToName()}
          getOptionsFunc={getAssignedOptionsList}
          errors={formState.errors.members}
          rules={validation('Members')}
          size="md"
        />

        <PopupFooter disabled={isDisabled} hiddenDeleteButton saveButtonText={isEdit ? 'Update' : 'Create team'} />
      </form>
    </div>
  );
};

export default CreateTeam;
