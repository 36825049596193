import { useEffect } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import { notifySuccess } from 'components/common/Toast/Toast';
import { useSlidingPane } from 'components/tasks/slidingPane/useSlidingPane';
import { useAppDispatch } from 'hooks/redux';
import { useForm } from 'react-hook-form';
import { closeModal } from 'store/modal/modalSlice';
import { useLazyGetPatientQuery } from 'store/patients/patientsSlice';
import { useStopReceivingTasksMutation } from 'store/tasks/tasksSlice';

const StopReceivingTasks: React.FC<{ patientId: string }> = ({ patientId }) => {
  const dispatch = useAppDispatch();
  const { handleSubmit } = useForm();
  const { handleClose } = useSlidingPane();
  const [getPatient, { data: patient }] = useLazyGetPatientQuery();
  const [stopReceivingTasks, { isLoading }] = useStopReceivingTasksMutation();

  const fullName = patient ? `${patient.firstName} ${patient.lastName}` : '';
  const doctor = patient ? patient.doctor : '';

  const cancelStopReceivingTasks = () => dispatch(closeModal());

  const handleStopReceivingTasks = () => {
    if (patientId)
      stopReceivingTasks(patientId)
        .unwrap()
        .then((res) => {
          dispatch(closeModal());
          handleClose();
          notifySuccess(res?.message);
        });
  };

  useEffect(() => {
    if (patientId) getPatient({ id: patientId });
  }, [getPatient, patientId]);

  return (
    <div className="p-6">
      <h2 className="mb-2 text-xl font-bold text-gray-700">Stop receiving tasks?</h2>
      <p className="mb-6 text-base font-medium text-gray-700">
        You will no longer receive tasks for <span className="font-bold">{fullName}</span>. Future tasks will be
        assigned to their provider, <span className="font-bold">{doctor}</span>. This action cannot be undone.
      </p>
      <form onSubmit={handleSubmit(handleStopReceivingTasks)} className="flex flex-col gap-4">
        <div className="mt-2 grid grid-cols-2 gap-x-2">
          <Common.Button
            type="button"
            color="white-alt"
            className="!w-full justify-center"
            onClick={cancelStopReceivingTasks}
            size="sm"
          >
            Cancel
          </Common.Button>
          <Common.Button className="!w-full justify-center" type="submit" color="red" size="sm" disabled={isLoading}>
            Stop receiving tasks
          </Common.Button>
        </div>
      </form>
    </div>
  );
};

export default StopReceivingTasks;
