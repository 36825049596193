import { Common } from '@thecvlb/design-system';
import { FieldValues, useController } from 'react-hook-form';

import { ControlledMultiSelectProps } from './controlledMultiSelect.types';

const ControlledMultiSelect = <TFieldValues extends FieldValues>({
  name,
  control,
  rules,
  onInputChange,
  size = 'sm',
  label,
  helperText,
  options = [],
  placeholder,
  labelDirection,
  onChange,
  value,
  ...otherProps
}: ControlledMultiSelectProps<TFieldValues>) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules });

  return (
    <Common.MultiSelect
      {...otherProps}
      onInputChange={onInputChange}
      placeholder={placeholder}
      label={label}
      size={size}
      name={field.name}
      value={value || field.value}
      onChange={onChange || field.onChange}
      helperText={helperText || error?.message}
      options={options}
      errors={error}
      labelDirection={labelDirection}
    />
  );
};

export default ControlledMultiSelect;
