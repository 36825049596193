import React from 'react';

import { Common } from '@thecvlb/design-system/lib/src';

const Skeleton: React.FC<{
  title: string;
  description?: string;
  isFetching?: boolean;
  cardCount?: number;
  helperComponents?: React.ReactNode;
}> = ({ title, description, isFetching, cardCount = 3, helperComponents }) => {
  if (!isFetching) {
    return (
      <div className="flex flex-col items-center py-4">
        <span className="flex size-16 items-center justify-center gap-1 rounded-full bg-gray-100">
          <Common.Icon name="calendar" className="size-8 opacity-50" />
        </span>
        <p className="mb-1 mt-4 font-bold leading-5 text-gray-500">{title}</p>
        <p className="text-sm text-gray-500">{description}</p>
        {helperComponents && <div className="mt-4">{helperComponents}</div>}
      </div>
    );
  }
  return (
    <>
      {Array.from(Array(cardCount).keys()).map((val) => {
        return (
          <div key={val} className="my-3 w-full rounded-md border border-gray-300 p-4 shadow">
            <div className="flex animate-pulse items-center space-x-4">
              <div className="size-11 rounded-md bg-gray-400"></div>
              <div className="flex flex-1 flex-col justify-between">
                <div className="mb-4 flex space-x-2">
                  <div className="h-3 w-14 rounded-md bg-gray-400"></div>
                  <div className="h-3 w-12 rounded-md bg-gray-400"></div>
                </div>
                <div className="flex space-x-2">
                  <div className="h-2 w-20 rounded-md bg-gray-400"></div>
                  <div className="h-2 w-24 rounded-md bg-gray-400"></div>
                  <div className="h-2 w-10 rounded-md bg-gray-400"></div>
                </div>
              </div>
              <div className="h-8 w-20 rounded-md bg-gray-400" />
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Skeleton;
