import { ResourcesConfig } from 'aws-amplify';

export const getAuthConfig = ({
  redirectSignIn,
  redirectSignOut,
}: {
  redirectSignIn: string[];
  redirectSignOut: string[];
}): ResourcesConfig => ({
  Auth: {
    Cognito: {
      userPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID,
      userPoolClientId: import.meta.env.VITE_COGNITO_CLIENT_ID,
      loginWith: {
        oauth: {
          domain: import.meta.env.VITE_COGNITO_OAUTH_DOMAIN,
          scopes: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn,
          redirectSignOut,
          responseType: 'code' as 'code' | 'token',
          providers: ['Google'],
        },
      },
    },
  },
});
