export const getPaymentNumbers = (totalPrice?: string, discount?: string, creditBalance?: string) => {
  const numTotalPrice = Number(totalPrice);
  if (!numTotalPrice) {
    return {
      creditUsed: '',
      discountValue: '',
      finalPrice: '',
    };
  }
  const discountValue = !discount ? 0 : (numTotalPrice * parseFloat(discount)) / 100;
  const creditUsed = !creditBalance ? 0 : Math.min(numTotalPrice - discountValue, parseFloat(creditBalance));
  const finalPrice = Math.max(numTotalPrice - discountValue - parseFloat(creditBalance || '0'), 0);
  return {
    creditUsed: (creditUsed || 0).toFixed(2),
    discountValue: (discountValue || 0).toFixed(2),
    // if price has decimal, show it. If no, hide .00
    finalPrice: finalPrice.toFixed(finalPrice % 1 !== 0 ? 2 : 0),
  };
};
