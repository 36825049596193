import { useState } from 'react';

import Tooltip from 'components/tables/TaskTable/OnboardingDetails/Tooltip';

import { sortingFieldsLabels } from '../sortingBanner.settings';
import { SortingFieldLabelProps } from './sortingFieldLabel.types';

const SortingFieldLabel: React.FC<SortingFieldLabelProps> = ({ sortName, sortOrder, index }) => {
  const [isLabelHovered, setIsLabelHovered] = useState(false);

  const currentSort = sortingFieldsLabels[sortName];
  const label = currentSort?.label || sortName;
  const orderLabel = sortOrder ? currentSort?.options[sortOrder]?.label || sortOrder : '';
  const tooltipList = sortOrder ? currentSort?.options[sortOrder]?.tooltipList || [] : [];
  const title = `${label} • ${orderLabel}`;

  const labelFocusProps = {
    onMouseOver: () => {
      setIsLabelHovered(true);
    },
    onMouseLeave: () => {
      setIsLabelHovered(false);
    },
  };

  return (
    <span onMouseLeave={labelFocusProps.onMouseLeave} className="relative w-fit">
      {!!index && ', then '}
      <span className="font-bold" onMouseOver={labelFocusProps.onMouseOver}>
        {title}
      </span>
      {isLabelHovered && (
        <Tooltip>
          <span className="text-sm">
            <span className="font-bold">{title}</span>
            <ul className="text-left font-medium">
              {tooltipList?.map((listItem) => <li key={listItem}>• {listItem}</li>)}
            </ul>
          </span>
        </Tooltip>
      )}
    </span>
  );
};

export default SortingFieldLabel;
