import classNames from 'classnames';

import { getItems } from './prescriptionInfo.settings';
import { PrescriptionInfoProps } from './prescriptionInfo.types';

const OrderInfo: React.FC<PrescriptionInfoProps> = ({ prescription, wrapperClassNames, labelClassNames }) => {
  const wrapperClasses = classNames(wrapperClassNames || 'grid grid-cols-2 gap-x-6 gap-y-2');
  const labelClasses = classNames('text-base mr-5 min-w-fit flex-none text-gray', labelClassNames || 'w-28');

  return (
    <div className={wrapperClasses}>
      {getItems(prescription).map((el) => (
        <div data-testid="personal_info_data_field" key={el.label} className="flex">
          <p className={labelClasses}>{el.label}</p>
          <p className={classNames('text-base text-gray-700', { underline: el.isUnderline })}>{el.value}</p>
        </div>
      ))}
    </div>
  );
};

export default OrderInfo;
