export enum InsuranceLabels {
  MemberId = 'Member Id',
  RxGroup = 'Rx Group',
  RxBIN = 'Rx BIN',
  PCN = 'PCN',
  Relationship = 'Relationship',
}

export enum OrderLabels {
  OrderId = 'Order Id',
  Type = 'Type',
  CreatedDate = 'Created Date',
  OrderStatus = 'Order Status',
  ShippingStatus = 'Shipping Status',
  PharmacyStatus = 'Pharmacy Status',
  TrackingNumber = 'Tracking number',
}

export enum PatientLabels {
  FirstName = 'First name',
  LastName = 'Last name',
  Email = 'Email',
  Address = 'Shipping address',
  SexAtBirth = 'Sex at birth',
  Phone = 'Phone',
  Allergies = 'Allergies',
  Medications = 'Medications',
  Conditions = 'Conditions',
}

export enum PrescriptionLabels {
  Name = 'Name',
  Brand = 'Brand',
  NDC = 'NDC',
  Quantity = 'Quantity',
}
