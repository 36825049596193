import { useEffect, useMemo, useState } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import { updatePassword } from 'aws-amplify/auth';
import Loader from 'components/common/Loader';
import ResetPassword from 'components/common/ResetPassword';
import { notifyError, notifySuccess } from 'components/common/Toast/Toast';
import CurrentRoleSelect from 'components/modals/components/EditProfileForm/components/CurrentRoleSelect';
import MainFormComponents from 'components/modals/components/EditProfileForm/components/MainFormComponents';
import PaySection from 'components/modals/components/EditProfileForm/components/PaySection';
import PopupFooter from 'components/modals/components/PopupFooter';
import ReAssignAppointmentPopup from 'components/modals/ReAssignAppointmentPopup';
import { PathName } from 'enums/pathName';
import { RoleShortName } from 'enums/role';
import { TaskCategories } from 'enums/taskCategories';
import { useAppSelector } from 'hooks/redux';
import { TaskStatus } from 'models/tasks.types';
import queryString from 'query-string';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useUpdateMyProfileMutation } from 'store/api/apiSlice';
import { useGetTimeZoneQuery } from 'store/lookup/lookupSlice';
import { closeModal } from 'store/modal/modalSlice';
import { useLazyGetRolesQuery } from 'store/roles/rolesSlice';
import {
  useAddStaffMemberMutation,
  useLazyGetStaffsQuery,
  useUnassignStaffTasksMutation,
  useUpdateStaffMemberMutation,
} from 'store/staffs/staffsSlice';
import { useLazyGetTasksQuery } from 'store/tasks/tasksSlice';
import { selectUser } from 'store/user/userSlice';
import { EDIT_POPUP_PERMISSION, hasPermission } from 'utils/popupsContentPermission';

import AdditionalSection from './components/AdditionalSection';
import Notifications from './components/Notifications/Notifications';
import { appointmentFilterParams, getResetMemberStaffData, getSubmitStaffMemberData } from './editProfileForm.settings';
import { EditProfileFormProps, ProfileFormStateProps } from './editProfileForm.types';

const labelClasses = 'flex-none min-w-[120px] mr-1 mt-2 self-start font-semibold text-gray-700 text-sm';

const EditProfileForm: React.FC<EditProfileFormProps> = ({
  profileDetails,
  isMyProfile,
  onFormSubmit,
  onUpdateMyProfile,
  isFetching,
  profileId = '',
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isFederated } = useAppSelector(selectUser);
  const canChangePassword = isMyProfile && !isFederated;
  const canChangeEmail = !isMyProfile && !profileId;
  const isCreateStaffFlow = !profileId;
  const [getTasks] = useLazyGetTasksQuery();
  const [updateStaffMember, { isLoading: isLoadEdit }] = useUpdateStaffMemberMutation();
  const [editMyProfile, { isLoading: isLoadEditMyProfile }] = useUpdateMyProfileMutation();
  const [addStaffMember, { isLoading: isLoadAdd }] = useAddStaffMemberMutation();
  const [getRoles, { data: rolesData, isSuccess: isSuccessRoles }] = useLazyGetRolesQuery();
  const [unassignStaffTasks, { isLoading: isUnassignLoading }] = useUnassignStaffTasksMutation();
  const { data: timeZoneData } = useGetTimeZoneQuery();
  const [getStaffs] = useLazyGetStaffsQuery();
  const [disabled, setDisabled] = useState(false);
  const [showReassignModal, setShowReassignModal] = useState(false);
  const [shouldRedirectToTasks, setShouldRedirectToTasks] = useState(false);

  const methods = useForm<ProfileFormStateProps>({
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    mode: 'onChange',
  });

  useEffect(() => {
    if (!isMyProfile) {
      // TODO: add pagination
      getRoles({ params: { limit: 100, status: 'active' } });
    }
  }, [getRoles, isMyProfile]);

  const roleOptions = useMemo(
    () =>
      rolesData?.roles?.length && isSuccessRoles
        ? rolesData?.roles?.map((role) => ({ label: role.name, value: role._id }))
        : [],
    [isSuccessRoles, rolesData?.roles],
  );
  const formRoleId = methods.watch('role')?.value;

  const staffRoleShortCode = useMemo(() => {
    if (isMyProfile) {
      return profileDetails?.userType?.shortCode || '';
    } else {
      const formRole = rolesData?.roles?.find((role) => role._id === formRoleId);
      return formRole?.userType?.shortCode ?? (profileDetails?.userRoleInfo?.userType?.shortCode || '');
    }
  }, [
    formRoleId,
    profileDetails?.userRoleInfo?.userType?.shortCode,
    rolesData?.roles,
    isMyProfile,
    profileDetails?.userType?.shortCode,
  ]);

  const isShowAppointmentNotify =
    hasPermission(EDIT_POPUP_PERMISSION.isShowAppointmentNotify, staffRoleShortCode) || isMyProfile;

  const onSuccess = (message: string) => {
    notifySuccess(message);
    getStaffs({ params: queryString.parse(location.search) });

    if (!onUpdateMyProfile) {
      dispatch(closeModal());
    }

    onFormSubmit && onFormSubmit();
  };

  const onError = (errorMessage?: string) => {
    errorMessage && notifyError(errorMessage);
    setDisabled(false);
  };

  const handleCloseReassignModal = () => {
    setShowReassignModal(false);
    setShouldRedirectToTasks(false);
    setDisabled(false);
  };

  const onRedirectToTask = () => {
    const firstName = profileDetails?.firstName;
    const lastName = profileDetails?.lastName;
    const assignName = firstName && lastName ? `&assignedToNames=${firstName}+${lastName}` : '';

    navigate({
      pathname: PathName.Tasks,
      search: `${appointmentFilterParams}${assignName}`,
    });
  };

  const onUpdateStaff = (shouldReassignActiveTask?: boolean) => {
    if (shouldRedirectToTasks) {
      return onRedirectToTask();
    }

    const staff = getSubmitStaffMemberData(methods.watch(), profileDetails, !!isMyProfile);

    updateStaffMember({ staffId: profileId, staff })
      .unwrap()
      .then((data) => onSuccess(data.message))
      .catch(() => onError())
      .finally(() => {
        if (!shouldReassignActiveTask) {
          setDisabled(false);
        }
      });

    if (shouldReassignActiveTask) {
      unassignStaffTasks({ staffId: profileId })
        .unwrap()
        .finally(() => handleCloseReassignModal());
    }
  };

  const checkActiveAppointment = () => {
    setDisabled(true);
    getTasks({
      params: {
        assignedToNames: `${profileDetails?.firstName} ${profileDetails?.lastName}`,
        /**
         * These statuses are copied from the backend
         * They use these statuses to determine if the staff has active tasks
         */
        status: ['Active', 'New', 'URGENT', 'Open', 'Cancelled'] as TaskStatus[],
      },
    })
      .unwrap()
      .then((res) => {
        /**
         * If the staff has active appointments
         * we need to show the reassign modal
         * else just update the staff
         */
        if (res.tasks && Array.isArray(res.tasks) && res.tasks.length > 0) {
          const hasAppointment = res.tasks.some((task) => task.category === TaskCategories.Appointment);
          if (hasAppointment) {
            setShouldRedirectToTasks(true);
          }
          setShowReassignModal(true);
        } else {
          onUpdateStaff();
        }
      });
  };

  const handleFormSubmit: SubmitHandler<ProfileFormStateProps> = async (formData) => {
    setDisabled(true);

    if (formData) {
      const staff = getSubmitStaffMemberData(formData, profileDetails, !!isMyProfile);

      if (formData.resetPassword?.oldPassword && formData.resetPassword?.newPassword) {
        try {
          await updatePassword({
            oldPassword: formData.resetPassword.oldPassword,
            newPassword: formData.resetPassword.newPassword,
          });
        } catch (error) {
          if (typeof error === 'object' && error !== null && 'message' in error) {
            return onError((error as { message: string }).message);
          } else return onError(JSON.stringify(error));
        }
      }

      if (isMyProfile) {
        editMyProfile({ staff })
          .unwrap()
          .then((data) => onSuccess(data.message))
          .catch(() => onError());
      } else if (profileId) {
        /**
         * If we change status to inactive and this if PH role
         * we need to check if there are any appointments assigned to this staff
         */
        if (
          profileDetails?.userType?.shortCode === RoleShortName.Physician &&
          staff?.status === 'inactive' &&
          staff.status !== profileDetails?.status
        ) {
          return checkActiveAppointment();
        }
        updateStaffMember({ staffId: profileId, staff })
          .unwrap()
          .then((data) => onSuccess(data.message))
          .catch(() => onError());
      } else {
        addStaffMember({ staff })
          .unwrap()
          .then((data) => onSuccess(data.message))
          .catch(() => onError());
      }
    }
    return;
  };

  useEffect(() => {
    if (profileDetails && !isFetching) {
      const resetStaffData = getResetMemberStaffData(profileDetails, timeZoneData);

      methods.reset(resetStaffData);
    }
  }, [profileDetails, isFetching, methods.reset, methods, timeZoneData]);

  return (
    <FormProvider {...methods}>
      <Common.Modal isOpen={showReassignModal} zIndex={120} padding={false} hideCloseButton size="base">
        <ReAssignAppointmentPopup
          handleSubmit={() => onUpdateStaff(true)}
          handleCancel={handleCloseReassignModal}
          shouldReassign={shouldRedirectToTasks}
        />
      </Common.Modal>
      <form onSubmit={methods.handleSubmit(handleFormSubmit)} className="mt-5 grid grid-cols-2 gap-x-10 gap-y-3">
        <Loader isVisible={isLoadEdit || isLoadAdd || isLoadEditMyProfile || isUnassignLoading} />

        <div className="col-span-2 h-px w-full bg-gray-200" />

        {!isMyProfile && <CurrentRoleSelect roleOptions={roleOptions} />}

        <MainFormComponents
          labelClasses={labelClasses}
          canChangeEmail={canChangeEmail}
          isMyProfile={!!isMyProfile}
          licensedTeams={profileDetails?.teams}
        />

        {canChangePassword && (
          <Controller
            control={methods.control}
            name="resetPassword"
            render={({ field: { onChange } }) => (
              <ResetPassword
                wrapperResetPassword="flex flex-auto gap-10 col-span-2"
                control={methods.control}
                watch={methods.watch}
                onChange={onChange}
                labelClasses={labelClasses}
                isRequired={false}
              />
            )}
          />
        )}

        {hasPermission(EDIT_POPUP_PERMISSION.biographyAndEducationSection, staffRoleShortCode) && (
          <AdditionalSection
            currentRole={staffRoleShortCode}
            labelClasses={labelClasses}
            licensedStates={profileDetails?.activeStates}
            // licensedTeams={profileDetails?.teams}
            isMyProfile={!!isMyProfile}
          />
        )}
        {isShowAppointmentNotify && profileDetails?.notificationPreferences && (
          <>
            <div className="col-span-2 h-px w-full bg-gray-200" />
            <Notifications control={methods.control} />
          </>
        )}
        {!isMyProfile && (
          <>
            <div className="col-span-2 h-px w-full bg-gray-200" />
            <PaySection defaultUserType={profileDetails?.staffCostInfo?.staff} isCreateStaffFlow={isCreateStaffFlow} />
          </>
        )}

        <div className="col-span-2">
          <PopupFooter disabled={disabled} hiddenDeleteButton />
        </div>
      </form>
    </FormProvider>
  );
};

export default EditProfileForm;
