import { useEffect } from 'react';

import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import AppliedFiltersTags from 'components/filters/AppliedFiltersTags/AppliedFiltersTags';
import SearchForm from 'components/filters/SearchForm';
import { AgingAlertsTableProps } from 'components/tables/AgingAlertsTable/agingAlertsTable.types';
import BasicTable from 'components/tables/BasicTable';
import { columns } from 'components/tables/History/columns';
import { useGetDefaultBasicTableValues } from 'hooks/common/useGetDefaultBasicTableValues';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLazyGetHistoryQuery } from 'store/patients/patientsSlice';

import { HistoryProps } from './history.types';

const History: React.FC<HistoryProps> = ({ patientId }) => {
  const [getHistory, { data, isFetching, isUninitialized }] = useLazyGetHistoryQuery();
  const [tableData, tableColumns] = useGetDefaultBasicTableValues({
    isFetching: isFetching || isUninitialized,
    columns,
    data: data?.data,
  });
  const table = useReactTable({
    data: tableData,
    columns: tableColumns as ColumnDef<AgingAlertsTableProps, unknown>[],
    getCoreRowModel: getCoreRowModel(),
  });

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (queryString.parse(location.search)?.['active-tab'] === 'History' && location.search.includes('pageNo')) {
      const parsedUrl = queryString.parse(location.search);
      const query = Object.fromEntries(
        Object.entries(parsedUrl).filter(([key]) => {
          return key != 'active-tab';
        }),
      );
      getHistory({ id: patientId, params: `?${queryString.stringify(query)}` });
    }
  }, [location.search, navigate, getHistory, patientId]);

  return (
    <div className="px-6 pb-6 pt-4">
      <div className="relative mb-4 flex items-center justify-between">
        <AppliedFiltersTags />
        <SearchForm showSearchBar />
      </div>
      <div>
        <BasicTable table={table} totalCount={data?.info.totalCount || 0} isFetching={isFetching} />
      </div>
    </div>
  );
};

export default History;
