import { useEffect, useRef } from 'react';

import { notifyError } from 'components/common/Toast/Toast';

import { ChargifyFormProps } from './chargifyForm.types';

const chargifyConfig = {
  publicKey: import.meta.env.VITE_CHARGIFY_PUBLIC_KEY,
  selector: '#chargify-form',
  selectorForPayPalButton: '#pay-pal',
  serverHost: import.meta.env.VITE_CHARGIFY_SERVER,
  threeDSecure: false,
  // in case if we'll pass invalid cards, will uncomment
  // threeDSecure: import.meta.env.VITE_ENV === 'production',
  type: 'pay_pal',
};

const ChargifyForm: React.FC<ChargifyFormProps> = ({ onGetPaypalToken }) => {
  const chargifyForm = useRef<HTMLFormElement>(null);
  // Disable types for specific field Chargify from script https://js.chargify.com/latest/chargify.js
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: Unreachable code error
  const chargify = useRef(new window.Chargify());
  const currentChargify = chargify?.current;

  const handleSubmit = () => {
    try {
      currentChargify.token(chargifyForm.current, onGetPaypalToken, notifyError);
    } catch (err) {
      notifyError((err as { message: string }).message);
    }
  };

  useEffect(() => {
    currentChargify.load(chargifyConfig, {
      onPayPalAuthorized: handleSubmit,
      // this function is called on internal error related to PayPal
      onPayPalError: notifyError,
      onThreeDsConfigError: function (error: { message: string }) {
        if (typeof error === 'string') {
          notifyError(error);
        } else notifyError(error.message);
      },
    });
    return () => {
      currentChargify.unload();
    };
    // should depends only on chargify
  }, [chargify]);

  return (
    <form ref={chargifyForm} onSubmit={handleSubmit}>
      <div id="pay-pal"></div>
    </form>
  );
};

export default ChargifyForm;
