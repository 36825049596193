import { useCallback, useRef } from 'react';

import Card from 'components/common/Card';
import QuickLinks from 'components/common/QuickLinks';
import ManageRoleForm from 'components/Roles/ManageRoleForm';

const tabLinks = ['Role information', 'Page permissions', 'Editing permissions'];

const ManageRole: React.FC = () => {
  const roleRef = useRef<HTMLHeadingElement>(null);
  const pageRef = useRef<HTMLHeadingElement>(null);
  const editingRef = useRef<HTMLHeadingElement>(null);

  const handleActiveLink = useCallback((link: string) => {
    if (link === tabLinks[0]) {
      roleRef.current?.scrollIntoView({ behavior: 'smooth' });
    } else if (link === tabLinks[1]) {
      pageRef.current?.scrollIntoView({ behavior: 'smooth' });
    } else if (link === tabLinks[2]) {
      editingRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <Card dataTestId="manage_roles_page_block" className="flex flex-wrap gap-x-16 px-12 py-9">
      <ManageRoleForm tabLinks={tabLinks} roleRef={roleRef} pageRef={pageRef} editingRef={editingRef} />
      <QuickLinks tabLinks={tabLinks} onChange={handleActiveLink} />
    </Card>
  );
};

export default ManageRole;
