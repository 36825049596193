import { OrdersFilterFormProps } from './ordersFilterForm.types';

export const defaultValues: OrdersFilterFormProps = {
  patient: '',
  orderId: '',
  searchKey: '',
  createdAt: '',
  pharmacyType: { value: '', label: '' },
  orderStatus: { value: '', label: '' },
};
