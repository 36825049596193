import { Fragment } from 'react';

import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react';
import { Common } from '@thecvlb/design-system';
import { notifySuccess } from 'components/common/Toast/Toast';
import { useAppDispatch } from 'hooks/redux';
import { useUpdateTimezoneMutation } from 'store/myProfile/myProfileSlice';
import { useUpdateProviderTimezoneMutation } from 'store/providers/providersSlice';
import { Staff } from 'store/staffs/staffs.types';
import { updateUserTimezone } from 'store/user/userSlice';

import { FormValues } from '../updateTimezone.types';
import UpdateTimezoneForm from '../UpdateTimezoneForm/UpdateTimezoneForm';

const UpdateTimezonePopover: React.FC<{ popoverButtonTitle: string; staffDetails?: Staff }> = ({
  popoverButtonTitle,
  staffDetails,
}) => {
  const dispatch = useAppDispatch();

  const [updateTimezone, { isLoading: isLoadingUpdateLocalTimezone }] = useUpdateTimezoneMutation();
  const [updateStaffTimezone, { isLoading: isLoadingUpdateStaffTimezone }] = useUpdateProviderTimezoneMutation();

  const isLoading = isLoadingUpdateLocalTimezone || isLoadingUpdateStaffTimezone;

  const saveData = (data: FormValues, close: () => void) => {
    if (staffDetails) {
      updateStaffTimezone({ providerId: staffDetails.userId, timezone: data.timezone.value })
        .unwrap()
        .then((response) => {
          notifySuccess(response.message);
          close();
        });
    } else {
      updateTimezone({ timezone: data.timezone.value })
        .unwrap()
        .then((response) => {
          notifySuccess(response.message);
          dispatch(updateUserTimezone({ timezone: data.timezone.value }));
          close();
        });
    }
  };

  return (
    <Popover className="relative" data-testid="update-timezone-popover">
      <PopoverButton as={Common.Button} color="white" size="sm" className="rounded-lg">
        {popoverButtonTitle}
      </PopoverButton>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <PopoverPanel className="absolute right-0 top-10 z-10 w-[360px] rounded-2xl bg-white p-6 shadow-xl">
          {({ close }) => (
            <UpdateTimezoneForm
              isLoading={isLoading}
              saveData={(data) => saveData(data, close)}
              close={close}
              staffTimezone={staffDetails?.timezone}
            />
          )}
        </PopoverPanel>
      </Transition>
    </Popover>
  );
};

export default UpdateTimezonePopover;
