import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import {
  DayStaffSchedule,
  GetCombineStaffScheduleItem,
  ShiftTypes,
  ShiftTypesResponseProps,
} from 'store/calendar/calendar.types';

import { DaysProps, UsersProps } from './providerAvailability.types';

/**
 * container width(%) / hours = width
 * 100 / 24 = 4.16666666667
 */
const width = 4.16666666667;

export const hours = Array.from({ length: 24 }, (_, i) => dayjs().hour(i).format(DateFormat.ha));

export const timeStringToMinutes = (timeString: string) => {
  const parsedTimeString = timeString.split(':');
  return +parsedTimeString[0] * 60 + +parsedTimeString[1];
};

export const getLeft = (time: string) => timeStringToMinutes(time) * (width / 60) + '%';

export const getRight = (time: string) => (24 * 60 - timeStringToMinutes(time)) * (width / 60) + '%';

export const generateDaysList = (dataCombinedArray: GetCombineStaffScheduleItem[], week: number): DaysProps[] => {
  const fromDate = dayjs().week(week).day(0).format(DateFormat.YYYY_MM_DD);

  const weekDates = Array.from({ length: 7 }, (_, i) => dayjs(fromDate).add(i, 'day').format(DateFormat.YYYY_MM_DD));

  return weekDates.reduce((acc: DaysProps[], currentDate: string) => {
    const existingItem = dataCombinedArray.find((item) => item.date === currentDate);

    return [
      ...acc,
      {
        date: currentDate,
        timeSlots: existingItem
          ? existingItem.timeSlots.map((timeSlot) => ({
              left: getLeft(timeSlot.startTime),
              right: getRight(timeSlot.endTime),
              ...timeSlot,
            }))
          : [],
      },
    ];
  }, []);
};

export const generateUserTimeList = (
  staffList: DayStaffSchedule[],
  shiftTypes?: ShiftTypesResponseProps[],
  localTz?: string,
): UsersProps[] => {
  return staffList.map(({ schedule, user }) => {
    const newShiftTypes = (eventShiftTypes: ShiftTypes[]): ShiftTypesResponseProps[] => {
      return eventShiftTypes.reduce((list: ShiftTypesResponseProps[], item: ShiftTypes) => {
        const findItem = shiftTypes?.find((type) => type._id === item._id);

        if (findItem) {
          const selectedFilters =
            findItem.filters && findItem.filters.filter((filter) => item.filters && item.filters.includes(filter._id));

          const selectedExclusions =
            findItem.filters &&
            findItem.filters.filter((filter) => item.exclusions && item.exclusions.includes(filter._id));

          return [...list, { ...findItem, filters: selectedFilters ?? [], exclusions: selectedExclusions ?? [] }];
        }

        return list;
      }, []);
    };

    return {
      user: user,
      timeSlots: schedule.map((event) => ({
        startTime: dayjs(event.startTime).tz(localTz).format(DateFormat.HH_mm),
        endTime: dayjs(event.endTime).tz(localTz).format(DateFormat.HH_mm),
        left: getLeft(dayjs(event.startTime).tz(localTz).format(DateFormat.HH_mm)),
        right: getRight(dayjs(event.endTime).tz(localTz).format(DateFormat.HH_mm)),
        type: event.type,
        shiftTypes: newShiftTypes(event.shiftTypes ?? []),
      })),
    };
  });
};
