import { PaymentMethod } from 'enums/crossSell';
import { PaymentProfile } from 'store/patients/patients.types';

export const getCardTypeName = (data: PaymentProfile) => {
  const isPayPal = data.paymentMethod === PaymentMethod.Paypal;
  if (isPayPal) {
    return 'PayPal';
  }
  if (data.cardType === 'master card') {
    return 'Mastercard';
  }
  return data.cardType;
};
