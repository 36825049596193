import { useCallback, useMemo } from 'react';

import { AutocompleteInputSelectProps } from '@thecvlb/design-system/lib/src/common/AutocompleteInputSelect/autocompleteInputSelect.props';
import dayjs from 'dayjs';
import { useAppSelector } from 'hooks/redux';
import { useDebounce } from 'react-use';
import type { AvailableStaffResponse } from 'store/tasks/task.types';
import { selectTask, useLazyGetAvailableStaffQuery } from 'store/tasks/tasksSlice';

export const useGetAvailableStaff = (query: string, userId?: string, taskId?: string, includeLeadership?: boolean) => {
  const [getAvailableStaff, { data: availableStaff, isFetching, isSuccess, isLoading }] =
    useLazyGetAvailableStaffQuery();
  const { taskDetails } = useAppSelector(selectTask);

  useDebounce(
    () => {
      if (query?.trim()) {
        getAvailableStaff({
          taskId: taskId ?? taskDetails._id,
          params: { searchKey: query, timezone: dayjs.tz.guess() },
        });
      }
    },
    1000,
    [query, taskDetails, taskId],
  );

  /**
   * This value show if the list has a clinical leader
   */
  const hasClinicalLeader = availableStaff?.data?.some((item) => item.isClinicalLeadership);

  const normalizeAvailableStaffData = useCallback(
    (data: AvailableStaffResponse[]): AutocompleteInputSelectProps['options'] =>
      data.flatMap((item) => {
        if (item._id === userId) return [];
        /**
         * If user don't want to include leadership in the list
         * And the item is a clinical leader
         * Then return an empty array
         */
        if (!includeLeadership && item.isClinicalLeadership) return [];

        return [
          {
            label: item.name,
            value: item,
            id: item._id,
            avatar: {
              size: 'sm',
              role: item.userType.shortCode,
              src: item.profileImage,
            },
            isClinicalLeadership: item.isClinicalLeadership,
            customClassName: !!item.warning?.message ? 'opacity-30' : '',
          },
        ];
      }),
    [includeLeadership, userId],
  );

  const searchData: AutocompleteInputSelectProps['options'] = useMemo(
    () => (availableStaff?.data?.length ? normalizeAvailableStaffData(availableStaff.data) : []),
    [availableStaff, normalizeAvailableStaffData],
  );

  return useMemo(
    () => ({
      searchData,
      isLoading: isLoading || isFetching,
      hasClinicalLeader,
      isSuccess,
    }),
    [searchData, isLoading, isFetching, hasClinicalLeader, isSuccess],
  );
};
