import { MouseEvent, useEffect, useMemo, useState } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';
import Loader from 'components/common/Loader';
import { useDispatch } from 'react-redux';
import { closeModal } from 'store/modal/modalSlice';
import { ReviewProps, ReviewStatuses } from 'store/reviews/reviews.types';
import { useModerateReviewsMutation } from 'store/reviews/reviewsSlice';

import { getPrevNextReviewsId } from './review.settings';
import ReviewDetails from './ReviewDetails';

const Review: React.FC<{
  id: string;
  reviews: ReviewProps[];
}> = ({ id, reviews }) => {
  const dispatch = useDispatch();
  const [moderateReviews, { isLoading }] = useModerateReviewsMutation();
  const [reviewId, setReviewId] = useState(id);
  const review = useMemo(() => reviews.find((item) => item._id === reviewId), [reviewId, reviews]);
  const { prevReviewId, nextReviewId } = useMemo(() => getPrevNextReviewsId(reviewId, reviews), [reviewId, reviews]);
  const isHidden = review?.status === ReviewStatuses.Hidden;
  const isPublished = review?.status === ReviewStatuses.Published;

  useEffect(() => {
    if (!reviewId) {
      dispatch(closeModal());
    }
  }, [reviewId, dispatch]);

  const handlePagination = (event: MouseEvent<HTMLButtonElement>) => {
    if (event.currentTarget.name === 'prev' && prevReviewId) {
      setReviewId(prevReviewId);
    }
    if (event.currentTarget.name === 'next' && nextReviewId) {
      setReviewId(nextReviewId);
    }
  };

  const isNextReviewIconDisabled = !nextReviewId || isLoading;
  const isPrevReviewIconDisabled = !prevReviewId || isLoading;
  const nextReviewClassNames = classNames({ 'text-gray-500': isNextReviewIconDisabled });
  const prevReviewClassNames = classNames({ 'text-gray-500': isPrevReviewIconDisabled });

  const handleModerateReview = (event: MouseEvent<HTMLButtonElement>) => {
    if (!reviewId) return;
    let status;
    if (event.currentTarget.name === ReviewStatuses.Hidden) {
      status = isHidden ? ReviewStatuses.New : ReviewStatuses.Hidden;
    }
    if (event.currentTarget.name === ReviewStatuses.Published) {
      status = isPublished ? ReviewStatuses.New : ReviewStatuses.Published;
    }
    const body = {
      reviewIds: [reviewId],
      status,
    };
    moderateReviews(body)
      .unwrap()
      .then(() => {
        if (nextReviewId) {
          setTimeout(() => {
            setReviewId(nextReviewId);
          }, 300);
        } else {
          dispatch(closeModal());
        }
      });
  };

  const actionButtonStyle = isPublished || isHidden ? 'text-only' : undefined;

  return (
    <>
      <Loader isVisible={isLoading} />
      <div data-testid="review_moderation_popup" className="p-6">
        <div className="flex content-end justify-between">
          <h1 className="mb-9 text-xl font-bold text-gray-700">Review: {review?.patientInfo?.name}</h1>
          <div className="flex items-start overflow-hidden">
            <button className="p-1" onClick={handlePagination} disabled={isPrevReviewIconDisabled} name="prev">
              <Common.Icon name="arrow-left" className={prevReviewClassNames} />
            </button>
            <button className="p-1" onClick={handlePagination} disabled={isNextReviewIconDisabled} name="next">
              <Common.Icon name="arrow-right" className={nextReviewClassNames} />
            </button>
          </div>
        </div>

        <ReviewDetails review={review} />

        <div className="mt-10 flex justify-between gap-x-2">
          <Common.Button
            color="white-alt"
            onClick={() => dispatch(closeModal())}
            size="sm"
            name={ReviewStatuses.Hidden}
          >
            Cancel
          </Common.Button>

          <div className="flex gap-2">
            <Common.Button
              color={isHidden ? 'red' : isPublished ? 'white' : 'red-alt'}
              style={actionButtonStyle}
              size="sm"
              onClick={handleModerateReview}
              name={ReviewStatuses.Hidden}
              disabled={isLoading || isHidden}
            >
              <Common.Icon name={isHidden ? 'check' : 'not-available'} />
              {isHidden ? 'Hidden' : 'Hide'}
            </Common.Button>

            <Common.Button
              color={isPublished ? 'green' : isHidden ? 'white' : 'blue'}
              style={actionButtonStyle}
              onClick={handleModerateReview}
              name={ReviewStatuses.Published}
              size="sm"
              disabled={isLoading || isPublished}
            >
              <Common.Icon name={isPublished ? 'check' : 'thumb-up'} className="" />
              {isPublished ? 'Published' : 'Publish'}
            </Common.Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Review;
