import { useMemo } from 'react';

import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { AgingAlertsTableProps } from 'components/tables/AgingAlertsTable/agingAlertsTable.types';
import BasicTable from 'components/tables/BasicTable';
import { columns } from 'components/tables/PatientPrescriptions/column';
import { useGetDefaultBasicTableValues } from 'hooks/common/useGetDefaultBasicTableValues';
import queryString from 'query-string';
import { useSearchParams } from 'react-router-dom';
import { useGetPatientPrescriptionsQuery } from 'store/prescriptions/prescriptionsSlice';

import { PrescriptionTableProps } from './prescriptionTable.types';

const PrescriptionTable: React.FC<PrescriptionTableProps> = ({ patientInfo }) => {
  const patientId = patientInfo?._id || '';
  const [searchParams] = useSearchParams();

  const queryParams = useMemo(() => {
    const queryParamsData = queryString.parse(searchParams.toString());
    if (queryParamsData?.taskModalOpenID) delete queryParamsData?.taskModalOpenID;
    return queryParamsData.limit ? queryParamsData : { limit: 10, ...queryParamsData };
  }, [searchParams]);

  const { data, isLoading, isFetching } = useGetPatientPrescriptionsQuery({ patientId, params: queryParams });
  const isFetchingData = isLoading || isFetching;

  const [tableData, tableColumns] = useGetDefaultBasicTableValues({
    isFetching: isFetchingData,
    columns: columns(patientInfo),
    data: data?.data || [],
  });
  const table = useReactTable({
    data: tableData,
    columns: tableColumns as ColumnDef<AgingAlertsTableProps, unknown>[],
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div>
      <BasicTable table={table} isFetching={isLoading} totalCount={data?.info.totalCount || 0} />
    </div>
  );
};

export default PrescriptionTable;
