import { DayPicker } from 'react-day-picker';
import styled from 'styled-components';
import tw from 'twin.macro';

export const StyledDayPicker = styled(DayPicker)`
  .rdp-day {
    ${tw`w-8 h-8 m-1 text-sm`};
  }
  .rdp-day_selected:not(.rdp-day_disabled):not(.rdp-day_outside) {
    ${tw`bg-primary hover:bg-primary hover:opacity-90`};
  }
  .rdp-day_selected,
  .rdp-day_range_end {
    ${tw`!rounded-full`}
  }
  .rdp-head_row {
    ${tw`w-9 h-8 text-base m-1 uppercase`};
  }
  .rdp-day:not(.rdp-day_disabled):not(.rdp-day_selected) {
    ${tw`text-gray-700`};
  }
  .rdp-day:not(.rdp-day_disabled):not(.rdp-day_selected):hover {
    ${tw`text-primary font-bold bg-secondary-100`};
  }
  .rdp-day_outside {
    ${tw`opacity-20`}
  }
  .rdp-head_cell {
    ${tw`text-gray text-xs font-medium`}
  }
  .rdp-caption_label {
    ${tw`text-xl`};
  }
  .rdp-nav_icon {
    ${tw`w-3 h-5`}
  }
  .rdp-nav_button_next:hover,
  .rdp-nav_button_next:hover {
    ${tw`font-bold bg-secondary-100`}
  }

  .rdp-months {
    ${tw`mt-0 lg:text-xs`};
  }
  .DayPicker-wrapper {
    ${tw`pt-4`};
  }
`;
