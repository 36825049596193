import styled from 'styled-components';

export const StyledFormWrapper = styled.div`
  .tox.tox-tinymce {
    border: none;
  }
  .tox-editor-container {
    border-radius: 12px;
  }
`;
