import { VideoCallIssueType, VideoCallProblemType } from 'enums/videoCall';

export interface StaffFeedbackProps {
  patientInfo: {
    name: string;
  };
  providerInfo: {
    name: string;
  };
  status: string;
  issueDescriptions?: VideoCallProblemType[];
  issueType?: VideoCallIssueType;
  comment?: string;
  roomName: string;
  createdAt: string;
}
export interface StaffFeedbackQueryParams {
  limit?: number;
  pageNo?: number;
  sortField?: string;
  sortOrder?: string;
  searchKey?: string;
  patientName?: string;
  providerName?: string;
  createdAt?: string;
  status?: string;
  issueType?: string;
  issueDescriptions?: string[];
  roomName?: string;
  startCreateDate?: string;
  endCreateDate?: string;
}

export interface StaffFeedbackListProps {
  data: StaffFeedbackProps[];
  info: {
    totalCount: number;
  };
}

export const initialState: StaffFeedbackListProps = {
  data: [
    {
      patientInfo: {
        name: '',
      },
      providerInfo: {
        name: '',
      },
      status: '',
      roomName: '',
      createdAt: '',
    },
  ],
  info: {
    totalCount: 0,
  },
};
