import { Common } from '@thecvlb/design-system';

import { NavBarItemsProps } from './navbar.types';

const NavBarItems: React.FC<NavBarItemsProps> = ({ areaKey, sectionKeys, areaName, sectionName, handleItemClick }) => {
  return (
    <>
      {sectionKeys.map((key) => (
        <Common.MenuVertical
          key={key}
          label={key}
          active={areaKey === areaName && key === sectionName}
          size="md"
          onClick={() => handleItemClick(areaKey, key)}
        />
      ))}
    </>
  );
};

export default NavBarItems;
