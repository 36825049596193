import { FieldValues, useController } from 'react-hook-form';

import { ControlledSelectProps } from './controlledSelect.types';
import Select from '../Select';

const ControlledSelect = <TFieldValues extends FieldValues>({
  control,
  name,
  helper,
  rules,
  options = [],
  className,
  label,
  disabled,
}: ControlledSelectProps<TFieldValues>) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });
  return (
    <Select
      label={label}
      name={field.name}
      value={field.value}
      onChange={field.onChange}
      options={options}
      helper={helper || error?.message}
      errors={error}
      className={className}
      disabled={disabled}
    />
  );
};

export default ControlledSelect;
