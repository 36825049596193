import { createSelector } from '@reduxjs/toolkit';
import { Common } from '@thecvlb/design-system';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import { useAppSelector } from 'hooks/redux';
import { parseWorkHours } from 'pages/Availability/availability.settings';
import { FormProvider, useForm } from 'react-hook-form';
import { selectAvailability, useUpdateAvailabilityMutation } from 'store/availability/availabilitySlice';

import { FormProps } from './form.types';
import DayCard from '../DayCard';

dayjs.extend(localeData);

const days = dayjs.weekdays();

const selectFormState = createSelector(selectAvailability, (availability) => ({
  workHours: availability.data.workHours || {},
  availableDays: availability.availableDays,
}));

const Form: React.FC<FormProps> = ({ tabsData, setActiveTab, userId, getLocalTz }) => {
  const [updateAvailability] = useUpdateAvailabilityMutation();

  const { workHours, availableDays } = useAppSelector(selectFormState);

  const methods = useForm({
    criteriaMode: 'all',
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const onSubmit = () => {
    const body = { ...workHours };
    for (const day in body) {
      if (!availableDays[day]) {
        body[day] = [];
      }
    }
    updateAvailability({ userId, localTz: getLocalTz(), body });
  };

  return (
    <FormProvider {...methods}>
      <form
        id="availability-form"
        className="mx-8 flex w-3/5 flex-col divide-y divide-gray-100"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        {tabsData && (
          <div className="pt-4">
            <div className="my-5">
              <Common.Tabs onChange={setActiveTab} data={tabsData} type="pill" />
            </div>
          </div>
        )}
        {days.map((day: string) => (
          <DayCard isChecked={availableDays[day]} key={day} day={day} workHours={parseWorkHours(day, workHours)} />
        ))}
      </form>
    </FormProvider>
  );
};

export default Form;
