export const NOT_ACTIVE_TASK_STATUSSES = ['Completed', 'Deleted'];

export const TASK_CATEGORIES = [
  { label: 'Messages', value: 'Messages' },
  { label: 'Post-consult', value: 'Post-consult' },
  { label: 'Appointments', value: 'Appointment' },
  { label: 'Requests', value: 'Requests' },
  { label: 'Requests • PA', value: 'Request • PA' },
  { label: 'Review', value: 'Review' },
  { label: 'Async', value: 'Async' },
  { label: 'Front desk', value: 'Front desk' },
  { label: 'Onboarding', value: 'Onboarding' },
  { label: 'Pre-consult', value: 'Pre-consult' },
  { label: 'Queue', value: 'Queue' },
];

export const TASK_STATUSES = [
  { label: 'All', value: 'All', textColor: 'text-gray-700', bgColor: 'bg-gray-200' },
  { label: 'Urgent', value: 'URGENT', textColor: 'text-red-500', bgColor: 'bg-red-100' },
  { label: 'New', value: 'New', textColor: 'text-secondary-500', bgColor: 'bg-secondary-100' },
  { label: 'Active', value: 'Active', textColor: 'text-yellow-500', bgColor: 'bg-yellow-100' },
  { label: 'Open', value: 'Open', textColor: 'text-primary-500', bgColor: 'bg-primary-100' },
  { label: 'Cancelled', value: 'Cancelled', textColor: 'text-gray-700', bgColor: 'bg-gray-200' },
];

export const CATEGORY_OPTIONS_HISTORY = [
  { label: 'Appointments task', value: 'appointment' },
  { label: 'Pre-consult task', value: 'pre-consult' },
  { label: 'Onboarding task', value: 'onboarding' },
  { label: 'Messages task or response', value: 'message' },
  { label: 'Front desk task', value: 'front-desk' },
  { label: 'Requests task', value: 'request' },
  { label: 'Post-consult task', value: 'post-consult' },
  { label: 'Review', value: 'review' },
  { label: 'Notifications', value: 'notification' },
  { label: 'App/Patient Activity', value: 'app/patient activity' },
  { label: 'Billing/Account', value: 'billing/account' },
  { label: 'System events', value: 'system' },
  { label: 'Automations', value: 'automations' },
  { label: 'Other', value: 'other' },
];
