import { apiSlice } from 'store/api/apiSlice';

export const providersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    updateProviderTimezone: builder.mutation<{ message: string }, { providerId: string; timezone: string }>({
      query: ({ providerId, timezone }) => ({
        url: `/providers/${providerId}/timezone`,
        method: 'PATCH',
        body: { timezone },
      }),
      invalidatesTags: ['Staff'],
    }),
  }),
});

export const { useUpdateProviderTimezoneMutation } = providersApiSlice;
