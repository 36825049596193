import { useCallback, useEffect, useMemo, useRef } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import {
  clearStaffNotes,
  selectStaffNotes,
  staffNotesSelectors,
  useLazyInitStaffNotesQuery,
} from 'store/staffNotes/staffNotesSlice';

import { selectUser } from '../../store/user/userSlice';
import { hasPermission, TASK_POPUP_PERMISSIONS } from '../../utils/popupsContentPermission';
import { useAppDispatch, useAppSelector } from '../redux';

const selectStaffNotesState = createSelector(
  [selectStaffNotes, staffNotesSelectors.selectTotal, selectUser],
  (staffNotes, staffNotesListLength, user) => ({
    totalCount: staffNotes.totalStaffNotesCount,
    staffNotesListLength,
    userTypeName: user.userType.name,
  }),
);

export const useStaffNotes = ({ patientId }: { patientId: string }) => {
  const [initStaffNotes, { isLoading, isFetching }] = useLazyInitStaffNotesQuery({ refetchOnReconnect: true });
  const { totalCount, staffNotesListLength, userTypeName } = useAppSelector(selectStaffNotesState);
  const staffNotesLimit = 10;
  const page = useRef(Math.ceil(staffNotesListLength / 10));
  const dispatch = useAppDispatch();

  const hasPermissionToSendMessage = useMemo(() => {
    return hasPermission(TASK_POPUP_PERMISSIONS.sendStaffNote, userTypeName);
  }, [userTypeName]);

  const loadMessages = useCallback(() => {
    page.current += 1;

    if (totalCount !== staffNotesListLength && (!isLoading || !isFetching)) {
      initStaffNotes({ id: patientId, limit: staffNotesLimit, pageNo: page.current });
    }
  }, [totalCount, staffNotesListLength, isLoading, isFetching, initStaffNotes, patientId]);

  const resetStaffNotes = useCallback(() => {
    dispatch(clearStaffNotes());
  }, [dispatch]);

  useEffect(() => {
    if (patientId) initStaffNotes({ id: patientId, limit: staffNotesLimit, pageNo: 0 });
  }, [initStaffNotes, patientId]);

  return { hasPermissionToSendMessage, loadMessages, isLoading, isFetching, resetStaffNotes, staffNotesListLength };
};
