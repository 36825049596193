import { Common } from '@thecvlb/design-system/lib/src';
import { DateFormat } from 'enums/dateFormats';
import { useAppDispatch } from 'hooks/redux';
import { closeModal } from 'store/modal/modalSlice';

import { getEventTime, getEventTypeName } from './calendarTimeConflict.settings';
import { CalendarTimeConflictProps } from './calendarTimeConflict.types';

const CalendarTimeConflict: React.FC<CalendarTimeConflictProps> = ({ conflicts, eventDetails, calendarTimezone }) => {
  const dispatch = useAppDispatch();

  const { startTime, endTime, isSameDay } = getEventTime(eventDetails, calendarTimezone);
  const eventTypeName = getEventTypeName(eventDetails.type);
  const conflictingEventTypeName = getEventTypeName(conflicts[0].type);

  return (
    <div className="flex flex-col gap-6 p-6">
      <h3 className="mb-2 text-xl font-bold leading-6">Time conflict</h3>
      <span className="flex flex-col text-justify text-base font-medium text-gray-700">
        <span>
          Could not create {eventTypeName} for {startTime.format(DateFormat.dddd_for_h_a)} -{' '}
          {endTime.format(isSameDay ? DateFormat.h_a : DateFormat.dddd_for_h_a)} as that would overlap with:
        </span>
        <ul className="list-inside list-none">
          {(conflicts || []).map((event) => {
            const { startTime: start, endTime: end, isSameDay: isSame } = getEventTime(event, calendarTimezone, true);
            return (
              <li key={event._id}>
                <span className="px-2">•</span>
                {start.format(DateFormat.dddd__h_a)} - {end.format(isSame ? DateFormat.h_a : DateFormat.dddd__h_a)}
              </li>
            );
          })}
        </ul>
        <span className="pt-6">Please edit the overlapping {conflictingEventTypeName} first.</span>
      </span>
      <Common.Button
        onClick={() => dispatch(closeModal())}
        color="white-alt"
        size="sm"
        className="w-full justify-center"
      >
        Close
      </Common.Button>
    </div>
  );
};

export default CalendarTimeConflict;
