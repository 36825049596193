import { Common } from '@thecvlb/design-system';
import { PathName } from 'enums/pathName';
import { useAppSelector } from 'hooks/redux';
import { useNavigate } from 'react-router-dom';

import { formatHeaderText, getColorMapValue } from '../../../../components/tables/OrdersTable/ordersTable.settings';
const Body: React.FC<{ handleClose: () => void }> = ({ handleClose }) => {
  const navigate = useNavigate();
  const { orderDetails } = useAppSelector(({ orders }) => orders);
  const handleViewPatientDetails = () => {
    if (typeof orderDetails.patient === 'object') {
      navigate(`${PathName.Patient}/${orderDetails.patient?.externalId}`);
    }
  };
  return (
    <>
      <div className="rounded-tl-2xl border-b-2 bg-gray-50 p-6">
        <div className="flex w-full items-center justify-between">
          <div className="flex items-center">
            <button
              data-testid="close_btn"
              onClick={handleClose}
              className="mr-4 size-8 rounded-full border border-gray-200 bg-gray-50 p-[5px] text-gray outline-none"
            >
              <Common.Icon data-testid="close_btn" name="close" />
            </button>
            {typeof orderDetails.patient === 'object' && (
              <>
                <div className="flex">
                  <div className="mr-4">
                    <span className="cursor-pointer text-xl font-bold text-gray-700" onClick={handleViewPatientDetails}>
                      {orderDetails.patient?.firstName} {orderDetails.patient?.lastName}
                    </span>
                    <div className="text-base">
                      <span className="font-bold">Order</span> {orderDetails._id}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="flex gap-4">
            <div className="min-w-12">
              <div className="mb-2 flex justify-center">
                <Common.Icon className="text-primary" name="list-view-outline" />
              </div>
              {orderDetails.status && (
                <Common.ColorTag
                  color={getColorMapValue(orderDetails.status)}
                  text={formatHeaderText(orderDetails.status) || ''}
                />
              )}
            </div>
            <div className="min-w-12">
              <div className="mb-2 flex justify-center">
                <Common.Icon className="text-primary" name="prescription-outline" />
              </div>
              {orderDetails.pharmacyStatus && (
                <Common.ColorTag
                  color={getColorMapValue(orderDetails.pharmacyStatus)}
                  text={formatHeaderText(orderDetails.pharmacyStatus) || ''}
                />
              )}
            </div>
            <div className="min-w-12">
              <div className="mb-2 flex justify-center">
                <Common.Icon className="text-primary" name="package" />
              </div>
              {orderDetails.shippingStatus && (
                <Common.ColorTag
                  color={getColorMapValue(orderDetails.shippingStatus)}
                  text={formatHeaderText(orderDetails.shippingStatus) || ''}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
