import { BACKEND_DATE_FORMAT, FRONTEND_DATE_FORMAT } from 'constants/dateFormat';

import { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import ControlledSelect from 'components/common/form/ControlledSelect';
import DateInput from 'components/common/form/DateInput';
import NoAvailableDoctors from 'components/crossSell/NoAvailableDoctors';
import SelectDoctorCard from 'components/crossSell/SelectDoctorCard';
import { DoctorStateProps } from 'components/crossSell/SelectDoctorCard/selectDoctorCard.types';
import dayjs from 'dayjs';
import { useFormContext } from 'react-hook-form';
import {
  useGetMembershipPlansQuery,
  useLazyGetAvailableDoctorsQuery,
  useLazyGetDoctorsQuery,
} from 'store/crossSell/crossSellSlice';
import { parseDateForTodayAndTomorrow } from 'utils/datesAndTime';

const ITEMS_COUNT = 2;

const SelectDoctor: React.FC<{ states: { value: string; label: string }[]; isOptaviaPatient: boolean }> = ({
  states,
  isOptaviaPatient,
}) => {
  const { control, setValue, getValues, watch } = useFormContext();
  const [isFocus, setIsFocus] = useState(false);
  const [doctors, setDoctors] = useState<DoctorStateProps[]>([]);
  const { data: membershipPlans } = useGetMembershipPlansQuery();
  const [getDoctors, { isFetching }] = useLazyGetDoctorsQuery();
  const [getAvailableDoctors, { isFetching: isFetchingAvailable }] = useLazyGetAvailableDoctorsQuery();

  const dateInputRef = useRef<null>(null);
  const divRef = useRef<HTMLDivElement | null>(null);

  const planType = watch('planType');
  const type = watch('type');
  const date = watch('date');
  const doctorState = watch('doctorState');
  const appointmentType = watch('appointmentType');

  const isDisabled = !planType;
  const showNoAvailableMessage = !isDisabled && !!doctorState?.label;

  const startYear = dayjs().toDate();
  const lastYear = dayjs().add(50, 'year').toDate();

  const selectedDate = getValues('date') || dayjs().format(FRONTEND_DATE_FORMAT);
  const appType = appointmentType?.value;

  const customDateInputClasses = classNames(
    isFocus ? 'border-b-primary text-primary' : 'border-b-secondary text-secondary',
    'relative mb-4 w-1/3 py-0.5 mt-1 flex items-center gap-1.5 shadow border border-b-2 rounded-t-md rounded-b-sm',
  );

  useEffect(() => {
    if (!isDisabled) {
      const appointmentDate = date;
      const stateValue = doctorState?.label;

      const plan = membershipPlans?.find((planItem) => planItem._id === planType.plan._id);

      if (stateValue && plan?._id) {
        if (appType) {
          getDoctors({
            planId: plan?._id,
            page: 0,
            itemsCount: ITEMS_COUNT,
            date: dayjs(appointmentDate).format(BACKEND_DATE_FORMAT),
            state: stateValue,
            timezone: dayjs.tz?.guess(),
            isUnlimitedPlan: plan?.isUnlimitedPlan,
            isInitialVisit: true,
            ...(isOptaviaPatient && { onboardingPartnerName: 'Optavia' }),
            ...(appType && { appointmentTypeId: appType }),
          })
            .unwrap()
            .then((res) => setDoctors(res?.data || []));
        } else {
          getAvailableDoctors({
            state: stateValue,
            limit: ITEMS_COUNT,
            pageNo: 0,
            isUnlimitedPlan: plan?.isUnlimitedPlan,
            isInitialVisit: true,
          })
            .unwrap()
            .then((res) => {
              const resDoctors = res?.data || [];
              setDoctors(resDoctors);
              if (!resDoctors.map((doctor) => doctor.userId).includes(getValues('doctorId'))) {
                setValue('doctorId', '');
                setValue('appointmentTime', '');
              }
            });
        }
      }
    }

    // We have to monitor certain parameters through the watch()
  }, [getDoctors, watch, planType, type, date, doctorState, appointmentType]);

  return (
    <div data-testid="select_doc_section" className="flex flex-col items-center">
      <p className="pb-4 text-base text-gray-700">
        Showing doctors for {parseDateForTodayAndTomorrow(selectedDate).toLowerCase()}:
      </p>
      {appType && (
        <div ref={divRef} className={customDateInputClasses}>
          <DateInput
            name="date"
            ref={dateInputRef}
            value={date}
            setValue={setValue}
            labelTitle="Date"
            labelClasses="absolute text-xs top-1.5 left-9 z-[1]"
            inputClasses="w-full pt-1.5 mb-1 pl-9 text-gray-700 focus-visible:outline-none"
            wrapperInputClasses="grow w-full"
            containerClasses="w-full"
            format={FRONTEND_DATE_FORMAT}
            startYear={startYear}
            lastYear={lastYear}
            onFocus={() => setIsFocus(true)}
            onBlur={() => setIsFocus(false)}
          />
        </div>
      )}
      <ControlledSelect
        control={control}
        options={states}
        name="doctorState"
        label="State"
        className="mb-4 hidden w-1/3"
        disabled={isDisabled}
        rules={{
          required: {
            value: true,
            message: 'State is required',
          },
        }}
      />
      {(isFetching || isFetchingAvailable) && <div className="w-full pb-4 text-center">Loading...</div>}
      {appType ? (
        <div className="flex w-full gap-8">
          {!isFetching &&
            (doctors.length
              ? doctors.map((doctorInfo) => <SelectDoctorCard key={doctorInfo.userId} doctor={doctorInfo} />)
              : showNoAvailableMessage && <NoAvailableDoctors />)}
        </div>
      ) : (
        <div className="mt-1.5 pb-4 text-xs text-red">Appointment type is required</div>
      )}
    </div>
  );
};

export default SelectDoctor;
