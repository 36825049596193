import { Editor, IAllProps } from '@tinymce/tinymce-react';

interface MessageEditorProps extends Omit<IAllProps, 'init'> {
  placeholder?: string;
  toolbarOptions?: string;
  minHeight?: number;
  maxHeight?: number;
  autoresizeBottomMargin?: number;
  contentStyle?: string;
}

export const MessageEditor: React.FC<MessageEditorProps> = ({
  onEditorChange,
  value,
  placeholder,
  onKeyDown,
  onFocus,
  onInit,
  toolbarOptions,
  minHeight,
  maxHeight,
  autoresizeBottomMargin,
  contentStyle,
}) => {
  return (
    <Editor
      onEditorChange={onEditorChange}
      value={value}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      onInit={onInit}
      init={{
        apiKey: import.meta.env.VITE_TINYMCE_API_KEY,
        placeholder: placeholder,
        plugins: 'autoresize link lists',
        height: '100%',
        min_height: minHeight || 90,
        max_height: maxHeight || 260,
        autoresize_bottom_margin: autoresizeBottomMargin || 10,
        toolbar: toolbarOptions || false,
        contextmenu: false,
        smart_paste: false,
        menubar: false,
        branding: false,
        statusbar: false,
        content_style:
          contentStyle || 'body { font-family: Greycliff CF,Arial,sans-serif; color: #424647; font-size:14px }',
      }}
    />
  );
};
