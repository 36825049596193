/* NOTE: I need to call some actions in api slice before define them */

import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityId,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import { getImagesFromMessages, groupMessagesByDate } from 'contexts/MessagesContext/messageContext.settings';
import { MessageProps } from 'contexts/MessagesContext/messagesContext.types';
import { MessageType, Status } from 'enums/messages';
import SocketFrontDesk from 'socket/socketFrontDesk';
import SocketMessages from 'socket/socketMessages';
import type { RootState } from 'store';
import { FileItemProps } from 'store/chat/chat.types';
import { selectPatient } from 'store/patients/patientsSlice';
import { getMessageType } from 'utils/helpers';

import { apiSlice } from '../api/apiSlice';
import { updateChannel } from '../channels/channelsSlice';

const messagesAdapter = createEntityAdapter<MessageProps, EntityId>({
  selectId: (message) => message._id,
  sortComparer: (a, b) => b.messageStatus.sent.localeCompare(a.messageStatus.sent),
});

export const chatApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getCareMessages: build.query<
      { messages: MessageProps[]; totalCount: number },
      { channelId: string; limit?: number; pageNo?: number; type: MessageType }
    >({
      query: ({ channelId, limit = 10, pageNo = 0, type }) => {
        const messagesType = type === MessageType.Medical ? 'care-messages' : 'front-desk-messages';
        return {
          url: `channels/${channelId}/${messagesType}`,
          params: {
            limit,
            pageNo,
          },
        };
      },
      async onCacheEntryAdded(arg, { cacheDataLoaded, cacheEntryRemoved, dispatch }) {
        const socket = arg.type === MessageType.Medical ? SocketMessages : SocketFrontDesk;

        // when data is received from the socket connection to the server,
        // update our query result with the received message
        const messageToClientsListener = (newMessage: MessageProps) => {
          // Insert received message from the websocket
          // into the existing RTKQ cache array
          if (newMessage) {
            if (newMessage.channelId === arg.channelId) {
              const update = {
                channelId: newMessage.channelId,
                latestMessage: newMessage.message,
                latestMessageDate: newMessage.messageStatus.sent,
                unreadMessageCount: 0,
              };
              dispatch(addMessage(newMessage));
              dispatch(updateChannel(update));
            }
          }
        };

        const markedSeenListener = (seenMessage: MessageProps) => {
          if (seenMessage) {
            if (seenMessage.channelId === arg.channelId) dispatch(markSeen(seenMessage));
          }
        };

        try {
          // wait for the initial query to resolve before proceeding
          await cacheDataLoaded;

          socket.on('messageToClients', messageToClientsListener);
          socket.on('markedSeen', markedSeenListener);
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
        // cacheEntryRemoved will resolve when the cache subscription is no longer active
        await cacheEntryRemoved;
        // perform cleanup steps once the `cacheEntryRemoved` promise resolve ex. https://redux-toolkit.js.org/rtk-query/usage/streaming-updates#streaming-update-examples
        socket.off('messageToClients', messageToClientsListener);
        socket.off('markedSeen', markedSeenListener);
      },
      keepUnusedDataFor: 0,
      transformResponse: (response: { data: { messages: MessageProps[] }; info: { totalCount: number } }) => {
        return {
          messages: response.data.messages.map((message) => ({ ...message, isBlurred: true })),
          totalCount: response.info.totalCount,
        };
      },
      providesTags: (result) => {
        return result
          ? [
              { type: 'Messages', id: 'LIST' },
              ...result.messages.map(({ _id }) => ({ type: 'Messages' as const, id: _id })),
            ]
          : ['Messages'];
      },
    }),
    updateMessage: build.mutation({
      query: ({ messageId, message, type }) => {
        const messagesType = getMessageType(type);

        const params = {
          url: `/messages/${messageId}?type=${messagesType}`,
          method: 'PATCH',
          body: { message },
        };

        return params;
      },
      invalidatesTags: [{ type: 'Messages', id: 'LIST' }],
    }),
    deleteMessage: build.mutation({
      query: ({ messageId, type }) => {
        const messagesType = getMessageType(type);

        const params = {
          url: `/messages/${messageId}?type=${messagesType}`,
          method: 'DELETE',
        };

        return params;
      },
      invalidatesTags: [{ type: 'Messages', id: 'LIST' }],
    }),
    uploadFile: build.mutation({
      query: (file) => {
        const formData = new FormData();
        formData.append('chatFile', file);
        return {
          url: '/channels/upload-file',
          method: 'POST',
          headers: {
            'API-KEY': import.meta.env.VITE_API_KEY,
          },
          body: formData,
        };
      },
      transformResponse: (response: { data: FileItemProps }) => response.data,
    }),
  }),
});

const initialState: {
  messages: EntityState<MessageProps, EntityId>;
  images: string[];
  loadingMessagesStatus: Status;
  totalCount: number | null;
} = {
  messages: messagesAdapter.getInitialState(),
  loadingMessagesStatus: Status.Idle,
  images: [],
  totalCount: null,
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    addMessage: (state, action: PayloadAction<MessageProps>) => {
      if (action.payload?.fileName && action.payload?.filePath) {
        const newImage = getImagesFromMessages([action.payload]);
        state.images.push(...newImage);
      }
      messagesAdapter.upsertOne(state.messages, { ...action.payload });
      state.totalCount = messagesAdapter.getSelectors().selectTotal(state.messages);
    },
    markSeen: (state, action: PayloadAction<MessageProps>) => {
      const selected = messagesAdapter.getSelectors().selectById(state.messages, action.payload._id);
      if (selected) {
        const update = {
          ...selected,
          receiverDetails: selected.receiverDetails.map((details) => ({
            ...details,
            ...action.payload.receiverDetails.find((message) => details.id === message.id),
          })),
        };

        messagesAdapter.upsertOne(state.messages, update);
      }
    },
    clearMessages: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(chatApi.endpoints.getCareMessages.matchFulfilled, (state, { payload, meta }) => {
      // If the pageNo is 0, then we reset messages, to prevent mix messages from different patients
      if (!meta.arg.originalArgs.pageNo || meta.arg.originalArgs.pageNo === 0) {
        messagesAdapter.setMany(state.messages, payload.messages);
      } else {
        messagesAdapter.upsertMany(state.messages, payload.messages);
      }
      state.images.push(...getImagesFromMessages(payload.messages));
      state.totalCount = payload.totalCount;
      state.loadingMessagesStatus = Status.Fulfilled;
    });
    builder.addMatcher(chatApi.endpoints.getCareMessages.matchPending, (state) => {
      state.loadingMessagesStatus = Status.Pending;
    });
    builder.addMatcher(chatApi.endpoints.getCareMessages.matchRejected, (state) => {
      state.loadingMessagesStatus = Status.Rejected;
    });
  },
});

export const { clearMessages, addMessage, markSeen } = chatSlice.actions;

export const messagesSelectors = messagesAdapter.getSelectors<RootState>((state) => state.chat.messages);

export const selectMessages = createSelector([messagesSelectors.selectAll, selectPatient], (messages, patient) => {
  /**
   * @description
   * Remove messages that are not belong to particular patient.
   * It is possible to receive messages from previous patient because of race conditions
   */
  const filteredMessages = messages.filter(
    (message) =>
      message.receiverDetails.some(
        (details) => details.id === (patient.patientInfo?._id || patient?.documents?.patientId),
      ) || message.senderDetails?.id === (patient.patientInfo?._id || patient?.documents?.patientId),
  );

  return groupMessagesByDate(filteredMessages);
});

export const selectChat = (state: RootState) => state.chat;

export const {
  useUploadFileMutation,
  useLazyGetCareMessagesQuery,
  useDeleteMessageMutation,
  useUpdateMessageMutation,
} = chatApi;

export default chatSlice.reducer;
