import { PricePointProps } from 'components/crossSell/PlanType/planType.types';
import { getRecommendationPlansStatus } from 'components/modals/PlanRecommendation/planRecommendation.settings';
import { PlanRecommendationStatus } from 'enums/planRecommendation';
import upperFirst from 'lodash/upperFirst';
import { PatientPlanRecommendation } from 'store/patients/patients.types';
export const priceInfo = (activePP: PricePointProps, isPlanCard?: boolean) => {
  if (activePP) {
    if (activePP.paymentInterval.qty === 1 && activePP.paymentInterval.units === 'months') {
      return `${activePP.totalCost}/${isPlanCard ? 'mo' : 'month'}`;
    }
    return `${activePP.totalCost}/every ${activePP.paymentInterval.qty} ${isPlanCard ? 'mo' : 'months'}`;
  }
};

export const getPlanRecommendationTitle = (planRecommendations?: PatientPlanRecommendation[]) => {
  const planRecommendationStatus = getRecommendationPlansStatus(planRecommendations);

  switch (planRecommendationStatus) {
    case PlanRecommendationStatus.ACCEPTED:
      return { title: 'Recommend other GLP-1', subTitle: 'Maintenance' };
    case PlanRecommendationStatus.PENDING:
      return { title: 'Edit recommendation', subTitle: 'Maintenance recommended' };
    default:
      return { title: 'Recommend maintenance', subTitle: '' };
  }
};

export const getSubPlanNames = (planRecommendations?: PatientPlanRecommendation[]) => {
  const planRecommendationStatus = getRecommendationPlansStatus(planRecommendations);

  switch (planRecommendationStatus) {
    case PlanRecommendationStatus.ACCEPTED:
      return (
        planRecommendations?.find((plan) => plan.status === PlanRecommendationStatus.ACCEPTED)?.medicationName || ''
      );
    case PlanRecommendationStatus.PENDING:
      return planRecommendations
        ?.map((plan) => upperFirst(plan.medicationName?.toLowerCase().replace('low-dose', '').trim()) || '')
        .join(', ');
    default:
      return '';
  }
};
