import { ChangeEvent } from 'react';

import { FieldValues, useFormContext } from 'react-hook-form';

import PasswordInput from './PasswordInput';
import { ResetPasswordProps } from './ResetPassword.types';

const ResetPassword = <TFieldValues extends FieldValues>({
  wrapperResetPassword = 'flex flex-auto gap-2',
  inputClasses,
  onChange,
  labelClasses,
  isRequired,
}: ResetPasswordProps<TFieldValues>) => {
  const { control, getValues, register, trigger } = useFormContext();
  const newPasswordRegister = register('newPassword', {
    validate: (newPasswordInputValue) => {
      const { oldPassword } = getValues();
      return (
        !oldPassword ||
        !newPasswordInputValue ||
        newPasswordInputValue !== oldPassword ||
        'New password must be different'
      );
    },
  });
  const onChangeOldPassword = async (event: ChangeEvent<HTMLInputElement>) => {
    onChange && onChange({ newPassword: getValues('newPassword'), oldPassword: event.target.value });
    await trigger('newPassword');
  };
  const onChangeNewPassword = (event: ChangeEvent<HTMLInputElement>) => {
    onChange && onChange({ oldPassword: getValues('oldPassword'), newPassword: event.target.value });
  };

  return (
    <div className={wrapperResetPassword}>
      <PasswordInput
        name="oldPassword"
        labelTitle="Old password"
        wrapperClasses="flex-auto"
        control={control}
        onChange={onChangeOldPassword}
        inputClasses={inputClasses}
        labelClasses={labelClasses}
        isRequired={isRequired}
        placeholder="**********"
      />
      <PasswordInput
        {...newPasswordRegister}
        labelTitle="New password"
        wrapperClasses="flex-auto"
        control={control}
        onChange={onChangeNewPassword}
        inputClasses={inputClasses}
        labelClasses={labelClasses}
        isRequired={isRequired}
        placeholder="**********"
      />
    </div>
  );
};

export default ResetPassword;
