import { FormProps } from 'components/patient/Chart/SocialHistory/socialHistory.types';
import { Gender, SexAtBirth } from 'enums/gender';
import { RoughPeriodDate } from 'store/patientChart/patientChart.types';

export const COUNTRY_CODE_US = '+1';

export const SEARCH_USER_TYPE = {
  patient: 'Patient',
  physician: 'Physician',
  staff: 'Staff',
};

export const SEX_AT_BIRTH = [
  { label: 'Male', value: SexAtBirth.Male },
  { label: 'Female', value: SexAtBirth.Female },
  { label: 'Intersex/Other', value: SexAtBirth.Other },
  { label: 'Unknown', value: SexAtBirth.Unknown },
];

export const GENDER_OPTIONS: { label: string; value: Gender }[] = [
  { label: 'Man', value: Gender.Man },
  { label: 'Woman', value: Gender.Woman },
  { label: 'Transgender man / trans masculine', value: Gender.TransgenderMan },
  { label: 'Transgender woman / trans feminine', value: Gender.TransgenderWoman },
  { label: 'Non-binary / genderqueer / gender fluid', value: Gender.NonBinary },
  { label: 'Two spirit', value: Gender.TwoSpirit },
  { label: 'Option not listed', value: Gender.NotListed },
  { label: 'Prefer not to say', value: Gender.PreferToHide },
  { label: 'Unknown', value: Gender.Unknown },
];

export const MEDICAL_HISTORY_FORM_FIELDS = [
  { fieldID: '1', label: 'High blood pressure' },
  { fieldID: '2', label: 'High blood sugar' },
  { fieldID: '3', label: 'High cholesterol' },
  { fieldID: '4', label: 'Joint or back pains' },
  { fieldID: '5', label: 'Overweight / obese' },
  { fieldID: '6', label: 'Recently suffered an injury' },
  { fieldID: '7', label: 'Depression' },
  { fieldID: '8', label: 'Anxiety' },
  { fieldID: '0', label: 'None of the above' },
];

export const CHRONIC_DISEASES_FORM_FIELDS = [
  { fieldID: '1', label: 'Bowel disease' },
  { fieldID: '2', label: 'Cancer' },
  { fieldID: '3', label: 'Cardiovascular disease' },
  { fieldID: '4', label: 'Diabetes' },
  { fieldID: '5', label: 'Endocrine disease' },
  { fieldID: '8', label: 'Kidney disease' },
  { fieldID: '6', label: 'Liver Disease' },
  { fieldID: '7', label: 'Lung disease' },
  { fieldID: '9', label: 'Urinary disease' },
  { fieldID: '10', label: 'Other disease' },
  { fieldID: '0', label: 'None of the above' },
];

export const PERIOD_OPTIONS: { label: string; value: RoughPeriodDate }[] = [
  { label: '1-2 weeks', value: '0' },
  { label: '3-5 weeks', value: '1' },
  { label: '6-9 weeks', value: '2' },
  { label: '2-3 months', value: '3' },
  { label: '4 months or more', value: '4' },
];

export const SOCIAL_HISTORY_FORM_FIELDS: FormProps = [
  {
    additional: {
      label: 'How often do you smoke?',
      options: [
        { label: 'I used to smoke', value: '0' },
        { label: 'I currently smoke some days', value: '1' },
        { label: 'I currently smoke every day', value: '2' },
      ],
    },
    id: '1',
    label: 'Cigarettes',
    type: 'select',
  },
  {
    additional: {
      label: 'How often do you vape?',
      options: [
        { label: 'I used to vape', value: '0' },
        { label: 'I currently vape some days', value: '1' },
        { label: 'I currently vape every day', value: '2' },
      ],
    },
    id: '2',
    label: 'Vape',
    type: 'select',
  },
  {
    additional: {
      label: 'How often do you drink?',
      options: [
        { label: 'I used to drink', value: '0' },
        { label: 'I currently drink some days', value: '1' },
        { label: 'I currently drink every day', value: '2' },
      ],
    },
    id: '3',
    label: 'Alcohol',
    type: 'select',
  },
  {
    additional: {
      label: 'What recreational drugs have you used?',
      placeholder: 'e.g. marijuana, cocaine, heroin, amphetamines...',
    },
    id: '4',
    label: 'Recreational drugs',
    type: 'textarea',
  },
  {
    id: '5',
    label: 'I’m sexually active',
    type: 'checkbox',
  },
  {
    id: '0',
    label: 'None of the above',
    type: 'checkbox',
  },
];
