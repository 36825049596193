import { Common } from '@thecvlb/design-system/lib/src';
import { Role } from 'enums/role';
import { useAppSelector } from 'hooks/redux';
import capitalize from 'lodash/capitalize';
import { selectUser } from 'store/user/userSlice';
import { stripHTML } from 'utils/common/parseHTML';

import { EventDetailsProps } from './eventDetails.types';

const EventDetails: React.FC<EventDetailsProps> = ({ eventLog }) => {
  const { userType } = useAppSelector(selectUser);
  const isAdmin = userType?.name === Role.AD;

  const goToTwilio = (id: string) => {
    const link = `https://console.twilio.com/us1/monitor/insights/video/video-rooms/${id}/about`;
    return () => window.open(link, '_blank');
  };

  const showJoinCallStates = (joinCallData?: { video?: string; audio?: string }) => {
    if (joinCallData) {
      return (
        <>
          <div className="flex gap-2 text-sm font-semibold text-gray">
            {`Video `}
            <Common.Icon
              className="size-4 text-gray-600"
              name={joinCallData?.video === 'disabled' ? 'close' : 'check'}
            />
          </div>
          <div className="flex gap-2 text-sm font-semibold text-gray">
            {`Audio `}
            <Common.Icon
              className="size-4 text-gray-600"
              name={joinCallData?.audio === 'disabled' ? 'close' : 'check'}
            />
          </div>
        </>
      );
    }
  };

  if (eventLog?.twilioRoomSid) {
    return (
      <div className="flex w-full justify-between">
        <div>
          <div className="text-sm font-semibold">{eventLog.eventDetails}</div>
          <div className="flex gap-8">
            <div className="w-[250px] text-sm font-semibold text-gray">{`SID: ${eventLog.twilioRoomSid}`}</div>
            {eventLog?.platform && (
              <div className="w-[140px] text-sm font-semibold text-gray">{`${capitalize(
                eventLog.platform,
              )} platform`}</div>
            )}
            {showJoinCallStates(eventLog?.patientJoinCall)}
            {showJoinCallStates(eventLog?.providerJoinCall)}
          </div>
        </div>
        {isAdmin && (
          <div>
            <Common.Button size="sm" color="white" onClick={goToTwilio(eventLog.twilioRoomSid)}>
              Go to Twilio
            </Common.Button>
          </div>
        )}
      </div>
    );
  }

  if (eventLog?.zoomRoomSid) {
    return (
      <div className="flex w-full justify-between">
        <div>
          <div className="text-sm font-semibold">{eventLog.eventDetails}</div>
          <div className="flex gap-8">
            <div className="text-sm font-semibold text-gray">{`Zoom SID: ${eventLog.zoomRoomSid}`}</div>
            {eventLog?.platform && (
              <div className="text-sm font-semibold text-gray">{`${capitalize(eventLog.platform)} platform`}</div>
            )}
            {showJoinCallStates(eventLog?.patientJoinCall)}
            {showJoinCallStates(eventLog?.providerJoinCall)}
          </div>
        </div>
        {/* TODO: Add the button to go to Zoom */}
      </div>
    );
  }

  return <span className="font-semibold">{eventLog?.eventDetails && stripHTML(eventLog?.eventDetails)}</span>;
};

export default EventDetails;
