import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import classNames from 'classnames';

import PhysicianSelect from './PhysicianSelect';
import ProviderAvailability from '../ProviderAvailability';

const StaffAvailability = () => {
  const getTabClassNames = (active: boolean) =>
    classNames('text-gray text-sm font-semibold py-2 px-4', {
      'text-secondary bg-secondary-100 rounded-full pointer-events-none': active,
    });

  return (
    <div className="pb-6">
      <TabGroup>
        <TabList className="mb-6 flex items-center justify-between px-6">
          <Tab className={({ selected }) => getTabClassNames(selected)}>Staff availability</Tab>
          <PhysicianSelect />
        </TabList>
        <TabPanels>
          <TabPanel>
            <ProviderAvailability />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </div>
  );
};

export default StaffAvailability;
