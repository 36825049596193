import { useCallback, useEffect, useState } from 'react';

import { ErrorMessage } from '@hookform/error-message';
import * as RadioGroup from '@radix-ui/react-radio-group';
import { Common } from '@thecvlb/design-system';
import DatePickerInput from 'components/common/DatePickerInput';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { useFormContext } from 'react-hook-form';

import { EndsProps } from './ends.types';

const indicatorClasses =
  'flex h-full w-full items-center justify-center after:h-1.5 after:w-1.5 after:rounded-full after:bg-white';

const Ends: React.FC<EndsProps> = ({ errors }) => {
  const { watch, setValue, setError, clearErrors, register } = useFormContext();
  const [inputValue, setInputValue] = useState<string>(dayjs(watch('ends.date')).format(DateFormat.MM_DD_YYYY));

  const getRadioItemClasses = (value: string) => {
    return `h-4 w-4 rounded-full border ${
      watch('ends.option') === value ? 'border-blue bg-blue' : 'border-gray-400 bg-white'
    }`;
  };

  const getLabelClasses = (value: string) => {
    return `text-base  text-gray-700 cursor-pointer ${watch('ends.option') === value ? 'font-bold' : 'font-medium'}`;
  };

  const handleSelectedDate = useCallback(
    (date?: Date) => {
      if (dayjs(date).isValid())
        //This was done for the form trigger, but with the condition that the field was actually changed
        setValue('ends.date', date, { shouldValidate: true, shouldDirty: !dayjs(date).isSame(dayjs(inputValue)) });
    },
    [inputValue, setValue],
  );

  useEffect(() => {
    const subscription = watch((value) => {
      if (value.ends?.option === 'on' && dayjs(value.ends.date).isBefore(dayjs(value.shiftStart?.day), 'day')) {
        setError('ends.date', { message: 'Ends "on" should be after the start date' });
      } else {
        clearErrors('ends.date');
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setError, clearErrors]);

  return (
    <div className="mb-6 border-b border-gray-200 pb-4">
      <p className="mb-2 text-base font-semibold">Ends</p>
      <RadioGroup.Root
        defaultValue="never"
        value={watch('ends.option')}
        onValueChange={(value) =>
          setValue('ends.option', value, { shouldValidate: true, shouldDirty: true, shouldTouch: true })
        }
        className="flex flex-col items-start gap-1"
      >
        <div className="flex h-[32px] items-center gap-2">
          <RadioGroup.Item value="never" className={getRadioItemClasses('never')} id="never">
            <RadioGroup.Indicator className={indicatorClasses} />
          </RadioGroup.Item>
          <label className={getLabelClasses('never')} htmlFor="never">
            Never
          </label>
        </div>
        <div className="mb-2 flex items-center gap-5">
          <div className="flex items-center gap-2">
            <RadioGroup.Item value="on" className={getRadioItemClasses('on')} id="on">
              <RadioGroup.Indicator className={indicatorClasses} />
            </RadioGroup.Item>
            <label className={getLabelClasses('on')} htmlFor="on">
              On
            </label>
          </div>
          <div className="w-[250px]">
            <DatePickerInput
              captionLayout="dropdown"
              inputValue={inputValue}
              setInputValue={setInputValue}
              selectedDate={watch('ends.date')}
              setSelectedDate={handleSelectedDate}
              startAllowedDate={new Date()}
              disabled={watch('ends.option') !== 'on'}
              size="sm"
            />

            <ErrorMessage
              errors={errors}
              name="ends.date"
              render={({ message }) => (
                <p className="mt-2 text-sm text-red-500" data-testid="ends-date-error">
                  {message}
                </p>
              )}
            />
          </div>
        </div>
        <div className="flex items-center gap-2">
          <RadioGroup.Item value="after" className={getRadioItemClasses('after')} id="after">
            <RadioGroup.Indicator className={indicatorClasses} />
          </RadioGroup.Item>
          <label className={getLabelClasses('after')} htmlFor="after">
            After
          </label>
          <div className="w-[250px]">
            <Common.Input
              type="number"
              placeholder="Add occurrences"
              disabled={watch('ends.option') !== 'after'}
              size="sm"
              {...register('ends.count', {
                valueAsNumber: true,
                validate: (value) => {
                  if (watch('ends.option') === 'after')
                    return (
                      (value !== undefined && Number.isInteger(value) && value > 0) || 'Please enter a valid number'
                    );
                },
              })}
            />
          </div>
        </div>
      </RadioGroup.Root>
    </div>
  );
};

export default Ends;
