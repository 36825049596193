import { DOC_EXTENSIONS, VIDEO_EXTENSIONS } from 'constants/extensions';

import { notifyError } from 'components/common/Toast/Toast';

export const handleProcessFile = (
  newFile: File,
  onChange: (file: File | null, preview: string | ArrayBuffer | null) => void,
) => {
  if (!newFile) return;

  const reader = new FileReader();
  reader.onloadend = () => {
    const extensionIndex = newFile.name.lastIndexOf('.');
    const fileExtension = newFile.name.substring(extensionIndex).toLowerCase();
    if (fileExtension === '.heic' || fileExtension === '.heif') {
      notifyError(`We aren't support ${fileExtension} format please chose supported one`);
    } else {
      const preview = DOC_EXTENSIONS.includes(fileExtension)
        ? 'document'
        : VIDEO_EXTENSIONS.includes(fileExtension)
          ? 'video'
          : reader.result;
      onChange(newFile, preview);
    }
  };
  reader.readAsDataURL(newFile);
};
